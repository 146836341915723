import { css, Global } from '@emotion/react'
import { Trans } from '@lingui/macro'
import { useEffect } from 'react'

import { Warning } from './SRSCartCleanup'
import { useLogOut } from '../account-dashboard-page/AccountDashboardPageLayout/logout/useLogOut'
import Button from '../input/Button'
import { getShopIdFromCookie } from '../layout/Header/TopBar/ShopIdTopBarBanner'
import Heading from '../typography/Heading'
import useInactivity from '../utils/useInactivity'

const SRSSessionCleanup = () => {
    const shopId = getShopIdFromCookie()
    const isAlmostInactive = useInactivity(4 * 60 * 1000, false)
    const isInactive = useInactivity(5 * 60 * 1000, true)

    const logout = useLogOut()

    useEffect(() => {
        if (isInactive && shopId !== undefined) {
            logout()
        }
    }, [isInactive, shopId, logout])

    if (shopId === undefined) {
        return null
    }

    if (isAlmostInactive && !isInactive) {
        return (
            <Global
                styles={css`
                    body {
                        transition: ease 5s filter;
                        filter: grayscale(100%) blur(5px);
                    }
                `}
            />
        )
    }

    if (!isInactive) {
        return null
    }

    return (
        <Warning>
            <Heading variant="h1" element="h2" color="light">
                <Trans id="srs.sessionCleanup.title">Warning</Trans>
            </Heading>
            <p>
                <Trans id="srs.sessionCleanup.explanation">
                    You have been logged out to protect your privacy.
                </Trans>
            </p>
            <div>
                <Button
                    minWidth
                    category="srs.session-cleanup"
                    name="leave"
                    size="large"
                    onClick={() => (window.location.href = '/')}
                >
                    <Trans id="srs.sessionCleanup.acknowledge">
                        Acknowledge
                    </Trans>
                </Button>
            </div>
        </Warning>
    )
}

export default SRSSessionCleanup
