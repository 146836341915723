import { Picture } from '@emico-react/image'
import styled from '@emotion/styled'
import cx from 'classnames'
import { ReactNode } from 'react'

import { useBreakpoints } from '@emico/ui'

import styles from './BannerBlocks.module.scss'
import Carousel from './behaviour/Carousel'
import Button from './input/Button'
import Col from './layout/Col'
import Container from './layout/Container'
import Row from './layout/Row'
import getLayoutStyleVars, { LayoutStyle } from './LayoutStyle'
import Link, { LinkVariant } from './navigation/Link'
import theme from './theme'
import breakpoints from './theme/breakpoints'
import ButtonVariant from './types/ButtonVariant'
import Heading from './typography/Heading'

export interface BannerBlocksItem {
    image?: {
        url: string
        alt?: string
    }
    title?: string
    url?: string
    subtitle?: string
    buttonText?: string
    mobileImage?: {
        url: string
        alt?: string
    }
    children?: ReactNode
}
// Sizes are dependend on the slidesToShowXX settings
// The slidesToShowXX setting determines the size of the card,
// and therefore also the size of the image.
// Sizes entered here are the max size a card can have for a certain viewport.

// regular measured at 1920, as the component reaches max width then.
// medium measured at 991 as this is the max width before going desktop (at 992)
// small measured at 767 as this is the max width before going tablet (at 768)
const imageSize = {
    4: {
        regularWidth: 452,
        regularHeight: 452,
        mediumWidth: 509,
        mediumHeight: 509,
        smallWidth: 407,
        smallHeight: 407,
    },
    3: {
        regularWidth: 610,
        regularHeight: 610,
        mediumWidth: 660,
        mediumHeight: 660,
        smallWidth: 528,
        smallHeight: 528,
    },
    2: {
        // for regular, the size is actually 924 but we reuse the slightly larger one
        // so we get more imaginary cache hits
        regularWidth: 931,
        regularHeight: 931,
        mediumWidth: 931,
        mediumHeight: 931,
        smallWidth: 747,
        smallHeight: 747,
    },
}

export interface Props {
    title: string
    style: LayoutStyle
    className?: string
    bannerBlocks: BannerBlocksItem[]
    amount?: number
    buttonVariant?: ButtonVariant
    linkVariant?: LinkVariant
    slidesToShow?: number
}

const Section = styled.section`
    background: var(--background-override, ${theme.colors.background});

    // Prevent overflow
    max-width: 100%;
    overflow: hidden;
`

const renderItems = (
    items: BannerBlocksItem[],
    isCarousel: boolean,
    amount: number,
    buttonVariant: ButtonVariant,
    className?: string,
    linkVariant?: LinkVariant,
) =>
    items.map(
        ({
            image,
            title,
            url,
            subtitle,
            buttonText,
            mobileImage,
            children,
        }) => {
            let size = imageSize[2]

            switch (amount) {
                case 4:
                    size = imageSize[4]
                    break
                case 3:
                    size = imageSize[3]
                    break
            }
            const article = (
                <article
                    className={cx(styles.slide, className)}
                    key={`${title}${subtitle}`}
                >
                    <Picture
                        lazy
                        breakpoints={{
                            [breakpoints.lg]: {
                                width: size.regularWidth,
                                height: size.regularHeight,
                                url: image?.url ?? mobileImage?.url ?? '',
                                params: {
                                    resizingType: 'fill-down',
                                },
                            },
                            [breakpoints.md]: {
                                width: size.mediumWidth,
                                height: size.mediumHeight,
                                url: image?.url ?? mobileImage?.url ?? '',
                                params: {
                                    resizingType: 'fill-down',
                                },
                            },
                            [breakpoints.xs]: {
                                width: size.smallWidth,
                                height: size.smallHeight,
                                url: mobileImage?.url ?? image?.url ?? '',
                                params: {
                                    resizingType: 'fill-down',
                                },
                            },
                        }}
                        params={{
                            resizingType: 'fill-down',
                            gravity: 'sm',
                        }}
                        alt={mobileImage?.alt ?? image?.alt ?? title ?? ''}
                        className={
                            isCarousel ? styles.slideImage : styles.singleImage
                        }
                    />

                    <span className={styles.slideContent}>
                        {title && (
                            <Heading
                                className={styles.slideTitle}
                                color="light"
                                variant="h1"
                                element="h1"
                            >
                                {title}
                            </Heading>
                        )}
                        {subtitle && (
                            <Heading
                                className={styles.slideSubTitle}
                                color="light"
                                variant="h4"
                                element="span"
                            >
                                {subtitle}
                            </Heading>
                        )}
                        {buttonText && (
                            <Button
                                minWidth
                                variant={buttonVariant}
                                name="Edit"
                                category="bannerblocks.block"
                                className={styles.slideButton}
                            >
                                {buttonText}
                            </Button>
                        )}
                    </span>
                </article>
            )

            return url ? (
                <>
                    <Link
                        name={title ?? image?.alt ?? ''}
                        category="bannerblocks.item"
                        to={url}
                        title={title ?? image?.alt ?? ''}
                        key={url + title}
                        preload
                        variant={linkVariant}
                    >
                        {article}
                        {children}
                    </Link>
                </>
            ) : (
                <>
                    {article}
                    {children}
                </>
            )
        },
    )

export const BannerBlocks = ({
    buttonVariant = ButtonVariant.primary,
    bannerBlocks,
    title,
    amount,
    className,
    linkVariant,
    slidesToShow,
    style,
}: Props) => {
    const { isDesktop } = useBreakpoints()
    const layoutStyle = getLayoutStyleVars(style)
    const itemlayoutStyle = getLayoutStyleVars('dark')

    // Filter blocks based on images supplied
    // On mobile, mobile image should be present.
    // Portrait tablet is considered mobile,
    // Landscape tablet is considered desktop
    const filteredBannerBlocks = bannerBlocks.filter((block) =>
        isDesktop ? Boolean(block.image?.url) : Boolean(block.mobileImage?.url),
    )

    if (!filteredBannerBlocks || filteredBannerBlocks.length !== amount) {
        return null
    }

    const isCarousel = amount !== 2 || isDesktop

    let slidesToShowSm = 1.2
    let slidesToShowMd = 1.4

    if (amount === 4) {
        slidesToShowSm = 1.6
        slidesToShowMd = 1.8
    }

    const slidesToShowLg = isDesktop ? amount : slidesToShowMd

    return (
        <Section css={layoutStyle}>
            <Container
                className={cx(styles.container, {
                    [styles.four]: amount === 4,
                    [styles.three]: amount === 3,
                    [styles.two]: amount === 2,
                })}
            >
                {title && (
                    <Heading
                        className={styles.heading}
                        variant="h2"
                        element="h2"
                        fuss={['pme']}
                    >
                        {title}
                    </Heading>
                )}
                <Row className={styles.row} noGutters>
                    <Col className={styles.carousel} css={itemlayoutStyle}>
                        {isCarousel && (
                            <Carousel
                                settings={{
                                    arrows: true,
                                    infinite: false,
                                    slidesToShow: slidesToShow ?? amount,
                                    slidesToShowSm,
                                    slidesToShowMd,
                                    slidesToShowLg,
                                    speed: 300,
                                    cssEase: 'cubic-bezier(0.25, 0.1, 0.25, 1)',
                                }}
                            >
                                {renderItems(
                                    filteredBannerBlocks,
                                    isCarousel,
                                    amount,
                                    buttonVariant,
                                    className,
                                    linkVariant,
                                )}
                            </Carousel>
                        )}
                        {!isCarousel &&
                            renderItems(
                                filteredBannerBlocks,
                                isCarousel,
                                amount,
                                buttonVariant,
                                className,
                                linkVariant,
                            )}
                    </Col>
                </Row>
            </Container>
        </Section>
    )
}

export default BannerBlocks
