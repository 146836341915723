export interface PossibleTypesResultData {
    possibleTypes: {
        [key: string]: string[]
    }
}
const result: PossibleTypesResultData = {
    possibleTypes: {
        CmsPageInterface: [
            'CmsPageFaq',
            'CmsPageHomepage',
            'CmsPageHomepageJbfo',
            'CmsPageHomepageJustbrands',
            'CmsPagePlain',
            'CmsPageStoriesOverview',
            'CmsPageStoryArticle',
            'CmsPageStoryArticleCastIron',
            'CmsPageStoryArticleVanguard',
        ],
        CustomAttributeMetadataInterface: [
            'AttributeMetadata',
            'CatalogAttributeMetadata',
            'CustomerAttributeMetadata',
        ],
        CustomAttributeOptionInterface: ['AttributeOptionMetadata'],
        CartAddressInterface: ['BillingCartAddress', 'ShippingCartAddress'],
        ProductInterface: [
            'BundleProduct',
            'ConfigurableProduct',
            'SimpleProduct',
            'GroupedProduct',
            'VirtualProduct',
        ],
        CategoryInterface: ['CategoryTree'],
        AttributeValueInterface: ['AttributeSelectedOptions', 'AttributeValue'],
        MediaGalleryInterface: ['ProductImage', 'ProductVideo'],
        ProductLinksInterface: ['ProductLinks'],
        CartItemInterface: [
            'BundleCartItem',
            'ConfigurableCartItem',
            'SimpleCartItem',
            'VirtualCartItem',
        ],
        RoutableInterface: [
            'CategoryTree',
            'CmsPage',
            'BundleProduct',
            'ConfigurableProduct',
            'SimpleProduct',
            'GroupedProduct',
            'RoutableUrl',
            'VirtualProduct',
        ],
        CreditMemoItemInterface: ['BundleCreditMemoItem', 'CreditMemoItem'],
        OrderItemInterface: ['BundleOrderItem', 'OrderItem'],
        InvoiceItemInterface: ['BundleInvoiceItem', 'InvoiceItem'],
        ShipmentItemInterface: ['BundleShipmentItem', 'ShipmentItem'],
        WishlistItemInterface: [
            'BundleWishlistItem',
            'ConfigurableWishlistItem',
            'GroupedProductWishlistItem',
            'SimpleWishlistItem',
            'VirtualWishlistItem',
        ],
        AggregationOptionInterface: ['AggregationOption'],
        LayerFilterItemInterface: ['LayerFilterItem', 'SwatchLayerFilterItem'],
        Document: [
            'HeroBanner',
            'StorePage',
            'SimpleForm',
            'MonstersaleLoginPage',
            'LoyaltyCard',
            'PmeOutletFaqPage',
            'VanguardTag',
            'SearchPage',
            'FooterMonstersale',
            'MonstersalePage',
            'FooterJustbrands',
            'StorelocatorPage',
            'MemberUsp',
            'VanguardFaqPage',
            'JustbrandsTag',
            'PlpMemberUsps',
            'FooterPmeoutlet',
            'FaqPage',
            'Tag',
            'JbfoFaqPage',
            'CastironFaqPage',
            'Page',
            'JustbrandsPage',
            'VisualFilter',
            'JustbrandsFaqPage',
            'Promoinfo',
            'FooterJbfo',
            'PmeOutletPage',
            'FooterVanguard',
            'CategoryBanners',
            'PmeOutletLoginPage',
            'JbfoPage',
            'VanguardPage',
            'Usps',
            'JustBrandsLogin',
            'MemberUsps',
            'CastironPage',
            'Category',
            'GuidedSellingPage',
            'PrivilegesUsps',
            'PsRegistrationBox',
            'PrivilegesPerks',
            'CastironTag',
            'MonstersaleFaqPage',
            'FooterCastiron',
            'QueuePage',
            'FooterPme',
            'JbfoLoginPage',
            'PrivilegesPage',
        ],
        Linkable: [
            'HeroBanner',
            'StorePage',
            'SimpleForm',
            'MonstersaleLoginPage',
            'LoyaltyCard',
            'PmeOutletFaqPage',
            'VanguardTag',
            'SearchPage',
            'FooterMonstersale',
            'MonstersalePage',
            'FooterJustbrands',
            'StorelocatorPage',
            'MemberUsp',
            'VanguardFaqPage',
            'JustbrandsTag',
            'PlpMemberUsps',
            'FooterPmeoutlet',
            'FaqPage',
            'Tag',
            'JbfoFaqPage',
            'CastironFaqPage',
            'Page',
            'JustbrandsPage',
            'VisualFilter',
            'JustbrandsFaqPage',
            'Promoinfo',
            'FooterJbfo',
            'PmeOutletPage',
            'FooterVanguard',
            'CategoryBanners',
            'PmeOutletLoginPage',
            'JbfoPage',
            'VanguardPage',
            'Usps',
            'JustBrandsLogin',
            'MemberUsps',
            'CastironPage',
            'Category',
            'GuidedSellingPage',
            'PrivilegesUsps',
            'PsRegistrationBox',
            'PrivilegesPerks',
            'CastironTag',
            'MonstersaleFaqPage',
            'FooterCastiron',
            'QueuePage',
            'FooterPme',
            'JbfoLoginPage',
            'PrivilegesPage',
            'ExternalLink',
            'FileLink',
            'ImageLink',
        ],
        StorePageBody: [
            'StorePageBodyTextOnly',
            'StorePageBodyImageOnly',
            'StorePageBodyTextWithMedia',
            'StorePageBodyHtml',
            'StorePageBodyHero',
            'StorePageBodyStoreinfo',
            'StorePageBodyBannerBlocks2',
            'StorePageBodyBannerBlocks',
            'StorePageBodyBannerBlocks1',
        ],
        MonstersaleLoginPageBody: ['MonstersaleLoginPageBodyFaqSubject'],
        PmeOutletFaqPageBody: [
            'PmeOutletFaqPageBodyHero',
            'PmeOutletFaqPageBodyFaqSubject',
        ],
        SearchPageBody: ['SearchPageBodyCategoryList'],
        FooterMonstersaleBody: [
            'FooterMonstersaleBodyCategoryList',
            'FooterMonstersaleBodyServiceContact',
        ],
        MonstersalePageBody: [
            'MonstersalePageBodyHero',
            'MonstersalePageBodyTextOnly',
            'MonstersalePageBodyTextWithMedia',
            'MonstersalePageBodyTweakwiseSlider',
            'MonstersalePageBodyTileSlider',
            'MonstersalePageBodyImageOnly',
            'MonstersalePageBodyHtml',
            'MonstersalePageBodyCategoryElement',
            'MonstersalePageBodyProductVideoHero',
            'MonstersalePageBodyFaq',
            'MonstersalePageBodyBannerBlocks',
            'MonstersalePageBodyBannerBlocks1',
            'MonstersalePageBodyBannerBlocks2',
            'MonstersalePageBodyCategoryList',
        ],
        FooterJustbrandsBody: [
            'FooterJustbrandsBodyCategoryList',
            'FooterJustbrandsBodyServiceContact',
        ],
        StorelocatorPageBody: [
            'StorelocatorPageBodyProductHero',
            'StorelocatorPageBodyTweakwiseSlider',
            'StorelocatorPageBodyImageSlider',
            'StorelocatorPageBodyHero',
            'StorelocatorPageBodyTextOnly',
            'StorelocatorPageBodyImageOnly',
            'StorelocatorPageBodyTextWithMedia',
            'StorelocatorPageBodyHtml',
            'StorelocatorPageBodyCategoryElement',
            'StorelocatorPageBodyFaq',
            'StorelocatorPageBodyBannerBlocks',
            'StorelocatorPageBodyBannerBlocks1',
            'StorelocatorPageBodyBannerBlocks2',
            'StorelocatorPageBodyCategoryList',
        ],
        VanguardFaqPageBody: [
            'VanguardFaqPageBodyHero',
            'VanguardFaqPageBodyFaqSubject',
        ],
        FooterPmeoutletBody: [
            'FooterPmeoutletBodyCategoryList',
            'FooterPmeoutletBodyServiceContact',
        ],
        FaqPageBody: ['FaqPageBodyHero', 'FaqPageBodyFaqSubject'],
        JbfoFaqPageBody: ['JbfoFaqPageBodyHero', 'JbfoFaqPageBodyFaqSubject'],
        CastironFaqPageBody: [
            'CastironFaqPageBodyHero',
            'CastironFaqPageBodyFaqSubject',
        ],
        PageBody: [
            'PageBodyProductHero',
            'PageBodyTweakwiseSlider',
            'PageBodyImageSlider',
            'PageBodyHero',
            'PageBodyTextOnly',
            'PageBodyImageOnly',
            'PageBodyTextWithMedia',
            'PageBodyHtml',
            'PageBodyCategoryElement',
            'PageBodyFaq',
            'PageBodyBannerBlocks',
            'PageBodyBannerBlocks1',
            'PageBodyBannerBlocks2',
            'PageBodyCategoryList',
            'PageBodyStoriesOverviewHeader',
            'PageBodyStoryOverviewPage',
            'PageBodyMemberUspS',
            'PageBodyVisualFilter',
            'PageBodyBecomeMemberUsp',
            'PageBodyFaqSubject',
            'PageBodyForm',
            'PageBodyBlog',
        ],
        JustbrandsPageBody: [
            'JustbrandsPageBodyProductHero',
            'JustbrandsPageBodyTweakwiseSlider',
            'JustbrandsPageBodyImageSlider',
            'JustbrandsPageBodyHero',
            'JustbrandsPageBodyTextOnly',
            'JustbrandsPageBodyImageOnly',
            'JustbrandsPageBodyTextWithMedia',
            'JustbrandsPageBodyHtml',
            'JustbrandsPageBodyBrand',
            'JustbrandsPageBodyTeam',
            'JustbrandsPageBodyStore',
            'JustbrandsPageBodyCategoryElement',
            'JustbrandsPageBodyFaq',
            'JustbrandsPageBodyBannerBlocks',
            'JustbrandsPageBodyBannerBlocks1',
            'JustbrandsPageBodyBannerBlocks2',
            'JustbrandsPageBodyCategoryList',
            'JustbrandsPageBodyStoriesOverviewHeader',
            'JustbrandsPageBodyStoryOverviewPage',
            'JustbrandsPageBodyVisualFilter',
            'JustbrandsPageBodyForm',
            'JustbrandsPageBodyBlog',
        ],
        JustbrandsFaqPageBody: [
            'JustbrandsFaqPageBodyHero',
            'JustbrandsFaqPageBodyFaqSubject',
        ],
        FooterJbfoBody: [
            'FooterJbfoBodyCategoryList',
            'FooterJbfoBodyServiceContact',
        ],
        PmeOutletPageBody: [
            'PmeOutletPageBodyProductHero',
            'PmeOutletPageBodyTweakwiseSlider',
            'PmeOutletPageBodyImageSlider',
            'PmeOutletPageBodyHero',
            'PmeOutletPageBodyTextOnly',
            'PmeOutletPageBodyImageOnly',
            'PmeOutletPageBodyTextWithMedia',
            'PmeOutletPageBodyHtml',
            'PmeOutletPageBodyCategoryElement',
            'PmeOutletPageBodyBannerBlocks',
            'PmeOutletPageBodyBannerBlocks1',
            'PmeOutletPageBodyBannerBlocks2',
            'PmeOutletPageBodyFaq',
            'PmeOutletPageBodyCategoryList',
        ],
        FooterVanguardBody: [
            'FooterVanguardBodyCategoryList',
            'FooterVanguardBodyServiceContact',
        ],
        CategoryBannersBody: [
            'CategoryBannersBodyFreeGifts',
            'CategoryBannersBodyVouchers',
            'CategoryBannersBodyBecomeMemberUsp',
        ],
        PmeOutletLoginPageBody: ['PmeOutletLoginPageBodyFaqSubject'],
        JbfoPageBody: [
            'JbfoPageBodyHero',
            'JbfoPageBodyTextWithMedia',
            'JbfoPageBodyTextOnly',
            'JbfoPageBodyImageOnly',
            'JbfoPageBodyTileSlider',
            'JbfoPageBodyTweakwiseSlider',
            'JbfoPageBodyBrand',
            'JbfoPageBodyTeam',
            'JbfoPageBodyStore',
            'JbfoPageBodyHtml',
            'JbfoPageBodyCategoryElement',
            'JbfoPageBodyProductVideoHero',
            'JbfoPageBodyFaq',
            'JbfoPageBodyBannerBlocks',
            'JbfoPageBodyBannerBlocks1',
            'JbfoPageBodyBannerBlocks2',
            'JbfoPageBodyCategoryList',
            'JbfoPageBodyBlog',
        ],
        VanguardPageBody: [
            'VanguardPageBodyProductHero',
            'VanguardPageBodyTweakwiseSlider',
            'VanguardPageBodyImageSlider',
            'VanguardPageBodyHero',
            'VanguardPageBodyTextOnly',
            'VanguardPageBodyImageOnly',
            'VanguardPageBodyTextWithMedia',
            'VanguardPageBodyTwoimageswithheading',
            'VanguardPageBodyHtml',
            'VanguardPageBodyCategoryElement',
            'VanguardPageBodyFaq',
            'VanguardPageBodyBannerBlocks',
            'VanguardPageBodyBannerBlocks1',
            'VanguardPageBodyBannerBlocks2',
            'VanguardPageBodyCategoryList',
            'VanguardPageBodyStoriesOverviewHeader',
            'VanguardPageBodyStoryOverviewPage',
            'VanguardPageBodyVisualFilter',
            'VanguardPageBodyBlog',
        ],
        CastironPageBody: [
            'CastironPageBodyProductHero',
            'CastironPageBodyTweakwiseSlider',
            'CastironPageBodyImageSlider',
            'CastironPageBodyHero',
            'CastironPageBodyTextOnly',
            'CastironPageBodyImageOnly',
            'CastironPageBodyTextWithMedia',
            'CastironPageBodyOverlappingImages',
            'CastironPageBodyHtml',
            'CastironPageBodyCategoryElement',
            'CastironPageBodyFaq',
            'CastironPageBodyBannerBlocks',
            'CastironPageBodyBannerBlocks1',
            'CastironPageBodyBannerBlocks2',
            'CastironPageBodyCategoryList',
            'CastironPageBodyStoriesOverviewHeader',
            'CastironPageBodyStoryOverviewPage',
            'CastironPageBodyVisualFilter',
            'CastironPageBodyBlog',
        ],
        CategoryBody: [
            'CategoryBodyImageOnlyBlock',
            'CategoryBodyTextOnly',
            'CategoryBodyNewsletter',
            'CategoryBodyEndOfContent',
            'CategoryBodySeoFooter',
            'CategoryBodySeoHeader',
            'CategoryBodyTopBanner',
            'CategoryBodyPromoCtaBanner',
            'CategoryBodyLoyaltyCard',
        ],
        PsRegistrationBoxBody: ['PsRegistrationBoxBodyUsp'],
        MonstersaleFaqPageBody: [
            'MonstersaleFaqPageBodyHero',
            'MonstersaleFaqPageBodyFaqSubject',
        ],
        FooterCastironBody: [
            'FooterCastironBodyCategoryList',
            'FooterCastironBodyServiceContact',
        ],
        FooterPmeBody: [
            'FooterPmeBodyCategoryList',
            'FooterPmeBodyServiceContact',
        ],
        JbfoLoginPageBody: ['JbfoLoginPageBodyFaqSubject'],
        PrivilegesPageBody: [
            'PrivilegesPageBodyPerks',
            'PrivilegesPageBodyPromoUsps',
            'PrivilegesPageBodyHero',
            'PrivilegesPageBodyFaqSubject',
            'PrivilegesPageBodyFooter',
        ],
        AttributeSelectedOptionInterface: ['AttributeSelectedOption'],
        PhysicalProductInterface: [
            'BundleProduct',
            'ConfigurableProduct',
            'SimpleProduct',
            'GroupedProduct',
        ],
        CustomizableProductInterface: [
            'BundleProduct',
            'ConfigurableProduct',
            'SimpleProduct',
            'VirtualProduct',
        ],
        CustomizableOptionInterface: [
            'CustomizableAreaOption',
            'CustomizableCheckboxOption',
            'CustomizableDateOption',
            'CustomizableDropDownOption',
            'CustomizableFieldOption',
            'CustomizableFileOption',
            'CustomizableMultipleOption',
            'CustomizableRadioOption',
        ],
        SwatchDataInterface: [
            'ColorSwatchData',
            'ImageSwatchData',
            'TextSwatchData',
        ],
        ErrorInterface: ['InternalError', 'NoSuchEntityUidError'],
        SwatchLayerFilterItemInterface: ['SwatchLayerFilterItem'],
    },
}
export default result
