import { CartItemFragment } from '@emico-hooks/cart-fragment'
import { DataLayerProduct } from '@emico-utils/datalayer-templates'

import parseItemVariant from './parseItemVariant'
import { BasicConfigurableProduct } from '../../catalog/ProductPage/ConfigurableProduct'
import { BasicProduct } from '../../catalog/ProductPage/GetBasicProduct.query'
import { getBreadcrumbs } from '../../navigation/ProductBreadcrumbs'
import { getPrices } from '../../presentation/PriceBox'

export interface GA4ProductWithMeta {
    product: BasicProduct | BasicConfigurableProduct
    meta: DataLayerProduct
}

const toGA4Item = (
    productInput:
        | (BasicProduct | BasicConfigurableProduct)
        | GA4ProductWithMeta
        | CartItemFragment,
    rootCategoryId: number,
    rest?: DataLayerProduct,
): DataLayerProduct & { item_color?: string } => {
    const product =
        'product' in productInput ? productInput.product : productInput
    const meta = 'meta' in productInput ? productInput.meta : {}
    const configuredVariant =
        'configuredVariant' in productInput
            ? productInput.configuredVariant
            : undefined

    if (!product) {
        return {}
    }

    const prices = getPrices(product)

    const { sku, name, brand } = product

    const data: DataLayerProduct = {
        item_id: sku,
        item_sku: configuredVariant?.sku ?? sku,
        item_name: name.toLowerCase(),
        item_brand: brand,
        item_color: product.baseColorValues?.[0]?.label,
    }

    if (prices?.actualPrice?.value) {
        data.price = prices.actualPrice.value
        data.currency = prices.actualPrice.currency
    }

    if ('quantity' in productInput) {
        data.quantity = productInput.quantity
    }

    if (
        'configuredVariant' in productInput &&
        'variants' in (productInput.product ?? {})
    ) {
        data.item_variant = parseItemVariant(
            productInput.configurableOptions
                .map((v) => `${v.optionLabel}:${v.valueLabel}`)
                .join(','),
        )
    }

    // Usually we would supply a rootid but because this makes our code
    // less flexible to use (rootid has to be fetched from backend via hook)
    // and the GA code doesn't really care, we fetch category data based on order.
    const breadcrumbs = rootCategoryId
        ? getBreadcrumbs(product.categories, rootCategoryId)
        : []

    if (product.mainCategory?.id) {
        data.item_category = product.mainCategory?.id?.toString() ?? ''
    }
    // Check if there are any relevant categories
    // based on the breadcrumbs.
    if (breadcrumbs.length > 0) {
        breadcrumbs
            .filter(({ id }) => String(id) !== data.item_category)
            .forEach(({ label, id }, index) => {
                data[`item_category${index + 2}`] = id?.toString() ?? label
            })
    }

    return { ...meta, ...data, ...rest }
}

export default toGA4Item
