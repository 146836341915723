import {
    PrismicHyperlink,
    PrismicImageType,
    RichTextBlock,
} from '@emico/prismic'
export type UnknownJSON = unknown | null

export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
    ID: string
    String: string
    Boolean: boolean
    Int: number
    Float: number
    /** The JSON scalar type represents JSON values as specified by ECMA-404. */
    Json: UnknownJSON
    /** DateTime */
    DateTime: any
    /** The `Long` scalar type represents non-fractional signed whole numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
    Long: any
    /** Date */
    Date: any
    TODO: any
}

/** Defines the bundle products to add to the cart. */
export interface AddBundleProductsToCartInput {
    /** The ID of the cart. */
    cart_id: Scalars['String']
    /** An array of bundle products to add. */
    cart_items: Array<Maybe<BundleProductCartItemInput>>
}

/** Contains details about the cart after adding bundle products. */
export interface AddBundleProductsToCartOutput {
    __typename?: 'AddBundleProductsToCartOutput'
    /** The cart after adding products. */
    cart: Cart
}

/** Defines the configurable products to add to the cart. */
export interface AddConfigurableProductsToCartInput {
    /** The ID of the cart. */
    cart_id: Scalars['String']
    /** An array of configurable products to add. */
    cart_items: Array<Maybe<ConfigurableProductCartItemInput>>
}

/** Contains details about the cart after adding configurable products. */
export interface AddConfigurableProductsToCartOutput {
    __typename?: 'AddConfigurableProductsToCartOutput'
    /** The cart after adding products. */
    cart: Cart
}

/** Contains details about the cart after adding products to it. */
export interface AddProductsToCartOutput {
    __typename?: 'AddProductsToCartOutput'
    /** The cart after products have been added. */
    cart: Cart
    /** Contains errors encountered while adding an item to the cart. */
    userErrors: Array<Maybe<CartUserInputError>>
}

/** Contains products to add to an existing compare list. */
export interface AddProductsToCompareListInput {
    /** An array of product IDs to add to the compare list. */
    products: Array<Maybe<Scalars['ID']>>
    /** The unique identifier of the compare list to modify. */
    uid: Scalars['ID']
}

/** Contains the customer's wish list and any errors encountered. */
export interface AddProductsToWishlistOutput {
    __typename?: 'AddProductsToWishlistOutput'
    /** An array of errors encountered while adding products to a wish list. */
    userErrors: Array<Maybe<WishListUserInputError>>
    /** Contains the wish list with all items that were successfully added. */
    wishlist: Wishlist
}

export interface AddressSearchOption {
    __typename?: 'AddressSearchOption'
    city: Maybe<Scalars['String']>
    country: Maybe<Scalars['String']>
    houseNumber: Maybe<Scalars['Int']>
    houseNumberAddition: Maybe<Scalars['String']>
    postcode: Maybe<Scalars['String']>
    region: Maybe<Scalars['String']>
    street: Maybe<Scalars['String']>
}

export interface AddressSearchResult {
    __typename?: 'AddressSearchResult'
    options: Maybe<Array<Maybe<AddressSearchOption>>>
}

/** Defines the simple and group products to add to the cart. */
export interface AddSimpleProductsToCartInput {
    /** The unique ID of a `Cart` object. */
    cart_id: Scalars['String']
    /** An array of simple and group items to add. */
    cart_items: Array<Maybe<SimpleProductCartItemInput>>
}

/** Contains details about the cart after adding simple or group products. */
export interface AddSimpleProductsToCartOutput {
    __typename?: 'AddSimpleProductsToCartOutput'
    /** The cart after adding products. */
    cart: Cart
}

/** Defines the virtual products to add to the cart. */
export interface AddVirtualProductsToCartInput {
    /** The unique ID of a `Cart` object. */
    cart_id: Scalars['String']
    /** An array of virtual products to add. */
    cart_items: Array<Maybe<VirtualProductCartItemInput>>
}

/** Contains details about the cart after adding virtual products. */
export interface AddVirtualProductsToCartOutput {
    __typename?: 'AddVirtualProductsToCartOutput'
    /** The cart after adding products. */
    cart: Cart
}

/** Contains the resultant wish list and any error information. */
export interface AddWishlistItemsToCartOutput {
    __typename?: 'AddWishlistItemsToCartOutput'
    /** An array of errors encountered while adding products to the customer's cart. */
    addWishlistItemsToCartUserErrors: Array<Maybe<WishlistCartUserInputError>>
    cartId: Maybe<Scalars['String']>
    /** Indicates whether the attempt to add items to the customer's cart was successful. */
    status: Scalars['Boolean']
    /** Contains the wish list with all items that were successfully added. */
    wishlist: Wishlist
}

export interface AdyenAdditionalData {
    /** Type of alternative payment method. */
    brand_code: Scalars['String']
    /** Ratepay device identification token. */
    df_value?: Maybe<Scalars['String']>
    /** Email address if customer is guest. */
    guestEmail?: Maybe<Scalars['String']>
    /** Recurring processing model to tokenize the payment method. */
    recurringProcessingModel?: Maybe<Scalars['String']>
    /**
     * The URL to return to in case of a redirection. The format depends on the
     * channel. This URL can have a maximum of 1024 characters. It can include a
     * placeholder `:merchantReference` to identify the order e.g.
     * `https://your-company.com/checkout?shopperOrder=:merchantReference`.
     */
    returnUrl?: Maybe<Scalars['String']>
    /** JSON string of filled fields. */
    stateData: Scalars['String']
}

export interface AdyenAdditionalDataCc {
    /** Credit card brand. */
    cc_type?: Maybe<Scalars['String']>
    /** Debit or Credit card. */
    combo_card_type?: Maybe<Scalars['String']>
    /** Email address if customer is guest. */
    guestEmail?: Maybe<Scalars['String']>
    /** If combo_card_type is credit, Number of installments for the payment. */
    number_of_installments?: Maybe<Scalars['Int']>
    /** Recurring processing model to tokenize the payment method. */
    recurringProcessingModel?: Maybe<Scalars['String']>
    /**
     * The URL to return to in case of a redirection. The format depends on the
     * channel. This URL can have a maximum of 1024 characters. It can include a
     * placeholder `:merchantReference` to identify the order e.g.
     * `https://your-company.com/checkout?shopperOrder=:merchantReference`.
     */
    returnUrl?: Maybe<Scalars['String']>
    /** JSON string of filled fields. */
    stateData?: Maybe<Scalars['String']>
}

export interface AdyenAdditionalDataPosCloud {
    /** Number of installments for the payment. */
    number_of_installments?: Maybe<Scalars['Int']>
    /** Terminal ID of selected terminal. */
    terminal_id?: Maybe<Scalars['String']>
}

export interface AdyenGiftcard {
    __typename?: 'AdyenGiftcard'
    /** Remaining balance on the gift card. */
    balance: Maybe<PaymentMethodBalance>
    /** Gift card brand */
    brand: Maybe<Scalars['String']>
    /** Deducted balance from the gift card */
    deductedAmount: Maybe<Scalars['String']>
    /** Gift card identifier. */
    stateDataId: Maybe<Scalars['String']>
    /** Gift card payment method title */
    title: Maybe<Scalars['String']>
}

export interface AdyenPaymentMethodIcon {
    __typename?: 'AdyenPaymentMethodIcon'
    /** Height of the icon in pixels. */
    height: Maybe<Scalars['Int']>
    /** URL of the icon. */
    url: Maybe<Scalars['String']>
    /** Width of the icon in pixels. */
    width: Maybe<Scalars['Int']>
}

export interface AdyenPaymentMethods {
    __typename?: 'AdyenPaymentMethods'
    /** Payment method's additional details. */
    paymentMethodsExtraDetails: Maybe<
        Array<Maybe<AdyenPaymentMethodsExtraDetails>>
    >
    /** API response from Adyen with payment methods. */
    paymentMethodsResponse: Maybe<AdyenPaymentMethodsResponse>
}

export interface AdyenPaymentMethodsArray {
    __typename?: 'AdyenPaymentMethodsArray'
    /** Brand for the selected gift card. For example: plastix, hmclub. */
    brand: Maybe<Scalars['String']>
    /** List of possible brands. For example: visa, mc. */
    brands: Maybe<Array<Maybe<Scalars['String']>>>
    /** The configuration of the payment method. */
    configuration: Maybe<AdyenPaymentMethodsConfiguration>
    /** Payment method issuer list. */
    issuers: Maybe<Array<Maybe<AdyenPaymentMethodsIssuers>>>
    /** The displayable name of this payment method. */
    name: Maybe<Scalars['String']>
    /** The unique payment method code. */
    type: Maybe<Scalars['String']>
}

export interface AdyenPaymentMethodsBalanceResponse {
    __typename?: 'AdyenPaymentMethodsBalanceResponse'
    /** Balance of the payment method. */
    balanceResponse: Maybe<Scalars['String']>
}

export interface AdyenPaymentMethodsConfiguration {
    __typename?: 'AdyenPaymentMethodsConfiguration'
    /** Name of the merchant for Google Pay. */
    gatewayMerchantId: Maybe<Scalars['String']>
    /** ID of the merchant. */
    merchantId: Maybe<Scalars['String']>
    /** Name of the merchant. */
    merchantName: Maybe<Scalars['String']>
}

export interface AdyenPaymentMethodsDetails {
    __typename?: 'AdyenPaymentMethodsDetails'
    /** The items to choose from in case that the payment method includes a selection list. */
    items: Maybe<Array<Maybe<AdyenPaymentMethodsDetailsItems>>>
    /** The value to provide in the result. */
    key: Maybe<Scalars['String']>
    /** True if this input is optional. */
    optional: Maybe<Scalars['String']>
    /** The type of the required input. */
    type: Maybe<Scalars['String']>
    /** The value can be pre-filled, if available. */
    value: Maybe<Scalars['String']>
}

export interface AdyenPaymentMethodsDetailsItems {
    __typename?: 'AdyenPaymentMethodsDetailsItems'
    /** The value to provide in the result. */
    id: Maybe<Scalars['String']>
    /** The display name. */
    name: Maybe<Scalars['String']>
}

export interface AdyenPaymentMethodsExtraDetails {
    __typename?: 'AdyenPaymentMethodsExtraDetails'
    /** Extra configuration settings. */
    configuration: Maybe<AdyenPaymentMethodsExtraDetailsConfiguration>
    /** Icon for the payment method. */
    icon: Maybe<AdyenPaymentMethodIcon>
    /** True if the payment method is Open Invoice. */
    isOpenInvoice: Maybe<Scalars['Boolean']>
    /** The unique payment method code. */
    type: Maybe<Scalars['String']>
}

export interface AdyenPaymentMethodsExtraDetailsConfiguration {
    __typename?: 'AdyenPaymentMethodsExtraDetailsConfiguration'
    /** Current order amount in minor units. */
    amount: Maybe<Money>
    /** Current order currency. */
    currency: Maybe<Scalars['String']>
}

export interface AdyenPaymentMethodsIssuers {
    __typename?: 'AdyenPaymentMethodsIssuers'
    /** Issuer ID. */
    id: Maybe<Scalars['String']>
    /** Issuer name. */
    name: Maybe<Scalars['String']>
}

export interface AdyenPaymentMethodsResponse {
    __typename?: 'AdyenPaymentMethodsResponse'
    paymentMethods: Maybe<Array<Maybe<AdyenPaymentMethodsArray>>>
    storedPaymentMethods: Maybe<Array<Maybe<AdyenStoredPaymentMethodsArray>>>
}

export interface AdyenPaymentStatus {
    __typename?: 'AdyenPaymentStatus'
    /** Object containing information about the payment's next step. */
    action: Maybe<Scalars['String']>
    /** Additional data required for the next step in the payment process. */
    additionalData: Maybe<Scalars['String']>
    /** If True, no further action is required and customer should be redirect to success page. */
    isFinal: Maybe<Scalars['Boolean']>
    /** Current state of the order in Adyen. */
    resultCode: Maybe<Scalars['String']>
}

export interface AdyenRedeemedGiftcardsResponse {
    __typename?: 'AdyenRedeemedGiftcardsResponse'
    /** List of redeemed gift cards. */
    redeemedGiftcards: Maybe<Array<Maybe<AdyenGiftcard>>>
    /** Remaining order amount after giftcard discount. */
    remainingAmount: Maybe<Scalars['String']>
    /** Total giftcard discount applied to the cart. */
    totalDiscount: Maybe<Scalars['String']>
}

export interface AdyenStateData {
    __typename?: 'AdyenStateData'
    /** ID of the inserted stateData object. */
    stateDataId: Maybe<Scalars['String']>
}

export interface AdyenStoredPaymentMethodsArray {
    __typename?: 'AdyenStoredPaymentMethodsArray'
    /** The brand of the card. */
    brand: Maybe<Scalars['String']>
    /** The month the card expires. */
    expiryMonth: Maybe<Scalars['String']>
    /** The year the card expires. */
    expiryYear: Maybe<Scalars['String']>
    /** The unique payment method code. */
    holderName: Maybe<Scalars['String']>
    /** The IBAN of the bank account. */
    iban: Maybe<Scalars['String']>
    /** A unique identifier of this stored payment method. */
    id: Maybe<Scalars['String']>
    /** The last four digits of the PAN. */
    lastFour: Maybe<Scalars['String']>
    /** The display name of the stored payment method. */
    name: Maybe<Scalars['String']>
    /**
     * Returned in the response if you are not tokenizing with Adyen and are using
     * the Merchant-initiated transactions (MIT) framework from Mastercard or Visa.
     * This contains either the Mastercard Trace ID or the Visa Transaction ID.
     */
    networkTxReference: Maybe<Scalars['String']>
    /** The name of the bank account holder. */
    ownerName: Maybe<Scalars['String']>
    /** The shopper’s email address. */
    shopperEmail: Maybe<Scalars['String']>
    /** The supported shopper interactions for this stored payment method. */
    supportedShopperInteractions: Maybe<Array<Maybe<Scalars['String']>>>
    /** The type of payment method. */
    type: Maybe<Scalars['String']>
}

/** Contains information for each filterable option (such as price, category `UID`, and custom attributes). */
export interface Aggregation {
    __typename?: 'Aggregation'
    /** Attribute code of the aggregation group. */
    attributeCode: Scalars['String']
    /** The number of options in the aggregation group. */
    count: Maybe<Scalars['Int']>
    /** The aggregation display name. */
    label: Maybe<Scalars['String']>
    /** Array of options for the aggregation. */
    options: Maybe<Array<Maybe<AggregationOption>>>
    /** The relative position of the attribute in a layered navigation block. */
    position: Maybe<Scalars['Int']>
}

/** An implementation of `AggregationOptionInterface`. */
export interface AggregationOption extends AggregationOptionInterface {
    __typename?: 'AggregationOption'
    /** The number of items that match the aggregation option. */
    count: Maybe<Scalars['Int']>
    /** The display label for an aggregation option. */
    label: Maybe<Scalars['String']>
    /** The internal ID that represents the value of the option. */
    value: Scalars['String']
}

/** Defines aggregation option fields. */
export interface AggregationOptionInterface {
    /** The number of items that match the aggregation option. */
    count: Maybe<Scalars['Int']>
    /** The display label for an aggregation option. */
    label: Maybe<Scalars['String']>
    /** The internal ID that represents the value of the option. */
    value: Scalars['String']
}

/** Filter category aggregations in layered navigation. */
export interface AggregationsCategoryFilterInput {
    /** Indicates whether to include only direct subcategories or all children categories at all levels. */
    includeDirectChildrenOnly?: Maybe<Scalars['Boolean']>
}

/** An input object that specifies the filters used in product aggregations. */
export interface AggregationsFilterInput {
    /** Filter category aggregations in layered navigation. */
    category?: Maybe<AggregationsCategoryFilterInput>
}

/** Contains the applied coupon code. */
export interface AppliedCoupon {
    __typename?: 'AppliedCoupon'
    /** The coupon code the shopper applied to the card. */
    code: Scalars['String']
}

/** Specifies the coupon code to apply to the cart. */
export interface ApplyCouponToCartInput {
    /** The unique ID of a `Cart` object. */
    cart_id: Scalars['String']
    /** A valid coupon code. */
    coupon_code: Scalars['String']
}

/** Contains details about the cart after applying a coupon. */
export interface ApplyCouponToCartOutput {
    __typename?: 'ApplyCouponToCartOutput'
    /** The cart after applying a coupon. */
    cart: Cart
}

/** AreaInput defines the parameters which will be used for filter by specified location. */
export interface AreaInput {
    /** The radius for the search in KM. */
    radius: Scalars['Int']
    /** The country code where search must be performed. Required parameter together with region, city or postcode. */
    search_term: Scalars['String']
}

/** Contains the results of the request to assign a compare list. */
export interface AssignCompareListToCustomerOutput {
    __typename?: 'AssignCompareListToCustomerOutput'
    /** The contents of the customer's compare list. */
    compareList: Maybe<CompareList>
    /** Indicates whether the compare list was successfully assigned to the customer. */
    result: Scalars['Boolean']
}

/** Contains details about the attribute, including the code and type. */
export interface Attribute {
    __typename?: 'Attribute'
    /** The unique identifier for an attribute code. This value should be in lowercase letters without spaces. */
    attributeCode: Maybe<Scalars['String']>
    /** Attribute options list. */
    attributeOptions: Maybe<Array<Maybe<AttributeOption>>>
    /** The data type of the attribute. */
    attributeType: Maybe<Scalars['String']>
    /** The type of entity that defines the attribute. */
    entityType: Maybe<Scalars['String']>
    /** The frontend input type of the attribute. */
    inputType: Maybe<Scalars['String']>
    /** Details about the storefront properties configured for the attribute. */
    storefrontProperties: Maybe<StorefrontProperties>
}

/** List of all entity types. Populated by the modules introducing EAV entities. */
export enum AttributeEntityTypeEnum {
    CATALOG_PRODUCT = 'CATALOG_PRODUCT',
    CATALOG_CATEGORY = 'CATALOG_CATEGORY',
    CUSTOMER = 'CUSTOMER',
    CUSTOMER_ADDRESS = 'CUSTOMER_ADDRESS',
}

/** An input object that specifies the filters used for attributes. */
export interface AttributeFilterInput {
    /** Whether a product or category attribute can be compared against another or not. */
    is_comparable?: Maybe<Scalars['Boolean']>
    /** Whether a product or category attribute can be filtered or not. */
    is_filterable?: Maybe<Scalars['Boolean']>
    /** Whether a product or category attribute can be filtered in search or not. */
    is_filterable_in_search?: Maybe<Scalars['Boolean']>
    /** Whether a product or category attribute can use HTML on front or not. */
    is_html_allowed_on_front?: Maybe<Scalars['Boolean']>
    /** Whether a product or category attribute can be searched or not. */
    is_searchable?: Maybe<Scalars['Boolean']>
    /** Whether a product or category attribute can be used for price rules or not. */
    is_used_for_price_rules?: Maybe<Scalars['Boolean']>
    /** Whether a product or category attribute is used for promo rules or not. */
    is_used_for_promo_rules?: Maybe<Scalars['Boolean']>
    /** Whether a product or category attribute is visible in advanced search or not. */
    is_visible_in_advanced_search?: Maybe<Scalars['Boolean']>
    /** Whether a product or category attribute is visible on front or not. */
    is_visible_on_front?: Maybe<Scalars['Boolean']>
    /** Whether a product or category attribute has WYSIWYG enabled or not. */
    is_wysiwyg_enabled?: Maybe<Scalars['Boolean']>
    /** Whether a product or category attribute is used in product listing or not. */
    used_in_product_listing?: Maybe<Scalars['Boolean']>
}

/** EAV attribute frontend input types. */
export enum AttributeFrontendInputEnum {
    BOOLEAN = 'BOOLEAN',
    DATE = 'DATE',
    DATETIME = 'DATETIME',
    FILE = 'FILE',
    GALLERY = 'GALLERY',
    HIDDEN = 'HIDDEN',
    IMAGE = 'IMAGE',
    MEDIA_IMAGE = 'MEDIA_IMAGE',
    MULTILINE = 'MULTILINE',
    MULTISELECT = 'MULTISELECT',
    PRICE = 'PRICE',
    SELECT = 'SELECT',
    TEXT = 'TEXT',
    TEXTAREA = 'TEXTAREA',
    WEIGHT = 'WEIGHT',
    UNDEFINED = 'UNDEFINED',
}

/** Defines the attribute characteristics to search for the `attribute_code` and `entity_type` to search. */
export interface AttributeInput {
    /** The unique identifier for an attribute code. This value should be in lowercase letters without spaces. */
    attribute_code?: Maybe<Scalars['String']>
    /** The type of entity that defines the attribute. */
    entity_type?: Maybe<Scalars['String']>
}

/** Specifies selected option for a select or multiselect attribute value. */
export interface AttributeInputSelectedOption {
    /** The attribute option value. */
    value: Scalars['String']
}

/** Base EAV implementation of CustomAttributeMetadataInterface. */
export interface AttributeMetadata extends CustomAttributeMetadataInterface {
    __typename?: 'AttributeMetadata'
    /** The unique identifier for an attribute code. This value should be in lowercase letters without spaces. */
    code: Scalars['ID']
    /** Default attribute value. */
    defaultValue: Maybe<Scalars['String']>
    /** The type of entity that defines the attribute. */
    entityType: AttributeEntityTypeEnum
    /** The frontend class of the attribute. */
    frontendClass: Maybe<Scalars['String']>
    /** The frontend input type of the attribute. */
    frontendInput: Maybe<AttributeFrontendInputEnum>
    /** Whether the attribute value is required. */
    isRequired: Scalars['Boolean']
    /** Whether the attribute value must be unique. */
    isUnique: Scalars['Boolean']
    /** The label assigned to the attribute. */
    label: Maybe<Scalars['String']>
    /** Attribute options. */
    options: Array<Maybe<CustomAttributeOptionInterface>>
}

/** Attribute metadata retrieval error. */
export interface AttributeMetadataError {
    __typename?: 'AttributeMetadataError'
    /** Attribute metadata retrieval error message. */
    message: Scalars['String']
    /** Attribute metadata retrieval error type. */
    type: AttributeMetadataErrorType
}

/** Attribute metadata retrieval error types. */
export enum AttributeMetadataErrorType {
    /** The requested entity was not found. */
    ENTITY_NOT_FOUND = 'ENTITY_NOT_FOUND',
    /** The requested attribute was not found. */
    ATTRIBUTE_NOT_FOUND = 'ATTRIBUTE_NOT_FOUND',
    /** The filter cannot be applied as it does not belong to the entity */
    FILTER_NOT_FOUND = 'FILTER_NOT_FOUND',
    /** Not categorized error, see the error message. */
    UNDEFINED = 'UNDEFINED',
}

/** Defines an attribute option. */
export interface AttributeOption {
    __typename?: 'AttributeOption'
    /** The label assigned to the attribute option. */
    label: Maybe<Scalars['String']>
    /** The attribute option value. */
    value: Maybe<Scalars['String']>
}

/** Base EAV implementation of CustomAttributeOptionInterface. */
export interface AttributeOptionMetadata
    extends CustomAttributeOptionInterface {
    __typename?: 'AttributeOptionMetadata'
    /** Is the option value default. */
    isDefault: Scalars['Boolean']
    /** The label assigned to the attribute option. */
    label: Scalars['String']
    /** The attribute option value. */
    value: Scalars['String']
}

export interface AttributeSelectedOption
    extends AttributeSelectedOptionInterface {
    __typename?: 'AttributeSelectedOption'
    /** The attribute selected option label. */
    label: Scalars['String']
    /** The attribute selected option value. */
    value: Scalars['String']
}

export interface AttributeSelectedOptionInterface {
    /** The attribute selected option label. */
    label: Scalars['String']
    /** The attribute selected option value. */
    value: Scalars['String']
}

export interface AttributeSelectedOptions extends AttributeValueInterface {
    __typename?: 'AttributeSelectedOptions'
    /** The attribute code. */
    code: Scalars['ID']
    selectedOptions: Array<Maybe<AttributeSelectedOptionInterface>>
}

/** Metadata of EAV attributes associated to form */
export interface AttributesFormOutput {
    __typename?: 'AttributesFormOutput'
    /** Errors of retrieving certain attributes metadata. */
    errors: Array<Maybe<AttributeMetadataError>>
    /** Requested attributes metadata. */
    items: Array<Maybe<CustomAttributeMetadataInterface>>
}

/** Metadata of EAV attributes. */
export interface AttributesMetadataOutput {
    __typename?: 'AttributesMetadataOutput'
    /** Errors of retrieving certain attributes metadata. */
    errors: Array<Maybe<AttributeMetadataError>>
    /** Requested attributes metadata. */
    items: Array<Maybe<CustomAttributeMetadataInterface>>
}

export interface AttributeValue extends AttributeValueInterface {
    __typename?: 'AttributeValue'
    /** The attribute code. */
    code: Scalars['ID']
    /** The attribute value. */
    value: Scalars['String']
}

/** Specifies the value for attribute. */
export interface AttributeValueInput {
    /** The code of the attribute. */
    attribute_code: Scalars['String']
    /** An array containing selected options for a select or multiselect attribute. */
    selected_options?: Maybe<Array<Maybe<AttributeInputSelectedOption>>>
    /** The value assigned to the attribute. */
    value?: Maybe<Scalars['String']>
}

export interface AttributeValueInterface {
    /** The attribute code. */
    code: Scalars['ID']
}

/** Describes a payment method that the shopper can use to pay for the order. */
export interface AvailablePaymentMethod {
    __typename?: 'AvailablePaymentMethod'
    /** The payment method code. */
    code: Scalars['String']
    /** If the payment method is an online integration */
    isDeferred: Scalars['Boolean']
    /** Logo for the Payment Method. */
    logo: Maybe<Scalars['String']>
    /** The payment method title. */
    title: Scalars['String']
}

/** Contains details about the possible shipping methods and carriers. */
export interface AvailableShippingMethod {
    __typename?: 'AvailableShippingMethod'
    /** The cost of shipping using this shipping method. */
    amount: Money
    /** Indicates whether this shipping method can be applied to the cart. */
    available: Scalars['Boolean']
    /** @deprecated The field should not be used on the storefront. */
    baseAmount: Maybe<Money>
    /** A string that identifies a commercial carrier or an offline shipping method. */
    carrierCode: Scalars['String']
    /** The label for the carrier code. */
    carrierTitle: Scalars['String']
    /** Describes an error condition. */
    errorMessage: Maybe<Scalars['String']>
    /** A shipping method code associated with a carrier. The value could be null if no method is available. */
    methodCode: Maybe<Scalars['String']>
    /** The label for the shipping method code. The value could be null if no method is available. */
    methodTitle: Maybe<Scalars['String']>
    /** DEPRECATED: Get DHL Pickup Point options (Highstreet only) */
    options: Maybe<Array<Maybe<PickupPoint>>>
    /** The cost of shipping using this shipping method, excluding tax. */
    priceExclTax: Money
    /** The cost of shipping using this shipping method, including tax. */
    priceInclTax: Money
}

/** Contains details about the possible shipping methods and carriers. */
export interface AvailableShippingMethodOptionsArgs {
    input: Maybe<PickupPointFilterInput>
}

export enum BatchMutationStatus {
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE',
    MIXED_RESULTS = 'MIXED_RESULTS',
}

/** Defines the billing address. */
export interface BillingAddressInput {
    /** Defines a billing address. */
    address?: Maybe<CartAddressInput>
    /** An ID from the customer's address book that uniquely identifies the address to be used for billing. */
    customer_address_id?: Maybe<Scalars['Int']>
    /** Indicates whether to set the billing address to be the same as the existing shipping address on the cart. */
    same_as_shipping?: Maybe<Scalars['Boolean']>
    /** Indicates whether to set the shipping address to be the same as this billing address. */
    use_for_shipping?: Maybe<Scalars['Boolean']>
}

/** Contains details about the billing address. */
export interface BillingCartAddress extends CartAddressInterface {
    __typename?: 'BillingCartAddress'
    /** The city specified for the billing or shipping address. */
    city: Scalars['String']
    /** The company specified for the billing or shipping address. */
    company: Maybe<Scalars['String']>
    /** An object containing the country label and code. */
    country: CartAddressCountry
    /** @deprecated The field is used only in shipping address. */
    customerNotes: Maybe<Scalars['String']>
    /** The customer's fax number. */
    fax: Maybe<Scalars['String']>
    /** The first name of the customer or guest. */
    firstname: Scalars['String']
    /** The last name of the customer or guest. */
    lastname: Scalars['String']
    /** The middle name of the person associated with the billing/shipping address. */
    middlename: Maybe<Scalars['String']>
    /** The ZIP or postal code of the billing or shipping address. */
    postcode: Maybe<Scalars['String']>
    /** An honorific, such as Dr., Mr., or Mrs. */
    prefix: Maybe<Scalars['String']>
    /** An object containing the region label and code. */
    region: Maybe<CartAddressRegion>
    /** An array containing the street for the billing or shipping address. */
    street: Array<Maybe<Scalars['String']>>
    /** A value such as Sr., Jr., or III. */
    suffix: Maybe<Scalars['String']>
    /** The telephone number for the billing or shipping address. */
    telephone: Maybe<Scalars['String']>
    /** The unique id of the customer address. */
    uid: Scalars['String']
    /** The VAT company number for billing or shipping address. */
    vatId: Maybe<Scalars['String']>
}

/** Bonus Product */
export interface BonusProduct {
    __typename?: 'BonusProduct'
    product: ProductInterface
    promotionId: Maybe<Scalars['Int']>
}

export interface BonusProducts {
    __typename?: 'BonusProducts'
    items: Maybe<Array<Maybe<BonusProduct>>>
}

/** Contains details about an individual category that comprises a breadcrumb. */
export interface Breadcrumb {
    __typename?: 'Breadcrumb'
    /**
     * The ID of the category.
     * @deprecated Use `category_uid` instead.
     */
    categoryId: Maybe<Scalars['Int']>
    /** The category level. */
    categoryLevel: Maybe<Scalars['Int']>
    /** The display name of the category. */
    categoryName: Maybe<Scalars['String']>
    /** The unique ID for a `Breadcrumb` object. */
    categoryUid: Scalars['ID']
    /** The URL key of the category. */
    categoryUrlKey: Maybe<Scalars['String']>
    /** The URL path of the category. */
    categoryUrlPath: Maybe<Scalars['String']>
}

/** An implementation for bundle product cart items. */
export interface BundleCartItem extends CartItemInterface {
    __typename?: 'BundleCartItem'
    /** An array containing the bundle options the shopper selected. */
    bundleOptions: Array<Maybe<SelectedBundleOption>>
    /** An array containing the customizable options the shopper selected. */
    customizableOptions: Array<Maybe<SelectedCustomizableOption>>
    /** An array of errors encountered while loading the cart item */
    errors: Maybe<Array<Maybe<CartItemError>>>
    extensionAttributes: Maybe<CartItemExtensionAttributes>
    /** The entered gift message for the cart item */
    giftMessage: Maybe<GiftMessage>
    /** @deprecated Use `uid` instead. */
    id: Scalars['String']
    /** True if requested quantity is less than available stock, false otherwise. */
    isAvailable: Scalars['Boolean']
    isFreeGift: Maybe<Scalars['Boolean']>
    /** Contains details about the price of the item, including taxes and discounts. */
    prices: Maybe<CartItemPrices>
    /** Details about an item in the cart. */
    product: ProductInterface
    promotional: Maybe<Scalars['Boolean']>
    /** The quantity of this item in the cart. */
    quantity: Scalars['Float']
    reservation: Maybe<Reservation>
    /** The unique ID for a `CartItemInterface` object. */
    uid: Scalars['ID']
}

/** Defines bundle product options for `CreditMemoItemInterface`. */
export interface BundleCreditMemoItem extends CreditMemoItemInterface {
    __typename?: 'BundleCreditMemoItem'
    /** A list of bundle options that are assigned to a bundle product that is part of a credit memo. */
    bundleOptions: Maybe<Array<Maybe<ItemSelectedBundleOption>>>
    /** Details about the final discount amount for the base product, including discounts on options. */
    discounts: Maybe<Array<Maybe<Discount>>>
    /** The unique ID for a `CreditMemoItemInterface` object. */
    id: Scalars['ID']
    /** The order item the credit memo is applied to. */
    orderItem: Maybe<OrderItemInterface>
    /** The name of the base product. */
    productName: Maybe<Scalars['String']>
    /** The sale price for the base product, including selected options. */
    productSalePrice: Money
    /** The SKU of the base product. */
    productSku: Scalars['String']
    /** The number of refunded items. */
    quantityRefunded: Maybe<Scalars['Float']>
}

/** Defines bundle product options for `InvoiceItemInterface`. */
export interface BundleInvoiceItem extends InvoiceItemInterface {
    __typename?: 'BundleInvoiceItem'
    /** A list of bundle options that are assigned to an invoiced bundle product. */
    bundleOptions: Maybe<Array<Maybe<ItemSelectedBundleOption>>>
    /** Information about the final discount amount for the base product, including discounts on options. */
    discounts: Maybe<Array<Maybe<Discount>>>
    /** The unique ID for an `InvoiceItemInterface` object. */
    id: Scalars['ID']
    /** Details about an individual order item. */
    orderItem: Maybe<OrderItemInterface>
    /** The name of the base product. */
    productName: Maybe<Scalars['String']>
    /** The sale price for the base product including selected options. */
    productSalePrice: Money
    /** The SKU of the base product. */
    productSku: Scalars['String']
    /** The number of invoiced items. */
    quantityInvoiced: Maybe<Scalars['Float']>
}

/** Defines an individual item within a bundle product. */
export interface BundleItem {
    __typename?: 'BundleItem'
    /**
     * An ID assigned to each type of item in a bundle product.
     * @deprecated Use `uid` instead
     */
    optionId: Maybe<Scalars['Int']>
    /** An array of additional options for this bundle item. */
    options: Maybe<Array<Maybe<BundleItemOption>>>
    /** A number indicating the sequence order of this item compared to the other bundle items. */
    position: Maybe<Scalars['Int']>
    /** The range of prices for the product */
    priceRange: PriceRange
    /** Indicates whether the item must be included in the bundle. */
    required: Maybe<Scalars['Boolean']>
    /** The SKU of the bundle product. */
    sku: Maybe<Scalars['String']>
    /** The display name of the item. */
    title: Maybe<Scalars['String']>
    /** The input type that the customer uses to select the item. Examples include radio button and checkbox. */
    type: Maybe<Scalars['String']>
    /** The unique ID for a `BundleItem` object. */
    uid: Maybe<Scalars['ID']>
}

/** Defines the characteristics that comprise a specific bundle item and its options. */
export interface BundleItemOption {
    __typename?: 'BundleItemOption'
    /** Indicates whether the customer can change the number of items for this option. */
    canChangeQuantity: Maybe<Scalars['Boolean']>
    /**
     * The ID assigned to the bundled item option.
     * @deprecated Use `uid` instead
     */
    id: Maybe<Scalars['Int']>
    /** Indicates whether this option is the default option. */
    isDefault: Maybe<Scalars['Boolean']>
    /** The text that identifies the bundled item option. */
    label: Maybe<Scalars['String']>
    /** When a bundle item contains multiple options, the relative position of this option compared to the other options. */
    position: Maybe<Scalars['Int']>
    /** The price of the selected option. */
    price: Maybe<Scalars['Float']>
    /** One of FIXED, PERCENT, or DYNAMIC. */
    priceType: Maybe<PriceTypeEnum>
    /** Contains details about this product option. */
    product: Maybe<ProductInterface>
    /**
     * Indicates the quantity of this specific bundle item.
     * @deprecated Use `quantity` instead.
     */
    qty: Maybe<Scalars['Float']>
    /** The quantity of this specific bundle item. */
    quantity: Maybe<Scalars['Float']>
    /** The unique ID for a `BundleItemOption` object. */
    uid: Scalars['ID']
}

/** Defines the input for a bundle option. */
export interface BundleOptionInput {
    /** The ID of the option. */
    id: Scalars['Int']
    /** The number of the selected item to add to the cart. */
    quantity: Scalars['Float']
    /** An array with the chosen value of the option. */
    value: Array<Maybe<Scalars['String']>>
}

/** Defines bundle product options for `OrderItemInterface`. */
export interface BundleOrderItem extends OrderItemInterface {
    __typename?: 'BundleOrderItem'
    baseNetPrice: Maybe<Money>
    /** A list of bundle options that are assigned to the bundle product. */
    bundleOptions: Maybe<Array<Maybe<ItemSelectedBundleOption>>>
    /** The final discount information for the product. */
    discounts: Maybe<Array<Maybe<Discount>>>
    /** The entered option for the base product, such as a logo or image. */
    enteredOptions: Maybe<Array<Maybe<OrderItemOption>>>
    /** The selected gift message for the order item */
    giftMessage: Maybe<GiftMessage>
    /** The unique ID for an `OrderItemInterface` object. */
    id: Scalars['ID']
    netPrice: Maybe<Money>
    parentItemId: Maybe<Scalars['Int']>
    priceInclTax: Maybe<Money>
    /** The ProductInterface object, which contains details about the base product */
    product: Maybe<ProductInterface>
    /** The name of the base product. */
    productName: Maybe<Scalars['String']>
    /** The original price of the base product, including selected options. */
    productOriginalPrice: Money
    /** The sale price of the base product, including selected options. */
    productSalePrice: Money
    /** The SKU of the base product. */
    productSku: Scalars['String']
    /** The type of product, such as simple, configurable, etc. */
    productType: Maybe<Scalars['String']>
    /** URL key of the base product. */
    productUrlKey: Maybe<Scalars['String']>
    /** The number of canceled items. */
    quantityCanceled: Maybe<Scalars['Float']>
    /** The number of invoiced items. */
    quantityInvoiced: Maybe<Scalars['Float']>
    /** The number of units ordered for this item. */
    quantityOrdered: Maybe<Scalars['Float']>
    /** The number of refunded items. */
    quantityRefunded: Maybe<Scalars['Float']>
    /** The number of returned items. */
    quantityReturned: Maybe<Scalars['Float']>
    /** The number of shipped items. */
    quantityShipped: Maybe<Scalars['Float']>
    /** The selected options for the base product, such as color or size. */
    selectedOptions: Maybe<Array<Maybe<OrderItemOption>>>
    /** The status of the order item. */
    status: Maybe<Scalars['String']>
}

/** Defines basic features of a bundle product and contains multiple BundleItems. */
export interface BundleProduct
    extends ProductInterface,
        RoutableInterface,
        PhysicalProductInterface,
        CustomizableProductInterface {
    __typename?: 'BundleProduct'
    /** @deprecated Use the `custom_attributes` field instead. */
    baseColorHexCode: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    brandAlias: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    brandMediaPdpBottom: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    brandMediaPdpTop: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    brandName: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    brandPdpDescription: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    brandPdpTitle: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    featureDescription: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    featureMedia: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    featureName: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    itemLabelTextB2C: Maybe<Scalars['Int']>
    itemLabelTextB2CValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    itemLabelTypeB2C: Maybe<Scalars['Int']>
    itemLabelTypeB2CValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    itemMaterialBody: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    itemMaterialBodyLining: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    itemMaterialLining: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    itemMaterialShell: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    itemSizeAdvice: Maybe<Scalars['Int']>
    itemSizeAdviceValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    modelAdvice: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productArticleNumber: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productBeltLoops: Maybe<Scalars['Int']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productBodyShell: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productChestPockets: Maybe<Scalars['Int']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productClosure: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productClosureDetails: Maybe<Scalars['String']>
    productClosureDetailsValues: Maybe<Array<Maybe<AttributeOption>>>
    productClosureValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productCollar: Maybe<Scalars['Int']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productCollarDetails: Maybe<Scalars['String']>
    productCollarDetailsValues: Maybe<Array<Maybe<AttributeOption>>>
    productCollarValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productContainsNonTextilePartsOfAnimalOrigin: Maybe<Scalars['Int']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productCuffs: Maybe<Scalars['String']>
    productCuffsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productDimensions: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productFabric: Maybe<Scalars['String']>
    productFabricValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productFashionColor: Maybe<Scalars['Int']>
    productFashionColorValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productFit: Maybe<Scalars['Int']>
    productFitValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productHeight: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productHood: Maybe<Scalars['Int']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productHoodDetails: Maybe<Scalars['Int']>
    productHoodDetailsValues: Maybe<Array<Maybe<AttributeOption>>>
    productHoodValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productInnerJacketDetails: Maybe<Scalars['String']>
    productInnerJacketDetailsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productInnerPockets: Maybe<Scalars['Int']>
    productInnerPocketsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productJacketDetails: Maybe<Scalars['String']>
    productJacketDetailsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productJacketType: Maybe<Scalars['Int']>
    productJacketTypeValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productJacketlayers: Maybe<Scalars['Int']>
    productJacketlayersValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productLength: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productLengthDescription: Maybe<Scalars['Int']>
    productLengthDescriptionValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productLogoLocation: Maybe<Scalars['String']>
    productLogoLocationValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMarketPlacesDescription: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMarketingStyle: Maybe<Scalars['Int']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMaterialBack: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMaterialFilling: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMaterialFront: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMaterialHood: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMaterialLining: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMaterialPadding: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMaterialShell: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productNumberOfPockets: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productOccassion: Maybe<Scalars['Int']>
    productOccassionValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productOuterPockets: Maybe<Scalars['String']>
    productOuterPocketsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productPaddingType: Maybe<Scalars['Int']>
    productPaddingTypeValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productPattern: Maybe<Scalars['String']>
    productPatternValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productPocketType: Maybe<Scalars['String']>
    productPocketTypeValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productRelevance: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productSeasonStatus: Maybe<Scalars['Int']>
    productSeasonStatusValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productShape: Maybe<Scalars['Int']>
    productShapeValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productSizeTable: Maybe<Scalars['Int']>
    productSizeTableValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productSleeveCuffDetails: Maybe<Scalars['Int']>
    productSleeveCuffDetailsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productSleeveDetails: Maybe<Scalars['Int']>
    productSleeveDetailsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productSleeveLength: Maybe<Scalars['Int']>
    productSleeveLengthValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productSustainable: Maybe<Scalars['String']>
    productSustainableValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productType: Maybe<Scalars['Int']>
    productTypeValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productVolume: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productWaistRise: Maybe<Scalars['Int']>
    productWaistRiseValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productWashingInstructionsClotex: Maybe<Scalars['String']>
    productWashingInstructionsClotexValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productWidth: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    sizeTableIdentifier: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    washingSymbolDescription: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    washingSymbolMedia: Maybe<Scalars['String']>
    /**
     * The attribute set assigned to the product.
     * @deprecated The field should not be used on the storefront.
     */
    attributeSetId: Maybe<Scalars['Int']>
    /** @deprecated Use the `custom_attributes` field instead. */
    baseColor: Maybe<Scalars['String']>
    baseColorValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    brand: Maybe<Scalars['String']>
    /**
     * The relative canonical URL. This value is returned only if the system setting
     * 'Use Canonical Link Meta Tag For Products' is enabled.
     */
    canonicalUrl: Maybe<Scalars['String']>
    /** The categories assigned to a product. */
    categories: Maybe<Array<Maybe<CategoryInterface>>>
    categoryIds: Maybe<Array<Maybe<Scalars['Int']>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    color: Maybe<Scalars['String']>
    colorValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    configAttributes: Maybe<Scalars['String']>
    /** Product bottom content */
    contentBottomContent: Maybe<Scalars['String']>
    /** Product top image one */
    contentBottomImageOne: Maybe<Scalars['String']>
    /** Product top image two */
    contentBottomImageTwo: Maybe<Scalars['String']>
    /** Product bottom title */
    contentBottomTitle: Maybe<Scalars['String']>
    contentBottomUsp: Maybe<Scalars['String']>
    /** Product middle content */
    contentMiddleContent: Maybe<Scalars['String']>
    /** Product top image one */
    contentMiddleImageOne: Maybe<Scalars['String']>
    /** Product top image two */
    contentMiddleImageTwo: Maybe<Scalars['String']>
    /** Product middle title */
    contentMiddleTitle: Maybe<Scalars['String']>
    /** Product size guide image above */
    contentSizeGuideImageAbove: Maybe<Scalars['String']>
    /** Product size guide image below */
    contentSizeGuideImageBelow: Maybe<Scalars['String']>
    /** Product top content */
    contentTopContent: Maybe<Scalars['String']>
    /** Product top image four */
    contentTopImageFour: Maybe<Scalars['String']>
    /** Product top image one */
    contentTopImageOne: Maybe<Scalars['String']>
    /** Product top image three */
    contentTopImageThree: Maybe<Scalars['String']>
    /** Product top image two */
    contentTopImageTwo: Maybe<Scalars['String']>
    /** Product top title */
    contentTopTitle: Maybe<Scalars['String']>
    /** The product's country of origin. */
    countryOfManufacture: Maybe<Scalars['String']>
    /**
     * Timestamp indicating when the product was created.
     * @deprecated The field should not be used on the storefront.
     */
    createdAt: Maybe<Scalars['String']>
    /** Crosssell Products */
    crosssellProducts: Maybe<Array<Maybe<ProductInterface>>>
    /** Product custom attributes. */
    customAttributesV2: Maybe<ProductCustomAttributes>
    /** Product Delivery Message */
    deliveryMessage: Maybe<Scalars['String']>
    /** Detailed information about the product. The value can include simple HTML tags. */
    description: Maybe<ComplexTextValue>
    /** @deprecated Use the `custom_attributes` field instead. */
    discountAmount: Maybe<Scalars['Float']>
    /** @deprecated Use the `custom_attributes` field instead. */
    discountPercentage: Maybe<Scalars['Float']>
    /** Indicates whether the bundle product has a dynamic price. */
    dynamicPrice: Maybe<Scalars['Boolean']>
    /** Indicates whether the bundle product has a dynamic SKU. */
    dynamicSku: Maybe<Scalars['Boolean']>
    /** Indicates whether the bundle product has a dynamically calculated weight. */
    dynamicWeight: Maybe<Scalars['Boolean']>
    /** @deprecated Use the `custom_attributes` field instead. */
    featureIcons: Maybe<Scalars['String']>
    featureIconsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    features: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    fit: Maybe<Scalars['String']>
    fitValues: Maybe<Array<Maybe<AttributeOption>>>
    /** Indicates whether a gift message is available. */
    giftMessageAvailable: Maybe<Scalars['String']>
    hexColor: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    highlights: Maybe<Scalars['String']>
    highlightsValues: Maybe<Array<Maybe<AttributeOption>>>
    /**
     * The ID number assigned to the product.
     * @deprecated Use the `uid` field instead.
     */
    id: Scalars['Int']
    /** The relative path to the main image on the product page. */
    image: Maybe<ProductImage>
    /** URL for the Care Tab */
    infoTabCareUrl: Maybe<Scalars['String']>
    /** URL for the Features Tab */
    infoTabFeaturesUrl: Maybe<Scalars['String']>
    /** URL for the Fit Tab */
    infoTabFitUrl: Maybe<Scalars['String']>
    /** URL for the Highlights Tab */
    infoTabHighlightsUrl: Maybe<Scalars['String']>
    /** URL for the Materials Tab */
    infoTabMaterialsUrl: Maybe<Scalars['String']>
    /** An array containing information about individual bundle items. */
    items: Maybe<Array<Maybe<BundleItem>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    jeansSizeCombined: Maybe<Scalars['Int']>
    jeansSizeCombinedValues: Maybe<Array<Maybe<AttributeOption>>>
    /**
     * Proxy for ItemLabelTextB2C
     * @deprecated Use the `custom_attributes` field instead.
     */
    labeltekst: Maybe<Scalars['String']>
    /**
     * Proxy for ItemLabelTypeB2C
     * @deprecated Use the `custom_attributes` field instead.
     */
    labeltype: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    length: Maybe<Scalars['String']>
    lengthValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    lowestDate: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    lowestPrice: Maybe<Scalars['Float']>
    /** Main Category */
    mainCategory: Maybe<CategoryInterface>
    /**
     * A number representing the product's manufacturer.
     * @deprecated Use the `custom_attributes` field instead.
     */
    manufacturer: Maybe<Scalars['Int']>
    manufacturerValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    materialInside: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    materialOutside: Maybe<Scalars['String']>
    /** An array of media gallery objects. */
    mediaGallery: Maybe<Array<Maybe<MediaGalleryInterface>>>
    /**
     * An array of MediaGalleryEntry objects.
     * @deprecated Use `media_gallery` instead.
     */
    mediaGalleryEntries: Maybe<Array<Maybe<MediaGalleryEntry>>>
    /** A brief overview of the product for search results listings, maximum 255 characters. */
    metaDescription: Maybe<Scalars['String']>
    /** A comma-separated list of keywords that are visible only to search engines. */
    metaKeyword: Maybe<Scalars['String']>
    metaRobots: Maybe<Scalars['String']>
    /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
    metaTitle: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    modelImage: Maybe<Scalars['String']>
    /** Information related to the Model. */
    modelInfo: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    modelName: Maybe<Scalars['String']>
    /** The product name. Customers use this name to identify the product. */
    name: Scalars['String']
    /** The beginning date for new product listings, and determines if the product is featured as a new product. */
    newFromDate: Maybe<Scalars['String']>
    /** The end date for new product listings. */
    newToDate: Maybe<Scalars['String']>
    /** Product stock only x left count */
    onlyXLeftInStock: Maybe<Scalars['Float']>
    /** An array of options for a customizable product. */
    options: Maybe<Array<Maybe<CustomizableOptionInterface>>>
    /** If the product has multiple options, determines where they appear on the product page. */
    optionsContainer: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    outletAdviesprijs: Maybe<Scalars['Float']>
    /** @deprecated Use the `custom_attributes` field instead. */
    parentSku: Maybe<Scalars['String']>
    /**
     * Indicates the price of an item.
     * @deprecated Use `price_range` for product price information.
     */
    price: Maybe<ProductPrices>
    /** The price details of the main product */
    priceDetails: Maybe<PriceDetails>
    /** The range of prices for the product */
    priceRange: PriceRange
    /** An array of `TierPrice` objects. */
    priceTiers: Maybe<Array<Maybe<TierPrice>>>
    /** One of PRICE_RANGE or AS_LOW_AS. */
    priceView: Maybe<PriceViewEnum>
    /** @deprecated Use the `custom_attributes` field instead. */
    productArticleCategoryCode: Maybe<Scalars['Int']>
    productArticleCategoryCodeValues: Maybe<Array<Maybe<AttributeOption>>>
    /**
     * Product type like, jeans, jacket etc.
     * @deprecated Use the `custom_attributes` field instead.
     */
    productGroup: Maybe<Scalars['String']>
    /** An array of `ProductLinks` objects. */
    productLinks: Maybe<Array<Maybe<ProductLinksInterface>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    products: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productsustainable2: Maybe<Scalars['String']>
    productsustainable2Values: Maybe<Array<Maybe<AttributeOption>>>
    /** The average of all the ratings given to the product. */
    ratingSummary: Scalars['Float']
    /** @deprecated Use the `custom_attributes` field instead. */
    recommendations: Maybe<Scalars['String']>
    /**
     * Contains 0 when there is no redirect error. A value of 301 indicates the URL
     * of the requested resource has been changed permanently, while a value of 302
     * indicates a temporary redirect.
     */
    redirectCode: Scalars['Int']
    /** An array of products to be displayed in a Related Products block. */
    relatedProducts: Maybe<Array<Maybe<ProductInterface>>>
    /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
    relativeUrl: Maybe<Scalars['String']>
    /** The total count of all the reviews given to the product. */
    reviewCount: Scalars['Int']
    /** The list of products reviews. */
    reviews: ProductReviews
    /** @deprecated Use the `custom_attributes` field instead. */
    seasonCollection: Maybe<Scalars['String']>
    seasonCollectionValues: Maybe<Array<Maybe<AttributeOption>>>
    /** Indicates whether to ship bundle items together or individually. */
    shipBundleItems: Maybe<ShipBundleItemsEnum>
    /** @deprecated Use the `custom_attributes` field instead. */
    shopthelook: Maybe<Scalars['Int']>
    /** A short description of the product. Its use depends on the theme. */
    shortDescription: Maybe<ComplexTextValue>
    /** @deprecated Use the `custom_attributes` field instead. */
    shortDescription2: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    shortDescription3: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    shortDescription4: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    shortDescription5: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    shortDescription6: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    size: Maybe<Scalars['String']>
    sizeValues: Maybe<Array<Maybe<AttributeOption>>>
    /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
    sku: Scalars['String']
    /** The relative path to the small image, which is used on catalog pages. */
    smallImage: Maybe<ProductImage>
    /**
     * The beginning date that a product has a special price.
     * @deprecated The field should not be used on the storefront.
     */
    specialFromDate: Maybe<Scalars['String']>
    /** The discounted price of the product. */
    specialPrice: Maybe<Scalars['Float']>
    /** The end date for a product with a special price. */
    specialToDate: Maybe<Scalars['String']>
    /** Stock status of the product */
    stockStatus: Maybe<ProductStockStatus>
    /** The file name of a swatch image. */
    swatchImage: Maybe<Scalars['String']>
    /** The relative path to the product's thumbnail image. */
    thumbnail: Maybe<ProductImage>
    /**
     * The price when tier pricing is in effect and the items purchased threshold has been reached.
     * @deprecated Use `price_tiers` for product tier price information.
     */
    tierPrice: Maybe<Scalars['Float']>
    /**
     * An array of ProductTierPrices objects.
     * @deprecated Use `price_tiers` for product tier price information.
     */
    tierPrices: Maybe<Array<Maybe<ProductTierPrices>>>
    tweakwiseCrosssellGroupCode: Maybe<Scalars['String']>
    /** Value -1 means the group_code should be used */
    tweakwiseCrosssellTemplate: Maybe<Scalars['Int']>
    tweakwiseUpsellGroupCode: Maybe<Scalars['String']>
    /** Value -1 means the group_code should be used */
    tweakwiseUpsellTemplate: Maybe<Scalars['Int']>
    /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
    type: Maybe<UrlRewriteEntityTypeEnum>
    /**
     * One of simple, virtual, bundle, downloadable, grouped, or configurable.
     * @deprecated Use `__typename` instead.
     */
    typeId: Maybe<Scalars['String']>
    /** The unique ID for a `ProductInterface` object. */
    uid: Scalars['ID']
    /**
     * Timestamp indicating when the product was updated.
     * @deprecated The field should not be used on the storefront.
     */
    updatedAt: Maybe<Scalars['String']>
    /** Upsell Products */
    upsellProducts: Maybe<Array<Maybe<ProductInterface>>>
    /** The part of the URL that identifies the product */
    urlKey: Scalars['String']
    /** @deprecated Use product's `canonical_url` or url rewrites instead */
    urlPath: Maybe<Scalars['String']>
    /** URL rewrites list */
    urlRewrites: Maybe<Array<Maybe<UrlRewrite>>>
    /** The part of the product URL that is appended after the url key */
    urlSuffix: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    uspIcons: Maybe<Scalars['String']>
    uspIconsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** USPs for this specific Product. */
    usps: Array<Maybe<Usp>>
    warehouse: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    washingInstructions: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    washingSymbols: Maybe<Scalars['String']>
    washingSymbolsValues: Maybe<Array<Maybe<AttributeOption>>>
    /**
     * An array of websites in which the product is available.
     * @deprecated Use the `custom_attributes` field instead.
     */
    websites: Maybe<Array<Maybe<Website>>>
    /** The weight of the item, in units defined by the store. */
    weight: Maybe<Scalars['Float']>
}

/** Defines basic features of a bundle product and contains multiple BundleItems. */
export interface BundleProductCustomAttributesV2Args {
    filters: Maybe<AttributeFilterInput>
}

/** Defines basic features of a bundle product and contains multiple BundleItems. */
export interface BundleProductReviewsArgs {
    pageSize?: Maybe<Scalars['Int']>
    currentPage?: Maybe<Scalars['Int']>
}

/** Defines basic features of a bundle product and contains multiple BundleItems. */
export interface BundleProductUspsArgs {
    pageSize?: Maybe<Scalars['Int']>
    currentPage?: Maybe<Scalars['Int']>
}

/** Defines a single bundle product. */
export interface BundleProductCartItemInput {
    /** A mandatory array of options for the bundle product, including each chosen option and specified quantity. */
    bundle_options: Array<Maybe<BundleOptionInput>>
    /** The ID and value of the option. */
    customizable_options?: Maybe<Array<Maybe<CustomizableOptionInput>>>
    /** The quantity and SKU of the bundle product. */
    data: CartItemInput
}

/** Defines bundle product options for `ShipmentItemInterface`. */
export interface BundleShipmentItem extends ShipmentItemInterface {
    __typename?: 'BundleShipmentItem'
    /** A list of bundle options that are assigned to a shipped product. */
    bundleOptions: Maybe<Array<Maybe<ItemSelectedBundleOption>>>
    /** The unique ID for a `ShipmentItemInterface` object. */
    id: Scalars['ID']
    /** The order item associated with the shipment item. */
    orderItem: Maybe<OrderItemInterface>
    /** The name of the base product. */
    productName: Maybe<Scalars['String']>
    /** The sale price for the base product. */
    productSalePrice: Money
    /** The SKU of the base product. */
    productSku: Scalars['String']
    /** The number of shipped items. */
    quantityShipped: Scalars['Float']
}

/** Defines bundle product options for `WishlistItemInterface`. */
export interface BundleWishlistItem extends WishlistItemInterface {
    __typename?: 'BundleWishlistItem'
    /** The date and time the item was added to the wish list. */
    addedAt: Scalars['String']
    /** An array containing information about the selected bundle items. */
    bundleOptions: Maybe<Array<Maybe<SelectedBundleOption>>>
    /** Custom options selected for the wish list item. */
    customizableOptions: Array<Maybe<SelectedCustomizableOption>>
    /** The description of the item. */
    description: Maybe<Scalars['String']>
    /** The unique ID for a `WishlistItemInterface` object. */
    id: Scalars['ID']
    /** Product details of the wish list item. */
    product: Maybe<ProductInterface>
    /** The quantity of this wish list item. */
    quantity: Scalars['Float']
}

export enum CacheControlScope {
    PUBLIC = 'PUBLIC',
    PRIVATE = 'PRIVATE',
}

export interface CancellationReason {
    __typename?: 'CancellationReason'
    description: Scalars['String']
}

/** Defines the order to cancel. */
export interface CancelOrderInput {
    /** Order ID. */
    order_id: Scalars['ID']
    /** Cancellation reason. */
    reason: Scalars['String']
}

/** Contains the updated customer order and error message if any. */
export interface CancelOrderOutput {
    __typename?: 'CancelOrderOutput'
    /** Error encountered while cancelling the order. */
    error: Maybe<Scalars['String']>
    /** Updated customer order. */
    order: Maybe<CustomerOrder>
}

/** Contains the contents and other details about a guest or customer cart. */
export interface Cart {
    __typename?: 'Cart'
    /** @deprecated Use `applied_coupons` instead. */
    appliedCoupon: Maybe<AppliedCoupon>
    /** An array of `AppliedCoupon` objects. Each object contains the `code` text attribute, which specifies the coupon code. */
    appliedCoupons: Maybe<Array<Maybe<AppliedCoupon>>>
    /** An array of available payment methods. */
    availablePaymentMethods: Maybe<Array<Maybe<AvailablePaymentMethod>>>
    /** The billing address assigned to the cart. */
    billingAddress: Maybe<BillingCartAddress>
    bonusDiscount: Maybe<BonusProducts>
    /** The email address of the guest or customer. */
    email: Maybe<Scalars['String']>
    extensionAttributes: Maybe<CartExtensionAttributes>
    freeGifts: Maybe<Array<Maybe<FreeGiftRule>>>
    /** The entered gift message for the cart */
    giftMessage: Maybe<GiftMessage>
    /** The unique ID for a `Cart` object. */
    id: Scalars['ID']
    /** Indicates whether the cart contains only virtual products. */
    isVirtual: Scalars['Boolean']
    /**
     * An array of products that have been added to the cart.
     * @deprecated Use `itemsV2` instead.
     */
    items: Maybe<Array<Maybe<CartItemInterface>>>
    itemsV2: Maybe<CartItems>
    /** Pricing details for the quote. */
    prices: Maybe<CartPrices>
    /** Indicates which payment method was applied to the cart. */
    selectedPaymentMethod: Maybe<SelectedPaymentMethod>
    /** An array of shipping addresses assigned to the cart. */
    shippingAddresses: Array<Maybe<ShippingCartAddress>>
    /** The total number of items in the cart. */
    totalQuantity: Scalars['Float']
}

/** Contains the contents and other details about a guest or customer cart. */
export interface CartItemsV2Args {
    pageSize?: Maybe<Scalars['Int']>
    currentPage?: Maybe<Scalars['Int']>
    sort: Maybe<QuoteItemsSortInput>
}

/** Contains details the country in a billing or shipping address. */
export interface CartAddressCountry {
    __typename?: 'CartAddressCountry'
    /** The country code. */
    code: Scalars['String']
    /** The display label for the country. */
    label: Scalars['String']
}

/** Defines the billing or shipping address to be applied to the cart. */
export interface CartAddressInput {
    /** The city specified for the billing or shipping address. */
    city: Scalars['String']
    /** The company specified for the billing or shipping address. */
    company?: Maybe<Scalars['String']>
    /** The country code and label for the billing or shipping address. */
    country_code: Scalars['String']
    /** The customer's fax number. */
    fax?: Maybe<Scalars['String']>
    /** The first name of the customer or guest. */
    firstname: Scalars['String']
    /** The last name of the customer or guest. */
    lastname: Scalars['String']
    /** The middle name of the person associated with the billing/shipping address. */
    middlename?: Maybe<Scalars['String']>
    /** The ZIP or postal code of the billing or shipping address. */
    postcode?: Maybe<Scalars['String']>
    /** An honorific, such as Dr., Mr., or Mrs. */
    prefix?: Maybe<Scalars['String']>
    /** A string that defines the state or province of the billing or shipping address. */
    region?: Maybe<Scalars['String']>
    /** An integer that defines the state or province of the billing or shipping address. */
    region_id?: Maybe<Scalars['Int']>
    /** Determines whether to save the address in the customer's address book. The default value is true. */
    save_in_address_book?: Maybe<Scalars['Boolean']>
    /** An array containing the street for the billing or shipping address. */
    street: Array<Maybe<Scalars['String']>>
    /** A value such as Sr., Jr., or III. */
    suffix?: Maybe<Scalars['String']>
    /** The telephone number for the billing or shipping address. */
    telephone?: Maybe<Scalars['String']>
    /** The VAT company number for billing or shipping address. */
    vat_id?: Maybe<Scalars['String']>
}

export interface CartAddressInterface {
    /** The city specified for the billing or shipping address. */
    city: Scalars['String']
    /** The company specified for the billing or shipping address. */
    company: Maybe<Scalars['String']>
    /** An object containing the country label and code. */
    country: CartAddressCountry
    /** The customer's fax number. */
    fax: Maybe<Scalars['String']>
    /** The first name of the customer or guest. */
    firstname: Scalars['String']
    /** The last name of the customer or guest. */
    lastname: Scalars['String']
    /** The middle name of the person associated with the billing/shipping address. */
    middlename: Maybe<Scalars['String']>
    /** The ZIP or postal code of the billing or shipping address. */
    postcode: Maybe<Scalars['String']>
    /** An honorific, such as Dr., Mr., or Mrs. */
    prefix: Maybe<Scalars['String']>
    /** An object containing the region label and code. */
    region: Maybe<CartAddressRegion>
    /** An array containing the street for the billing or shipping address. */
    street: Array<Maybe<Scalars['String']>>
    /** A value such as Sr., Jr., or III. */
    suffix: Maybe<Scalars['String']>
    /** The telephone number for the billing or shipping address. */
    telephone: Maybe<Scalars['String']>
    /** The unique id of the customer address. */
    uid: Scalars['String']
    /** The VAT company number for billing or shipping address. */
    vatId: Maybe<Scalars['String']>
}

/** Contains details about the region in a billing or shipping address. */
export interface CartAddressRegion {
    __typename?: 'CartAddressRegion'
    /** The state or province code. */
    code: Maybe<Scalars['String']>
    /** The display label for the region. */
    label: Maybe<Scalars['String']>
    /** The unique ID for a pre-defined region. */
    regionId: Maybe<Scalars['Int']>
}

/** Contains information about discounts applied to the cart. */
export interface CartDiscount {
    __typename?: 'CartDiscount'
    /** The amount of the discount applied to the item. */
    amount: Money
    /** The description of the discount. */
    label: Array<Maybe<Scalars['String']>>
}

export enum CartDiscountType {
    ITEM = 'ITEM',
    SHIPPING = 'SHIPPING',
}

export interface CartExtensionAttributes {
    __typename?: 'CartExtensionAttributes'
    cartId: Maybe<Scalars['String']>
    cloneUrl: Maybe<Scalars['String']>
    dhlparcelShippingServicepointCountryId: Maybe<Scalars['String']>
    dhlparcelShippingServicepointId: Maybe<Scalars['String']>
    freeGifts: Maybe<Scalars['String']>
    shippingAssignments: Maybe<Scalars['String']>
    shippingEstimateMin: Maybe<Scalars['String']>
}

export interface CartItemError {
    __typename?: 'CartItemError'
    /** An error code that describes the error encountered */
    code: CartItemErrorType
    /** A localized error message */
    message: Scalars['String']
}

export enum CartItemErrorType {
    UNDEFINED = 'UNDEFINED',
    ITEM_QTY = 'ITEM_QTY',
    ITEM_INCREMENTS = 'ITEM_INCREMENTS',
}

export interface CartItemExtensionAttributes {
    __typename?: 'CartItemExtensionAttributes'
    discounts: Maybe<Scalars['String']>
    isFreeGift: Maybe<Scalars['String']>
    isPersonalShopperBoxItem: Maybe<Scalars['String']>
    mainCategory: Maybe<Scalars['String']>
    personalShopperBoxItemId: Maybe<Scalars['String']>
    promotional: Maybe<Scalars['String']>
}

/** Defines an item to be added to the cart. */
export interface CartItemInput {
    /** An array of entered options for the base product, such as personalization text. */
    entered_options?: Maybe<Array<Maybe<EnteredOptionInput>>>
    /** For a child product, the SKU of its parent product. */
    parent_sku?: Maybe<Scalars['String']>
    /** The amount or number of an item to add. */
    quantity: Scalars['Float']
    /**
     * The selected options for the base product, such as color or size, using the
     * unique ID for an object such as `CustomizableRadioOption`,
     * `CustomizableDropDownOption`, or `ConfigurableProductOptionsValues`.
     */
    selected_options?: Maybe<Array<Maybe<Scalars['ID']>>>
    /** The SKU of the product. */
    sku: Scalars['String']
}

/** An interface for products in a cart. */
export interface CartItemInterface {
    /** An array of errors encountered while loading the cart item */
    errors: Maybe<Array<Maybe<CartItemError>>>
    extensionAttributes: Maybe<CartItemExtensionAttributes>
    /** @deprecated Use `uid` instead. */
    id: Scalars['String']
    /** True if requested quantity is less than available stock, false otherwise. */
    isAvailable: Scalars['Boolean']
    isFreeGift: Maybe<Scalars['Boolean']>
    /** Contains details about the price of the item, including taxes and discounts. */
    prices: Maybe<CartItemPrices>
    /** Details about an item in the cart. */
    product: ProductInterface
    promotional: Maybe<Scalars['Boolean']>
    /** The quantity of this item in the cart. */
    quantity: Scalars['Float']
    reservation: Maybe<Reservation>
    /** The unique ID for a `CartItemInterface` object. */
    uid: Scalars['ID']
}

/** Contains details about the price of the item, including taxes and discounts. */
export interface CartItemPrices {
    __typename?: 'CartItemPrices'
    /** An array of discounts to be applied to the cart item. */
    discounts: Maybe<Array<Maybe<Discount>>>
    /** An array of FPTs applied to the cart item. */
    fixedProductTaxes: Maybe<Array<Maybe<FixedProductTax>>>
    /**
     * The price of the item before any discounts were applied. The price that might
     * include tax, depending on the configured display settings for cart.
     */
    price: Money
    /**
     * The price of the item before any discounts were applied. The price that might
     * include tax, depending on the configured display settings for cart.
     */
    priceIncludingTax: Money
    /** The value of the price multiplied by the quantity of the item. */
    rowTotal: Money
    /** The value of `row_total` plus the tax applied to the item. */
    rowTotalIncludingTax: Money
    /** The total of all discounts applied to the item. */
    totalItemDiscount: Maybe<Money>
}

/** Deprecated: The `ShippingCartAddress.cart_items` field now returns `CartItemInterface`. */
export interface CartItemQuantity {
    __typename?: 'CartItemQuantity'
    /** @deprecated The `ShippingCartAddress.cart_items` field now returns `CartItemInterface`. */
    cartItemId: Scalars['Int']
    /** @deprecated The `ShippingCartAddress.cart_items` field now returns `CartItemInterface`. */
    quantity: Scalars['Float']
}

export interface CartItems {
    __typename?: 'CartItems'
    /** An array of products that have been added to the cart. */
    items: Array<Maybe<CartItemInterface>>
    /** Metadata for pagination rendering. */
    pageInfo: Maybe<SearchResultPageInfo>
    /** The number of returned cart items. */
    totalCount: Scalars['Int']
}

/** Contains details about the price of a selected customizable value. */
export interface CartItemSelectedOptionValuePrice {
    __typename?: 'CartItemSelectedOptionValuePrice'
    /** Indicates whether the price type is fixed, percent, or dynamic. */
    type: PriceTypeEnum
    /** A string that describes the unit of the value. */
    units: Scalars['String']
    /** A price value. */
    value: Scalars['Float']
}

/** A single item to be updated. */
export interface CartItemUpdateInput {
    /** Deprecated. Use `cart_item_uid` instead. */
    cart_item_id?: Maybe<Scalars['Int']>
    /** The unique ID for a `CartItemInterface` object. */
    cart_item_uid?: Maybe<Scalars['ID']>
    /** An array that defines configurable options for the product. */
    configurable_options?: Maybe<Array<Maybe<ConfigurableOptionInput>>>
    /** An array that defines customizable options for the product. */
    customizable_options?: Maybe<Array<Maybe<CustomizableOptionInput>>>
    /** Gift message details for the cart item */
    gift_message?: Maybe<GiftMessageInput>
    /** The new quantity of the item. */
    quantity?: Maybe<Scalars['Float']>
}

/** Contains details about the final price of items in the cart, including discount and tax information. */
export interface CartPrices {
    __typename?: 'CartPrices'
    /** An array containing the names and amounts of taxes applied to each item in the cart. */
    appliedTaxes: Maybe<Array<Maybe<CartTaxItem>>>
    /** @deprecated Use discounts instead. */
    discount: Maybe<CartDiscount>
    /** An array containing cart rule discounts, store credit and gift cards applied to the cart. */
    discounts: Maybe<Array<Maybe<Discount>>>
    /** The total, including discounts, taxes, shipping, and other fees. */
    grandTotal: Maybe<Money>
    /** The subtotal without any applied taxes. */
    subtotalExcludingTax: Maybe<Money>
    /** The subtotal including any applied taxes. */
    subtotalIncludingTax: Maybe<Money>
    /** The subtotal with any discounts applied, but not taxes. */
    subtotalWithDiscountExcludingTax: Maybe<Money>
}

/** Contains tax information about an item in the cart. */
export interface CartTaxItem {
    __typename?: 'CartTaxItem'
    /** The amount of tax applied to the item. */
    amount: Money
    /** The description of the tax. */
    label: Scalars['String']
}

/** An error encountered while adding an item to the the cart. */
export interface CartUserInputError {
    __typename?: 'CartUserInputError'
    /** A cart-specific error code. */
    code: CartUserInputErrorType
    /** A localized error message. */
    message: Scalars['String']
}

export enum CartUserInputErrorType {
    PRODUCT_NOT_FOUND = 'PRODUCT_NOT_FOUND',
    NOT_SALABLE = 'NOT_SALABLE',
    INSUFFICIENT_STOCK = 'INSUFFICIENT_STOCK',
    UNDEFINED = 'UNDEFINED',
}

export interface CastironFaqPage extends Document, Linkable {
    __typename?: 'CastironFaqPage'
    urlKey: Maybe<Scalars['String']>
    search: Maybe<Scalars['Boolean']>
    seoTitle: Maybe<Scalars['String']>
    seoDescription: Maybe<Scalars['String']>
    seoKeywords: Maybe<Scalars['String']>
    style: Maybe<Scalars['String']>
    head: Maybe<Scalars['Json']>
    noindex: Maybe<Scalars['Boolean']>
    nofollow: Maybe<Scalars['Boolean']>
    body: Maybe<Array<CastironFaqPageBody>>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

export type CastironFaqPageBody =
    | CastironFaqPageBodyHero
    | CastironFaqPageBodyFaqSubject

export interface CastironFaqPageBodyFaqSubject {
    __typename?: 'CastironFaqPageBodyFaqSubject'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<CastironFaqPageBodyFaqSubjectPrimary>
    fields: Maybe<Array<CastironFaqPageBodyFaqSubjectFields>>
}

export interface CastironFaqPageBodyFaqSubjectFields {
    __typename?: 'CastironFaqPageBodyFaqSubjectFields'
    heading: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
}

export interface CastironFaqPageBodyFaqSubjectPrimary {
    __typename?: 'CastironFaqPageBodyFaqSubjectPrimary'
    urlKey: Maybe<Scalars['String']>
    seoTitle: Maybe<Scalars['String']>
    seoKeywords: Maybe<Scalars['String']>
    seoDescription: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    icon: Maybe<PrismicImageType>
}

export interface CastironFaqPageBodyHero {
    __typename?: 'CastironFaqPageBodyHero'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<CastironFaqPageBodyHeroPrimary>
}

export interface CastironFaqPageBodyHeroPrimary {
    __typename?: 'CastironFaqPageBodyHeroPrimary'
    fullWidth: Maybe<Scalars['Boolean']>
    size: Maybe<Scalars['String']>
    headingfuss: Maybe<Scalars['Boolean']>
    header: Maybe<Scalars['String']>
    headingVariant: Maybe<Scalars['String']>
    headingElement: Maybe<Scalars['String']>
    subheading: Maybe<Scalars['String']>
    subheadingVariant: Maybe<Scalars['String']>
    subheadingElement: Maybe<Scalars['String']>
    videoId: Maybe<Scalars['String']>
    mobileVideoId: Maybe<Scalars['String']>
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
}

/** A connection to a list of items. */
export interface CastironFaqPageConnectionConnection {
    __typename?: 'CastironFaqPageConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<CastironFaqPageConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface CastironFaqPageConnectionEdge {
    __typename?: 'CastironFaqPageConnectionEdge'
    /** The item at the end of the edge. */
    node: CastironFaqPage
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

export interface CastironPage extends Document, Linkable {
    __typename?: 'CastironPage'
    urlKey: Maybe<Scalars['String']>
    search: Maybe<Scalars['Boolean']>
    seoTitle: Maybe<Scalars['String']>
    seoDescription: Maybe<Scalars['String']>
    seoKeywords: Maybe<Scalars['String']>
    style: Maybe<Scalars['String']>
    head: Maybe<Scalars['Json']>
    noindex: Maybe<Scalars['Boolean']>
    nofollow: Maybe<Scalars['Boolean']>
    authRequired: Maybe<Scalars['Boolean']>
    pageType: Maybe<Scalars['String']>
    storyTag: Maybe<PrismicHyperlink>
    storyDescription: Maybe<Scalars['String']>
    storyTitle: Maybe<Scalars['String']>
    publicationField: Maybe<Scalars['Date']>
    thumbnailImage: Maybe<PrismicImageType>
    pageBackground: Maybe<Scalars['Json']>
    pageBackgroundColor: Maybe<Scalars['String']>
    body: Maybe<Array<CastironPageBody>>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

export type CastironPageBody =
    | CastironPageBodyProductHero
    | CastironPageBodyTweakwiseSlider
    | CastironPageBodyImageSlider
    | CastironPageBodyHero
    | CastironPageBodyTextOnly
    | CastironPageBodyImageOnly
    | CastironPageBodyTextWithMedia
    | CastironPageBodyOverlappingImages
    | CastironPageBodyHtml
    | CastironPageBodyCategoryElement
    | CastironPageBodyFaq
    | CastironPageBodyBannerBlocks
    | CastironPageBodyBannerBlocks1
    | CastironPageBodyBannerBlocks2
    | CastironPageBodyCategoryList
    | CastironPageBodyStoriesOverviewHeader
    | CastironPageBodyStoryOverviewPage
    | CastironPageBodyVisualFilter
    | CastironPageBodyBlog

export interface CastironPageBodyBannerBlocks {
    __typename?: 'CastironPageBodyBannerBlocks'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<CastironPageBodyBannerBlocksPrimary>
    fields: Maybe<Array<CastironPageBodyBannerBlocksFields>>
}

export interface CastironPageBodyBannerBlocks1 {
    __typename?: 'CastironPageBodyBannerBlocks1'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<CastironPageBodyBannerBlocks1Primary>
    fields: Maybe<Array<CastironPageBodyBannerBlocks1Fields>>
}

export interface CastironPageBodyBannerBlocks1Fields {
    __typename?: 'CastironPageBodyBannerBlocks1Fields'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    subtitle: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

export interface CastironPageBodyBannerBlocks1Primary {
    __typename?: 'CastironPageBodyBannerBlocks1Primary'
    style: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
}

export interface CastironPageBodyBannerBlocks2 {
    __typename?: 'CastironPageBodyBannerBlocks2'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<CastironPageBodyBannerBlocks2Primary>
    fields: Maybe<Array<CastironPageBodyBannerBlocks2Fields>>
}

export interface CastironPageBodyBannerBlocks2Fields {
    __typename?: 'CastironPageBodyBannerBlocks2Fields'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    subtitle: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

export interface CastironPageBodyBannerBlocks2Primary {
    __typename?: 'CastironPageBodyBannerBlocks2Primary'
    style: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
}

export interface CastironPageBodyBannerBlocksFields {
    __typename?: 'CastironPageBodyBannerBlocksFields'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
}

export interface CastironPageBodyBannerBlocksPrimary {
    __typename?: 'CastironPageBodyBannerBlocksPrimary'
    style: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
}

export interface CastironPageBodyBlog {
    __typename?: 'CastironPageBodyBlog'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<CastironPageBodyBlogPrimary>
    fields: Maybe<Array<CastironPageBodyBlogFields>>
}

export interface CastironPageBodyBlogFields {
    __typename?: 'CastironPageBodyBlogFields'
    desktopImage: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    header: Maybe<Scalars['String']>
    subHeader: Maybe<Scalars['String']>
    blogText: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
}

export interface CastironPageBodyBlogPrimary {
    __typename?: 'CastironPageBodyBlogPrimary'
    title: Maybe<Scalars['String']>
    blogStyle: Maybe<Scalars['String']>
}

export interface CastironPageBodyCategoryElement {
    __typename?: 'CastironPageBodyCategoryElement'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<CastironPageBodyCategoryElementPrimary>
    fields: Maybe<Array<CastironPageBodyCategoryElementFields>>
}

export interface CastironPageBodyCategoryElementFields {
    __typename?: 'CastironPageBodyCategoryElementFields'
    title: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
}

export interface CastironPageBodyCategoryElementPrimary {
    __typename?: 'CastironPageBodyCategoryElementPrimary'
    style: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
}

export interface CastironPageBodyCategoryList {
    __typename?: 'CastironPageBodyCategoryList'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<CastironPageBodyCategoryListPrimary>
    fields: Maybe<Array<CastironPageBodyCategoryListFields>>
}

export interface CastironPageBodyCategoryListFields {
    __typename?: 'CastironPageBodyCategoryListFields'
    title: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
    list: Maybe<Scalars['String']>
}

export interface CastironPageBodyCategoryListPrimary {
    __typename?: 'CastironPageBodyCategoryListPrimary'
    style: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    title1: Maybe<Scalars['String']>
    title2: Maybe<Scalars['String']>
    title3: Maybe<Scalars['String']>
    title4: Maybe<Scalars['String']>
}

export interface CastironPageBodyFaq {
    __typename?: 'CastironPageBodyFaq'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<CastironPageBodyFaqPrimary>
    fields: Maybe<Array<CastironPageBodyFaqFields>>
}

export interface CastironPageBodyFaqFields {
    __typename?: 'CastironPageBodyFaqFields'
    iconimage: Maybe<PrismicImageType>
    category: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
}

export interface CastironPageBodyFaqPrimary {
    __typename?: 'CastironPageBodyFaqPrimary'
    style: Maybe<Scalars['String']>
    emptyCategoryText: Maybe<Scalars['String']>
}

export interface CastironPageBodyHero {
    __typename?: 'CastironPageBodyHero'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<CastironPageBodyHeroPrimary>
}

export interface CastironPageBodyHeroPrimary {
    __typename?: 'CastironPageBodyHeroPrimary'
    fullWidth: Maybe<Scalars['Boolean']>
    size: Maybe<Scalars['String']>
    headingfuss: Maybe<Scalars['Boolean']>
    header: Maybe<Scalars['String']>
    headingVariant: Maybe<Scalars['String']>
    headingElement: Maybe<Scalars['String']>
    subheading: Maybe<Scalars['String']>
    subheadingVariant: Maybe<Scalars['String']>
    subheadingElement: Maybe<Scalars['String']>
    videoId: Maybe<Scalars['String']>
    mobileVideoId: Maybe<Scalars['String']>
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
}

export interface CastironPageBodyHtml {
    __typename?: 'CastironPageBodyHtml'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<CastironPageBodyHtmlPrimary>
}

export interface CastironPageBodyHtmlPrimary {
    __typename?: 'CastironPageBodyHtmlPrimary'
    style: Maybe<Scalars['String']>
    html: Maybe<Scalars['Json']>
    tablet: Maybe<Scalars['String']>
    desktop: Maybe<Scalars['String']>
}

export interface CastironPageBodyImageOnly {
    __typename?: 'CastironPageBodyImageOnly'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<CastironPageBodyImageOnlyPrimary>
}

export interface CastironPageBodyImageOnlyPrimary {
    __typename?: 'CastironPageBodyImageOnlyPrimary'
    style: Maybe<Scalars['String']>
    tablet: Maybe<Scalars['String']>
    desktop: Maybe<Scalars['String']>
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
}

export interface CastironPageBodyImageSlider {
    __typename?: 'CastironPageBodyImageSlider'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<CastironPageBodyImageSliderPrimary>
    fields: Maybe<Array<CastironPageBodyImageSliderFields>>
}

export interface CastironPageBodyImageSliderFields {
    __typename?: 'CastironPageBodyImageSliderFields'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    subtitle: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

export interface CastironPageBodyImageSliderPrimary {
    __typename?: 'CastironPageBodyImageSliderPrimary'
    style: Maybe<Scalars['String']>
    variant: Maybe<Scalars['String']>
    header: Maybe<Scalars['String']>
    content: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

export interface CastironPageBodyOverlappingImages {
    __typename?: 'CastironPageBodyOverlappingImages'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<CastironPageBodyOverlappingImagesPrimary>
    fields: Maybe<Array<CastironPageBodyOverlappingImagesFields>>
}

export interface CastironPageBodyOverlappingImagesFields {
    __typename?: 'CastironPageBodyOverlappingImagesFields'
    backgroundImage: Maybe<PrismicImageType>
    frontImage: Maybe<PrismicImageType>
}

export interface CastironPageBodyOverlappingImagesPrimary {
    __typename?: 'CastironPageBodyOverlappingImagesPrimary'
    style: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
}

export interface CastironPageBodyProductHero {
    __typename?: 'CastironPageBodyProductHero'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<CastironPageBodyProductHeroPrimary>
}

export interface CastironPageBodyProductHeroPrimary {
    __typename?: 'CastironPageBodyProductHeroPrimary'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    content: Maybe<Scalars['String']>
    subheading: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
    button2Url: Maybe<Scalars['String']>
    button2Text: Maybe<Scalars['String']>
    buttonStyle: Maybe<Scalars['String']>
    textStyle: Maybe<Scalars['String']>
    subheadingStyle: Maybe<Scalars['String']>
    videoId: Maybe<Scalars['String']>
    mobileVideoId: Maybe<Scalars['String']>
    productSkus: Maybe<Scalars['String']>
    productCueTimes: Maybe<Scalars['String']>
}

export interface CastironPageBodyStoriesOverviewHeader {
    __typename?: 'CastironPageBodyStoriesOverviewHeader'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    fields: Maybe<Array<CastironPageBodyStoriesOverviewHeaderFields>>
}

export interface CastironPageBodyStoriesOverviewHeaderFields {
    __typename?: 'CastironPageBodyStoriesOverviewHeaderFields'
    desktopImage: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    subtitle: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

export interface CastironPageBodyStoryOverviewPage {
    __typename?: 'CastironPageBodyStoryOverviewPage'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<CastironPageBodyStoryOverviewPagePrimary>
}

export interface CastironPageBodyStoryOverviewPagePrimary {
    __typename?: 'CastironPageBodyStoryOverviewPagePrimary'
    showStories: Maybe<Scalars['Boolean']>
}

export interface CastironPageBodyTextOnly {
    __typename?: 'CastironPageBodyTextOnly'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<CastironPageBodyTextOnlyPrimary>
}

export interface CastironPageBodyTextOnlyPrimary {
    __typename?: 'CastironPageBodyTextOnlyPrimary'
    style: Maybe<Scalars['String']>
    visibility: Maybe<Scalars['String']>
    tablet: Maybe<Scalars['String']>
    desktop: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    headingVariant: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
    buttonText: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    blockBackground: Maybe<Scalars['Json']>
    blockBackgroundColor: Maybe<Scalars['String']>
}

export interface CastironPageBodyTextWithMedia {
    __typename?: 'CastironPageBodyTextWithMedia'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<CastironPageBodyTextWithMediaPrimary>
    fields: Maybe<Array<CastironPageBodyTextWithMediaFields>>
}

export interface CastironPageBodyTextWithMediaFields {
    __typename?: 'CastironPageBodyTextWithMediaFields'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
}

export interface CastironPageBodyTextWithMediaPrimary {
    __typename?: 'CastironPageBodyTextWithMediaPrimary'
    style: Maybe<Scalars['String']>
    desktopMediaPosition: Maybe<Scalars['String']>
    mobileMediaPosition: Maybe<Scalars['String']>
    alignment: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    headingfuss: Maybe<Scalars['Boolean']>
    headingVariant: Maybe<Scalars['String']>
    headingElement: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
    buttonStyle: Maybe<Scalars['String']>
    button2Url: Maybe<Scalars['String']>
    button2Text: Maybe<Scalars['String']>
    button2Style: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
    videoId: Maybe<Scalars['String']>
    mobileVideoId: Maybe<Scalars['String']>
    blockBackground: Maybe<Scalars['Json']>
    blockBackgroundColor: Maybe<Scalars['String']>
}

export interface CastironPageBodyTweakwiseSlider {
    __typename?: 'CastironPageBodyTweakwiseSlider'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<CastironPageBodyTweakwiseSliderPrimary>
}

export interface CastironPageBodyTweakwiseSliderPrimary {
    __typename?: 'CastironPageBodyTweakwiseSliderPrimary'
    blueconicPosition: Maybe<Scalars['String']>
    style: Maybe<Scalars['String']>
    header: Maybe<Scalars['String']>
    content: Maybe<Scalars['String']>
    recommendationsIdentifier: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

export interface CastironPageBodyVisualFilter {
    __typename?: 'CastironPageBodyVisualFilter'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<CastironPageBodyVisualFilterPrimary>
}

export interface CastironPageBodyVisualFilterPrimary {
    __typename?: 'CastironPageBodyVisualFilterPrimary'
    visualFilter: Maybe<PrismicHyperlink>
}

/** A connection to a list of items. */
export interface CastironPageConnectionConnection {
    __typename?: 'CastironPageConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<CastironPageConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface CastironPageConnectionEdge {
    __typename?: 'CastironPageConnectionEdge'
    /** The item at the end of the edge. */
    node: CastironPage
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

export interface CastironTag extends Document, Linkable {
    __typename?: 'CastironTag'
    tagId: Maybe<Scalars['String']>
    tagName: Maybe<Scalars['String']>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

/** A connection to a list of items. */
export interface CastironTagConnectionConnection {
    __typename?: 'CastironTagConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<CastironTagConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface CastironTagConnectionEdge {
    __typename?: 'CastironTagConnectionEdge'
    /** The item at the end of the edge. */
    node: CastironTag
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

export enum CatalogAttributeApplyToEnum {
    SIMPLE = 'SIMPLE',
    VIRTUAL = 'VIRTUAL',
    BUNDLE = 'BUNDLE',
    DOWNLOADABLE = 'DOWNLOADABLE',
    CONFIGURABLE = 'CONFIGURABLE',
    GROUPED = 'GROUPED',
    CATEGORY = 'CATEGORY',
}

/** Swatch attribute metadata. */
export interface CatalogAttributeMetadata
    extends CustomAttributeMetadataInterface {
    __typename?: 'CatalogAttributeMetadata'
    /** To which catalog types an attribute can be applied. */
    applyTo: Maybe<Array<Maybe<CatalogAttributeApplyToEnum>>>
    /** The unique identifier for an attribute code. This value should be in lowercase letters without spaces. */
    code: Scalars['ID']
    /** Default attribute value. */
    defaultValue: Maybe<Scalars['String']>
    /** The type of entity that defines the attribute. */
    entityType: AttributeEntityTypeEnum
    /** The frontend class of the attribute. */
    frontendClass: Maybe<Scalars['String']>
    /** The frontend input type of the attribute. */
    frontendInput: Maybe<AttributeFrontendInputEnum>
    /** Whether a product or category attribute can be compared against another or not. */
    isComparable: Maybe<Scalars['Boolean']>
    /** Whether a product or category attribute can be filtered or not. */
    isFilterable: Maybe<Scalars['Boolean']>
    /** Whether a product or category attribute can be filtered in search or not. */
    isFilterableInSearch: Maybe<Scalars['Boolean']>
    /** Whether a product or category attribute can use HTML on front or not. */
    isHtmlAllowedOnFront: Maybe<Scalars['Boolean']>
    /** Whether the attribute value is required. */
    isRequired: Scalars['Boolean']
    /** Whether a product or category attribute can be searched or not. */
    isSearchable: Maybe<Scalars['Boolean']>
    /** Whether the attribute value must be unique. */
    isUnique: Scalars['Boolean']
    /** Whether a product or category attribute can be used for price rules or not. */
    isUsedForPriceRules: Maybe<Scalars['Boolean']>
    /** Whether a product or category attribute is used for promo rules or not. */
    isUsedForPromoRules: Maybe<Scalars['Boolean']>
    /** Whether a product or category attribute is visible in advanced search or not. */
    isVisibleInAdvancedSearch: Maybe<Scalars['Boolean']>
    /** Whether a product or category attribute is visible on front or not. */
    isVisibleOnFront: Maybe<Scalars['Boolean']>
    /** Whether a product or category attribute has WYSIWYG enabled or not. */
    isWysiwygEnabled: Maybe<Scalars['Boolean']>
    /** The label assigned to the attribute. */
    label: Maybe<Scalars['String']>
    /** Attribute options. */
    options: Array<Maybe<CustomAttributeOptionInterface>>
    /** Input type of the swatch attribute option. */
    swatchInputType: Maybe<SwatchInputTypeEnum>
    /** Whether update product preview image or not. */
    updateProductPreviewImage: Maybe<Scalars['Boolean']>
    /** Whether use product image for swatch or not. */
    useProductImageForSwatch: Maybe<Scalars['Boolean']>
    /** Whether a product or category attribute is used in product listing or not. */
    usedInProductListing: Maybe<Scalars['Boolean']>
}

export interface Category extends Document, Linkable {
    __typename?: 'Category'
    categoryId: Maybe<Scalars['String']>
    name: Maybe<Scalars['String']>
    body: Maybe<Array<CategoryBody>>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

export interface CategoryBanners extends Document, Linkable {
    __typename?: 'CategoryBanners'
    storeview: Maybe<Scalars['String']>
    body: Maybe<Array<CategoryBannersBody>>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

export type CategoryBannersBody =
    | CategoryBannersBodyFreeGifts
    | CategoryBannersBodyVouchers
    | CategoryBannersBodyBecomeMemberUsp

export interface CategoryBannersBodyBecomeMemberUsp {
    __typename?: 'CategoryBannersBodyBecomeMemberUsp'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<CategoryBannersBodyBecomeMemberUspPrimary>
}

export interface CategoryBannersBodyBecomeMemberUspPrimary {
    __typename?: 'CategoryBannersBodyBecomeMemberUspPrimary'
    show: Maybe<Scalars['Boolean']>
    header: Maybe<Scalars['String']>
    backgroundImage: Maybe<PrismicImageType>
    mobile: Maybe<Scalars['String']>
    tablet: Maybe<Scalars['String']>
    desktop: Maybe<Scalars['String']>
    buttonLabel: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonStyle: Maybe<Scalars['String']>
    memberUspsLink: Maybe<PrismicHyperlink>
}

export interface CategoryBannersBodyFreeGifts {
    __typename?: 'CategoryBannersBodyFreeGifts'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<CategoryBannersBodyFreeGiftsPrimary>
}

export interface CategoryBannersBodyFreeGiftsPrimary {
    __typename?: 'CategoryBannersBodyFreeGiftsPrimary'
    show: Maybe<Scalars['Boolean']>
    mobile: Maybe<Scalars['String']>
    tablet: Maybe<Scalars['String']>
    desktop: Maybe<Scalars['String']>
}

export interface CategoryBannersBodyVouchers {
    __typename?: 'CategoryBannersBodyVouchers'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<CategoryBannersBodyVouchersPrimary>
}

export interface CategoryBannersBodyVouchersPrimary {
    __typename?: 'CategoryBannersBodyVouchersPrimary'
    show: Maybe<Scalars['Boolean']>
    mobile: Maybe<Scalars['String']>
    tablet: Maybe<Scalars['String']>
    desktop: Maybe<Scalars['String']>
}

/** A connection to a list of items. */
export interface CategoryBannersConnectionConnection {
    __typename?: 'CategoryBannersConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<CategoryBannersConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface CategoryBannersConnectionEdge {
    __typename?: 'CategoryBannersConnectionEdge'
    /** The item at the end of the edge. */
    node: CategoryBanners
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

export type CategoryBody =
    | CategoryBodyImageOnlyBlock
    | CategoryBodyTextOnly
    | CategoryBodyNewsletter
    | CategoryBodyEndOfContent
    | CategoryBodySeoFooter
    | CategoryBodySeoHeader
    | CategoryBodyTopBanner
    | CategoryBodyPromoCtaBanner
    | CategoryBodyLoyaltyCard

export interface CategoryBodyEndOfContent {
    __typename?: 'CategoryBodyEndOfContent'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<CategoryBodyEndOfContentPrimary>
}

export interface CategoryBodyEndOfContentPrimary {
    __typename?: 'CategoryBodyEndOfContentPrimary'
    mobile: Maybe<Scalars['String']>
    tablet: Maybe<Scalars['String']>
    desktop: Maybe<Scalars['String']>
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    header: Maybe<Scalars['String']>
    subheader: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

export interface CategoryBodyImageOnlyBlock {
    __typename?: 'CategoryBodyImageOnlyBlock'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<CategoryBodyImageOnlyBlockPrimary>
}

export interface CategoryBodyImageOnlyBlockPrimary {
    __typename?: 'CategoryBodyImageOnlyBlockPrimary'
    mobile: Maybe<Scalars['String']>
    tablet: Maybe<Scalars['String']>
    desktop: Maybe<Scalars['String']>
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    videoId: Maybe<Scalars['String']>
    mobileVideoId: Maybe<Scalars['String']>
    header: Maybe<Scalars['String']>
    subheader: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

export interface CategoryBodyLoyaltyCard {
    __typename?: 'CategoryBodyLoyaltyCard'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<CategoryBodyLoyaltyCardPrimary>
}

export interface CategoryBodyLoyaltyCardPrimary {
    __typename?: 'CategoryBodyLoyaltyCardPrimary'
    mobile: Maybe<Scalars['String']>
    tablet: Maybe<Scalars['String']>
    desktop: Maybe<Scalars['String']>
    guestCard: Maybe<PrismicHyperlink>
    memberCard: Maybe<PrismicHyperlink>
}

export interface CategoryBodyNewsletter {
    __typename?: 'CategoryBodyNewsletter'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<CategoryBodyNewsletterPrimary>
}

export interface CategoryBodyNewsletterPrimary {
    __typename?: 'CategoryBodyNewsletterPrimary'
    mobile: Maybe<Scalars['String']>
    tablet: Maybe<Scalars['String']>
    desktop: Maybe<Scalars['String']>
    source: Maybe<Scalars['String']>
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    header: Maybe<Scalars['String']>
    subheader: Maybe<Scalars['String']>
    thankYouHeader: Maybe<Scalars['String']>
    thankYouSubheader: Maybe<Scalars['String']>
}

export interface CategoryBodyPromoCtaBanner {
    __typename?: 'CategoryBodyPromoCtaBanner'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<CategoryBodyPromoCtaBannerPrimary>
}

export interface CategoryBodyPromoCtaBannerPrimary {
    __typename?: 'CategoryBodyPromoCtaBannerPrimary'
    mobile: Maybe<Scalars['String']>
    tablet: Maybe<Scalars['String']>
    desktop: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    content: Maybe<Scalars['String']>
    icon: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
    button2Url: Maybe<Scalars['String']>
    button2Text: Maybe<Scalars['String']>
}

export interface CategoryBodySeoFooter {
    __typename?: 'CategoryBodySeoFooter'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<CategoryBodySeoFooterPrimary>
    fields: Maybe<Array<CategoryBodySeoFooterFields>>
}

export interface CategoryBodySeoFooterFields {
    __typename?: 'CategoryBodySeoFooterFields'
    header: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
}

export interface CategoryBodySeoFooterPrimary {
    __typename?: 'CategoryBodySeoFooterPrimary'
    seoHeader: Maybe<Scalars['String']>
    seoDescription: Maybe<Scalars['String']>
    seoExtendedDescription: Maybe<Scalars['Json']>
    style: Maybe<Scalars['String']>
}

export interface CategoryBodySeoHeader {
    __typename?: 'CategoryBodySeoHeader'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<CategoryBodySeoHeaderPrimary>
}

export interface CategoryBodySeoHeaderPrimary {
    __typename?: 'CategoryBodySeoHeaderPrimary'
    seoExtendedContent: Maybe<Scalars['Json']>
}

export interface CategoryBodyTextOnly {
    __typename?: 'CategoryBodyTextOnly'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<CategoryBodyTextOnlyPrimary>
}

export interface CategoryBodyTextOnlyPrimary {
    __typename?: 'CategoryBodyTextOnlyPrimary'
    mobile: Maybe<Scalars['String']>
    tablet: Maybe<Scalars['String']>
    desktop: Maybe<Scalars['String']>
    variant: Maybe<Scalars['String']>
    header: Maybe<Scalars['String']>
    subheading: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
}

export interface CategoryBodyTopBanner {
    __typename?: 'CategoryBodyTopBanner'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<CategoryBodyTopBannerPrimary>
}

export interface CategoryBodyTopBannerPrimary {
    __typename?: 'CategoryBodyTopBannerPrimary'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
}

/** A connection to a list of items. */
export interface CategoryConnectionConnection {
    __typename?: 'CategoryConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<CategoryConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface CategoryConnectionEdge {
    __typename?: 'CategoryConnectionEdge'
    /** The item at the end of the edge. */
    node: Category
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

/**
 * Defines the filters to be used in the search. A filter contains at least one
 * attribute, a comparison operator, and the value that is being searched for.
 */
export interface CategoryFilterInput {
    /** Filter by the unique category ID for a `CategoryInterface` object. */
    category_uid?: Maybe<FilterEqualTypeInput>
    /** Deprecated: use 'category_uid' to filter uniquely identifiers of categories. */
    ids?: Maybe<FilterEqualTypeInput>
    /** Filter by the display name of the category. */
    name?: Maybe<FilterMatchTypeInput>
    /** Filter by the unique parent category ID for a `CategoryInterface` object. */
    parent_category_uid?: Maybe<FilterEqualTypeInput>
    /** Filter by the unique parent category ID for a `CategoryInterface` object. */
    parent_id?: Maybe<FilterEqualTypeInput>
    /** Filter by the part of the URL that identifies the category. */
    url_key?: Maybe<FilterEqualTypeInput>
    /** Filter by the URL path for the category. */
    url_path?: Maybe<FilterEqualTypeInput>
}

/** Contains the full set of attributes that can be returned in a category search. */
export interface CategoryInterface {
    availableSortBy: Maybe<Array<Maybe<Scalars['String']>>>
    /** An array of breadcrumb items. */
    breadcrumbs: Maybe<Array<Maybe<Breadcrumb>>>
    /**
     * The relative canonical URL. This value is returned only if the system setting
     * 'Use Canonical Link Meta Tag For Categories' is enabled.
     */
    canonicalUrl: Maybe<Scalars['String']>
    childrenCount: Maybe<Scalars['String']>
    /** Contains a category CMS block. */
    cmsBlock: Maybe<CmsBlock>
    /**
     * The timestamp indicating when the category was created.
     * @deprecated The field should not be used on the storefront.
     */
    createdAt: Maybe<Scalars['String']>
    customLayoutUpdateFile: Maybe<Scalars['String']>
    /** The attribute to use for sorting. */
    defaultSortBy: Maybe<Scalars['String']>
    /** An optional description of the category. */
    description: Maybe<Scalars['String']>
    displayMode: Maybe<Scalars['String']>
    endofcontentButtonLabel: Maybe<Scalars['String']>
    endofcontentButtonLink: Maybe<Scalars['String']>
    endofcontentImage: Maybe<Scalars['String']>
    endofcontentText: Maybe<Scalars['String']>
    filterPriceRange: Maybe<Scalars['Float']>
    flavourButtontext1: Maybe<Scalars['String']>
    flavourButtontext2: Maybe<Scalars['String']>
    flavourButtontext3: Maybe<Scalars['String']>
    flavourButtontext4: Maybe<Scalars['String']>
    flavourButtontext5: Maybe<Scalars['String']>
    flavourButtontext6: Maybe<Scalars['String']>
    flavourButtontext7: Maybe<Scalars['String']>
    flavourButtonurl1: Maybe<Scalars['String']>
    flavourButtonurl2: Maybe<Scalars['String']>
    flavourButtonurl3: Maybe<Scalars['String']>
    flavourButtonurl4: Maybe<Scalars['String']>
    flavourButtonurl5: Maybe<Scalars['String']>
    flavourButtonurl6: Maybe<Scalars['String']>
    flavourButtonurl7: Maybe<Scalars['String']>
    flavourFeatured: Maybe<Scalars['Int']>
    flavourHeader1: Maybe<Scalars['String']>
    flavourHeader2: Maybe<Scalars['String']>
    flavourHeader3: Maybe<Scalars['String']>
    flavourHeader4: Maybe<Scalars['String']>
    flavourHeader5: Maybe<Scalars['String']>
    flavourHeader6: Maybe<Scalars['String']>
    flavourHeader7: Maybe<Scalars['String']>
    flavourImage1: Maybe<Scalars['String']>
    flavourImage2: Maybe<Scalars['String']>
    flavourImage3: Maybe<Scalars['String']>
    flavourImage4: Maybe<Scalars['String']>
    flavourImage5: Maybe<Scalars['String']>
    flavourImage6: Maybe<Scalars['String']>
    flavourImage7: Maybe<Scalars['String']>
    flavourLayoutStyle: Maybe<Scalars['String']>
    flavourSubheader1: Maybe<Scalars['String']>
    flavourSubheader2: Maybe<Scalars['String']>
    flavourSubheader3: Maybe<Scalars['String']>
    flavourSubheader4: Maybe<Scalars['String']>
    flavourSubheader5: Maybe<Scalars['String']>
    flavourSubheader6: Maybe<Scalars['String']>
    flavourSubheader7: Maybe<Scalars['String']>
    flavourText1: Maybe<Scalars['String']>
    flavourText2: Maybe<Scalars['String']>
    flavourText3: Maybe<Scalars['String']>
    flavourText4: Maybe<Scalars['String']>
    flavourText5: Maybe<Scalars['String']>
    flavourText6: Maybe<Scalars['String']>
    flavourText7: Maybe<Scalars['String']>
    highlighted: Maybe<Scalars['Int']>
    /**
     * An ID that uniquely identifies the category.
     * @deprecated Use `uid` instead.
     */
    id: Maybe<Scalars['Int']>
    image: Maybe<Scalars['String']>
    includeInMenu: Maybe<Scalars['Int']>
    isAnchor: Maybe<Scalars['Int']>
    landingPage: Maybe<Scalars['Int']>
    /** The depth of the category within the tree. */
    level: Maybe<Scalars['Int']>
    mainCategoryPosition: Maybe<Scalars['String']>
    metaDescription: Maybe<Scalars['String']>
    metaKeywords: Maybe<Scalars['String']>
    metaRobots: Maybe<Scalars['String']>
    metaTitle: Maybe<Scalars['String']>
    /** The display name of the category. */
    name: Maybe<Scalars['String']>
    newsletterFlavourBlockPosition: Maybe<Scalars['String']>
    newsletterHeader: Maybe<Scalars['String']>
    newsletterImage: Maybe<Scalars['String']>
    newsletterSubheader: Maybe<Scalars['String']>
    parentId: Maybe<Scalars['String']>
    /** The full category path. */
    path: Maybe<Scalars['String']>
    /** The category path within the store. */
    pathInStore: Maybe<Scalars['String']>
    /** The position of the category relative to other categories at the same level in tree. */
    position: Maybe<Scalars['Int']>
    /**
     * The number of products in the category that are marked as visible. By default,
     * in complex products, parent products are visible, but their child products are not.
     */
    productCount: Maybe<Scalars['Int']>
    /** The list of products assigned to the category. */
    products: Maybe<CategoryProducts>
    promotionBackgroundImage: Maybe<Scalars['String']>
    promotionButtonLink: Maybe<Scalars['String']>
    promotionButtonText: Maybe<Scalars['String']>
    promotionLink: Maybe<Scalars['String']>
    promotionText: Maybe<Scalars['String']>
    promotionUseParentSettings: Maybe<Scalars['Int']>
    tweakwiseCrosssellGroupCode: Maybe<Scalars['String']>
    tweakwiseCrosssellTemplate: Maybe<Scalars['Int']>
    tweakwiseFeaturedTemplate: Maybe<Scalars['Int']>
    tweakwiseFilterValuesWhitelist: Maybe<Scalars['String']>
    tweakwiseFilterWhitelist: Maybe<Scalars['String']>
    tweakwiseId: Maybe<Scalars['Int']>
    tweakwiseShoppingcartCrosssellGroupCode: Maybe<Scalars['String']>
    tweakwiseShoppingcartCrosssellTemplate: Maybe<Scalars['Int']>
    tweakwiseUpsellGroupCode: Maybe<Scalars['String']>
    tweakwiseUpsellTemplate: Maybe<Scalars['Int']>
    /** The unique ID for a `CategoryInterface` object. */
    uid: Scalars['ID']
    /**
     * The timestamp indicating when the category was updated.
     * @deprecated The field should not be used on the storefront.
     */
    updatedAt: Maybe<Scalars['String']>
    /** The URL key assigned to the category. */
    urlKey: Maybe<Scalars['String']>
    /** The URL path assigned to the category. */
    urlPath: Maybe<Scalars['String']>
    /** The part of the category URL that is appended after the url key */
    urlSuffix: Maybe<Scalars['String']>
    usp: Maybe<Scalars['String']>
    /** USPs for this specific Category. */
    usps: Array<Maybe<Usp>>
}

/** Contains the full set of attributes that can be returned in a category search. */
export interface CategoryInterfaceProductsArgs {
    pageSize?: Maybe<Scalars['Int']>
    currentPage?: Maybe<Scalars['Int']>
    sort: Maybe<ProductAttributeSortInput>
}

/** Contains the full set of attributes that can be returned in a category search. */
export interface CategoryInterfaceUspsArgs {
    pageSize?: Maybe<Scalars['Int']>
    currentPage?: Maybe<Scalars['Int']>
}

/** Contains details about the products assigned to a category. */
export interface CategoryProducts {
    __typename?: 'CategoryProducts'
    /** An array of products that are assigned to the category. */
    items: Maybe<Array<Maybe<ProductInterface>>>
    /** Pagination metadata. */
    pageInfo: Maybe<SearchResultPageInfo>
    /**
     * The number of products in the category that are marked as visible. By default,
     * in complex products, parent products are visible, but their child products are not.
     */
    totalCount: Maybe<Scalars['Int']>
}

/** Contains a collection of `CategoryTree` objects and pagination information. */
export interface CategoryResult {
    __typename?: 'CategoryResult'
    /** A list of categories that match the filter criteria. */
    items: Maybe<Array<Maybe<CategoryTree>>>
    /** An object that includes the `page_info` and `currentPage` values specified in the query. */
    pageInfo: Maybe<SearchResultPageInfo>
    /** The total number of categories that match the criteria. */
    totalCount: Maybe<Scalars['Int']>
}

/** Contains the hierarchy of categories. */
export interface CategoryTree extends CategoryInterface, RoutableInterface {
    __typename?: 'CategoryTree'
    availableSortBy: Maybe<Array<Maybe<Scalars['String']>>>
    /** An array of breadcrumb items. */
    breadcrumbs: Maybe<Array<Maybe<Breadcrumb>>>
    /**
     * The relative canonical URL. This value is returned only if the system setting
     * 'Use Canonical Link Meta Tag For Categories' is enabled.
     */
    canonicalUrl: Maybe<Scalars['String']>
    /** A tree of child categories. */
    children: Maybe<Array<Maybe<CategoryTree>>>
    childrenCount: Maybe<Scalars['String']>
    /** Contains a category CMS block. */
    cmsBlock: Maybe<CmsBlock>
    /**
     * The timestamp indicating when the category was created.
     * @deprecated The field should not be used on the storefront.
     */
    createdAt: Maybe<Scalars['String']>
    customLayoutUpdateFile: Maybe<Scalars['String']>
    /** The attribute to use for sorting. */
    defaultSortBy: Maybe<Scalars['String']>
    /** An optional description of the category. */
    description: Maybe<Scalars['String']>
    displayMode: Maybe<Scalars['String']>
    endofcontentButtonLabel: Maybe<Scalars['String']>
    endofcontentButtonLink: Maybe<Scalars['String']>
    endofcontentImage: Maybe<Scalars['String']>
    endofcontentText: Maybe<Scalars['String']>
    filterPriceRange: Maybe<Scalars['Float']>
    flavourButtontext1: Maybe<Scalars['String']>
    flavourButtontext2: Maybe<Scalars['String']>
    flavourButtontext3: Maybe<Scalars['String']>
    flavourButtontext4: Maybe<Scalars['String']>
    flavourButtontext5: Maybe<Scalars['String']>
    flavourButtontext6: Maybe<Scalars['String']>
    flavourButtontext7: Maybe<Scalars['String']>
    flavourButtonurl1: Maybe<Scalars['String']>
    flavourButtonurl2: Maybe<Scalars['String']>
    flavourButtonurl3: Maybe<Scalars['String']>
    flavourButtonurl4: Maybe<Scalars['String']>
    flavourButtonurl5: Maybe<Scalars['String']>
    flavourButtonurl6: Maybe<Scalars['String']>
    flavourButtonurl7: Maybe<Scalars['String']>
    flavourFeatured: Maybe<Scalars['Int']>
    flavourHeader1: Maybe<Scalars['String']>
    flavourHeader2: Maybe<Scalars['String']>
    flavourHeader3: Maybe<Scalars['String']>
    flavourHeader4: Maybe<Scalars['String']>
    flavourHeader5: Maybe<Scalars['String']>
    flavourHeader6: Maybe<Scalars['String']>
    flavourHeader7: Maybe<Scalars['String']>
    flavourImage1: Maybe<Scalars['String']>
    flavourImage2: Maybe<Scalars['String']>
    flavourImage3: Maybe<Scalars['String']>
    flavourImage4: Maybe<Scalars['String']>
    flavourImage5: Maybe<Scalars['String']>
    flavourImage6: Maybe<Scalars['String']>
    flavourImage7: Maybe<Scalars['String']>
    flavourLayoutStyle: Maybe<Scalars['String']>
    flavourSubheader1: Maybe<Scalars['String']>
    flavourSubheader2: Maybe<Scalars['String']>
    flavourSubheader3: Maybe<Scalars['String']>
    flavourSubheader4: Maybe<Scalars['String']>
    flavourSubheader5: Maybe<Scalars['String']>
    flavourSubheader6: Maybe<Scalars['String']>
    flavourSubheader7: Maybe<Scalars['String']>
    flavourText1: Maybe<Scalars['String']>
    flavourText2: Maybe<Scalars['String']>
    flavourText3: Maybe<Scalars['String']>
    flavourText4: Maybe<Scalars['String']>
    flavourText5: Maybe<Scalars['String']>
    flavourText6: Maybe<Scalars['String']>
    flavourText7: Maybe<Scalars['String']>
    highlighted: Maybe<Scalars['Int']>
    /**
     * An ID that uniquely identifies the category.
     * @deprecated Use `uid` instead.
     */
    id: Maybe<Scalars['Int']>
    image: Maybe<Scalars['String']>
    includeInMenu: Maybe<Scalars['Int']>
    isAnchor: Maybe<Scalars['Int']>
    landingPage: Maybe<Scalars['Int']>
    /** The depth of the category within the tree. */
    level: Maybe<Scalars['Int']>
    mainCategoryPosition: Maybe<Scalars['String']>
    metaDescription: Maybe<Scalars['String']>
    metaKeywords: Maybe<Scalars['String']>
    metaRobots: Maybe<Scalars['String']>
    metaTitle: Maybe<Scalars['String']>
    /** The display name of the category. */
    name: Maybe<Scalars['String']>
    newsletterFlavourBlockPosition: Maybe<Scalars['String']>
    newsletterHeader: Maybe<Scalars['String']>
    newsletterImage: Maybe<Scalars['String']>
    newsletterSubheader: Maybe<Scalars['String']>
    parentId: Maybe<Scalars['String']>
    /** The full category path. */
    path: Maybe<Scalars['String']>
    /** The category path within the store. */
    pathInStore: Maybe<Scalars['String']>
    /** The position of the category relative to other categories at the same level in tree. */
    position: Maybe<Scalars['Int']>
    /**
     * The number of products in the category that are marked as visible. By default,
     * in complex products, parent products are visible, but their child products are not.
     */
    productCount: Maybe<Scalars['Int']>
    /** The list of products assigned to the category. */
    products: Maybe<CategoryProducts>
    promotionBackgroundImage: Maybe<Scalars['String']>
    promotionButtonLink: Maybe<Scalars['String']>
    promotionButtonText: Maybe<Scalars['String']>
    promotionLink: Maybe<Scalars['String']>
    promotionText: Maybe<Scalars['String']>
    promotionUseParentSettings: Maybe<Scalars['Int']>
    /**
     * Contains 0 when there is no redirect error. A value of 301 indicates the URL
     * of the requested resource has been changed permanently, while a value of 302
     * indicates a temporary redirect.
     */
    redirectCode: Scalars['Int']
    /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
    relativeUrl: Maybe<Scalars['String']>
    tweakwiseCrosssellGroupCode: Maybe<Scalars['String']>
    tweakwiseCrosssellTemplate: Maybe<Scalars['Int']>
    tweakwiseFeaturedTemplate: Maybe<Scalars['Int']>
    tweakwiseFilterValuesWhitelist: Maybe<Scalars['String']>
    tweakwiseFilterWhitelist: Maybe<Scalars['String']>
    tweakwiseId: Maybe<Scalars['Int']>
    tweakwiseShoppingcartCrosssellGroupCode: Maybe<Scalars['String']>
    tweakwiseShoppingcartCrosssellTemplate: Maybe<Scalars['Int']>
    tweakwiseUpsellGroupCode: Maybe<Scalars['String']>
    tweakwiseUpsellTemplate: Maybe<Scalars['Int']>
    /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
    type: Maybe<UrlRewriteEntityTypeEnum>
    /** The unique ID for a `CategoryInterface` object. */
    uid: Scalars['ID']
    /**
     * The timestamp indicating when the category was updated.
     * @deprecated The field should not be used on the storefront.
     */
    updatedAt: Maybe<Scalars['String']>
    /** The URL key assigned to the category. */
    urlKey: Maybe<Scalars['String']>
    /** The URL path assigned to the category. */
    urlPath: Maybe<Scalars['String']>
    /** The part of the category URL that is appended after the url key */
    urlSuffix: Maybe<Scalars['String']>
    usp: Maybe<Scalars['String']>
    /** USPs for this specific Category. */
    usps: Array<Maybe<Usp>>
}

/** Contains the hierarchy of categories. */
export interface CategoryTreeProductsArgs {
    pageSize?: Maybe<Scalars['Int']>
    currentPage?: Maybe<Scalars['Int']>
    sort: Maybe<ProductAttributeSortInput>
}

/** Contains the hierarchy of categories. */
export interface CategoryTreeUspsArgs {
    pageSize?: Maybe<Scalars['Int']>
    currentPage?: Maybe<Scalars['Int']>
}

/** Defines details about an individual checkout agreement. */
export interface CheckoutAgreement {
    __typename?: 'CheckoutAgreement'
    /** The ID for a checkout agreement. */
    agreementId: Scalars['Int']
    /** The checkbox text for the checkout agreement. */
    checkboxText: Scalars['String']
    /** Required. The text of the agreement. */
    content: Scalars['String']
    /** The height of the text box where the Terms and Conditions statement appears during checkout. */
    contentHeight: Maybe<Scalars['String']>
    /** Indicates whether the `content` text is in HTML format. */
    isHtml: Scalars['Boolean']
    /** Indicates whether agreements are accepted automatically or manually. */
    mode: CheckoutAgreementMode
    /** The name given to the condition. */
    name: Scalars['String']
}

/** Indicates how agreements are accepted. */
export enum CheckoutAgreementMode {
    /** Conditions are automatically accepted upon checkout. */
    AUTO = 'AUTO',
    /** Shoppers must manually accept the conditions to place an order. */
    MANUAL = 'MANUAL',
}

/** An error encountered while adding an item to the cart. */
export interface CheckoutUserInputError {
    __typename?: 'CheckoutUserInputError'
    /** An error code that is specific to Checkout. */
    code: CheckoutUserInputErrorCodes
    /** A localized error message. */
    message: Scalars['String']
    /**
     * The path to the input field that caused an error. See the GraphQL
     * specification about path errors for details:
     * http://spec.graphql.org/draft/#sec-Errors
     */
    path: Array<Maybe<Scalars['String']>>
}

export enum CheckoutUserInputErrorCodes {
    REORDER_NOT_AVAILABLE = 'REORDER_NOT_AVAILABLE',
    PRODUCT_NOT_FOUND = 'PRODUCT_NOT_FOUND',
    NOT_SALABLE = 'NOT_SALABLE',
    INSUFFICIENT_STOCK = 'INSUFFICIENT_STOCK',
    UNDEFINED = 'UNDEFINED',
}

/** Contains details about a specific CMS block. */
export interface CmsBlock {
    __typename?: 'CmsBlock'
    /** The content of the CMS block in raw HTML. */
    content: Maybe<Scalars['String']>
    /** The CMS block identifier. */
    identifier: Maybe<Scalars['String']>
    /** The title assigned to the CMS block. */
    title: Maybe<Scalars['String']>
}

/** Contains an array CMS block items. */
export interface CmsBlocks {
    __typename?: 'CmsBlocks'
    /** An array of CMS blocks. */
    items: Maybe<Array<Maybe<CmsBlock>>>
}

/** Contains details about a CMS page. */
export interface CmsPage extends RoutableInterface {
    __typename?: 'CmsPage'
    /** The content of the CMS page in raw HTML. */
    content: Maybe<Scalars['String']>
    /** The heading that displays at the top of the CMS page. */
    contentHeading: Maybe<Scalars['String']>
    /** The ID of a CMS page. */
    identifier: Maybe<Scalars['String']>
    /** A brief description of the page for search results listings. */
    metaDescription: Maybe<Scalars['String']>
    /** A brief description of the page for search results listings. */
    metaKeywords: Maybe<Scalars['String']>
    /** A page title that is indexed by search engines and appears in search results listings. */
    metaTitle: Maybe<Scalars['String']>
    /** The design layout of the page, indicating the number of columns and navigation features used on the page. */
    pageLayout: Maybe<Scalars['String']>
    /**
     * Contains 0 when there is no redirect error. A value of 301 indicates the URL
     * of the requested resource has been changed permanently, while a value of 302
     * indicates a temporary redirect.
     */
    redirectCode: Scalars['Int']
    /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
    relativeUrl: Maybe<Scalars['String']>
    /** The name that appears in the breadcrumb trail navigation and in the browser title bar and tab. */
    title: Maybe<Scalars['String']>
    /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
    type: Maybe<UrlRewriteEntityTypeEnum>
    /** The URL key of the CMS page, which is often based on the `content_heading`. */
    urlKey: Maybe<Scalars['String']>
}

/** Categories */
export interface CmsPageBlockCategories {
    __typename?: 'CmsPageBlockCategories'
    buttonText: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    /** Content */
    content: Maybe<Scalars['String']>
    /** Header */
    header: Maybe<Scalars['String']>
    /** List of categories */
    list: Maybe<Array<Maybe<CmsPageListItem>>>
}

/** Discover looks */
export interface CmsPageBlockDiscoverLooks {
    __typename?: 'CmsPageBlockDiscoverLooks'
    buttonText: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    /** content */
    content: Maybe<Scalars['String']>
    /** header */
    header: Maybe<Scalars['String']>
    /** List of looks */
    list: Maybe<Array<Maybe<CmsPageListItem>>>
}

/** Hero */
export interface CmsPageBlockHero {
    __typename?: 'CmsPageBlockHero'
    buttonStyle: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    /** Content */
    content: Maybe<Scalars['String']>
    image: Maybe<CmsPageImage>
    imageMobile: Maybe<CmsPageImage>
    /** Comma separated list of cue times (in seconds) when a product should be visible in the video */
    productCueTimes: Maybe<Scalars['String']>
    /** Comma separated list of product SKU's */
    productSkus: Maybe<Scalars['String']>
    subheading: Maybe<Scalars['String']>
    subheadingStyle: Maybe<Scalars['String']>
    textStyle: Maybe<Scalars['String']>
    video: Maybe<Scalars['String']>
}

/** Latest arrivals */
export interface CmsPageBlockLatestArrivals {
    __typename?: 'CmsPageBlockLatestArrivals'
    buttonText: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    /** Content */
    content: Maybe<Scalars['String']>
    /** Header */
    header: Maybe<Scalars['String']>
    /** Recommendations identifier for TW API  */
    recommendationsIdentifier: Maybe<Scalars['Int']>
}

/** Sale */
export interface CmsPageBlockSale {
    __typename?: 'CmsPageBlockSale'
    buttonText: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    /** Content */
    content: Maybe<Scalars['String']>
    /** Header */
    header: Maybe<Scalars['String']>
    /** Recommendations identifier for TW API  */
    recommendationsIdentifier: Maybe<Scalars['Int']>
}

/** Stories */
export interface CmsPageBlockStories {
    __typename?: 'CmsPageBlockStories'
    buttonText: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    /** Content */
    content: Maybe<Scalars['String']>
    /** Header */
    header: Maybe<Scalars['String']>
    /** List of stories */
    list: Maybe<Array<Maybe<CmsPageListItem>>>
}

/** Stories Overview - Row one */
export interface CmsPageBlockStoriesOverviewRowOne {
    __typename?: 'CmsPageBlockStoriesOverviewRowOne'
    /** Element 1 - image */
    elementOneImage: Maybe<CmsPageImage>
    elementOneImageMobile: Maybe<CmsPageImage>
    /** Element 1 - quote */
    elementOneQuote: Maybe<Scalars['String']>
    /** Element 2 - heading */
    elementTwoHeading: Maybe<Scalars['String']>
    /** Element 2 - image */
    elementTwoImage: Maybe<CmsPageImage>
    elementTwoImageMobile: Maybe<CmsPageImage>
    /** Element 2 - subheading */
    elementTwoSubheading: Maybe<Scalars['String']>
    /** Element 2 - url key */
    elementTwoUrlkey: Maybe<Scalars['String']>
}

/** Stories Overview - Row three */
export interface CmsPageBlockStoriesOverviewRowThree {
    __typename?: 'CmsPageBlockStoriesOverviewRowThree'
    /** Element 1 - heading */
    elementOneHeading: Maybe<Scalars['String']>
    /** Element 1 - image */
    elementOneImage: Maybe<CmsPageImage>
    /** Element 1 - image */
    elementOneImageMobile: Maybe<CmsPageImage>
    /** Element 1 - subheading */
    elementOneSubheading: Maybe<Scalars['String']>
    /** Element 1 - url key */
    elementOneUrlkey: Maybe<Scalars['String']>
    /** Element 2 - image */
    elementTwoImage: Maybe<CmsPageImage>
    /** Element 2 - image */
    elementTwoImageMobile: Maybe<CmsPageImage>
    /** Element 2 - quote */
    elementTwoQuote: Maybe<Scalars['String']>
}

/** Stories Overview - Row two */
export interface CmsPageBlockStoriesOverviewRowTwo {
    __typename?: 'CmsPageBlockStoriesOverviewRowTwo'
    /** Element 1 - heading */
    elementOneHeading: Maybe<Scalars['String']>
    /** Element 1 - image */
    elementOneImage: Maybe<CmsPageImage>
    elementOneImageMobile: Maybe<CmsPageImage>
    /** Element 1 - subheading */
    elementOneSubheading: Maybe<Scalars['String']>
    /** Element 1 - url key */
    elementOneUrlkey: Maybe<Scalars['String']>
    /** Element 3 - heading */
    elementThreeHeading: Maybe<Scalars['String']>
    /** Element 3 - image */
    elementThreeImage: Maybe<CmsPageImage>
    elementThreeImageMobile: Maybe<CmsPageImage>
    /** Element 3 - subheading */
    elementThreeSubheading: Maybe<Scalars['String']>
    /** Element 3 - urlkey */
    elementThreeUrlkey: Maybe<Scalars['String']>
    /** Element 2 - image */
    elementTwoImage: Maybe<CmsPageImage>
    elementTwoImageMobile: Maybe<CmsPageImage>
    /** Element 2 - quote */
    elementTwoQuote: Maybe<Scalars['String']>
}

/** CastIron Story Article - Hero */
export interface CmsPageBlockStoryArticleCastIronHero {
    __typename?: 'CmsPageBlockStoryArticleCastIronHero'
    /** CastIron Heading */
    heading: Maybe<Scalars['String']>
    /** CastIron Image */
    image: Maybe<CmsPageImage>
    imageMobile: Maybe<CmsPageImage>
    /** CastIron Subheading */
    subheading: Maybe<Scalars['String']>
}

/** CastIron Story Article - Row five */
export interface CmsPageBlockStoryArticleCastIronRowFive {
    __typename?: 'CmsPageBlockStoryArticleCastIronRowFive'
    /** CastIron Heading 4 */
    headingFour: Maybe<Scalars['String']>
    /** CastIron Heading 1 */
    headingOne: Maybe<Scalars['String']>
    /** CastIron Heading 3 */
    headingThree: Maybe<Scalars['String']>
    /** CastIron Heading 2 */
    headingTwo: Maybe<Scalars['String']>
    /** CastIron Product Block */
    recommendationsIdentifier: Maybe<Scalars['Int']>
}

/** CastIron Story Article - Row four */
export interface CmsPageBlockStoryArticleCastIronRowFour {
    __typename?: 'CmsPageBlockStoryArticleCastIronRowFour'
    /** CastIron Full width image */
    fullWidthImage: Maybe<CmsPageImage>
    fullWidthImageMobile: Maybe<CmsPageImage>
    /** CastIron Heading */
    heading: Maybe<Scalars['String']>
    /** CastIron Image one */
    imageOne: Maybe<CmsPageImage>
    imageOneMobile: Maybe<CmsPageImage>
}

/** CastIron Story Article - Row one */
export interface CmsPageBlockStoryArticleCastIronRowOne {
    __typename?: 'CmsPageBlockStoryArticleCastIronRowOne'
    /** CastIron content */
    content: Maybe<Scalars['String']>
    /** CastIron Heading */
    heading: Maybe<Scalars['String']>
    /** CastIron Image one */
    imageOne: Maybe<CmsPageImage>
    imageOneMobile: Maybe<CmsPageImage>
}

/** CastIron Story Article - Row three */
export interface CmsPageBlockStoryArticleCastIronRowThree {
    __typename?: 'CmsPageBlockStoryArticleCastIronRowThree'
    /** CastIron content */
    content: Maybe<Scalars['String']>
    /** CastIron Heading */
    heading: Maybe<Scalars['String']>
    imageOne: Maybe<CmsPageImage>
    imageOneMobile: Maybe<CmsPageImage>
}

/** CastIron Story Article - Row two */
export interface CmsPageBlockStoryArticleCastIronRowTwo {
    __typename?: 'CmsPageBlockStoryArticleCastIronRowTwo'
    /** CastIron content */
    content: Maybe<Scalars['String']>
    /** CastIron Heading */
    heading: Maybe<Scalars['String']>
    /** CastIron Image one */
    imageOne: Maybe<CmsPageImage>
    imageOneMobile: Maybe<CmsPageImage>
    /** CastIron Image two */
    imageTwo: Maybe<CmsPageImage>
    imageTwoMobile: Maybe<CmsPageImage>
}

/** Story Article - Hero */
export interface CmsPageBlockStoryArticleHero {
    __typename?: 'CmsPageBlockStoryArticleHero'
    /** Heading */
    heading: Maybe<Scalars['String']>
    /** Image */
    image: Maybe<CmsPageImage>
    imageMobile: Maybe<CmsPageImage>
    /** Subheading */
    subheading: Maybe<Scalars['String']>
}

/** Story Article - Row one */
export interface CmsPageBlockStoryArticleRowOne {
    __typename?: 'CmsPageBlockStoryArticleRowOne'
    /** Full width image */
    fullWidthImage: Maybe<CmsPageImage>
    fullWidthImageMobile: Maybe<CmsPageImage>
    /** Heading */
    heading: Maybe<Scalars['String']>
    /** Image one */
    imageOne: Maybe<CmsPageImage>
    imageOneMobile: Maybe<CmsPageImage>
    /** Paragraph heading */
    paragraphHeading: Maybe<Scalars['String']>
}

/** Story Article - Row three */
export interface CmsPageBlockStoryArticleRowThree {
    __typename?: 'CmsPageBlockStoryArticleRowThree'
    /** Heading */
    heading: Maybe<Scalars['String']>
    imageOne: Maybe<CmsPageImage>
    imageOneMobile: Maybe<CmsPageImage>
    /** Paragraph heading */
    paragraphHeading: Maybe<Scalars['String']>
}

/** Story Article - Row two */
export interface CmsPageBlockStoryArticleRowTwo {
    __typename?: 'CmsPageBlockStoryArticleRowTwo'
    /** Full width image */
    fullWidthImage: Maybe<CmsPageImage>
    fullWidthImageMobile: Maybe<CmsPageImage>
    /** Heading */
    heading: Maybe<Scalars['String']>
    /** Image one */
    imageOne: Maybe<CmsPageImage>
    imageOneMobile: Maybe<CmsPageImage>
    /** Image two */
    imageTwo: Maybe<CmsPageImage>
    imageTwoMobile: Maybe<CmsPageImage>
    /** Paragraph heading */
    paragraphHeading: Maybe<Scalars['String']>
}

/** Vanguard Story Article - Hero */
export interface CmsPageBlockStoryArticleVanguardHero {
    __typename?: 'CmsPageBlockStoryArticleVanguardHero'
    /** Vanguard Heading */
    heading: Maybe<Scalars['String']>
    /** Vanguard Image */
    image: Maybe<CmsPageImage>
    imageMobile: Maybe<CmsPageImage>
    /** Vanguard Subheading */
    subheading: Maybe<Scalars['String']>
}

/** Vanguard Story Article - Row five */
export interface CmsPageBlockStoryArticleVanguardRowFive {
    __typename?: 'CmsPageBlockStoryArticleVanguardRowFive'
    /** Vanguard Content */
    content: Maybe<Scalars['String']>
    /** Full width image */
    fullWidthImage: Maybe<CmsPageImage>
    fullWidthImageMobile: Maybe<CmsPageImage>
    /** Vanguard Heading */
    heading: Maybe<Scalars['String']>
    /** Vanguard Image one */
    imageOne: Maybe<CmsPageImage>
    imageOneMobile: Maybe<CmsPageImage>
}

/** Vanguard Story Article - Row four */
export interface CmsPageBlockStoryArticleVanguardRowFour {
    __typename?: 'CmsPageBlockStoryArticleVanguardRowFour'
    /** Vanguard Heading */
    heading: Maybe<Scalars['String']>
    /** Vanguard Image one */
    imageOne: Maybe<CmsPageImage>
    imageOneMobile: Maybe<CmsPageImage>
    /** Vanguard Image two */
    imageTwo: Maybe<CmsPageImage>
    imageTwoMobile: Maybe<CmsPageImage>
}

/** Vanguard Story Article - Row one */
export interface CmsPageBlockStoryArticleVanguardRowOne {
    __typename?: 'CmsPageBlockStoryArticleVanguardRowOne'
    /** Vanguard Content */
    content: Maybe<Scalars['String']>
    /** Vanguard Heading */
    heading: Maybe<Scalars['String']>
    /** Vanguard Image one */
    imageOne: Maybe<CmsPageImage>
    imageOneMobile: Maybe<CmsPageImage>
}

/** Vanguard Story Article - Row six */
export interface CmsPageBlockStoryArticleVanguardRowSix {
    __typename?: 'CmsPageBlockStoryArticleVanguardRowSix'
    /** Vanguard Content */
    content: Maybe<Scalars['String']>
    /** Vanguard Heading */
    heading: Maybe<Scalars['String']>
    /** Vanguard Image one */
    imageOne: Maybe<CmsPageImage>
    imageOneMobile: Maybe<CmsPageImage>
}

/** Vanguard Story Article - Row three */
export interface CmsPageBlockStoryArticleVanguardRowThree {
    __typename?: 'CmsPageBlockStoryArticleVanguardRowThree'
    /** Vanguard Content */
    content: Maybe<Scalars['String']>
    /** Vanguard Heading */
    heading: Maybe<Scalars['String']>
    imageOne: Maybe<CmsPageImage>
    imageOneMobile: Maybe<CmsPageImage>
    /** Image upload */
    productImage: Maybe<CmsPageImage>
    /** Image upload */
    productImageMobile: Maybe<CmsPageImage>
    /** text input */
    productSku: Maybe<Scalars['String']>
    /** text input */
    productTitle: Maybe<Scalars['String']>
}

/** Vanguard Story Article - Row two */
export interface CmsPageBlockStoryArticleVanguardRowTwo {
    __typename?: 'CmsPageBlockStoryArticleVanguardRowTwo'
    /** Vanguard Image one */
    imageOne: Maybe<CmsPageImage>
    imageOneMobile: Maybe<CmsPageImage>
}

/** FAQ page */
export interface CmsPageFaq extends CmsPageInterface {
    __typename?: 'CmsPageFaq'
    /** Faq page contact button text */
    contactButtonText: Maybe<Scalars['String']>
    /** Faq page contact content */
    contactContent: Maybe<Scalars['String']>
    /** Faq page contact title */
    contactTitle: Maybe<Scalars['String']>
    /** Head JS files */
    headJsFiles: Maybe<Array<Maybe<CmsPageJsFile>>>
    /** Hero image heading */
    heroHeading: Maybe<Scalars['String']>
    /** Hero image desktop */
    heroImage: Maybe<CmsPageImage>
    /** Hero image mobile */
    heroImageMobile: Maybe<CmsPageImage>
    /** HTML meta description */
    metaDescription: Maybe<Scalars['String']>
    /** HTML meta keywords */
    metaKeywords: Maybe<Scalars['String']>
    /** HTML meta robots */
    metaRobots: Maybe<Scalars['String']>
    /** Page unique id */
    pageId: Scalars['Int']
    questions: Maybe<Array<Maybe<CmsPageQuestion>>>
    /** Page title */
    title: Scalars['String']
    /** Url of the page */
    urlKey: Scalars['String']
}

/** Homepage */
export interface CmsPageHomepage extends CmsPageInterface {
    __typename?: 'CmsPageHomepage'
    /** Categories block */
    categories: Maybe<CmsPageBlockCategories>
    /** Discover looks block */
    discoverLooks: Maybe<CmsPageBlockDiscoverLooks>
    /** Head JS files */
    headJsFiles: Maybe<Array<Maybe<CmsPageJsFile>>>
    /** Hero block */
    hero: Maybe<CmsPageBlockHero>
    /** Latest arrivals block */
    latestArrivals: Maybe<CmsPageBlockLatestArrivals>
    /** HTML meta description */
    metaDescription: Maybe<Scalars['String']>
    /** HTML meta keywords */
    metaKeywords: Maybe<Scalars['String']>
    /** HTML meta robots */
    metaRobots: Maybe<Scalars['String']>
    /** Page unique id */
    pageId: Scalars['Int']
    /** Sale block */
    sale: Maybe<CmsPageBlockSale>
    /** Stories block */
    stories: Maybe<CmsPageBlockStories>
    /** Page title */
    title: Scalars['String']
    /** Url of the page */
    urlKey: Scalars['String']
}

/** Homepage JBFO */
export interface CmsPageHomepageJbfo extends CmsPageInterface {
    __typename?: 'CmsPageHomepageJbfo'
    brand: Maybe<CmsPageHomepageJustbrandsBrandsBlock>
    categories: Maybe<CmsPageBlockCategories>
    /** Head JS files */
    headJsFiles: Maybe<Array<Maybe<CmsPageJsFile>>>
    hero: Maybe<CmsPageHomepageJbfoHeroBlock>
    /** HTML meta description */
    metaDescription: Maybe<Scalars['String']>
    /** HTML meta keywords */
    metaKeywords: Maybe<Scalars['String']>
    /** HTML meta robots */
    metaRobots: Maybe<Scalars['String']>
    /** Page unique id */
    pageId: Scalars['Int']
    productRowOne: Maybe<CmsPageHomepageJbfoProductRowBlock>
    productRowTwo: Maybe<CmsPageHomepageJbfoProductRowBlock>
    /** Page title */
    title: Scalars['String']
    /** Url of the page */
    urlKey: Scalars['String']
}

export interface CmsPageHomepageJbfoHeroBlock {
    __typename?: 'CmsPageHomepageJbfoHeroBlock'
    buttonStyle: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    headingColor: Maybe<Scalars['String']>
    image: Maybe<CmsPageImage>
    imageMobile: Maybe<CmsPageImage>
    subheading: Maybe<Scalars['String']>
    subheadingPink: Maybe<Scalars['String']>
    subheadingWhite: Maybe<Scalars['String']>
}

export interface CmsPageHomepageJbfoProductRowBlock {
    __typename?: 'CmsPageHomepageJbfoProductRowBlock'
    buttonText: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    content: Maybe<Scalars['String']>
    header: Maybe<Scalars['String']>
    recommendationsIdentifier: Maybe<Scalars['Int']>
}

/** Homepage Justbrands */
export interface CmsPageHomepageJustbrands extends CmsPageInterface {
    __typename?: 'CmsPageHomepageJustbrands'
    /** Discover our brands */
    brand: Maybe<CmsPageHomepageJustbrandsBrandsBlock>
    /** Head JS files */
    headJsFiles: Maybe<Array<Maybe<CmsPageJsFile>>>
    /** Hero block */
    hero: Maybe<CmsPageHomepageJustbrandsHeroBlock>
    /** HTML meta description */
    metaDescription: Maybe<Scalars['String']>
    /** HTML meta keywords */
    metaKeywords: Maybe<Scalars['String']>
    /** HTML meta robots */
    metaRobots: Maybe<Scalars['String']>
    /** Page unique id */
    pageId: Scalars['Int']
    /** Stores / come by block */
    stores: Maybe<CmsPageHomepageJustbrandsStoresBlock>
    /** Meet us / team block */
    team: Maybe<CmsPageHomepageJustbrandsTeamBlock>
    /** Page title */
    title: Scalars['String']
    /** Url of the page */
    urlKey: Scalars['String']
}

export interface CmsPageHomepageJustbrandsBrand {
    __typename?: 'CmsPageHomepageJustbrandsBrand'
    buttonText: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    image: Maybe<CmsPageImage>
    imageMobile: Maybe<CmsPageImage>
    logo: Maybe<CmsPageImage>
    subheading: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
}

/** Discover our brands */
export interface CmsPageHomepageJustbrandsBrandsBlock {
    __typename?: 'CmsPageHomepageJustbrandsBrandsBlock'
    brands: Maybe<Array<Maybe<CmsPageHomepageJustbrandsBrand>>>
    heading: Maybe<Scalars['String']>
    subheading: Maybe<Scalars['String']>
}

/** Hero block */
export interface CmsPageHomepageJustbrandsHeroBlock {
    __typename?: 'CmsPageHomepageJustbrandsHeroBlock'
    buttonStyle: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    headingTickerWord1: Maybe<Scalars['String']>
    headingTickerWord2: Maybe<Scalars['String']>
    headingTickerWord3: Maybe<Scalars['String']>
    image: Maybe<CmsPageImage>
    imageMobile: Maybe<CmsPageImage>
    subheading: Maybe<Scalars['String']>
    uspText: Maybe<Scalars['String']>
}

export interface CmsPageHomepageJustbrandsStore {
    __typename?: 'CmsPageHomepageJustbrandsStore'
    address: Maybe<Scalars['String']>
    image: Maybe<CmsPageImage>
    imageMobile: Maybe<CmsPageImage>
    name: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
}

/** Stores / come by block */
export interface CmsPageHomepageJustbrandsStoresBlock {
    __typename?: 'CmsPageHomepageJustbrandsStoresBlock'
    buttonText: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    store: Maybe<Array<Maybe<CmsPageHomepageJustbrandsStore>>>
    subheading: Maybe<Scalars['String']>
}

/** Meet us / team block */
export interface CmsPageHomepageJustbrandsTeamBlock {
    __typename?: 'CmsPageHomepageJustbrandsTeamBlock'
    employees: Maybe<Array<Maybe<CmsPageHomepageJustbrandsTeamEmployee>>>
    heading: Maybe<Scalars['String']>
    subheading: Maybe<Scalars['String']>
}

/** Employee's */
export interface CmsPageHomepageJustbrandsTeamEmployee {
    __typename?: 'CmsPageHomepageJustbrandsTeamEmployee'
    bigImage: Maybe<CmsPageImage>
    bigImageMobile: Maybe<CmsPageImage>
    firstName: Maybe<Scalars['String']>
    fullName: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    jobTitle: Maybe<Scalars['String']>
    portraitImage: Maybe<CmsPageImage>
    productId: Maybe<Scalars['Int']>
    quote: Maybe<Scalars['String']>
    smallImage: Maybe<CmsPageImage>
    smallImageMobile: Maybe<CmsPageImage>
    subheading: Maybe<Scalars['String']>
}

export interface CmsPageImage {
    __typename?: 'CmsPageImage'
    /** Image file */
    file: Maybe<Scalars['String']>
    /** Image url */
    url: Maybe<Scalars['String']>
}

/** Structured cms page data */
export interface CmsPageInterface {
    /** Head JS files */
    headJsFiles: Maybe<Array<Maybe<CmsPageJsFile>>>
    /** HTML meta description */
    metaDescription: Maybe<Scalars['String']>
    /** HTML meta keywords */
    metaKeywords: Maybe<Scalars['String']>
    /** HTML meta robots */
    metaRobots: Maybe<Scalars['String']>
    /** Page unique id */
    pageId: Scalars['Int']
    /** Page title */
    title: Scalars['String']
    /** Url of the page */
    urlKey: Scalars['String']
}

export interface CmsPageJsFile {
    __typename?: 'CmsPageJsFile'
    /** Integrity hash */
    integrity: Maybe<Scalars['String']>
    /** Source */
    src: Maybe<Scalars['String']>
}

export interface CmsPageListItem {
    __typename?: 'CmsPageListItem'
    /** Image */
    image: Maybe<CmsPageImage>
    imageMobile: Maybe<CmsPageImage>
    /** Link */
    link: Maybe<Scalars['String']>
    /** Subtitle */
    subtitle: Maybe<Scalars['String']>
    /** Title */
    title: Maybe<Scalars['String']>
}

/** Plain */
export interface CmsPagePlain extends CmsPageInterface {
    __typename?: 'CmsPagePlain'
    /** Plain content */
    content: Maybe<Scalars['String']>
    /** Head JS files */
    headJsFiles: Maybe<Array<Maybe<CmsPageJsFile>>>
    /** HTML meta description */
    metaDescription: Maybe<Scalars['String']>
    /** HTML meta keywords */
    metaKeywords: Maybe<Scalars['String']>
    /** HTML meta robots */
    metaRobots: Maybe<Scalars['String']>
    /** Page unique id */
    pageId: Scalars['Int']
    /** Page title */
    title: Scalars['String']
    /** Url of the page */
    urlKey: Scalars['String']
}

/** FAQ - Question */
export interface CmsPageQuestion {
    __typename?: 'CmsPageQuestion'
    /** Question category */
    category: Maybe<CmsPageQuestionCategory>
    /** Rich text content */
    content: Maybe<Scalars['String']>
    /** Title */
    title: Maybe<Scalars['String']>
}

export enum CmsPageQuestionCategory {
    POPULAR = 'POPULAR',
    ORDER = 'ORDER',
    PAYMENT = 'PAYMENT',
    DELIVERY = 'DELIVERY',
    RETURN = 'RETURN',
    PRODUCT = 'PRODUCT',
    SUSTAINABILITY = 'SUSTAINABILITY',
    ACCOUNT = 'ACCOUNT',
    REWARDS = 'REWARDS',
}

/** Stories Overview */
export interface CmsPageStoriesOverview extends CmsPageInterface {
    __typename?: 'CmsPageStoriesOverview'
    /** Head JS files */
    headJsFiles: Maybe<Array<Maybe<CmsPageJsFile>>>
    /** HTML meta description */
    metaDescription: Maybe<Scalars['String']>
    /** HTML meta keywords */
    metaKeywords: Maybe<Scalars['String']>
    /** HTML meta robots */
    metaRobots: Maybe<Scalars['String']>
    /** Page unique id */
    pageId: Scalars['Int']
    /** Row 1 block */
    rowOne: Maybe<CmsPageBlockStoriesOverviewRowOne>
    /** Row 3 block */
    rowThree: Maybe<CmsPageBlockStoriesOverviewRowThree>
    /** Row 2 block */
    rowTwo: Maybe<CmsPageBlockStoriesOverviewRowTwo>
    /** Page title */
    title: Scalars['String']
    /** Url of the page */
    urlKey: Scalars['String']
}

/** Story Article */
export interface CmsPageStoryArticle extends CmsPageInterface {
    __typename?: 'CmsPageStoryArticle'
    /** Head JS files */
    headJsFiles: Maybe<Array<Maybe<CmsPageJsFile>>>
    /** Hero block */
    hero: Maybe<CmsPageBlockStoryArticleHero>
    /** HTML meta description */
    metaDescription: Maybe<Scalars['String']>
    /** HTML meta keywords */
    metaKeywords: Maybe<Scalars['String']>
    /** HTML meta robots */
    metaRobots: Maybe<Scalars['String']>
    /** Page unique id */
    pageId: Scalars['Int']
    /** Row 1 block */
    rowOne: Maybe<CmsPageBlockStoryArticleRowOne>
    /** Row 3 block */
    rowThree: Maybe<CmsPageBlockStoryArticleRowThree>
    /** Row 2 block */
    rowTwo: Maybe<CmsPageBlockStoryArticleRowTwo>
    /** Page title */
    title: Scalars['String']
    /** Url of the page */
    urlKey: Scalars['String']
}

/** CastIron Story Article */
export interface CmsPageStoryArticleCastIron extends CmsPageInterface {
    __typename?: 'CmsPageStoryArticleCastIron'
    /** Head JS files */
    headJsFiles: Maybe<Array<Maybe<CmsPageJsFile>>>
    /** CastIron Hero block */
    hero: Maybe<CmsPageBlockStoryArticleCastIronHero>
    /** HTML meta description */
    metaDescription: Maybe<Scalars['String']>
    /** HTML meta keywords */
    metaKeywords: Maybe<Scalars['String']>
    /** HTML meta robots */
    metaRobots: Maybe<Scalars['String']>
    /** Page unique id */
    pageId: Scalars['Int']
    /** CastIron Row 5 block */
    rowFive: Maybe<CmsPageBlockStoryArticleCastIronRowFive>
    /** CastIron Row 4 block */
    rowFour: Maybe<CmsPageBlockStoryArticleCastIronRowFour>
    /** CastIron Row 1 block */
    rowOne: Maybe<CmsPageBlockStoryArticleCastIronRowOne>
    /** CastIron Row 3 block */
    rowThree: Maybe<CmsPageBlockStoryArticleCastIronRowThree>
    /** CastIron Row 2 block */
    rowTwo: Maybe<CmsPageBlockStoryArticleCastIronRowTwo>
    /** Page title */
    title: Scalars['String']
    /** Url of the page */
    urlKey: Scalars['String']
}

/** Vanguard Story Article */
export interface CmsPageStoryArticleVanguard extends CmsPageInterface {
    __typename?: 'CmsPageStoryArticleVanguard'
    /** Head JS files */
    headJsFiles: Maybe<Array<Maybe<CmsPageJsFile>>>
    /** Vanguard Hero block */
    hero: Maybe<CmsPageBlockStoryArticleVanguardHero>
    /** HTML meta description */
    metaDescription: Maybe<Scalars['String']>
    /** HTML meta keywords */
    metaKeywords: Maybe<Scalars['String']>
    /** HTML meta robots */
    metaRobots: Maybe<Scalars['String']>
    /** Page unique id */
    pageId: Scalars['Int']
    /** Vanguard Row 5 block */
    rowFive: Maybe<CmsPageBlockStoryArticleVanguardRowFive>
    /** Vanguard Row 4 block */
    rowFour: Maybe<CmsPageBlockStoryArticleVanguardRowFour>
    /** Vanguard Row 1 block */
    rowOne: Maybe<CmsPageBlockStoryArticleVanguardRowOne>
    /** Vanguard Row 6 block */
    rowSix: Maybe<CmsPageBlockStoryArticleVanguardRowSix>
    /** Vanguard Row 3 block */
    rowThree: Maybe<CmsPageBlockStoryArticleVanguardRowThree>
    /** Vanguard Row 2 block */
    rowTwo: Maybe<CmsPageBlockStoryArticleVanguardRowTwo>
    /** Page title */
    title: Scalars['String']
    /** Url of the page */
    urlKey: Scalars['String']
}

export interface ColorSwatch {
    __typename?: 'ColorSwatch'
    type: Scalars['Int']
    value: Scalars['String']
}

export interface ColorSwatchData extends SwatchDataInterface {
    __typename?: 'ColorSwatchData'
    /** The value can be represented as color (HEX code), image link, or text. */
    value: Maybe<Scalars['String']>
}

/** Contains an attribute code that is used for product comparisons. */
export interface ComparableAttribute {
    __typename?: 'ComparableAttribute'
    /** An attribute code that is enabled for product comparisons. */
    code: Scalars['String']
    /** The label of the attribute code. */
    label: Scalars['String']
}

/** Defines an object used to iterate through items for product comparisons. */
export interface ComparableItem {
    __typename?: 'ComparableItem'
    /** An array of product attributes that can be used to compare products. */
    attributes: Array<Maybe<ProductAttribute>>
    /** Details about a product in a compare list. */
    product: ProductInterface
    /** The unique ID of an item in a compare list. */
    uid: Scalars['ID']
}

/** Contains iterable information such as the array of items, the count, and attributes that represent the compare list. */
export interface CompareList {
    __typename?: 'CompareList'
    /** An array of attributes that can be used for comparing products. */
    attributes: Maybe<Array<Maybe<ComparableAttribute>>>
    /** The number of items in the compare list. */
    itemCount: Scalars['Int']
    /** An array of products to compare. */
    items: Maybe<Array<Maybe<ComparableItem>>>
    /** The unique ID assigned to the compare list. */
    uid: Scalars['ID']
}

export interface ComplexTextValue {
    __typename?: 'ComplexTextValue'
    /** Text that can contain HTML tags. */
    html: Scalars['String']
}

/** Contains details about a configurable product attribute option. */
export interface ConfigurableAttributeOption {
    __typename?: 'ConfigurableAttributeOption'
    /** The ID assigned to the attribute. */
    code: Maybe<Scalars['String']>
    /** A string that describes the configurable attribute option. */
    label: Maybe<Scalars['String']>
    /** The unique ID for a `ConfigurableAttributeOption` object. */
    uid: Scalars['ID']
    /** A unique index number assigned to the configurable product option. */
    valueIndex: Maybe<Scalars['Int']>
}

/** An implementation for configurable product cart items. */
export interface ConfigurableCartItem extends CartItemInterface {
    __typename?: 'ConfigurableCartItem'
    /** An array containing the configuranle options the shopper selected. */
    configurableOptions: Array<Maybe<SelectedConfigurableOption>>
    /** Product details of the cart item. */
    configuredVariant: ProductInterface
    /** An array containing the customizable options the shopper selected. */
    customizableOptions: Array<Maybe<SelectedCustomizableOption>>
    /** An array of errors encountered while loading the cart item */
    errors: Maybe<Array<Maybe<CartItemError>>>
    extensionAttributes: Maybe<CartItemExtensionAttributes>
    /** The entered gift message for the cart item */
    giftMessage: Maybe<GiftMessage>
    /** @deprecated Use `uid` instead. */
    id: Scalars['String']
    /** True if requested quantity is less than available stock, false otherwise. */
    isAvailable: Scalars['Boolean']
    isFreeGift: Maybe<Scalars['Boolean']>
    /** Contains details about the price of the item, including taxes and discounts. */
    prices: Maybe<CartItemPrices>
    /** Details about an item in the cart. */
    product: ProductInterface
    promotional: Maybe<Scalars['Boolean']>
    /** The quantity of this item in the cart. */
    quantity: Scalars['Float']
    reservation: Maybe<Reservation>
    /** The unique ID for a `CartItemInterface` object. */
    uid: Scalars['ID']
}

/** Describes configurable options that have been selected and can be selected as a result of the previous selections. */
export interface ConfigurableOptionAvailableForSelection {
    __typename?: 'ConfigurableOptionAvailableForSelection'
    /** An attribute code that uniquely identifies a configurable option. */
    attributeCode: Scalars['String']
    /** An array of selectable option value IDs. */
    optionValueUids: Array<Maybe<Scalars['ID']>>
}

/** Defines a configurable option. */
export interface ConfigurableOptionInput {
    /** The configurable option uid */
    configurable_product_option_uid?: Maybe<Scalars['ID']>
    /** The configurable option value uid */
    configurable_product_option_value_uid?: Maybe<Scalars['ID']>
}

/** Defines basic features of a configurable product and its simple product variants. */
export interface ConfigurableProduct
    extends ProductInterface,
        RoutableInterface,
        PhysicalProductInterface,
        CustomizableProductInterface {
    __typename?: 'ConfigurableProduct'
    /** @deprecated Use the `custom_attributes` field instead. */
    baseColorHexCode: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    brandAlias: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    brandMediaPdpBottom: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    brandMediaPdpTop: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    brandName: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    brandPdpDescription: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    brandPdpTitle: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    featureDescription: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    featureMedia: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    featureName: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    itemLabelTextB2C: Maybe<Scalars['Int']>
    itemLabelTextB2CValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    itemLabelTypeB2C: Maybe<Scalars['Int']>
    itemLabelTypeB2CValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    itemMaterialBody: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    itemMaterialBodyLining: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    itemMaterialLining: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    itemMaterialShell: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    itemSizeAdvice: Maybe<Scalars['Int']>
    itemSizeAdviceValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    modelAdvice: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productArticleNumber: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productBeltLoops: Maybe<Scalars['Int']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productBodyShell: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productChestPockets: Maybe<Scalars['Int']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productClosure: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productClosureDetails: Maybe<Scalars['String']>
    productClosureDetailsValues: Maybe<Array<Maybe<AttributeOption>>>
    productClosureValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productCollar: Maybe<Scalars['Int']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productCollarDetails: Maybe<Scalars['String']>
    productCollarDetailsValues: Maybe<Array<Maybe<AttributeOption>>>
    productCollarValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productContainsNonTextilePartsOfAnimalOrigin: Maybe<Scalars['Int']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productCuffs: Maybe<Scalars['String']>
    productCuffsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productDimensions: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productFabric: Maybe<Scalars['String']>
    productFabricValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productFashionColor: Maybe<Scalars['Int']>
    productFashionColorValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productFit: Maybe<Scalars['Int']>
    productFitValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productHeight: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productHood: Maybe<Scalars['Int']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productHoodDetails: Maybe<Scalars['Int']>
    productHoodDetailsValues: Maybe<Array<Maybe<AttributeOption>>>
    productHoodValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productInnerJacketDetails: Maybe<Scalars['String']>
    productInnerJacketDetailsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productInnerPockets: Maybe<Scalars['Int']>
    productInnerPocketsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productJacketDetails: Maybe<Scalars['String']>
    productJacketDetailsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productJacketType: Maybe<Scalars['Int']>
    productJacketTypeValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productJacketlayers: Maybe<Scalars['Int']>
    productJacketlayersValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productLength: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productLengthDescription: Maybe<Scalars['Int']>
    productLengthDescriptionValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productLogoLocation: Maybe<Scalars['String']>
    productLogoLocationValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMarketPlacesDescription: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMarketingStyle: Maybe<Scalars['Int']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMaterialBack: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMaterialFilling: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMaterialFront: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMaterialHood: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMaterialLining: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMaterialPadding: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMaterialShell: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productNumberOfPockets: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productOccassion: Maybe<Scalars['Int']>
    productOccassionValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productOuterPockets: Maybe<Scalars['String']>
    productOuterPocketsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productPaddingType: Maybe<Scalars['Int']>
    productPaddingTypeValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productPattern: Maybe<Scalars['String']>
    productPatternValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productPocketType: Maybe<Scalars['String']>
    productPocketTypeValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productRelevance: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productSeasonStatus: Maybe<Scalars['Int']>
    productSeasonStatusValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productShape: Maybe<Scalars['Int']>
    productShapeValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productSizeTable: Maybe<Scalars['Int']>
    productSizeTableValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productSleeveCuffDetails: Maybe<Scalars['Int']>
    productSleeveCuffDetailsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productSleeveDetails: Maybe<Scalars['Int']>
    productSleeveDetailsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productSleeveLength: Maybe<Scalars['Int']>
    productSleeveLengthValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productSustainable: Maybe<Scalars['String']>
    productSustainableValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productType: Maybe<Scalars['Int']>
    productTypeValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productVolume: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productWaistRise: Maybe<Scalars['Int']>
    productWaistRiseValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productWashingInstructionsClotex: Maybe<Scalars['String']>
    productWashingInstructionsClotexValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productWidth: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    sizeTableIdentifier: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    washingSymbolDescription: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    washingSymbolMedia: Maybe<Scalars['String']>
    /**
     * The attribute set assigned to the product.
     * @deprecated The field should not be used on the storefront.
     */
    attributeSetId: Maybe<Scalars['Int']>
    /** @deprecated Use the `custom_attributes` field instead. */
    baseColor: Maybe<Scalars['String']>
    baseColorValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    brand: Maybe<Scalars['String']>
    /**
     * The relative canonical URL. This value is returned only if the system setting
     * 'Use Canonical Link Meta Tag For Products' is enabled.
     */
    canonicalUrl: Maybe<Scalars['String']>
    /** The categories assigned to a product. */
    categories: Maybe<Array<Maybe<CategoryInterface>>>
    categoryIds: Maybe<Array<Maybe<Scalars['Int']>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    color: Maybe<Scalars['String']>
    colorValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    configAttributes: Maybe<Scalars['String']>
    /** An array of options for the configurable product. */
    configurableOptions: Maybe<Array<Maybe<ConfigurableProductOptions>>>
    /**
     * An array of media gallery items and other details about selected configurable
     * product options as well as details about remaining selectable options.
     */
    configurableProductOptionsSelection: Maybe<ConfigurableProductOptionsSelection>
    /** Product bottom content */
    contentBottomContent: Maybe<Scalars['String']>
    /** Product top image one */
    contentBottomImageOne: Maybe<Scalars['String']>
    /** Product top image two */
    contentBottomImageTwo: Maybe<Scalars['String']>
    /** Product bottom title */
    contentBottomTitle: Maybe<Scalars['String']>
    contentBottomUsp: Maybe<Scalars['String']>
    /** Product middle content */
    contentMiddleContent: Maybe<Scalars['String']>
    /** Product top image one */
    contentMiddleImageOne: Maybe<Scalars['String']>
    /** Product top image two */
    contentMiddleImageTwo: Maybe<Scalars['String']>
    /** Product middle title */
    contentMiddleTitle: Maybe<Scalars['String']>
    /** Product size guide image above */
    contentSizeGuideImageAbove: Maybe<Scalars['String']>
    /** Product size guide image below */
    contentSizeGuideImageBelow: Maybe<Scalars['String']>
    /** Product top content */
    contentTopContent: Maybe<Scalars['String']>
    /** Product top image four */
    contentTopImageFour: Maybe<Scalars['String']>
    /** Product top image one */
    contentTopImageOne: Maybe<Scalars['String']>
    /** Product top image three */
    contentTopImageThree: Maybe<Scalars['String']>
    /** Product top image two */
    contentTopImageTwo: Maybe<Scalars['String']>
    /** Product top title */
    contentTopTitle: Maybe<Scalars['String']>
    /** The product's country of origin. */
    countryOfManufacture: Maybe<Scalars['String']>
    /**
     * Timestamp indicating when the product was created.
     * @deprecated The field should not be used on the storefront.
     */
    createdAt: Maybe<Scalars['String']>
    /** Crosssell Products */
    crosssellProducts: Maybe<Array<Maybe<ProductInterface>>>
    /** Product custom attributes. */
    customAttributesV2: Maybe<ProductCustomAttributes>
    /** Product Delivery Message */
    deliveryMessage: Maybe<Scalars['String']>
    /** Detailed information about the product. The value can include simple HTML tags. */
    description: Maybe<ComplexTextValue>
    /** @deprecated Use the `custom_attributes` field instead. */
    discountAmount: Maybe<Scalars['Float']>
    /** @deprecated Use the `custom_attributes` field instead. */
    discountPercentage: Maybe<Scalars['Float']>
    /** @deprecated Use the `custom_attributes` field instead. */
    featureIcons: Maybe<Scalars['String']>
    featureIconsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    features: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    fit: Maybe<Scalars['String']>
    fitValues: Maybe<Array<Maybe<AttributeOption>>>
    /** Indicates whether a gift message is available. */
    giftMessageAvailable: Maybe<Scalars['String']>
    hexColor: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    highlights: Maybe<Scalars['String']>
    highlightsValues: Maybe<Array<Maybe<AttributeOption>>>
    /**
     * The ID number assigned to the product.
     * @deprecated Use the `uid` field instead.
     */
    id: Scalars['Int']
    /** The relative path to the main image on the product page. */
    image: Maybe<ProductImage>
    /** URL for the Care Tab */
    infoTabCareUrl: Maybe<Scalars['String']>
    /** URL for the Features Tab */
    infoTabFeaturesUrl: Maybe<Scalars['String']>
    /** URL for the Fit Tab */
    infoTabFitUrl: Maybe<Scalars['String']>
    /** URL for the Highlights Tab */
    infoTabHighlightsUrl: Maybe<Scalars['String']>
    /** URL for the Materials Tab */
    infoTabMaterialsUrl: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    jeansSizeCombined: Maybe<Scalars['Int']>
    jeansSizeCombinedValues: Maybe<Array<Maybe<AttributeOption>>>
    /**
     * Proxy for ItemLabelTextB2C
     * @deprecated Use the `custom_attributes` field instead.
     */
    labeltekst: Maybe<Scalars['String']>
    /**
     * Proxy for ItemLabelTypeB2C
     * @deprecated Use the `custom_attributes` field instead.
     */
    labeltype: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    length: Maybe<Scalars['String']>
    lengthValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    lowestDate: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    lowestPrice: Maybe<Scalars['Float']>
    /** Main Category */
    mainCategory: Maybe<CategoryInterface>
    /**
     * A number representing the product's manufacturer.
     * @deprecated Use the `custom_attributes` field instead.
     */
    manufacturer: Maybe<Scalars['Int']>
    manufacturerValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    materialInside: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    materialOutside: Maybe<Scalars['String']>
    /** An array of media gallery objects. */
    mediaGallery: Maybe<Array<Maybe<MediaGalleryInterface>>>
    /**
     * An array of MediaGalleryEntry objects.
     * @deprecated Use `media_gallery` instead.
     */
    mediaGalleryEntries: Maybe<Array<Maybe<MediaGalleryEntry>>>
    /** A brief overview of the product for search results listings, maximum 255 characters. */
    metaDescription: Maybe<Scalars['String']>
    /** A comma-separated list of keywords that are visible only to search engines. */
    metaKeyword: Maybe<Scalars['String']>
    metaRobots: Maybe<Scalars['String']>
    /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
    metaTitle: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    modelImage: Maybe<Scalars['String']>
    /** Information related to the Model. */
    modelInfo: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    modelName: Maybe<Scalars['String']>
    /** The product name. Customers use this name to identify the product. */
    name: Scalars['String']
    /** The beginning date for new product listings, and determines if the product is featured as a new product. */
    newFromDate: Maybe<Scalars['String']>
    /** The end date for new product listings. */
    newToDate: Maybe<Scalars['String']>
    /** Product stock only x left count */
    onlyXLeftInStock: Maybe<Scalars['Float']>
    /** An array of options for a customizable product. */
    options: Maybe<Array<Maybe<CustomizableOptionInterface>>>
    /** If the product has multiple options, determines where they appear on the product page. */
    optionsContainer: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    outletAdviesprijs: Maybe<Scalars['Float']>
    /** @deprecated Use the `custom_attributes` field instead. */
    parentSku: Maybe<Scalars['String']>
    /**
     * Indicates the price of an item.
     * @deprecated Use `price_range` for product price information.
     */
    price: Maybe<ProductPrices>
    /** The range of prices for the product */
    priceRange: PriceRange
    /** An array of `TierPrice` objects. */
    priceTiers: Maybe<Array<Maybe<TierPrice>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productArticleCategoryCode: Maybe<Scalars['Int']>
    productArticleCategoryCodeValues: Maybe<Array<Maybe<AttributeOption>>>
    /**
     * Product type like, jeans, jacket etc.
     * @deprecated Use the `custom_attributes` field instead.
     */
    productGroup: Maybe<Scalars['String']>
    /** An array of `ProductLinks` objects. */
    productLinks: Maybe<Array<Maybe<ProductLinksInterface>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    products: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productsustainable2: Maybe<Scalars['String']>
    productsustainable2Values: Maybe<Array<Maybe<AttributeOption>>>
    /** The average of all the ratings given to the product. */
    ratingSummary: Scalars['Float']
    /** @deprecated Use the `custom_attributes` field instead. */
    recommendations: Maybe<Scalars['String']>
    /**
     * Contains 0 when there is no redirect error. A value of 301 indicates the URL
     * of the requested resource has been changed permanently, while a value of 302
     * indicates a temporary redirect.
     */
    redirectCode: Scalars['Int']
    /** An array of products to be displayed in a Related Products block. */
    relatedProducts: Maybe<Array<Maybe<ProductInterface>>>
    /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
    relativeUrl: Maybe<Scalars['String']>
    /** The total count of all the reviews given to the product. */
    reviewCount: Scalars['Int']
    /** The list of products reviews. */
    reviews: ProductReviews
    /** @deprecated Use the `custom_attributes` field instead. */
    seasonCollection: Maybe<Scalars['String']>
    seasonCollectionValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    shopthelook: Maybe<Scalars['Int']>
    /** A short description of the product. Its use depends on the theme. */
    shortDescription: Maybe<ComplexTextValue>
    /** @deprecated Use the `custom_attributes` field instead. */
    shortDescription2: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    shortDescription3: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    shortDescription4: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    shortDescription5: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    shortDescription6: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    size: Maybe<Scalars['String']>
    sizeValues: Maybe<Array<Maybe<AttributeOption>>>
    /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
    sku: Scalars['String']
    /** The relative path to the small image, which is used on catalog pages. */
    smallImage: Maybe<ProductImage>
    /**
     * The beginning date that a product has a special price.
     * @deprecated The field should not be used on the storefront.
     */
    specialFromDate: Maybe<Scalars['String']>
    /** The discounted price of the product. */
    specialPrice: Maybe<Scalars['Float']>
    /** The end date for a product with a special price. */
    specialToDate: Maybe<Scalars['String']>
    /** Stock status of the product */
    stockStatus: Maybe<ProductStockStatus>
    /** The file name of a swatch image. */
    swatchImage: Maybe<Scalars['String']>
    /** The relative path to the product's thumbnail image. */
    thumbnail: Maybe<ProductImage>
    /**
     * The price when tier pricing is in effect and the items purchased threshold has been reached.
     * @deprecated Use `price_tiers` for product tier price information.
     */
    tierPrice: Maybe<Scalars['Float']>
    /**
     * An array of ProductTierPrices objects.
     * @deprecated Use `price_tiers` for product tier price information.
     */
    tierPrices: Maybe<Array<Maybe<ProductTierPrices>>>
    tweakwiseCrosssellGroupCode: Maybe<Scalars['String']>
    /** Value -1 means the group_code should be used */
    tweakwiseCrosssellTemplate: Maybe<Scalars['Int']>
    tweakwiseUpsellGroupCode: Maybe<Scalars['String']>
    /** Value -1 means the group_code should be used */
    tweakwiseUpsellTemplate: Maybe<Scalars['Int']>
    /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
    type: Maybe<UrlRewriteEntityTypeEnum>
    /**
     * One of simple, virtual, bundle, downloadable, grouped, or configurable.
     * @deprecated Use `__typename` instead.
     */
    typeId: Maybe<Scalars['String']>
    /** The unique ID for a `ProductInterface` object. */
    uid: Scalars['ID']
    /**
     * Timestamp indicating when the product was updated.
     * @deprecated The field should not be used on the storefront.
     */
    updatedAt: Maybe<Scalars['String']>
    /** Upsell Products */
    upsellProducts: Maybe<Array<Maybe<ProductInterface>>>
    /** The part of the URL that identifies the product */
    urlKey: Scalars['String']
    /** @deprecated Use product's `canonical_url` or url rewrites instead */
    urlPath: Maybe<Scalars['String']>
    /** URL rewrites list */
    urlRewrites: Maybe<Array<Maybe<UrlRewrite>>>
    /** The part of the product URL that is appended after the url key */
    urlSuffix: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    uspIcons: Maybe<Scalars['String']>
    uspIconsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** USPs for this specific Product. */
    usps: Array<Maybe<Usp>>
    /** An array of simple product variants. */
    variants: Maybe<Array<Maybe<ConfigurableVariant>>>
    /** Total number of simple product variants. */
    variantsCnt: Scalars['Int']
    /** An array of simple product variants in stock. */
    variantsInStock: Array<Maybe<ConfigurableVariant>>
    /** Total number of simple product variants in stock. */
    variantsInStockCnt: Scalars['Int']
    warehouse: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    washingInstructions: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    washingSymbols: Maybe<Scalars['String']>
    washingSymbolsValues: Maybe<Array<Maybe<AttributeOption>>>
    /**
     * An array of websites in which the product is available.
     * @deprecated Use the `custom_attributes` field instead.
     */
    websites: Maybe<Array<Maybe<Website>>>
    /** The weight of the item, in units defined by the store. */
    weight: Maybe<Scalars['Float']>
}

/** Defines basic features of a configurable product and its simple product variants. */
export interface ConfigurableProductConfigurableProductOptionsSelectionArgs {
    configurableOptionValueUids: Maybe<Array<Scalars['ID']>>
}

/** Defines basic features of a configurable product and its simple product variants. */
export interface ConfigurableProductCustomAttributesV2Args {
    filters: Maybe<AttributeFilterInput>
}

/** Defines basic features of a configurable product and its simple product variants. */
export interface ConfigurableProductReviewsArgs {
    pageSize?: Maybe<Scalars['Int']>
    currentPage?: Maybe<Scalars['Int']>
}

/** Defines basic features of a configurable product and its simple product variants. */
export interface ConfigurableProductUspsArgs {
    pageSize?: Maybe<Scalars['Int']>
    currentPage?: Maybe<Scalars['Int']>
}

export interface ConfigurableProductCartItemInput {
    /** The ID and value of the option. */
    customizable_options?: Maybe<Array<Maybe<CustomizableOptionInput>>>
    /** The quantity and SKU of the configurable product. */
    data: CartItemInput
    /** The SKU of the parent configurable product. */
    parent_sku?: Maybe<Scalars['String']>
    /** Deprecated. Use `CartItemInput.sku` instead. */
    variant_sku?: Maybe<Scalars['String']>
}

/** Contains details about configurable product options. */
export interface ConfigurableProductOption {
    __typename?: 'ConfigurableProductOption'
    /** An attribute code that uniquely identifies a configurable option. */
    attributeCode: Scalars['String']
    /** The display name of the option. */
    label: Scalars['String']
    /** The unique ID of the configurable option. */
    uid: Scalars['ID']
    /** An array of values that are applicable for this option. */
    values: Maybe<Array<Maybe<ConfigurableProductOptionValue>>>
}

/** Defines configurable attributes for the specified product. */
export interface ConfigurableProductOptions {
    __typename?: 'ConfigurableProductOptions'
    /** A string that identifies the attribute. */
    attributeCode: Maybe<Scalars['String']>
    /**
     * The ID assigned to the attribute.
     * @deprecated Use `attribute_uid` instead.
     */
    attributeId: Maybe<Scalars['String']>
    /**
     * The ID assigned to the attribute.
     * @deprecated Use `attribute_uid` instead.
     */
    attributeIdV2: Maybe<Scalars['Int']>
    /** The unique ID for an `Attribute` object. */
    attributeUid: Scalars['ID']
    /**
     * The configurable option ID number assigned by the system.
     * @deprecated Use `uid` instead.
     */
    id: Maybe<Scalars['Int']>
    /** A displayed string that describes the configurable product option. */
    label: Maybe<Scalars['String']>
    /** A number that indicates the order in which the attribute is displayed. */
    position: Maybe<Scalars['Int']>
    /**
     * This is the same as a product's `id` field.
     * @deprecated `product_id` is not needed and can be obtained from its parent.
     */
    productId: Maybe<Scalars['Int']>
    /** The unique ID for a `ConfigurableProductOptions` object. */
    uid: Scalars['ID']
    /** Indicates whether the option is the default. */
    useDefault: Maybe<Scalars['Boolean']>
    /** An array that defines the `value_index` codes assigned to the configurable product. */
    values: Maybe<Array<Maybe<ConfigurableProductOptionsValues>>>
}

/** Contains metadata corresponding to the selected configurable options. */
export interface ConfigurableProductOptionsSelection {
    __typename?: 'ConfigurableProductOptionsSelection'
    /** An array of all possible configurable options. */
    configurableOptions: Maybe<Array<Maybe<ConfigurableProductOption>>>
    /** Product images and videos corresponding to the specified configurable options selection. */
    mediaGallery: Maybe<Array<Maybe<MediaGalleryInterface>>>
    /** The configurable options available for further selection based on the current selection. */
    optionsAvailableForSelection: Maybe<
        Array<Maybe<ConfigurableOptionAvailableForSelection>>
    >
    /**
     * A variant represented by the specified configurable options selection. The
     * value is expected to be null until selections are made for each configurable option.
     */
    variant: Maybe<SimpleProduct>
}

/** Contains the index number assigned to a configurable product option. */
export interface ConfigurableProductOptionsValues {
    __typename?: 'ConfigurableProductOptionsValues'
    /** The label of the product on the default store. */
    defaultLabel: Maybe<Scalars['String']>
    /** The label of the product. */
    label: Maybe<Scalars['String']>
    /** The label of the product on the current store. */
    storeLabel: Maybe<Scalars['String']>
    /** Swatch data for a configurable product option. */
    swatchData: Maybe<SwatchDataInterface>
    /** The unique ID for a `ConfigurableProductOptionsValues` object. */
    uid: Maybe<Scalars['ID']>
    /** Indicates whether to use the default_label. */
    useDefaultValue: Maybe<Scalars['Boolean']>
    /**
     * A unique index number assigned to the configurable product option.
     * @deprecated Use `uid` instead.
     */
    valueIndex: Maybe<Scalars['Int']>
}

/** Defines a value for a configurable product option. */
export interface ConfigurableProductOptionValue {
    __typename?: 'ConfigurableProductOptionValue'
    /** Indicates whether the product is available with this selected option. */
    isAvailable: Scalars['Boolean']
    /** Indicates whether the value is the default. */
    isUseDefault: Scalars['Boolean']
    /** The display name of the value. */
    label: Scalars['String']
    /** The URL assigned to the thumbnail of the swatch image. */
    swatch: Maybe<SwatchDataInterface>
    /** The unique ID of the value. */
    uid: Scalars['ID']
}

/** Contains all the simple product variants of a configurable product. */
export interface ConfigurableVariant {
    __typename?: 'ConfigurableVariant'
    /** An array of configurable attribute options. */
    attributes: Maybe<Array<Maybe<ConfigurableAttributeOption>>>
    /** An array of linked simple products. */
    product: Maybe<SimpleProduct>
}

/** A configurable product wish list item. */
export interface ConfigurableWishlistItem extends WishlistItemInterface {
    __typename?: 'ConfigurableWishlistItem'
    /** The date and time the item was added to the wish list. */
    addedAt: Scalars['String']
    /**
     * The SKU of the simple product corresponding to a set of selected configurable options.
     * @deprecated Use `ConfigurableWishlistItem.configured_variant.sku` instead.
     */
    childSku: Scalars['String']
    /** An array of selected configurable options. */
    configurableOptions: Maybe<Array<Maybe<SelectedConfigurableOption>>>
    /** Product details of the selected variant. The value is null if some options are not configured. */
    configuredVariant: Maybe<ProductInterface>
    /** Custom options selected for the wish list item. */
    customizableOptions: Array<Maybe<SelectedCustomizableOption>>
    /** The description of the item. */
    description: Maybe<Scalars['String']>
    /** The unique ID for a `WishlistItemInterface` object. */
    id: Scalars['ID']
    /** Product details of the wish list item. */
    product: Maybe<ProductInterface>
    /** The quantity of this wish list item. */
    quantity: Scalars['Float']
}

/** List of account confirmation statuses. */
export enum ConfirmationStatusEnum {
    /** Account confirmed */
    ACCOUNT_CONFIRMED = 'ACCOUNT_CONFIRMED',
    /** Account confirmation not required */
    ACCOUNT_CONFIRMATION_NOT_REQUIRED = 'ACCOUNT_CONFIRMATION_NOT_REQUIRED',
}

/** Contains details about a customer email address to confirm. */
export interface ConfirmEmailInput {
    /** The key to confirm the email address. */
    confirmation_key: Scalars['String']
    /** The email address to be confirmed. */
    email: Scalars['String']
}

export interface ContactUsInput {
    /** The shopper's comment to the merchant. */
    comment: Scalars['String']
    /** The email address of the shopper. */
    email: Scalars['String']
    /** The full name of the shopper. */
    name: Scalars['String']
    /** The shopper's telephone number. */
    telephone?: Maybe<Scalars['String']>
}

/** Contains the status of the request. */
export interface ContactUsOutput {
    __typename?: 'ContactUsOutput'
    /** Indicates whether the request was successful. */
    status: Scalars['Boolean']
}

/** CoordinatesInput defines the parameters which will be used for filter by specified location. */
export interface CoordinatesInput {
    /** The latitude. */
    latitude: Scalars['Float']
    /** The longitude. */
    longitude: Scalars['Float']
}

export interface CoreConfigData {
    __typename?: 'CoreConfigData'
    /** Path of core_config_data row */
    code: Maybe<Scalars['String']>
    /** Value of core_config_data row */
    value: Maybe<Scalars['String']>
}

export interface CoreConfigResult {
    __typename?: 'CoreConfigResult'
    /** Data result of the path and values found in the `core_config_data` table */
    data: Array<Maybe<CoreConfigData>>
}

export interface Country {
    __typename?: 'Country'
    /** An array of regions within a particular country. */
    availableRegions: Maybe<Array<Maybe<Region>>>
    /** The name of the country in English. */
    fullNameEnglish: Maybe<Scalars['String']>
    /** The name of the country in the current locale. */
    fullNameLocale: Maybe<Scalars['String']>
    /** The unique ID for a `Country` object. */
    id: Maybe<Scalars['String']>
    /** The three-letter abbreviation of the country, such as USA. */
    threeLetterAbbreviation: Maybe<Scalars['String']>
    /** The two-letter abbreviation of the country, such as US. */
    twoLetterAbbreviation: Maybe<Scalars['String']>
}

/** The list of country codes. */
export enum CountryCodeEnum {
    /** Afghanistan */
    AF = 'AF',
    /** Åland Islands */
    AX = 'AX',
    /** Albania */
    AL = 'AL',
    /** Algeria */
    DZ = 'DZ',
    /** American Samoa */
    AS = 'AS',
    /** Andorra */
    AD = 'AD',
    /** Angola */
    AO = 'AO',
    /** Anguilla */
    AI = 'AI',
    /** Antarctica */
    AQ = 'AQ',
    /** Antigua & Barbuda */
    AG = 'AG',
    /** Argentina */
    AR = 'AR',
    /** Armenia */
    AM = 'AM',
    /** Aruba */
    AW = 'AW',
    /** Australia */
    AU = 'AU',
    /** Austria */
    AT = 'AT',
    /** Azerbaijan */
    AZ = 'AZ',
    /** Bahamas */
    BS = 'BS',
    /** Bahrain */
    BH = 'BH',
    /** Bangladesh */
    BD = 'BD',
    /** Barbados */
    BB = 'BB',
    /** Belarus */
    BY = 'BY',
    /** Belgium */
    BE = 'BE',
    /** Belize */
    BZ = 'BZ',
    /** Benin */
    BJ = 'BJ',
    /** Bermuda */
    BM = 'BM',
    /** Bhutan */
    BT = 'BT',
    /** Bolivia */
    BO = 'BO',
    /** Bosnia & Herzegovina */
    BA = 'BA',
    /** Botswana */
    BW = 'BW',
    /** Bouvet Island */
    BV = 'BV',
    /** Brazil */
    BR = 'BR',
    /** British Indian Ocean Territory */
    IO = 'IO',
    /** British Virgin Islands */
    VG = 'VG',
    /** Brunei */
    BN = 'BN',
    /** Bulgaria */
    BG = 'BG',
    /** Burkina Faso */
    BF = 'BF',
    /** Burundi */
    BI = 'BI',
    /** Cambodia */
    KH = 'KH',
    /** Cameroon */
    CM = 'CM',
    /** Canada */
    CA = 'CA',
    /** Cape Verde */
    CV = 'CV',
    /** Cayman Islands */
    KY = 'KY',
    /** Central African Republic */
    CF = 'CF',
    /** Chad */
    TD = 'TD',
    /** Chile */
    CL = 'CL',
    /** China */
    CN = 'CN',
    /** Christmas Island */
    CX = 'CX',
    /** Cocos (Keeling) Islands */
    CC = 'CC',
    /** Colombia */
    CO = 'CO',
    /** Comoros */
    KM = 'KM',
    /** Congo-Brazzaville */
    CG = 'CG',
    /** Congo-Kinshasa */
    CD = 'CD',
    /** Cook Islands */
    CK = 'CK',
    /** Costa Rica */
    CR = 'CR',
    /** Côte d’Ivoire */
    CI = 'CI',
    /** Croatia */
    HR = 'HR',
    /** Cuba */
    CU = 'CU',
    /** Cyprus */
    CY = 'CY',
    /** Czech Republic */
    CZ = 'CZ',
    /** Denmark */
    DK = 'DK',
    /** Djibouti */
    DJ = 'DJ',
    /** Dominica */
    DM = 'DM',
    /** Dominican Republic */
    DO = 'DO',
    /** Ecuador */
    EC = 'EC',
    /** Egypt */
    EG = 'EG',
    /** El Salvador */
    SV = 'SV',
    /** Equatorial Guinea */
    GQ = 'GQ',
    /** Eritrea */
    ER = 'ER',
    /** Estonia */
    EE = 'EE',
    /** Eswatini */
    SZ = 'SZ',
    /** Ethiopia */
    ET = 'ET',
    /** Falkland Islands */
    FK = 'FK',
    /** Faroe Islands */
    FO = 'FO',
    /** Fiji */
    FJ = 'FJ',
    /** Finland */
    FI = 'FI',
    /** France */
    FR = 'FR',
    /** French Guiana */
    GF = 'GF',
    /** French Polynesia */
    PF = 'PF',
    /** French Southern Territories */
    TF = 'TF',
    /** Gabon */
    GA = 'GA',
    /** Gambia */
    GM = 'GM',
    /** Georgia */
    GE = 'GE',
    /** Germany */
    DE = 'DE',
    /** Ghana */
    GH = 'GH',
    /** Gibraltar */
    GI = 'GI',
    /** Greece */
    GR = 'GR',
    /** Greenland */
    GL = 'GL',
    /** Grenada */
    GD = 'GD',
    /** Guadeloupe */
    GP = 'GP',
    /** Guam */
    GU = 'GU',
    /** Guatemala */
    GT = 'GT',
    /** Guernsey */
    GG = 'GG',
    /** Guinea */
    GN = 'GN',
    /** Guinea-Bissau */
    GW = 'GW',
    /** Guyana */
    GY = 'GY',
    /** Haiti */
    HT = 'HT',
    /** Heard &amp; McDonald Islands */
    HM = 'HM',
    /** Honduras */
    HN = 'HN',
    /** Hong Kong SAR China */
    HK = 'HK',
    /** Hungary */
    HU = 'HU',
    /** Iceland */
    IS = 'IS',
    /** India */
    IN = 'IN',
    /** Indonesia */
    ID = 'ID',
    /** Iran */
    IR = 'IR',
    /** Iraq */
    IQ = 'IQ',
    /** Ireland */
    IE = 'IE',
    /** Isle of Man */
    IM = 'IM',
    /** Israel */
    IL = 'IL',
    /** Italy */
    IT = 'IT',
    /** Jamaica */
    JM = 'JM',
    /** Japan */
    JP = 'JP',
    /** Jersey */
    JE = 'JE',
    /** Jordan */
    JO = 'JO',
    /** Kazakhstan */
    KZ = 'KZ',
    /** Kenya */
    KE = 'KE',
    /** Kiribati */
    KI = 'KI',
    /** Kuwait */
    KW = 'KW',
    /** Kyrgyzstan */
    KG = 'KG',
    /** Laos */
    LA = 'LA',
    /** Latvia */
    LV = 'LV',
    /** Lebanon */
    LB = 'LB',
    /** Lesotho */
    LS = 'LS',
    /** Liberia */
    LR = 'LR',
    /** Libya */
    LY = 'LY',
    /** Liechtenstein */
    LI = 'LI',
    /** Lithuania */
    LT = 'LT',
    /** Luxembourg */
    LU = 'LU',
    /** Macau SAR China */
    MO = 'MO',
    /** Macedonia */
    MK = 'MK',
    /** Madagascar */
    MG = 'MG',
    /** Malawi */
    MW = 'MW',
    /** Malaysia */
    MY = 'MY',
    /** Maldives */
    MV = 'MV',
    /** Mali */
    ML = 'ML',
    /** Malta */
    MT = 'MT',
    /** Marshall Islands */
    MH = 'MH',
    /** Martinique */
    MQ = 'MQ',
    /** Mauritania */
    MR = 'MR',
    /** Mauritius */
    MU = 'MU',
    /** Mayotte */
    YT = 'YT',
    /** Mexico */
    MX = 'MX',
    /** Micronesia */
    FM = 'FM',
    /** Moldova */
    MD = 'MD',
    /** Monaco */
    MC = 'MC',
    /** Mongolia */
    MN = 'MN',
    /** Montenegro */
    ME = 'ME',
    /** Montserrat */
    MS = 'MS',
    /** Morocco */
    MA = 'MA',
    /** Mozambique */
    MZ = 'MZ',
    /** Myanmar (Burma) */
    MM = 'MM',
    /** Namibia */
    NA = 'NA',
    /** Nauru */
    NR = 'NR',
    /** Nepal */
    NP = 'NP',
    /** Netherlands */
    NL = 'NL',
    /** Netherlands Antilles */
    AN = 'AN',
    /** New Caledonia */
    NC = 'NC',
    /** New Zealand */
    NZ = 'NZ',
    /** Nicaragua */
    NI = 'NI',
    /** Niger */
    NE = 'NE',
    /** Nigeria */
    NG = 'NG',
    /** Niue */
    NU = 'NU',
    /** Norfolk Island */
    NF = 'NF',
    /** Northern Mariana Islands */
    MP = 'MP',
    /** North Korea */
    KP = 'KP',
    /** Norway */
    NO = 'NO',
    /** Oman */
    OM = 'OM',
    /** Pakistan */
    PK = 'PK',
    /** Palau */
    PW = 'PW',
    /** Palestinian Territories */
    PS = 'PS',
    /** Panama */
    PA = 'PA',
    /** Papua New Guinea */
    PG = 'PG',
    /** Paraguay */
    PY = 'PY',
    /** Peru */
    PE = 'PE',
    /** Philippines */
    PH = 'PH',
    /** Pitcairn Islands */
    PN = 'PN',
    /** Poland */
    PL = 'PL',
    /** Portugal */
    PT = 'PT',
    /** Qatar */
    QA = 'QA',
    /** Réunion */
    RE = 'RE',
    /** Romania */
    RO = 'RO',
    /** Russia */
    RU = 'RU',
    /** Rwanda */
    RW = 'RW',
    /** Samoa */
    WS = 'WS',
    /** San Marino */
    SM = 'SM',
    /** São Tomé & Príncipe */
    ST = 'ST',
    /** Saudi Arabia */
    SA = 'SA',
    /** Senegal */
    SN = 'SN',
    /** Serbia */
    RS = 'RS',
    /** Seychelles */
    SC = 'SC',
    /** Sierra Leone */
    SL = 'SL',
    /** Singapore */
    SG = 'SG',
    /** Slovakia */
    SK = 'SK',
    /** Slovenia */
    SI = 'SI',
    /** Solomon Islands */
    SB = 'SB',
    /** Somalia */
    SO = 'SO',
    /** South Africa */
    ZA = 'ZA',
    /** South Georgia & South Sandwich Islands */
    GS = 'GS',
    /** South Korea */
    KR = 'KR',
    /** Spain */
    ES = 'ES',
    /** Sri Lanka */
    LK = 'LK',
    /** St. Barthélemy */
    BL = 'BL',
    /** St. Helena */
    SH = 'SH',
    /** St. Kitts & Nevis */
    KN = 'KN',
    /** St. Lucia */
    LC = 'LC',
    /** St. Martin */
    MF = 'MF',
    /** St. Pierre & Miquelon */
    PM = 'PM',
    /** St. Vincent & Grenadines */
    VC = 'VC',
    /** Sudan */
    SD = 'SD',
    /** Suriname */
    SR = 'SR',
    /** Svalbard & Jan Mayen */
    SJ = 'SJ',
    /** Sweden */
    SE = 'SE',
    /** Switzerland */
    CH = 'CH',
    /** Syria */
    SY = 'SY',
    /** Taiwan */
    TW = 'TW',
    /** Tajikistan */
    TJ = 'TJ',
    /** Tanzania */
    TZ = 'TZ',
    /** Thailand */
    TH = 'TH',
    /** Timor-Leste */
    TL = 'TL',
    /** Togo */
    TG = 'TG',
    /** Tokelau */
    TK = 'TK',
    /** Tonga */
    TO = 'TO',
    /** Trinidad & Tobago */
    TT = 'TT',
    /** Tunisia */
    TN = 'TN',
    /** Turkey */
    TR = 'TR',
    /** Turkmenistan */
    TM = 'TM',
    /** Turks & Caicos Islands */
    TC = 'TC',
    /** Tuvalu */
    TV = 'TV',
    /** Uganda */
    UG = 'UG',
    /** Ukraine */
    UA = 'UA',
    /** United Arab Emirates */
    AE = 'AE',
    /** United Kingdom */
    GB = 'GB',
    /** United States */
    US = 'US',
    /** Uruguay */
    UY = 'UY',
    /** U.S. Outlying Islands */
    UM = 'UM',
    /** U.S. Virgin Islands */
    VI = 'VI',
    /** Uzbekistan */
    UZ = 'UZ',
    /** Vanuatu */
    VU = 'VU',
    /** Vatican City */
    VA = 'VA',
    /** Venezuela */
    VE = 'VE',
    /** Vietnam */
    VN = 'VN',
    /** Wallis & Futuna */
    WF = 'WF',
    /** Western Sahara */
    EH = 'EH',
    /** Yemen */
    YE = 'YE',
    /** Zambia */
    ZM = 'ZM',
    /** Zimbabwe */
    ZW = 'ZW',
}

/** Contains an array of product IDs to use for creating a compare list. */
export interface CreateCompareListInput {
    /** An array of product IDs to add to the compare list. */
    products?: Maybe<Array<Maybe<Scalars['ID']>>>
}

/** Assigns a specific `cart_id` to the empty cart. */
export interface CreateEmptyCartInput {
    /** The ID to assign to the cart. */
    cart_id?: Maybe<Scalars['String']>
}

export interface CreateGuestCartInput {
    /** Optional client-generated ID */
    cart_uid?: Maybe<Scalars['ID']>
}

export interface CreateGuestCartOutput {
    __typename?: 'CreateGuestCartOutput'
    /** The newly created cart. */
    cart: Maybe<Cart>
}

export interface CreateGuestWishlistOutput {
    __typename?: 'CreateGuestWishlistOutput'
    /** An array of errors encountered while creating the guest wish list */
    userErrors: Array<Maybe<WishListUserInputError>>
    /** Contains the created guest wish list */
    wishlist: Wishlist
}

/** Contains the secure information used to authorize transaction. Applies to Payflow Pro and Payments Pro payment methods. */
export interface CreatePayflowProTokenOutput {
    __typename?: 'CreatePayflowProTokenOutput'
    /** The RESPMSG returned by PayPal. If the `result` is `0`, then `response_message` is `Approved`. */
    responseMessage: Scalars['String']
    /** A non-zero value if any errors occurred. */
    result: Scalars['Int']
    /** The RESULT returned by PayPal. A value of `0` indicates the transaction was approved. */
    resultCode: Scalars['Int']
    /** A secure token generated by PayPal. */
    secureToken: Scalars['String']
    /** A secure token ID generated by PayPal. */
    secureTokenId: Scalars['String']
}

/** Defines a new product review. */
export interface CreateProductReviewInput {
    /** The customer's nickname. Defaults to the customer name, if logged in. */
    nickname: Scalars['String']
    /** The ratings details by category. For example, Price: 5 stars, Quality: 4 stars, etc. */
    ratings: Array<Maybe<ProductReviewRatingInput>>
    /** The SKU of the reviewed product. */
    sku: Scalars['String']
    /** The summary (title) of the review. */
    summary: Scalars['String']
    /** The review text. */
    text: Scalars['String']
}

/** Contains the completed product review. */
export interface CreateProductReviewOutput {
    __typename?: 'CreateProductReviewOutput'
    /** Product review details. */
    review: ProductReview
}

/** Required fields for Payflow Pro and Payments Pro credit card payments. */
export interface CreditCardDetailsInput {
    /** The credit card expiration month. */
    cc_exp_month: Scalars['Int']
    /** The credit card expiration year. */
    cc_exp_year: Scalars['Int']
    /** The last 4 digits of the credit card. */
    cc_last_4: Scalars['Int']
    /** The credit card type. */
    cc_type: Scalars['String']
}

/** Contains credit memo details. */
export interface CreditMemo {
    __typename?: 'CreditMemo'
    /** Comments on the credit memo. */
    comments: Maybe<Array<Maybe<SalesCommentItem>>>
    /** The unique ID for a `CreditMemo` object. */
    id: Scalars['ID']
    /** An array containing details about refunded items. */
    items: Maybe<Array<Maybe<CreditMemoItemInterface>>>
    /** The sequential credit memo number. */
    number: Scalars['String']
    /** Details about the total refunded amount. */
    total: Maybe<CreditMemoTotal>
}

export interface CreditMemoItem extends CreditMemoItemInterface {
    __typename?: 'CreditMemoItem'
    /** Details about the final discount amount for the base product, including discounts on options. */
    discounts: Maybe<Array<Maybe<Discount>>>
    /** The unique ID for a `CreditMemoItemInterface` object. */
    id: Scalars['ID']
    /** The order item the credit memo is applied to. */
    orderItem: Maybe<OrderItemInterface>
    /** The name of the base product. */
    productName: Maybe<Scalars['String']>
    /** The sale price for the base product, including selected options. */
    productSalePrice: Money
    /** The SKU of the base product. */
    productSku: Scalars['String']
    /** The number of refunded items. */
    quantityRefunded: Maybe<Scalars['Float']>
}

/** Credit memo item details. */
export interface CreditMemoItemInterface {
    /** Details about the final discount amount for the base product, including discounts on options. */
    discounts: Maybe<Array<Maybe<Discount>>>
    /** The unique ID for a `CreditMemoItemInterface` object. */
    id: Scalars['ID']
    /** The order item the credit memo is applied to. */
    orderItem: Maybe<OrderItemInterface>
    /** The name of the base product. */
    productName: Maybe<Scalars['String']>
    /** The sale price for the base product, including selected options. */
    productSalePrice: Money
    /** The SKU of the base product. */
    productSku: Scalars['String']
    /** The number of refunded items. */
    quantityRefunded: Maybe<Scalars['Float']>
}

/** Contains credit memo price details. */
export interface CreditMemoTotal {
    __typename?: 'CreditMemoTotal'
    /** An adjustment manually applied to the order. */
    adjustment: Money
    /** The final base grand total amount in the base currency. */
    baseGrandTotal: Money
    /** The applied discounts to the credit memo. */
    discounts: Maybe<Array<Maybe<Discount>>>
    /** The final total amount, including shipping, discounts, and taxes. */
    grandTotal: Money
    /** Details about the shipping and handling costs for the credit memo. */
    shippingHandling: Maybe<ShippingHandling>
    /** The subtotal of the invoice, excluding shipping, discounts, and taxes. */
    subtotal: Money
    /** The credit memo tax details. */
    taxes: Maybe<Array<Maybe<TaxItem>>>
    /** The shipping amount for the credit memo. */
    totalShipping: Money
    /** The amount of tax applied to the credit memo. */
    totalTax: Money
}

export interface Currency {
    __typename?: 'Currency'
    /** An array of three-letter currency codes accepted by the store, such as USD and EUR. */
    availableCurrencyCodes: Maybe<Array<Maybe<Scalars['String']>>>
    /** The base currency set for the store, such as USD. */
    baseCurrencyCode: Maybe<Scalars['String']>
    /** The symbol for the specified base currency, such as $. */
    baseCurrencySymbol: Maybe<Scalars['String']>
    /** @deprecated Symbol was missed. Use `default_display_currency_code`. */
    defaultDisplayCurrecyCode: Maybe<Scalars['String']>
    /** @deprecated Symbol was missed. Use `default_display_currency_code`. */
    defaultDisplayCurrecySymbol: Maybe<Scalars['String']>
    /** The currency that is displayed by default, such as USD. */
    defaultDisplayCurrencyCode: Maybe<Scalars['String']>
    /** The currency symbol that is displayed by default, such as $. */
    defaultDisplayCurrencySymbol: Maybe<Scalars['String']>
    /** An array of exchange rates for currencies defined in the store. */
    exchangeRates: Maybe<Array<Maybe<ExchangeRate>>>
}

/** The list of available currency codes. */
export enum CurrencyEnum {
    AFN = 'AFN',
    ALL = 'ALL',
    AZN = 'AZN',
    DZD = 'DZD',
    AOA = 'AOA',
    ARS = 'ARS',
    AMD = 'AMD',
    AWG = 'AWG',
    AUD = 'AUD',
    BSD = 'BSD',
    BHD = 'BHD',
    BDT = 'BDT',
    BBD = 'BBD',
    BYN = 'BYN',
    BZD = 'BZD',
    BMD = 'BMD',
    BTN = 'BTN',
    BOB = 'BOB',
    BAM = 'BAM',
    BWP = 'BWP',
    BRL = 'BRL',
    GBP = 'GBP',
    BND = 'BND',
    BGN = 'BGN',
    BUK = 'BUK',
    BIF = 'BIF',
    KHR = 'KHR',
    CAD = 'CAD',
    CVE = 'CVE',
    CZK = 'CZK',
    KYD = 'KYD',
    GQE = 'GQE',
    CLP = 'CLP',
    CNY = 'CNY',
    COP = 'COP',
    KMF = 'KMF',
    CDF = 'CDF',
    CRC = 'CRC',
    HRK = 'HRK',
    CUP = 'CUP',
    DKK = 'DKK',
    DJF = 'DJF',
    DOP = 'DOP',
    XCD = 'XCD',
    EGP = 'EGP',
    SVC = 'SVC',
    ERN = 'ERN',
    EEK = 'EEK',
    ETB = 'ETB',
    EUR = 'EUR',
    FKP = 'FKP',
    FJD = 'FJD',
    GMD = 'GMD',
    GEK = 'GEK',
    GEL = 'GEL',
    GHS = 'GHS',
    GIP = 'GIP',
    GTQ = 'GTQ',
    GNF = 'GNF',
    GYD = 'GYD',
    HTG = 'HTG',
    HNL = 'HNL',
    HKD = 'HKD',
    HUF = 'HUF',
    ISK = 'ISK',
    INR = 'INR',
    IDR = 'IDR',
    IRR = 'IRR',
    IQD = 'IQD',
    ILS = 'ILS',
    JMD = 'JMD',
    JPY = 'JPY',
    JOD = 'JOD',
    KZT = 'KZT',
    KES = 'KES',
    KWD = 'KWD',
    KGS = 'KGS',
    LAK = 'LAK',
    LVL = 'LVL',
    LBP = 'LBP',
    LSL = 'LSL',
    LRD = 'LRD',
    LYD = 'LYD',
    LTL = 'LTL',
    MOP = 'MOP',
    MKD = 'MKD',
    MGA = 'MGA',
    MWK = 'MWK',
    MYR = 'MYR',
    MVR = 'MVR',
    LSM = 'LSM',
    MRO = 'MRO',
    MUR = 'MUR',
    MXN = 'MXN',
    MDL = 'MDL',
    MNT = 'MNT',
    MAD = 'MAD',
    MZN = 'MZN',
    MMK = 'MMK',
    NAD = 'NAD',
    NPR = 'NPR',
    ANG = 'ANG',
    YTL = 'YTL',
    NZD = 'NZD',
    NIC = 'NIC',
    NGN = 'NGN',
    KPW = 'KPW',
    NOK = 'NOK',
    OMR = 'OMR',
    PKR = 'PKR',
    PAB = 'PAB',
    PGK = 'PGK',
    PYG = 'PYG',
    PEN = 'PEN',
    PHP = 'PHP',
    PLN = 'PLN',
    QAR = 'QAR',
    RHD = 'RHD',
    RON = 'RON',
    RUB = 'RUB',
    RWF = 'RWF',
    SHP = 'SHP',
    STD = 'STD',
    SAR = 'SAR',
    RSD = 'RSD',
    SCR = 'SCR',
    SLL = 'SLL',
    SGD = 'SGD',
    SKK = 'SKK',
    SBD = 'SBD',
    SOS = 'SOS',
    ZAR = 'ZAR',
    KRW = 'KRW',
    LKR = 'LKR',
    SDG = 'SDG',
    SRD = 'SRD',
    SZL = 'SZL',
    SEK = 'SEK',
    CHF = 'CHF',
    SYP = 'SYP',
    TWD = 'TWD',
    TJS = 'TJS',
    TZS = 'TZS',
    THB = 'THB',
    TOP = 'TOP',
    TTD = 'TTD',
    TND = 'TND',
    TMM = 'TMM',
    USD = 'USD',
    UGX = 'UGX',
    UAH = 'UAH',
    AED = 'AED',
    UYU = 'UYU',
    UZS = 'UZS',
    VUV = 'VUV',
    VEB = 'VEB',
    VEF = 'VEF',
    VND = 'VND',
    CHE = 'CHE',
    CHW = 'CHW',
    XOF = 'XOF',
    WST = 'WST',
    YER = 'YER',
    ZMK = 'ZMK',
    ZWD = 'ZWD',
    TRY = 'TRY',
    AZM = 'AZM',
    ROL = 'ROL',
    TRL = 'TRL',
    XPF = 'XPF',
}

/** Defines an array of custom attributes. */
export interface CustomAttributeMetadata {
    __typename?: 'CustomAttributeMetadata'
    /** An array of attributes. */
    items: Maybe<Array<Maybe<Attribute>>>
}

/** An interface containing fields that define the EAV attribute. */
export interface CustomAttributeMetadataInterface {
    /** The unique identifier for an attribute code. This value should be in lowercase letters without spaces. */
    code: Scalars['ID']
    /** Default attribute value. */
    defaultValue: Maybe<Scalars['String']>
    /** The type of entity that defines the attribute. */
    entityType: AttributeEntityTypeEnum
    /** The frontend class of the attribute. */
    frontendClass: Maybe<Scalars['String']>
    /** The frontend input type of the attribute. */
    frontendInput: Maybe<AttributeFrontendInputEnum>
    /** Whether the attribute value is required. */
    isRequired: Scalars['Boolean']
    /** Whether the attribute value must be unique. */
    isUnique: Scalars['Boolean']
    /** The label assigned to the attribute. */
    label: Maybe<Scalars['String']>
    /** Attribute options. */
    options: Array<Maybe<CustomAttributeOptionInterface>>
}

export interface CustomAttributeOptionInterface {
    /** Is the option value default. */
    isDefault: Scalars['Boolean']
    /** The label assigned to the attribute option. */
    label: Scalars['String']
    /** The attribute option value. */
    value: Scalars['String']
}

/** Defines the customer name, addresses, and other details. */
export interface Customer {
    __typename?: 'Customer'
    /** An array containing the customer's shipping and billing addresses. */
    addresses: Maybe<Array<Maybe<CustomerAddress>>>
    /** Indicates whether the customer has enabled remote shopping assistance. */
    allowRemoteShoppingAssistance: Scalars['Boolean']
    /** The contents of the customer's compare list. */
    compareList: Maybe<CompareList>
    /** The customer's confirmation status. */
    confirmationStatus: ConfirmationStatusEnum
    /** Timestamp indicating when the account was created. */
    createdAt: Maybe<Scalars['String']>
    /** Customer's custom attributes. */
    customAttributes: Maybe<Array<Maybe<AttributeValueInterface>>>
    /** The customer's date of birth. */
    dateOfBirth: Maybe<Scalars['String']>
    /** The ID assigned to the billing address. */
    defaultBilling: Maybe<Scalars['String']>
    /** The ID assigned to the shipping address. */
    defaultShipping: Maybe<Scalars['String']>
    /**
     * The customer's date of birth.
     * @deprecated Use `date_of_birth` instead.
     */
    dob: Maybe<Scalars['String']>
    /** The customer's email address. Required. */
    email: Maybe<Scalars['String']>
    /** The customer's first name. */
    firstname: Maybe<Scalars['String']>
    /** The customer's gender (Male - 1, Female - 2). */
    gender: Maybe<Scalars['Int']>
    /**
     * The customer's group ID.
     * @deprecated Customer group should not be exposed in the storefront scenarios.
     */
    groupId: Maybe<Scalars['Int']>
    /**
     * The ID assigned to the customer.
     * @deprecated `id` is not needed as part of `Customer`, because on the server side, it can be identified based on the customer token used for authentication. There is no need to know customer ID on the client side.
     */
    id: Maybe<Scalars['Int']>
    /** Indicates whether the customer is subscribed to the company's newsletter. */
    isSubscribed: Maybe<Scalars['Boolean']>
    /** The customer's family name. */
    lastname: Maybe<Scalars['String']>
    /**
     * The customer's SRS Loyalty Card ID.
     * @deprecated Use the `Customer.srs_id` field instead.
     */
    loyaltyCardId: Maybe<Scalars['String']>
    /** SRS Loyalty Points */
    loyaltyPoints: Maybe<Scalars['Int']>
    /** The customer's middle name. */
    middlename: Maybe<Scalars['String']>
    orders: Maybe<CustomerOrders>
    personalShopperBoxes: CustomerPersonalShopperBoxes
    /** Returns personalized image if customer is logged in */
    personalizedImage: Maybe<Scalars['String']>
    /** An honorific, such as Dr., Mr., or Mrs. */
    prefix: Maybe<Scalars['String']>
    /** Contains the customer's product reviews. */
    reviews: ProductReviews
    /** Sales Limits Status */
    salesLimitsInfo: Maybe<SalesLimitsInfo>
    /** Customer Allowed Shopping for Promo sale */
    shoppingAllowed: Maybe<Scalars['Boolean']>
    /** The customer's SRS ID. */
    srsId: Maybe<Scalars['Int']>
    /**
     * The customer's SRS Loyalty Card ID.
     * @deprecated Use the `Customer.srs_id` field instead.
     */
    srsLoyaltyCardId: Maybe<Scalars['String']>
    /** The customer's SRS Shop ID. */
    srsShopId: Maybe<Scalars['Int']>
    /** Returns SrsVouchers for Customer */
    srsVouchers: Maybe<Array<Maybe<SrsVoucher>>>
    /** A value such as Sr., Jr., or III. */
    suffix: Maybe<Scalars['String']>
    /** The customer's Value-added tax (VAT) number (for corporate customers). */
    taxvat: Maybe<Scalars['String']>
    /**
     * Return a customer's wish lists.
     * @deprecated Use `Customer.wishlists` or `Customer.wishlist_v2` instead.
     */
    wishlist: Wishlist
    /** Retrieve the wish list identified by the unique ID for a `Wishlist` object. */
    wishlistV2: Maybe<Wishlist>
    /**
     * An array of wishlists. In Magento Open Source, customers are limited to one
     * wish list. The number of wish lists is configurable for Adobe Commerce.
     */
    wishlists: Array<Maybe<Wishlist>>
}

/** Defines the customer name, addresses, and other details. */
export interface CustomerCustomAttributesArgs {
    attributeCodes: Maybe<Array<Scalars['ID']>>
}

/** Defines the customer name, addresses, and other details. */
export interface CustomerOrdersArgs {
    filter: Maybe<CustomerOrdersFilterInput>
    currentPage?: Maybe<Scalars['Int']>
    pageSize?: Maybe<Scalars['Int']>
    sort: Maybe<CustomerOrderSortInput>
    scope: Maybe<ScopeTypeEnum>
}

/** Defines the customer name, addresses, and other details. */
export interface CustomerReviewsArgs {
    pageSize?: Maybe<Scalars['Int']>
    currentPage?: Maybe<Scalars['Int']>
}

/** Defines the customer name, addresses, and other details. */
export interface CustomerWishlistV2Args {
    id: Scalars['ID']
}

/** Defines the customer name, addresses, and other details. */
export interface CustomerWishlistsArgs {
    pageSize?: Maybe<Scalars['Int']>
    currentPage?: Maybe<Scalars['Int']>
}

/** Contains detailed information about a customer's billing or shipping address. */
export interface CustomerAddress {
    __typename?: 'CustomerAddress'
    /** The customer's city or town. */
    city: Maybe<Scalars['String']>
    /** The customer's company. */
    company: Maybe<Scalars['String']>
    /** The customer's country. */
    countryCode: Maybe<CountryCodeEnum>
    /**
     * The customer's country.
     * @deprecated Use `country_code` instead.
     */
    countryId: Maybe<Scalars['String']>
    /** @deprecated Use custom_attributesV2 instead. */
    customAttributes: Maybe<Array<Maybe<CustomerAddressAttribute>>>
    /** Custom attributes assigned to the customer address. */
    customAttributesV2: Array<Maybe<AttributeValueInterface>>
    /**
     * The customer ID
     * @deprecated `customer_id` is not needed as part of `CustomerAddress`. The `id` is a unique identifier for the addresses.
     */
    customerId: Maybe<Scalars['Int']>
    /** Indicates whether the address is the customer's default billing address. */
    defaultBilling: Maybe<Scalars['Boolean']>
    /** Indicates whether the address is the customer's default shipping address. */
    defaultShipping: Maybe<Scalars['Boolean']>
    /** Contains any extension attributes for the address. */
    extensionAttributes: Maybe<Array<Maybe<CustomerAddressAttribute>>>
    /** The customer's fax number. */
    fax: Maybe<Scalars['String']>
    /** The first name of the person associated with the shipping/billing address. */
    firstname: Maybe<Scalars['String']>
    /** The ID of a `CustomerAddress` object. */
    id: Maybe<Scalars['Int']>
    /** The family name of the person associated with the shipping/billing address. */
    lastname: Maybe<Scalars['String']>
    /** The middle name of the person associated with the shipping/billing address. */
    middlename: Maybe<Scalars['String']>
    /** The customer's ZIP or postal code. */
    postcode: Maybe<Scalars['String']>
    /** An honorific, such as Dr., Mr., or Mrs. */
    prefix: Maybe<Scalars['String']>
    /** An object containing the region name, region code, and region ID. */
    region: Maybe<CustomerAddressRegion>
    /** The unique ID for a pre-defined region. */
    regionId: Maybe<Scalars['Int']>
    /** An array of strings that define the street number and name. */
    street: Maybe<Array<Maybe<Scalars['String']>>>
    /** A value such as Sr., Jr., or III. */
    suffix: Maybe<Scalars['String']>
    /** The customer's telephone number. */
    telephone: Maybe<Scalars['String']>
    /** The customer's Value-added tax (VAT) number (for corporate customers). */
    vatId: Maybe<Scalars['String']>
}

/** Contains detailed information about a customer's billing or shipping address. */
export interface CustomerAddressCustomAttributesV2Args {
    attributeCodes: Maybe<Array<Scalars['ID']>>
}

/** Specifies the attribute code and value of a customer address attribute. */
export interface CustomerAddressAttribute {
    __typename?: 'CustomerAddressAttribute'
    /** The name assigned to the customer address attribute. */
    attributeCode: Maybe<Scalars['String']>
    /** The value assigned to the customer address attribute. */
    value: Maybe<Scalars['String']>
}

/** Specifies the attribute code and value of a customer attribute. */
export interface CustomerAddressAttributeInput {
    /** The name assigned to the attribute. */
    attribute_code: Scalars['String']
    /** The value assigned to the attribute. */
    value: Scalars['String']
}

/** Contains details about a billing or shipping address. */
export interface CustomerAddressInput {
    /** The customer's city or town. */
    city?: Maybe<Scalars['String']>
    /** The customer's company. */
    company?: Maybe<Scalars['String']>
    /** The two-letter code representing the customer's country. */
    country_code?: Maybe<CountryCodeEnum>
    /** Deprecated: use `country_code` instead. */
    country_id?: Maybe<CountryCodeEnum>
    /** Deprecated. Use custom_attributesV2 instead. */
    custom_attributes?: Maybe<Array<Maybe<CustomerAddressAttributeInput>>>
    /** Custom attributes assigned to the customer address. */
    custom_attributesV2?: Maybe<Array<Maybe<AttributeValueInput>>>
    /** Indicates whether the address is the default billing address. */
    default_billing?: Maybe<Scalars['Boolean']>
    /** Indicates whether the address is the default shipping address. */
    default_shipping?: Maybe<Scalars['Boolean']>
    /** The customer's fax number. */
    fax?: Maybe<Scalars['String']>
    /** The first name of the person associated with the billing/shipping address. */
    firstname?: Maybe<Scalars['String']>
    /** The family name of the person associated with the billing/shipping address. */
    lastname?: Maybe<Scalars['String']>
    /** The middle name of the person associated with the billing/shipping address. */
    middlename?: Maybe<Scalars['String']>
    /** The customer's ZIP or postal code. */
    postcode?: Maybe<Scalars['String']>
    /** An honorific, such as Dr., Mr., or Mrs. */
    prefix?: Maybe<Scalars['String']>
    /** An object containing the region name, region code, and region ID. */
    region?: Maybe<CustomerAddressRegionInput>
    /** An array of strings that define the street number and name. */
    street?: Maybe<Array<Maybe<Scalars['String']>>>
    /** A value such as Sr., Jr., or III. */
    suffix?: Maybe<Scalars['String']>
    /** The customer's telephone number. */
    telephone?: Maybe<Scalars['String']>
    /** The customer's Tax/VAT number (for corporate customers). */
    vat_id?: Maybe<Scalars['String']>
}

/** Defines the customer's state or province. */
export interface CustomerAddressRegion {
    __typename?: 'CustomerAddressRegion'
    /** The state or province name. */
    region: Maybe<Scalars['String']>
    /** The address region code. */
    regionCode: Maybe<Scalars['String']>
    /** The unique ID for a pre-defined region. */
    regionId: Maybe<Scalars['Int']>
}

/** Defines the customer's state or province. */
export interface CustomerAddressRegionInput {
    /** The state or province name. */
    region?: Maybe<Scalars['String']>
    /** The address region code. */
    region_code?: Maybe<Scalars['String']>
    /** The unique ID for a pre-defined region. */
    region_id?: Maybe<Scalars['Int']>
}

/** Customer attribute metadata. */
export interface CustomerAttributeMetadata
    extends CustomAttributeMetadataInterface {
    __typename?: 'CustomerAttributeMetadata'
    /** The unique identifier for an attribute code. This value should be in lowercase letters without spaces. */
    code: Scalars['ID']
    /** Default attribute value. */
    defaultValue: Maybe<Scalars['String']>
    /** The type of entity that defines the attribute. */
    entityType: AttributeEntityTypeEnum
    /** The frontend class of the attribute. */
    frontendClass: Maybe<Scalars['String']>
    /** The frontend input type of the attribute. */
    frontendInput: Maybe<AttributeFrontendInputEnum>
    /** The template used for the input of the attribute (e.g., 'date'). */
    inputFilter: Maybe<InputFilterEnum>
    /** Whether the attribute value is required. */
    isRequired: Scalars['Boolean']
    /** Whether the attribute value must be unique. */
    isUnique: Scalars['Boolean']
    /** The label assigned to the attribute. */
    label: Maybe<Scalars['String']>
    /** The number of lines of the attribute value. */
    multilineCount: Maybe<Scalars['Int']>
    /** Attribute options. */
    options: Array<Maybe<CustomAttributeOptionInterface>>
    /** The position of the attribute in the form. */
    sortOrder: Maybe<Scalars['Int']>
    /** The validation rules of the attribute value. */
    validateRules: Maybe<Array<Maybe<ValidationRule>>>
}

export interface CustomerCoupon {
    __typename?: 'CustomerCoupon'
    /** Coupon discount amount */
    amount: Money
    /** Date the coupon was applied to the cart or empty if coupon is not applied yet */
    applied: Maybe<Scalars['String']>
    /** Coupon code */
    code: Scalars['String']
    /** Coupon ID */
    couponId: Scalars['String']
    description: Maybe<Scalars['String']>
    /** True or false whether the coupon is used in the current quote */
    isActive: Scalars['Boolean']
    /** Coupon minimal order amount */
    minimalOrderAmount: Maybe<Money>
    type: CustomerCouponType
    /** Date until when the coupon is valid or empty when no end date */
    validUntil: Maybe<Scalars['String']>
}

export interface CustomerCouponRemoveResult {
    __typename?: 'CustomerCouponRemoveResult'
    /** Will always return true. An error produces a graphql error */
    result: Maybe<Scalars['Boolean']>
}

export interface CustomerCouponResult {
    __typename?: 'CustomerCouponResult'
    items: Maybe<Array<Maybe<CustomerCoupon>>>
    totalCount: Scalars['Int']
}

export interface CustomerCouponType {
    __typename?: 'CustomerCouponType'
    description: Scalars['String']
    image: Scalars['String']
    title: Scalars['String']
}

/** An input object for creating a customer. */
export interface CustomerCreateInput {
    /** Indicates whether the customer has enabled remote shopping assistance. */
    allow_remote_shopping_assistance?: Maybe<Scalars['Boolean']>
    /** The customer's custom attributes. */
    custom_attributes?: Maybe<Array<Maybe<AttributeValueInput>>>
    /** The customer's date of birth. */
    date_of_birth?: Maybe<Scalars['String']>
    /** Deprecated: Use `date_of_birth` instead. */
    dob?: Maybe<Scalars['String']>
    /** The customer's email address. */
    email: Scalars['String']
    /** The customer's first name. */
    firstname: Scalars['String']
    /** The customer's gender (Male - 1, Female - 2). */
    gender?: Maybe<Scalars['Int']>
    /** Indicates whether the customer is subscribed to the company's newsletter. */
    is_subscribed?: Maybe<Scalars['Boolean']>
    /** The customer's family name. */
    lastname: Scalars['String']
    /** The customer's middle name. */
    middlename?: Maybe<Scalars['String']>
    /** The customer's password. */
    password?: Maybe<Scalars['String']>
    /** An honorific, such as Dr., Mr., or Mrs. */
    prefix?: Maybe<Scalars['String']>
    /** The customer's SRS Shop ID. */
    srs_shop_id?: Maybe<Scalars['Int']>
    /** A value such as Sr., Jr., or III. */
    suffix?: Maybe<Scalars['String']>
    /** The customer's Tax/VAT number (for corporate customers). */
    taxvat?: Maybe<Scalars['String']>
}

/** An input object that assigns or updates customer attributes. */
export interface CustomerInput {
    /** The customer's date of birth. */
    date_of_birth?: Maybe<Scalars['String']>
    /** Deprecated: Use `date_of_birth` instead. */
    dob?: Maybe<Scalars['String']>
    /** The customer's email address. Required when creating a customer. */
    email?: Maybe<Scalars['String']>
    /** The customer's first name. */
    firstname?: Maybe<Scalars['String']>
    /** The customer's gender (Male - 1, Female - 2). */
    gender?: Maybe<Scalars['Int']>
    /** Indicates whether the customer is subscribed to the company's newsletter. */
    is_subscribed?: Maybe<Scalars['Boolean']>
    /** The customer's family name. */
    lastname?: Maybe<Scalars['String']>
    /** The customer's middle name. */
    middlename?: Maybe<Scalars['String']>
    /** The customer's password. */
    password?: Maybe<Scalars['String']>
    /** An honorific, such as Dr., Mr., or Mrs. */
    prefix?: Maybe<Scalars['String']>
    /** The customer's SRS Shop ID. */
    srs_shop_id?: Maybe<Scalars['Int']>
    /** A value such as Sr., Jr., or III. */
    suffix?: Maybe<Scalars['String']>
    /** The customer's Tax/VAT number (for corporate customers). */
    taxvat?: Maybe<Scalars['String']>
}

/** Contains details about each of the customer's orders. */
export interface CustomerOrder {
    __typename?: 'CustomerOrder'
    /** Coupons applied to the order. */
    appliedCoupons: Array<Maybe<AppliedCoupon>>
    /** The billing address for the order. */
    billingAddress: Maybe<OrderAddress>
    /** The shipping carrier for the order delivery. */
    carrier: Maybe<Scalars['String']>
    /** Comments about the order. */
    comments: Maybe<Array<Maybe<SalesCommentItem>>>
    /** @deprecated Use the `order_date` field instead. */
    createdAt: Maybe<Scalars['String']>
    /** A list of credit memos. */
    creditMemos: Maybe<Array<Maybe<CreditMemo>>>
    discountAmount: Maybe<Money>
    discountDescription: Maybe<Scalars['String']>
    /** Order customer email. */
    email: Maybe<Scalars['String']>
    /** The entered gift message for the order */
    giftMessage: Maybe<GiftMessage>
    /** @deprecated Use the `totals.grand_total` field instead. */
    grandTotal: Maybe<Scalars['Float']>
    /** The unique ID for a `CustomerOrder` object. */
    id: Scalars['ID']
    /** @deprecated Use the `id` field instead. */
    incrementId: Maybe<Scalars['String']>
    /** A list of invoices for the order. */
    invoices: Array<Maybe<Invoice>>
    /** An array containing the items purchased in this order. */
    items: Maybe<Array<Maybe<OrderItemInterface>>>
    /** The order number. */
    number: Scalars['String']
    /** The date the order was placed. */
    orderDate: Scalars['String']
    /** @deprecated Use the `number` field instead. */
    orderNumber: Scalars['String']
    payment: Maybe<CustomerOrderPayment>
    /** Payment details for the order. */
    paymentMethods: Maybe<Array<Maybe<OrderPaymentMethod>>>
    /** A list of shipments for the order. */
    shipments: Maybe<Array<Maybe<OrderShipment>>>
    /** The shipping address for the order. */
    shippingAddress: Maybe<OrderAddress>
    shippingInclTax: Money
    /** The delivery method for the order. */
    shippingMethod: Maybe<Scalars['String']>
    /** The current status of the order. */
    status: Scalars['String']
    subtotalInclTax: Money
    taxAmount: Money
    /** The token that can be used to retrieve the order using order query. */
    token: Scalars['String']
    /** Details about the calculated totals for this order. */
    total: Maybe<OrderTotal>
}

/** Customer order payment info */
export interface CustomerOrderPayment {
    __typename?: 'CustomerOrderPayment'
    ccType: Maybe<Scalars['String']>
    method: Scalars['String']
    methodTitle: Maybe<Scalars['String']>
}

/** The collection of orders that match the conditions defined in the filter. */
export interface CustomerOrders {
    __typename?: 'CustomerOrders'
    /** An array of customer orders. */
    items: Array<Maybe<CustomerOrder>>
    /** Contains pagination metadata. */
    pageInfo: Maybe<SearchResultPageInfo>
    /** The total count of customer orders. */
    totalCount: Maybe<Scalars['Int']>
}

/** Identifies the filter to use for filtering orders. */
export interface CustomerOrdersFilterInput {
    /** Filters by order number. */
    number?: Maybe<FilterStringTypeInput>
}

/** Specifies the field to use for sorting */
export enum CustomerOrderSortableField {
    /** Sorts customer orders by number */
    NUMBER = 'NUMBER',
    /** Sorts customer orders by created_at field */
    CREATED_AT = 'CREATED_AT',
}

/**
 * CustomerOrderSortInput specifies the field to use for sorting search results and
 * indicates whether the results are sorted in ascending or descending order.
 */
export interface CustomerOrderSortInput {
    /** This enumeration indicates whether to return results in ascending or descending order */
    sort_direction: SortEnum
    /** Specifies the field to use for sorting */
    sort_field: CustomerOrderSortableField
}

/** Contains details about a newly-created or updated customer. */
export interface CustomerOutput {
    __typename?: 'CustomerOutput'
    /** Customer details after creating or updating a customer. */
    customer: Customer
}

/** Contains payment tokens stored in the customer's vault. */
export interface CustomerPaymentTokens {
    __typename?: 'CustomerPaymentTokens'
    /** An array of payment tokens. */
    items: Array<Maybe<PaymentToken>>
}

/** Collection of Customer Personal Shopper Boxes. */
export interface CustomerPersonalShopperBoxes {
    __typename?: 'CustomerPersonalShopperBoxes'
    /** Array of Customer Personal Shopper Boxes. */
    items: Array<Maybe<PersonalShopperBox>>
}

/** Contains a customer authorization token. */
export interface CustomerToken {
    __typename?: 'CustomerToken'
    /** The customer authorization token. */
    token: Maybe<Scalars['String']>
}

/** An input object for updating a customer. */
export interface CustomerUpdateInput {
    /** Indicates whether the customer has enabled remote shopping assistance. */
    allow_remote_shopping_assistance?: Maybe<Scalars['Boolean']>
    /** The customer's custom attributes. */
    custom_attributes?: Maybe<Array<Maybe<AttributeValueInput>>>
    /** The customer's date of birth. */
    date_of_birth?: Maybe<Scalars['String']>
    /** Deprecated: Use `date_of_birth` instead. */
    dob?: Maybe<Scalars['String']>
    /** The customer's first name. */
    firstname?: Maybe<Scalars['String']>
    /** The customer's gender (Male - 1, Female - 2). */
    gender?: Maybe<Scalars['Int']>
    /** Indicates whether the customer is subscribed to the company's newsletter. */
    is_subscribed?: Maybe<Scalars['Boolean']>
    /** The customer's family name. */
    lastname?: Maybe<Scalars['String']>
    /** The customer's middle name. */
    middlename?: Maybe<Scalars['String']>
    /** An honorific, such as Dr., Mr., or Mrs. */
    prefix?: Maybe<Scalars['String']>
    /** A value such as Sr., Jr., or III. */
    suffix?: Maybe<Scalars['String']>
    /** The customer's Tax/VAT number (for corporate customers). */
    taxvat?: Maybe<Scalars['String']>
}

/** Contains information about a text area that is defined as part of a customizable option. */
export interface CustomizableAreaOption extends CustomizableOptionInterface {
    __typename?: 'CustomizableAreaOption'
    /**
     * Option ID.
     * @deprecated Use `uid` instead
     */
    optionId: Maybe<Scalars['Int']>
    /** The Stock Keeping Unit of the base product. */
    productSku: Maybe<Scalars['String']>
    /** Indicates whether the option is required. */
    required: Maybe<Scalars['Boolean']>
    /** The order in which the option is displayed. */
    sortOrder: Maybe<Scalars['Int']>
    /** The display name for this option. */
    title: Maybe<Scalars['String']>
    /** The unique ID for a `CustomizableOptionInterface` object. */
    uid: Scalars['ID']
    /** An object that defines a text area. */
    value: Maybe<CustomizableAreaValue>
}

/** Defines the price and sku of a product whose page contains a customized text area. */
export interface CustomizableAreaValue {
    __typename?: 'CustomizableAreaValue'
    /** The maximum number of characters that can be entered for this customizable option. */
    maxCharacters: Maybe<Scalars['Int']>
    /** The price assigned to this option. */
    price: Maybe<Scalars['Float']>
    /** FIXED, PERCENT, or DYNAMIC. */
    priceType: Maybe<PriceTypeEnum>
    /** The Stock Keeping Unit for this option. */
    sku: Maybe<Scalars['String']>
    /** The unique ID for a `CustomizableAreaValue` object. */
    uid: Scalars['ID']
}

/** Contains information about a set of checkbox values that are defined as part of a customizable option. */
export interface CustomizableCheckboxOption
    extends CustomizableOptionInterface {
    __typename?: 'CustomizableCheckboxOption'
    /**
     * Option ID.
     * @deprecated Use `uid` instead
     */
    optionId: Maybe<Scalars['Int']>
    /** Indicates whether the option is required. */
    required: Maybe<Scalars['Boolean']>
    /** The order in which the option is displayed. */
    sortOrder: Maybe<Scalars['Int']>
    /** The display name for this option. */
    title: Maybe<Scalars['String']>
    /** The unique ID for a `CustomizableOptionInterface` object. */
    uid: Scalars['ID']
    /** An array that defines a set of checkbox values. */
    value: Maybe<Array<Maybe<CustomizableCheckboxValue>>>
}

/** Defines the price and sku of a product whose page contains a customized set of checkbox values. */
export interface CustomizableCheckboxValue {
    __typename?: 'CustomizableCheckboxValue'
    /** The ID assigned to the value. */
    optionTypeId: Maybe<Scalars['Int']>
    /** The price assigned to this option. */
    price: Maybe<Scalars['Float']>
    /** FIXED, PERCENT, or DYNAMIC. */
    priceType: Maybe<PriceTypeEnum>
    /** The Stock Keeping Unit for this option. */
    sku: Maybe<Scalars['String']>
    /** The order in which the checkbox value is displayed. */
    sortOrder: Maybe<Scalars['Int']>
    /** The display name for this option. */
    title: Maybe<Scalars['String']>
    /** The unique ID for a `CustomizableCheckboxValue` object. */
    uid: Scalars['ID']
}

/** Contains information about a date picker that is defined as part of a customizable option. */
export interface CustomizableDateOption extends CustomizableOptionInterface {
    __typename?: 'CustomizableDateOption'
    /**
     * Option ID.
     * @deprecated Use `uid` instead
     */
    optionId: Maybe<Scalars['Int']>
    /** The Stock Keeping Unit of the base product. */
    productSku: Maybe<Scalars['String']>
    /** Indicates whether the option is required. */
    required: Maybe<Scalars['Boolean']>
    /** The order in which the option is displayed. */
    sortOrder: Maybe<Scalars['Int']>
    /** The display name for this option. */
    title: Maybe<Scalars['String']>
    /** The unique ID for a `CustomizableOptionInterface` object. */
    uid: Scalars['ID']
    /** An object that defines a date field in a customizable option. */
    value: Maybe<CustomizableDateValue>
}

/** Defines the customizable date type. */
export enum CustomizableDateTypeEnum {
    DATE = 'DATE',
    DATE_TIME = 'DATE_TIME',
    TIME = 'TIME',
}

/** Defines the price and sku of a product whose page contains a customized date picker. */
export interface CustomizableDateValue {
    __typename?: 'CustomizableDateValue'
    /** The price assigned to this option. */
    price: Maybe<Scalars['Float']>
    /** FIXED, PERCENT, or DYNAMIC. */
    priceType: Maybe<PriceTypeEnum>
    /** The Stock Keeping Unit for this option. */
    sku: Maybe<Scalars['String']>
    /** DATE, DATE_TIME or TIME */
    type: Maybe<CustomizableDateTypeEnum>
    /** The unique ID for a `CustomizableDateValue` object. */
    uid: Scalars['ID']
}

/** Contains information about a drop down menu that is defined as part of a customizable option. */
export interface CustomizableDropDownOption
    extends CustomizableOptionInterface {
    __typename?: 'CustomizableDropDownOption'
    /**
     * Option ID.
     * @deprecated Use `uid` instead
     */
    optionId: Maybe<Scalars['Int']>
    /** Indicates whether the option is required. */
    required: Maybe<Scalars['Boolean']>
    /** The order in which the option is displayed. */
    sortOrder: Maybe<Scalars['Int']>
    /** The display name for this option. */
    title: Maybe<Scalars['String']>
    /** The unique ID for a `CustomizableOptionInterface` object. */
    uid: Scalars['ID']
    /** An array that defines the set of options for a drop down menu. */
    value: Maybe<Array<Maybe<CustomizableDropDownValue>>>
}

/** Defines the price and sku of a product whose page contains a customized drop down menu. */
export interface CustomizableDropDownValue {
    __typename?: 'CustomizableDropDownValue'
    /** The ID assigned to the value. */
    optionTypeId: Maybe<Scalars['Int']>
    /** The price assigned to this option. */
    price: Maybe<Scalars['Float']>
    /** FIXED, PERCENT, or DYNAMIC. */
    priceType: Maybe<PriceTypeEnum>
    /** The Stock Keeping Unit for this option. */
    sku: Maybe<Scalars['String']>
    /** The order in which the option is displayed. */
    sortOrder: Maybe<Scalars['Int']>
    /** The display name for this option. */
    title: Maybe<Scalars['String']>
    /** The unique ID for a `CustomizableDropDownValue` object. */
    uid: Scalars['ID']
}

/** Contains information about a text field that is defined as part of a customizable option. */
export interface CustomizableFieldOption extends CustomizableOptionInterface {
    __typename?: 'CustomizableFieldOption'
    /**
     * Option ID.
     * @deprecated Use `uid` instead
     */
    optionId: Maybe<Scalars['Int']>
    /** The Stock Keeping Unit of the base product. */
    productSku: Maybe<Scalars['String']>
    /** Indicates whether the option is required. */
    required: Maybe<Scalars['Boolean']>
    /** The order in which the option is displayed. */
    sortOrder: Maybe<Scalars['Int']>
    /** The display name for this option. */
    title: Maybe<Scalars['String']>
    /** The unique ID for a `CustomizableOptionInterface` object. */
    uid: Scalars['ID']
    /** An object that defines a text field. */
    value: Maybe<CustomizableFieldValue>
}

/** Defines the price and sku of a product whose page contains a customized text field. */
export interface CustomizableFieldValue {
    __typename?: 'CustomizableFieldValue'
    /** The maximum number of characters that can be entered for this customizable option. */
    maxCharacters: Maybe<Scalars['Int']>
    /** The price of the custom value. */
    price: Maybe<Scalars['Float']>
    /** FIXED, PERCENT, or DYNAMIC. */
    priceType: Maybe<PriceTypeEnum>
    /** The Stock Keeping Unit for this option. */
    sku: Maybe<Scalars['String']>
    /** The unique ID for a `CustomizableFieldValue` object. */
    uid: Scalars['ID']
}

/** Contains information about a file picker that is defined as part of a customizable option. */
export interface CustomizableFileOption extends CustomizableOptionInterface {
    __typename?: 'CustomizableFileOption'
    /**
     * Option ID.
     * @deprecated Use `uid` instead
     */
    optionId: Maybe<Scalars['Int']>
    /** The Stock Keeping Unit of the base product. */
    productSku: Maybe<Scalars['String']>
    /** Indicates whether the option is required. */
    required: Maybe<Scalars['Boolean']>
    /** The order in which the option is displayed. */
    sortOrder: Maybe<Scalars['Int']>
    /** The display name for this option. */
    title: Maybe<Scalars['String']>
    /** The unique ID for a `CustomizableOptionInterface` object. */
    uid: Scalars['ID']
    /** An object that defines a file value. */
    value: Maybe<CustomizableFileValue>
}

/** Defines the price and sku of a product whose page contains a customized file picker. */
export interface CustomizableFileValue {
    __typename?: 'CustomizableFileValue'
    /** The file extension to accept. */
    fileExtension: Maybe<Scalars['String']>
    /** The maximum width of an image. */
    imageSizeX: Maybe<Scalars['Int']>
    /** The maximum height of an image. */
    imageSizeY: Maybe<Scalars['Int']>
    /** The price assigned to this option. */
    price: Maybe<Scalars['Float']>
    /** FIXED, PERCENT, or DYNAMIC. */
    priceType: Maybe<PriceTypeEnum>
    /** The Stock Keeping Unit for this option. */
    sku: Maybe<Scalars['String']>
    /** The unique ID for a `CustomizableFileValue` object. */
    uid: Scalars['ID']
}

/** Contains information about a multiselect that is defined as part of a customizable option. */
export interface CustomizableMultipleOption
    extends CustomizableOptionInterface {
    __typename?: 'CustomizableMultipleOption'
    /**
     * Option ID.
     * @deprecated Use `uid` instead
     */
    optionId: Maybe<Scalars['Int']>
    /** Indicates whether the option is required. */
    required: Maybe<Scalars['Boolean']>
    /** The order in which the option is displayed. */
    sortOrder: Maybe<Scalars['Int']>
    /** The display name for this option. */
    title: Maybe<Scalars['String']>
    /** The unique ID for a `CustomizableOptionInterface` object. */
    uid: Scalars['ID']
    /** An array that defines the set of options for a multiselect. */
    value: Maybe<Array<Maybe<CustomizableMultipleValue>>>
}

/** Defines the price and sku of a product whose page contains a customized multiselect. */
export interface CustomizableMultipleValue {
    __typename?: 'CustomizableMultipleValue'
    /** The ID assigned to the value. */
    optionTypeId: Maybe<Scalars['Int']>
    /** The price assigned to this option. */
    price: Maybe<Scalars['Float']>
    /** FIXED, PERCENT, or DYNAMIC. */
    priceType: Maybe<PriceTypeEnum>
    /** The Stock Keeping Unit for this option. */
    sku: Maybe<Scalars['String']>
    /** The order in which the option is displayed. */
    sortOrder: Maybe<Scalars['Int']>
    /** The display name for this option. */
    title: Maybe<Scalars['String']>
    /** The unique ID for a `CustomizableMultipleValue` object. */
    uid: Scalars['ID']
}

/** Defines a customizable option. */
export interface CustomizableOptionInput {
    /** The customizable option ID of the product. */
    id?: Maybe<Scalars['Int']>
    /** The unique ID for a `CartItemInterface` object. */
    uid?: Maybe<Scalars['ID']>
    /** The string value of the option. */
    value_string: Scalars['String']
}

/** Contains basic information about a customizable option. It can be implemented by several types of configurable options. */
export interface CustomizableOptionInterface {
    /**
     * Option ID.
     * @deprecated Use `uid` instead
     */
    optionId: Maybe<Scalars['Int']>
    /** Indicates whether the option is required. */
    required: Maybe<Scalars['Boolean']>
    /** The order in which the option is displayed. */
    sortOrder: Maybe<Scalars['Int']>
    /** The display name for this option. */
    title: Maybe<Scalars['String']>
    /** The unique ID for a `CustomizableOptionInterface` object. */
    uid: Scalars['ID']
}

/** Contains information about customizable product options. */
export interface CustomizableProductInterface {
    /** An array of options for a customizable product. */
    options: Maybe<Array<Maybe<CustomizableOptionInterface>>>
}

/** Contains information about a set of radio buttons that are defined as part of a customizable option. */
export interface CustomizableRadioOption extends CustomizableOptionInterface {
    __typename?: 'CustomizableRadioOption'
    /**
     * Option ID.
     * @deprecated Use `uid` instead
     */
    optionId: Maybe<Scalars['Int']>
    /** Indicates whether the option is required. */
    required: Maybe<Scalars['Boolean']>
    /** The order in which the option is displayed. */
    sortOrder: Maybe<Scalars['Int']>
    /** The display name for this option. */
    title: Maybe<Scalars['String']>
    /** The unique ID for a `CustomizableOptionInterface` object. */
    uid: Scalars['ID']
    /** An array that defines a set of radio buttons. */
    value: Maybe<Array<Maybe<CustomizableRadioValue>>>
}

/** Defines the price and sku of a product whose page contains a customized set of radio buttons. */
export interface CustomizableRadioValue {
    __typename?: 'CustomizableRadioValue'
    /** The ID assigned to the value. */
    optionTypeId: Maybe<Scalars['Int']>
    /** The price assigned to this option. */
    price: Maybe<Scalars['Float']>
    /** FIXED, PERCENT, or DYNAMIC. */
    priceType: Maybe<PriceTypeEnum>
    /** The Stock Keeping Unit for this option. */
    sku: Maybe<Scalars['String']>
    /** The order in which the radio button is displayed. */
    sortOrder: Maybe<Scalars['Int']>
    /** The display name for this option. */
    title: Maybe<Scalars['String']>
    /** The unique ID for a `CustomizableRadioValue` object. */
    uid: Scalars['ID']
}

/** Contains the results of the request to delete a compare list. */
export interface DeleteCompareListOutput {
    __typename?: 'DeleteCompareListOutput'
    /** Indicates whether the compare list was successfully deleted. */
    result: Scalars['Boolean']
}

/** Indicates whether the request succeeded and returns the remaining customer payment tokens. */
export interface DeletePaymentTokenOutput {
    __typename?: 'DeletePaymentTokenOutput'
    /** A container for the customer's remaining payment tokens. */
    customerPaymentTokens: Maybe<CustomerPaymentTokens>
    /** Indicates whether the request succeeded. */
    result: Scalars['Boolean']
}

export interface DhlParcelShippingServicePoint {
    __typename?: 'DhlParcelShippingServicePoint'
    /** Address */
    address: Maybe<DhlParcelShippingServicePointAddress>
    /** Country */
    country: Maybe<Scalars['String']>
    /** Distance */
    distance: Maybe<Scalars['Int']>
    /** Geo Location */
    geoLocation: Maybe<DhlParcelShippingServicePointGeoLocation>
    /** Service Point Identifier */
    id: Scalars['ID']
    /** Keyword */
    keyword: Maybe<Scalars['String']>
    /** Name */
    name: Maybe<Scalars['String']>
    /** Opening Times */
    openingTimes: Maybe<Array<Maybe<DhlParcelShippingServicePointOpeningTime>>>
    /** Shop Type */
    shopType: Maybe<Scalars['String']>
}

export interface DhlParcelShippingServicePointAddress {
    __typename?: 'DhlParcelShippingServicePointAddress'
    /** Addition */
    addition: Maybe<Scalars['String']>
    /** City */
    city: Maybe<Scalars['String']>
    /** ISO 3166-1 Country Code */
    countryCode: Maybe<Scalars['String']>
    /** Is Business? */
    isBusiness: Maybe<Scalars['Boolean']>
    /** Number */
    number: Maybe<Scalars['String']>
    /** Postal Code */
    postalCode: Maybe<Scalars['String']>
    /** Street */
    street: Maybe<Scalars['String']>
}

export interface DhlParcelShippingServicePointGeoLocation {
    __typename?: 'DhlParcelShippingServicePointGeoLocation'
    /** Latitude */
    latitude: Maybe<Scalars['Float']>
    /** Longitude */
    longitude: Maybe<Scalars['Float']>
}

export interface DhlParcelShippingServicePointOpeningTime {
    __typename?: 'DhlParcelShippingServicePointOpeningTime'
    /** Time From */
    timeFrom: Maybe<Scalars['String']>
    /** Time To */
    timeTo: Maybe<Scalars['String']>
    /** Week Day */
    weekDay: Maybe<Scalars['Int']>
}

export interface DhlParcelShippingServicePointsResult {
    __typename?: 'DhlParcelShippingServicePointsResult'
    /** An array of DhlParcel Shipping Service Points */
    items: Maybe<Array<Maybe<DhlParcelShippingServicePoint>>>
}

/** Defines an individual discount. A discount can be applied to the cart as a whole or to an item, shipping. */
export interface Discount {
    __typename?: 'Discount'
    /** The amount of the discount. */
    amount: Money
    /** The type of the entity the discount is applied to. */
    appliedTo: CartDiscountType
    /** The coupon related to the discount. */
    coupon: Maybe<AppliedCoupon>
    /** A description of the discount. */
    label: Scalars['String']
}

/** A prismic document */
export interface Document {
    meta: Meta
}

/** A connection to a list of items. */
export interface DocumentConnection {
    __typename?: 'DocumentConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<DocumentEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface DocumentEdge {
    __typename?: 'DocumentEdge'
    /** The item at the end of the edge. */
    node: Document
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

/** Email Address Validation Result */
export interface EmailValidationResult {
    __typename?: 'EmailValidationResult'
    /** Optional suggestion when a possible typo in the Email Address has been detected */
    autocorrect: Maybe<Scalars['String']>
    /** Status of validation */
    status: EmailValidationResultStatus
}

export enum EmailValidationResultStatus {
    INVALID = 'INVALID',
    VALID = 'VALID',
    WARNING = 'WARNING',
}

/** Defines a customer-entered option. */
export interface EnteredOptionInput {
    /**
     * The unique ID for a `CustomizableOptionInterface` object, such as a
     * `CustomizableFieldOption`, `CustomizableFileOption`, or
     * `CustomizableAreaOption` object.
     */
    uid: Scalars['ID']
    /** Text the customer entered. */
    value: Scalars['String']
}

/** Contains the `uid`, `relative_url`, and `type` attributes. */
export interface EntityUrl {
    __typename?: 'EntityUrl'
    /** True if the Prismic Document requires authorization. */
    authRequired: Maybe<Scalars['Boolean']>
    /** @deprecated Use `relative_url` instead. */
    canonicalUrl: Maybe<Scalars['String']>
    category: Maybe<CategoryTree>
    /**
     * The unique ID for a `ProductInterface`, `CategoryInterface`, `CmsPage`, or
     * similar object associated with the specified URL. This could be a product,
     * category, or CMS page UID.
     */
    entityUid: Maybe<Scalars['ID']>
    /**
     * The ID assigned to the object associated with the specified url. This could be a product ID, category ID, or page ID.
     * @deprecated Use `entity_uid` instead.
     */
    id: Maybe<Scalars['Int']>
    /** The Prismic Document type, if any. */
    prismicType: Maybe<Scalars['String']>
    product: Maybe<ProductInterface>
    /**
     * Contains 0 when there is no redirect error. A value of 301 indicates the URL
     * of the requested resource has been changed permanently, while a value of 302
     * indicates a temporary redirect.
     */
    redirectCode: Maybe<Scalars['Int']>
    /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
    relativeUrl: Maybe<Scalars['String']>
    /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
    type: Maybe<UrlRewriteEntityTypeEnum>
}

export interface ErrorInterface {
    /** The returned error message. */
    message: Scalars['String']
}

/** Contains details about an address. */
export interface EstimateAddressInput {
    /** The two-letter code representing the customer's country. */
    country_code: CountryCodeEnum
    /** The customer's ZIP or postal code. */
    postcode?: Maybe<Scalars['String']>
    /** An object containing the region name, region code, and region ID. */
    region?: Maybe<CustomerAddressRegionInput>
}

export interface EstimateTotalsInput {
    /** Customer's address to estimate totals. */
    address: EstimateAddressInput
    /** The unique ID of the cart to query. */
    cart_id: Scalars['String']
    /** Selected shipping method to estimate totals. */
    shipping_method?: Maybe<ShippingMethodInput>
}

/** Estimate totals output. */
export interface EstimateTotalsOutput {
    __typename?: 'EstimateTotalsOutput'
    /** Cart after totals estimation */
    cart: Maybe<Cart>
}

/** Lists the exchange rate. */
export interface ExchangeRate {
    __typename?: 'ExchangeRate'
    /** Specifies the store’s default currency to exchange to. */
    currencyTo: Maybe<Scalars['String']>
    /** The exchange rate for the store’s default currency. */
    rate: Maybe<Scalars['Float']>
}

/** An external link */
export interface ExternalLink extends Linkable {
    __typename?: 'ExternalLink'
    url: Scalars['String']
    target: Maybe<Scalars['String']>
    linkType: Maybe<Scalars['String']>
}

export interface FacetSettings {
    __typename?: 'FacetSettings'
    facetId: Scalars['Int']
    isVisible: Scalars['Boolean']
    attributeName: Scalars['ID']
    urlKey: Scalars['String']
    title: Scalars['String']
    isCollapsible: Scalars['Boolean']
    isCollapsed: Scalars['Boolean']
    nrOfShownAttributes: Scalars['Int']
    expandText: Scalars['String']
    collapseText: Scalars['String']
    isMultiSelect: Scalars['Boolean']
    multiSelectLogic: Maybe<Scalars['TODO']>
    selectionType: Scalars['String']
    nrOfColumns: Scalars['Int']
    isNrOfResultsVisible: Scalars['Boolean']
    isInfoVisible: Scalars['Boolean']
    infoText: Maybe<Scalars['String']>
    containsClickPoints: Scalars['Boolean']
    containsBuckets: Scalars['Boolean']
    source: Scalars['String']
    prefix: Maybe<Scalars['String']>
    postfix: Maybe<Scalars['String']>
    cssClass: Maybe<Scalars['String']>
}

export interface FaqPage extends Document, Linkable {
    __typename?: 'FaqPage'
    urlKey: Maybe<Scalars['String']>
    search: Maybe<Scalars['Boolean']>
    seoTitle: Maybe<Scalars['String']>
    seoDescription: Maybe<Scalars['String']>
    seoKeywords: Maybe<Scalars['String']>
    style: Maybe<Scalars['String']>
    head: Maybe<Scalars['Json']>
    noindex: Maybe<Scalars['Boolean']>
    nofollow: Maybe<Scalars['Boolean']>
    body: Maybe<Array<FaqPageBody>>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

export type FaqPageBody = FaqPageBodyHero | FaqPageBodyFaqSubject

export interface FaqPageBodyFaqSubject {
    __typename?: 'FaqPageBodyFaqSubject'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<FaqPageBodyFaqSubjectPrimary>
    fields: Maybe<Array<FaqPageBodyFaqSubjectFields>>
}

export interface FaqPageBodyFaqSubjectFields {
    __typename?: 'FaqPageBodyFaqSubjectFields'
    heading: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
}

export interface FaqPageBodyFaqSubjectPrimary {
    __typename?: 'FaqPageBodyFaqSubjectPrimary'
    urlKey: Maybe<Scalars['String']>
    seoTitle: Maybe<Scalars['String']>
    seoKeywords: Maybe<Scalars['String']>
    seoDescription: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    icon: Maybe<PrismicImageType>
}

export interface FaqPageBodyHero {
    __typename?: 'FaqPageBodyHero'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<FaqPageBodyHeroPrimary>
}

export interface FaqPageBodyHeroPrimary {
    __typename?: 'FaqPageBodyHeroPrimary'
    fullWidth: Maybe<Scalars['Boolean']>
    size: Maybe<Scalars['String']>
    headingfuss: Maybe<Scalars['Boolean']>
    header: Maybe<Scalars['String']>
    headingVariant: Maybe<Scalars['String']>
    headingElement: Maybe<Scalars['String']>
    subheading: Maybe<Scalars['String']>
    subheadingVariant: Maybe<Scalars['String']>
    subheadingElement: Maybe<Scalars['String']>
    videoId: Maybe<Scalars['String']>
    mobileVideoId: Maybe<Scalars['String']>
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
}

/** A connection to a list of items. */
export interface FaqPageConnectionConnection {
    __typename?: 'FaqPageConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<FaqPageConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface FaqPageConnectionEdge {
    __typename?: 'FaqPageConnectionEdge'
    /** The item at the end of the edge. */
    node: FaqPage
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

/** A linked file */
export interface FileLink extends Linkable {
    __typename?: 'FileLink'
    name: Scalars['String']
    url: Scalars['String']
    size: Scalars['Long']
    linkType: Maybe<Scalars['String']>
}

/** Defines a filter that matches the input exactly. */
export interface FilterEqualTypeInput {
    /**
     * Use this attribute to exactly match the specified string. For example, to
     * filter on a specific category ID, specify a value such as `5`.
     */
    eq?: Maybe<Scalars['String']>
    /**
     * Use this attribute to filter on an array of values. For example, to filter on
     * category IDs 4, 5, and 6, specify a value of `["4", "5", "6"]`.
     */
    in?: Maybe<Array<Maybe<Scalars['String']>>>
}

export enum FilterMatchTypeEnum {
    FULL = 'FULL',
    PARTIAL = 'PARTIAL',
}

/** Defines a filter that performs a fuzzy search. */
export interface FilterMatchTypeInput {
    /**
     * Use this attribute to fuzzy match the specified string. For example, to filter
     * on a specific SKU, specify a value such as `24-MB01`.
     */
    match?: Maybe<Scalars['String']>
    /**
     * Filter match type for fine-tuned results. Possible values FULL or PARTIAL. If
     * match_type is not provided, returned results will default to FULL match.
     */
    match_type?: Maybe<FilterMatchTypeEnum>
}

/** Defines a filter that matches a range of values, such as prices or dates. */
export interface FilterRangeTypeInput {
    /** Use this attribute to specify the lowest possible value in the range. */
    from?: Maybe<Scalars['String']>
    /** Use this attribute to specify the highest possible value in the range. */
    to?: Maybe<Scalars['String']>
}

/** Defines a filter for an input string. */
export interface FilterStringTypeInput {
    /** Filters items that are exactly the same as the specified string. */
    eq?: Maybe<Scalars['String']>
    /** Filters items that are exactly the same as entries specified in an array of strings. */
    in?: Maybe<Array<Maybe<Scalars['String']>>>
    /** Defines a filter that performs a fuzzy search using the specified string. */
    match?: Maybe<Scalars['String']>
}

/** Defines the comparison operators that can be used in a filter. */
export interface FilterTypeInput {
    /** Equals. */
    eq?: Maybe<Scalars['String']>
    finset?: Maybe<Array<Maybe<Scalars['String']>>>
    /** From. Must be used with the `to` field. */
    from?: Maybe<Scalars['String']>
    /** Greater than. */
    gt?: Maybe<Scalars['String']>
    /** Greater than or equal to. */
    gteq?: Maybe<Scalars['String']>
    /** In. The value can contain a set of comma-separated values. */
    in?: Maybe<Array<Maybe<Scalars['String']>>>
    /** Like. The specified value can contain % (percent signs) to allow matching of 0 or more characters. */
    like?: Maybe<Scalars['String']>
    /** Less than. */
    lt?: Maybe<Scalars['String']>
    /** Less than or equal to. */
    lteq?: Maybe<Scalars['String']>
    /** More than or equal to. */
    moreq?: Maybe<Scalars['String']>
    /** Not equal to. */
    neq?: Maybe<Scalars['String']>
    /** Not in. The value can contain a set of comma-separated values. */
    nin?: Maybe<Array<Maybe<Scalars['String']>>>
    /** Not null. */
    notnull?: Maybe<Scalars['String']>
    /** Is null. */
    null?: Maybe<Scalars['String']>
    /** To. Must be used with the `from` field. */
    to?: Maybe<Scalars['String']>
}

/** A single FPT that can be applied to a product price. */
export interface FixedProductTax {
    __typename?: 'FixedProductTax'
    /** The amount of the Fixed Product Tax. */
    amount: Maybe<Money>
    /** The display label assigned to the Fixed Product Tax. */
    label: Maybe<Scalars['String']>
}

/** Lists display settings for the Fixed Product Tax. */
export enum FixedProductTaxDisplaySettings {
    /**
     * The displayed price includes the FPT amount without displaying the
     * `ProductPrice.fixed_product_taxes` values. This value corresponds to
     * 'Including FPT only'.
     */
    INCLUDE_FPT_WITHOUT_DETAILS = 'INCLUDE_FPT_WITHOUT_DETAILS',
    /**
     * The displayed price includes the FPT amount while displaying the values of
     * `ProductPrice.fixed_product_taxes` separately. This value corresponds to
     * 'Including FPT and FPT description'.
     */
    INCLUDE_FPT_WITH_DETAILS = 'INCLUDE_FPT_WITH_DETAILS',
    /**
     * The displayed price does not include the FPT amount. The values of
     * `ProductPrice.fixed_product_taxes` and the price including the FPT are
     * displayed separately. This value corresponds to 'Excluding FPT, Including FPT
     * description and final price.'
     */
    EXCLUDE_FPT_AND_INCLUDE_WITH_DETAILS = 'EXCLUDE_FPT_AND_INCLUDE_WITH_DETAILS',
    /**
     * The displayed price does not include the FPT amount. The values from
     * `ProductPrice.fixed_product_taxes` are not displayed. This value corresponds
     * to 'Excluding FPT'.
     */
    EXCLUDE_FPT_WITHOUT_DETAILS = 'EXCLUDE_FPT_WITHOUT_DETAILS',
    /** The FPT feature is not enabled. You can omit `ProductPrice.fixed_product_taxes` from your query. */
    FPT_DISABLED = 'FPT_DISABLED',
}

export interface FooterCastiron extends Document, Linkable {
    __typename?: 'FooterCastiron'
    urlKey: Maybe<Scalars['String']>
    showDownloadApp: Maybe<Scalars['Boolean']>
    showPayment: Maybe<Scalars['Boolean']>
    showSocials: Maybe<Scalars['Boolean']>
    showLanguageSelector: Maybe<Scalars['Boolean']>
    urlIosApp: Maybe<Scalars['String']>
    urlAndroidApp: Maybe<Scalars['String']>
    newsletterHeading: Maybe<Scalars['String']>
    newsletterContent: Maybe<Scalars['String']>
    body: Maybe<Array<FooterCastironBody>>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

export type FooterCastironBody =
    | FooterCastironBodyCategoryList
    | FooterCastironBodyServiceContact

export interface FooterCastironBodyCategoryList {
    __typename?: 'FooterCastironBodyCategoryList'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<FooterCastironBodyCategoryListPrimary>
    fields: Maybe<Array<FooterCastironBodyCategoryListFields>>
}

export interface FooterCastironBodyCategoryListFields {
    __typename?: 'FooterCastironBodyCategoryListFields'
    title: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
    list: Maybe<Scalars['String']>
}

export interface FooterCastironBodyCategoryListPrimary {
    __typename?: 'FooterCastironBodyCategoryListPrimary'
    heading: Maybe<Scalars['String']>
    title1: Maybe<Scalars['String']>
    title2: Maybe<Scalars['String']>
    title3: Maybe<Scalars['String']>
    title4: Maybe<Scalars['String']>
}

export interface FooterCastironBodyServiceContact {
    __typename?: 'FooterCastironBodyServiceContact'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<FooterCastironBodyServiceContactPrimary>
    fields: Maybe<Array<FooterCastironBodyServiceContactFields>>
}

export interface FooterCastironBodyServiceContactFields {
    __typename?: 'FooterCastironBodyServiceContactFields'
    iconImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    content: Maybe<Scalars['String']>
    subText: Maybe<Scalars['String']>
    linkType: Maybe<Scalars['String']>
}

export interface FooterCastironBodyServiceContactPrimary {
    __typename?: 'FooterCastironBodyServiceContactPrimary'
    contactTitle: Maybe<Scalars['String']>
    contactContent: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
}

/** A connection to a list of items. */
export interface FooterCastironConnectionConnection {
    __typename?: 'FooterCastironConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<FooterCastironConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface FooterCastironConnectionEdge {
    __typename?: 'FooterCastironConnectionEdge'
    /** The item at the end of the edge. */
    node: FooterCastiron
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

export interface FooterJbfo extends Document, Linkable {
    __typename?: 'FooterJbfo'
    urlKey: Maybe<Scalars['String']>
    showDownloadApp: Maybe<Scalars['Boolean']>
    showPayment: Maybe<Scalars['Boolean']>
    showSocials: Maybe<Scalars['Boolean']>
    showLanguageSelector: Maybe<Scalars['Boolean']>
    urlIosApp: Maybe<Scalars['String']>
    urlAndroidApp: Maybe<Scalars['String']>
    newsletterHeading: Maybe<Scalars['String']>
    newsletterContent: Maybe<Scalars['String']>
    body: Maybe<Array<FooterJbfoBody>>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

export type FooterJbfoBody =
    | FooterJbfoBodyCategoryList
    | FooterJbfoBodyServiceContact

export interface FooterJbfoBodyCategoryList {
    __typename?: 'FooterJbfoBodyCategoryList'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<FooterJbfoBodyCategoryListPrimary>
    fields: Maybe<Array<FooterJbfoBodyCategoryListFields>>
}

export interface FooterJbfoBodyCategoryListFields {
    __typename?: 'FooterJbfoBodyCategoryListFields'
    title: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
    list: Maybe<Scalars['String']>
}

export interface FooterJbfoBodyCategoryListPrimary {
    __typename?: 'FooterJbfoBodyCategoryListPrimary'
    heading: Maybe<Scalars['String']>
    title1: Maybe<Scalars['String']>
    title2: Maybe<Scalars['String']>
    title3: Maybe<Scalars['String']>
    title4: Maybe<Scalars['String']>
}

export interface FooterJbfoBodyServiceContact {
    __typename?: 'FooterJbfoBodyServiceContact'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<FooterJbfoBodyServiceContactPrimary>
    fields: Maybe<Array<FooterJbfoBodyServiceContactFields>>
}

export interface FooterJbfoBodyServiceContactFields {
    __typename?: 'FooterJbfoBodyServiceContactFields'
    iconImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    content: Maybe<Scalars['String']>
    subText: Maybe<Scalars['String']>
    linkType: Maybe<Scalars['String']>
}

export interface FooterJbfoBodyServiceContactPrimary {
    __typename?: 'FooterJbfoBodyServiceContactPrimary'
    contactTitle: Maybe<Scalars['String']>
    contactContent: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
}

/** A connection to a list of items. */
export interface FooterJbfoConnectionConnection {
    __typename?: 'FooterJbfoConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<FooterJbfoConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface FooterJbfoConnectionEdge {
    __typename?: 'FooterJbfoConnectionEdge'
    /** The item at the end of the edge. */
    node: FooterJbfo
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

export interface FooterJustbrands extends Document, Linkable {
    __typename?: 'FooterJustbrands'
    urlKey: Maybe<Scalars['String']>
    showDownloadApp: Maybe<Scalars['Boolean']>
    showPayment: Maybe<Scalars['Boolean']>
    showSocials: Maybe<Scalars['Boolean']>
    showLanguageSelector: Maybe<Scalars['Boolean']>
    urlIosApp: Maybe<Scalars['String']>
    urlAndroidApp: Maybe<Scalars['String']>
    newsletterHeading: Maybe<Scalars['String']>
    newsletterContent: Maybe<Scalars['String']>
    body: Maybe<Array<FooterJustbrandsBody>>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

export type FooterJustbrandsBody =
    | FooterJustbrandsBodyCategoryList
    | FooterJustbrandsBodyServiceContact

export interface FooterJustbrandsBodyCategoryList {
    __typename?: 'FooterJustbrandsBodyCategoryList'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<FooterJustbrandsBodyCategoryListPrimary>
    fields: Maybe<Array<FooterJustbrandsBodyCategoryListFields>>
}

export interface FooterJustbrandsBodyCategoryListFields {
    __typename?: 'FooterJustbrandsBodyCategoryListFields'
    title: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
    list: Maybe<Scalars['String']>
}

export interface FooterJustbrandsBodyCategoryListPrimary {
    __typename?: 'FooterJustbrandsBodyCategoryListPrimary'
    heading: Maybe<Scalars['String']>
    title1: Maybe<Scalars['String']>
    title2: Maybe<Scalars['String']>
    title3: Maybe<Scalars['String']>
    title4: Maybe<Scalars['String']>
}

export interface FooterJustbrandsBodyServiceContact {
    __typename?: 'FooterJustbrandsBodyServiceContact'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<FooterJustbrandsBodyServiceContactPrimary>
    fields: Maybe<Array<FooterJustbrandsBodyServiceContactFields>>
}

export interface FooterJustbrandsBodyServiceContactFields {
    __typename?: 'FooterJustbrandsBodyServiceContactFields'
    iconImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    content: Maybe<Scalars['String']>
    subText: Maybe<Scalars['String']>
    linkType: Maybe<Scalars['String']>
}

export interface FooterJustbrandsBodyServiceContactPrimary {
    __typename?: 'FooterJustbrandsBodyServiceContactPrimary'
    contactTitle: Maybe<Scalars['String']>
    contactContent: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
}

/** A connection to a list of items. */
export interface FooterJustbrandsConnectionConnection {
    __typename?: 'FooterJustbrandsConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<FooterJustbrandsConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface FooterJustbrandsConnectionEdge {
    __typename?: 'FooterJustbrandsConnectionEdge'
    /** The item at the end of the edge. */
    node: FooterJustbrands
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

export interface FooterMonstersale extends Document, Linkable {
    __typename?: 'FooterMonstersale'
    urlKey: Maybe<Scalars['String']>
    showDownloadApp: Maybe<Scalars['Boolean']>
    showPayment: Maybe<Scalars['Boolean']>
    showSocials: Maybe<Scalars['Boolean']>
    showLanguageSelector: Maybe<Scalars['Boolean']>
    urlIosApp: Maybe<Scalars['String']>
    urlAndroidApp: Maybe<Scalars['String']>
    newsletterHeading: Maybe<Scalars['String']>
    newsletterContent: Maybe<Scalars['String']>
    body: Maybe<Array<FooterMonstersaleBody>>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

export type FooterMonstersaleBody =
    | FooterMonstersaleBodyCategoryList
    | FooterMonstersaleBodyServiceContact

export interface FooterMonstersaleBodyCategoryList {
    __typename?: 'FooterMonstersaleBodyCategoryList'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<FooterMonstersaleBodyCategoryListPrimary>
    fields: Maybe<Array<FooterMonstersaleBodyCategoryListFields>>
}

export interface FooterMonstersaleBodyCategoryListFields {
    __typename?: 'FooterMonstersaleBodyCategoryListFields'
    title: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
    list: Maybe<Scalars['String']>
}

export interface FooterMonstersaleBodyCategoryListPrimary {
    __typename?: 'FooterMonstersaleBodyCategoryListPrimary'
    heading: Maybe<Scalars['String']>
    title1: Maybe<Scalars['String']>
    title2: Maybe<Scalars['String']>
    title3: Maybe<Scalars['String']>
    title4: Maybe<Scalars['String']>
}

export interface FooterMonstersaleBodyServiceContact {
    __typename?: 'FooterMonstersaleBodyServiceContact'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<FooterMonstersaleBodyServiceContactPrimary>
    fields: Maybe<Array<FooterMonstersaleBodyServiceContactFields>>
}

export interface FooterMonstersaleBodyServiceContactFields {
    __typename?: 'FooterMonstersaleBodyServiceContactFields'
    iconImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    content: Maybe<Scalars['String']>
    subText: Maybe<Scalars['String']>
    linkType: Maybe<Scalars['String']>
}

export interface FooterMonstersaleBodyServiceContactPrimary {
    __typename?: 'FooterMonstersaleBodyServiceContactPrimary'
    contactTitle: Maybe<Scalars['String']>
    contactContent: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
}

/** A connection to a list of items. */
export interface FooterMonstersaleConnectionConnection {
    __typename?: 'FooterMonstersaleConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<FooterMonstersaleConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface FooterMonstersaleConnectionEdge {
    __typename?: 'FooterMonstersaleConnectionEdge'
    /** The item at the end of the edge. */
    node: FooterMonstersale
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

export interface FooterPme extends Document, Linkable {
    __typename?: 'FooterPme'
    urlKey: Maybe<Scalars['String']>
    showDownloadApp: Maybe<Scalars['Boolean']>
    showPayment: Maybe<Scalars['Boolean']>
    showSocials: Maybe<Scalars['Boolean']>
    showLanguageSelector: Maybe<Scalars['Boolean']>
    urlIosApp: Maybe<Scalars['String']>
    urlAndroidApp: Maybe<Scalars['String']>
    newsletterHeading: Maybe<Scalars['String']>
    newsletterContent: Maybe<Scalars['String']>
    body: Maybe<Array<FooterPmeBody>>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

export type FooterPmeBody =
    | FooterPmeBodyCategoryList
    | FooterPmeBodyServiceContact

export interface FooterPmeBodyCategoryList {
    __typename?: 'FooterPmeBodyCategoryList'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<FooterPmeBodyCategoryListPrimary>
    fields: Maybe<Array<FooterPmeBodyCategoryListFields>>
}

export interface FooterPmeBodyCategoryListFields {
    __typename?: 'FooterPmeBodyCategoryListFields'
    title: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
    list: Maybe<Scalars['String']>
}

export interface FooterPmeBodyCategoryListPrimary {
    __typename?: 'FooterPmeBodyCategoryListPrimary'
    heading: Maybe<Scalars['String']>
    title1: Maybe<Scalars['String']>
    title2: Maybe<Scalars['String']>
    title3: Maybe<Scalars['String']>
    title4: Maybe<Scalars['String']>
}

export interface FooterPmeBodyServiceContact {
    __typename?: 'FooterPmeBodyServiceContact'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<FooterPmeBodyServiceContactPrimary>
    fields: Maybe<Array<FooterPmeBodyServiceContactFields>>
}

export interface FooterPmeBodyServiceContactFields {
    __typename?: 'FooterPmeBodyServiceContactFields'
    iconImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    content: Maybe<Scalars['String']>
    subText: Maybe<Scalars['String']>
    linkType: Maybe<Scalars['String']>
}

export interface FooterPmeBodyServiceContactPrimary {
    __typename?: 'FooterPmeBodyServiceContactPrimary'
    contactTitle: Maybe<Scalars['String']>
    contactContent: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
}

/** A connection to a list of items. */
export interface FooterPmeConnectionConnection {
    __typename?: 'FooterPmeConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<FooterPmeConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface FooterPmeConnectionEdge {
    __typename?: 'FooterPmeConnectionEdge'
    /** The item at the end of the edge. */
    node: FooterPme
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

export interface FooterPmeoutlet extends Document, Linkable {
    __typename?: 'FooterPmeoutlet'
    urlKey: Maybe<Scalars['String']>
    showDownloadApp: Maybe<Scalars['Boolean']>
    showPayment: Maybe<Scalars['Boolean']>
    showSocials: Maybe<Scalars['Boolean']>
    showLanguageSelector: Maybe<Scalars['Boolean']>
    urlIosApp: Maybe<Scalars['String']>
    urlAndroidApp: Maybe<Scalars['String']>
    newsletterHeading: Maybe<Scalars['String']>
    newsletterContent: Maybe<Scalars['String']>
    body: Maybe<Array<FooterPmeoutletBody>>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

export type FooterPmeoutletBody =
    | FooterPmeoutletBodyCategoryList
    | FooterPmeoutletBodyServiceContact

export interface FooterPmeoutletBodyCategoryList {
    __typename?: 'FooterPmeoutletBodyCategoryList'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<FooterPmeoutletBodyCategoryListPrimary>
    fields: Maybe<Array<FooterPmeoutletBodyCategoryListFields>>
}

export interface FooterPmeoutletBodyCategoryListFields {
    __typename?: 'FooterPmeoutletBodyCategoryListFields'
    title: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
    list: Maybe<Scalars['String']>
}

export interface FooterPmeoutletBodyCategoryListPrimary {
    __typename?: 'FooterPmeoutletBodyCategoryListPrimary'
    heading: Maybe<Scalars['String']>
    title1: Maybe<Scalars['String']>
    title2: Maybe<Scalars['String']>
    title3: Maybe<Scalars['String']>
    title4: Maybe<Scalars['String']>
}

export interface FooterPmeoutletBodyServiceContact {
    __typename?: 'FooterPmeoutletBodyServiceContact'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<FooterPmeoutletBodyServiceContactPrimary>
    fields: Maybe<Array<FooterPmeoutletBodyServiceContactFields>>
}

export interface FooterPmeoutletBodyServiceContactFields {
    __typename?: 'FooterPmeoutletBodyServiceContactFields'
    iconImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    content: Maybe<Scalars['String']>
    subText: Maybe<Scalars['String']>
    linkType: Maybe<Scalars['String']>
}

export interface FooterPmeoutletBodyServiceContactPrimary {
    __typename?: 'FooterPmeoutletBodyServiceContactPrimary'
    contactTitle: Maybe<Scalars['String']>
    contactContent: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
}

/** A connection to a list of items. */
export interface FooterPmeoutletConnectionConnection {
    __typename?: 'FooterPmeoutletConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<FooterPmeoutletConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface FooterPmeoutletConnectionEdge {
    __typename?: 'FooterPmeoutletConnectionEdge'
    /** The item at the end of the edge. */
    node: FooterPmeoutlet
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

export interface FooterVanguard extends Document, Linkable {
    __typename?: 'FooterVanguard'
    urlKey: Maybe<Scalars['String']>
    showDownloadApp: Maybe<Scalars['Boolean']>
    showPayment: Maybe<Scalars['Boolean']>
    showSocials: Maybe<Scalars['Boolean']>
    showLanguageSelector: Maybe<Scalars['Boolean']>
    urlIosApp: Maybe<Scalars['String']>
    urlAndroidApp: Maybe<Scalars['String']>
    newsletterHeading: Maybe<Scalars['String']>
    newsletterContent: Maybe<Scalars['String']>
    body: Maybe<Array<FooterVanguardBody>>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

export type FooterVanguardBody =
    | FooterVanguardBodyCategoryList
    | FooterVanguardBodyServiceContact

export interface FooterVanguardBodyCategoryList {
    __typename?: 'FooterVanguardBodyCategoryList'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<FooterVanguardBodyCategoryListPrimary>
    fields: Maybe<Array<FooterVanguardBodyCategoryListFields>>
}

export interface FooterVanguardBodyCategoryListFields {
    __typename?: 'FooterVanguardBodyCategoryListFields'
    title: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
    list: Maybe<Scalars['String']>
}

export interface FooterVanguardBodyCategoryListPrimary {
    __typename?: 'FooterVanguardBodyCategoryListPrimary'
    heading: Maybe<Scalars['String']>
    title1: Maybe<Scalars['String']>
    title2: Maybe<Scalars['String']>
    title3: Maybe<Scalars['String']>
    title4: Maybe<Scalars['String']>
}

export interface FooterVanguardBodyServiceContact {
    __typename?: 'FooterVanguardBodyServiceContact'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<FooterVanguardBodyServiceContactPrimary>
    fields: Maybe<Array<FooterVanguardBodyServiceContactFields>>
}

export interface FooterVanguardBodyServiceContactFields {
    __typename?: 'FooterVanguardBodyServiceContactFields'
    iconImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    content: Maybe<Scalars['String']>
    subText: Maybe<Scalars['String']>
    linkType: Maybe<Scalars['String']>
}

export interface FooterVanguardBodyServiceContactPrimary {
    __typename?: 'FooterVanguardBodyServiceContactPrimary'
    contactTitle: Maybe<Scalars['String']>
    contactContent: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
}

/** A connection to a list of items. */
export interface FooterVanguardConnectionConnection {
    __typename?: 'FooterVanguardConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<FooterVanguardConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface FooterVanguardConnectionEdge {
    __typename?: 'FooterVanguardConnectionEdge'
    /** The item at the end of the edge. */
    node: FooterVanguard
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

/** FreeGiftRule */
export interface FreeGiftRule {
    __typename?: 'FreeGiftRule'
    /** Claimed Label */
    claimedLabel: Maybe<Scalars['String']>
    /** Description */
    description: Maybe<Scalars['String']>
    /** Has Coupon Code */
    hasCouponCode: Maybe<Scalars['Boolean']>
    /** ID */
    id: Scalars['Int']
    /** Image */
    image: Maybe<Scalars['String']>
    /** Insufficient Amount */
    insufficientAmount: Maybe<Money>
    /** Insufficient Base Subtotal */
    insufficientBaseSubtotal: Maybe<Money>
    /** Insufficient Base Subtotal Total Incl Tax */
    insufficientBaseSubtotalTotalInclTax: Maybe<Money>
    /** Insufficient Base Subtotal With Discount */
    insufficientBaseSubtotalWithDiscount: Maybe<Money>
    /** Insufficient Subtotal */
    insufficientSubtotal: Maybe<Money>
    /** Insufficient Subtotal Total Incl Tax */
    insufficientSubtotalTotalInclTax: Maybe<Money>
    /** Insufficient Subtotal With Discount */
    insufficientSubtotalWithDiscount: Maybe<Money>
    /** Is Active */
    isActive: Maybe<Scalars['Boolean']>
    /** Is Available */
    isAvailable: Maybe<Scalars['Boolean']>
    /** Is Valid */
    isValid: Maybe<Scalars['Boolean']>
    /** Is Valid Conditions */
    isValidConditions: Maybe<Scalars['Boolean']>
    /** Is Valid Customer Group */
    isValidCustomerGroup: Maybe<Scalars['Boolean']>
    /** Item IDs */
    itemIds: Maybe<Array<Maybe<Scalars['Int']>>>
    /** Label */
    label: Maybe<Scalars['String']>
    /** Max Items */
    maxItems: Maybe<Scalars['Int']>
    /** Name */
    name: Maybe<Scalars['String']>
    /** Free Gift Products */
    products: Maybe<Array<Maybe<ProductInterface>>>
    /** Sort Order */
    sortOrder: Maybe<Scalars['Int']>
    /** Is Available */
    tooltip: Maybe<Scalars['String']>
    /** Unclaimed Label */
    unclaimedLabel: Maybe<Scalars['String']>
}

/** Identifies which customer requires remote shopping assistance. */
export interface GenerateCustomerTokenAsAdminInput {
    /** The email address of the customer requesting remote shopping assistance. */
    customer_email: Scalars['String']
}

/** Contains the generated customer token. */
export interface GenerateCustomerTokenAsAdminOutput {
    __typename?: 'GenerateCustomerTokenAsAdminOutput'
    /** The generated customer token. */
    customerToken: Scalars['String']
}

/** Contains the text of a gift message, its sender, and recipient */
export interface GiftMessage {
    __typename?: 'GiftMessage'
    /** Sender name */
    from: Scalars['String']
    /** Gift message text */
    message: Scalars['String']
    /** Recipient name */
    to: Scalars['String']
}

/** Contains the text of a gift message, its sender, and recipient */
export interface GiftMessageInput {
    /** Sender name */
    from: Scalars['String']
    /** Gift message text */
    message: Scalars['String']
    /** Recipient name */
    to: Scalars['String']
}

export interface GoogleAddressComponent {
    __typename?: 'GoogleAddressComponent'
    longName: Maybe<Scalars['String']>
    shortName: Maybe<Scalars['String']>
    types: Maybe<Array<Scalars['String']>>
}

export enum GoogleBusinessStatus {
    OPERATIONAL = 'OPERATIONAL',
    CLOSED_TEMPORARILY = 'CLOSED_TEMPORARILY',
    CLOSED_PERMANENTLY = 'CLOSED_PERMANENTLY',
}

export interface GoogleDayTime {
    __typename?: 'GoogleDayTime'
    /** A number from 0–6, corresponding to the days of the week, starting on Sunday. For example, 2 means Tuesday. */
    day: Maybe<Scalars['Int']>
    /**
     * May contain a time of day in 24-hour hhmm format. Values are in the range
     * 0000–2359. The time will be reported in the place’s time zone.
     */
    time: Maybe<Scalars['Int']>
}

export interface GoogleGeometry {
    __typename?: 'GoogleGeometry'
    location: Maybe<GooglePlaceLatLong>
    viewport: Maybe<GoogleViewport>
}

export interface GoogleMapsDistanceDestination {
    __typename?: 'GoogleMapsDistanceDestination'
    destination: Maybe<Scalars['String']>
    distances: Maybe<Array<Maybe<GoogleMapsTravelDistanceElements>>>
}

export interface GoogleMapsDistanceDestinationInput {
    destination: Scalars['String']
    lat: Scalars['Float']
    lng: Scalars['Float']
}

export interface GoogleMapsDistanceDestinationsInput {
    origin: GoogleMapsGeocodeLocationInput
    destinations: Array<GoogleMapsDistanceDestinationInput>
}

export interface GoogleMapsGeocodeGeometry {
    __typename?: 'GoogleMapsGeocodeGeometry'
    location: Maybe<GoogleMapsGeocodeLocation>
}

export interface GoogleMapsGeocodeLocation {
    __typename?: 'GoogleMapsGeocodeLocation'
    lat: Maybe<Scalars['Float']>
    lng: Maybe<Scalars['Float']>
}

export interface GoogleMapsGeocodeLocationInput {
    lat?: Maybe<Scalars['Float']>
    lng?: Maybe<Scalars['Float']>
}

export interface GoogleMapsGeocodeResult {
    __typename?: 'GoogleMapsGeocodeResult'
    geometry: Maybe<GoogleMapsGeocodeGeometry>
    formattedAddress: Maybe<Scalars['String']>
    placeId: Maybe<Scalars['String']>
}

export interface GoogleMapsTravelDistance {
    __typename?: 'GoogleMapsTravelDistance'
    elements: Maybe<Array<Maybe<GoogleMapsTravelDistanceElements>>>
}

export interface GoogleMapsTravelDistanceDuration {
    __typename?: 'GoogleMapsTravelDistanceDuration'
    value: Maybe<Scalars['Int']>
    text: Maybe<Scalars['String']>
}

export interface GoogleMapsTravelDistanceElements {
    __typename?: 'GoogleMapsTravelDistanceElements'
    status: Maybe<Scalars['String']>
    duration: Maybe<GoogleMapsTravelDistanceDuration>
    distance: Maybe<GoogleMapsTravelDistanceLength>
}

export interface GoogleMapsTravelDistanceLength {
    __typename?: 'GoogleMapsTravelDistanceLength'
    value: Maybe<Scalars['Int']>
    text: Maybe<Scalars['String']>
}

export interface GoogleOpeningHours {
    __typename?: 'GoogleOpeningHours'
    openNow: Maybe<Scalars['Boolean']>
    periods: Maybe<Array<GooglePeriod>>
    weekdayText: Maybe<Array<Scalars['String']>>
}

export interface GooglePeriod {
    __typename?: 'GooglePeriod'
    open: Maybe<GoogleDayTime>
    close: Maybe<GoogleDayTime>
}

export interface GooglePhoto {
    __typename?: 'GooglePhoto'
    photoReference: Maybe<Scalars['String']>
    height: Maybe<Scalars['Int']>
    width: Maybe<Scalars['Int']>
    htmlAttributions: Maybe<Array<Scalars['String']>>
}

export interface GooglePlace {
    __typename?: 'GooglePlace'
    placeId: Scalars['ID']
    addressComponents: Maybe<Array<GoogleAddressComponent>>
    businessStatus: Maybe<GoogleBusinessStatus>
    formattedAddress: Maybe<Scalars['String']>
    geometry: Maybe<GoogleGeometry>
    icon: Maybe<Scalars['String']>
    name: Maybe<Scalars['String']>
    photos: Maybe<Array<Scalars['String']>>
    plusCode: Maybe<Scalars['String']>
    types: Maybe<Array<Scalars['String']>>
    openingHours: Maybe<GoogleOpeningHours>
    formattedPhoneNumber: Maybe<Scalars['String']>
}

export interface GooglePlaceAutocompleteResult {
    __typename?: 'GooglePlaceAutocompleteResult'
    placeId: Scalars['ID']
    description: Maybe<Scalars['String']>
    distanceMeters: Maybe<Scalars['Int']>
    matchedSubstrings: Array<MatchedSubstring>
    reference: Maybe<Scalars['ID']>
    terms: Array<GooglePlacesTerms>
    types: Maybe<Array<Maybe<Scalars['String']>>>
    structuredFormatting: Maybe<GooglePlacesStructuredFormatting>
}

export interface GooglePlaceLatLong {
    __typename?: 'GooglePlaceLatLong'
    lat: Maybe<Scalars['Float']>
    lng: Maybe<Scalars['Float']>
}

export interface GooglePlaceSearchResults {
    __typename?: 'GooglePlaceSearchResults'
    placeId: Scalars['ID']
}

export interface GooglePlacesStructuredFormatting {
    __typename?: 'GooglePlacesStructuredFormatting'
    mainText: Maybe<Scalars['String']>
    mainTextMatchedSubstrings: Maybe<Array<Maybe<MatchedSubstring>>>
    secondaryText: Maybe<Scalars['String']>
}

export interface GooglePlacesTerms {
    __typename?: 'GooglePlacesTerms'
    offset: Scalars['Int']
    value: Scalars['String']
}

export interface GoogleViewport {
    __typename?: 'GoogleViewport'
    northeast: Maybe<GooglePlaceLatLong>
    southwest: Maybe<GooglePlaceLatLong>
}

/** Defines a grouped product, which consists of simple standalone products that are presented as a group. */
export interface GroupedProduct
    extends ProductInterface,
        RoutableInterface,
        PhysicalProductInterface {
    __typename?: 'GroupedProduct'
    /** @deprecated Use the `custom_attributes` field instead. */
    baseColorHexCode: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    brandAlias: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    brandMediaPdpBottom: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    brandMediaPdpTop: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    brandName: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    brandPdpDescription: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    brandPdpTitle: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    featureDescription: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    featureMedia: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    featureName: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    itemLabelTextB2C: Maybe<Scalars['Int']>
    itemLabelTextB2CValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    itemLabelTypeB2C: Maybe<Scalars['Int']>
    itemLabelTypeB2CValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    itemMaterialBody: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    itemMaterialBodyLining: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    itemMaterialLining: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    itemMaterialShell: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    itemSizeAdvice: Maybe<Scalars['Int']>
    itemSizeAdviceValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    modelAdvice: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productArticleNumber: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productBeltLoops: Maybe<Scalars['Int']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productBodyShell: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productChestPockets: Maybe<Scalars['Int']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productClosure: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productClosureDetails: Maybe<Scalars['String']>
    productClosureDetailsValues: Maybe<Array<Maybe<AttributeOption>>>
    productClosureValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productCollar: Maybe<Scalars['Int']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productCollarDetails: Maybe<Scalars['String']>
    productCollarDetailsValues: Maybe<Array<Maybe<AttributeOption>>>
    productCollarValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productContainsNonTextilePartsOfAnimalOrigin: Maybe<Scalars['Int']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productCuffs: Maybe<Scalars['String']>
    productCuffsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productDimensions: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productFabric: Maybe<Scalars['String']>
    productFabricValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productFashionColor: Maybe<Scalars['Int']>
    productFashionColorValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productFit: Maybe<Scalars['Int']>
    productFitValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productHeight: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productHood: Maybe<Scalars['Int']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productHoodDetails: Maybe<Scalars['Int']>
    productHoodDetailsValues: Maybe<Array<Maybe<AttributeOption>>>
    productHoodValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productInnerJacketDetails: Maybe<Scalars['String']>
    productInnerJacketDetailsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productInnerPockets: Maybe<Scalars['Int']>
    productInnerPocketsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productJacketDetails: Maybe<Scalars['String']>
    productJacketDetailsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productJacketType: Maybe<Scalars['Int']>
    productJacketTypeValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productJacketlayers: Maybe<Scalars['Int']>
    productJacketlayersValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productLength: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productLengthDescription: Maybe<Scalars['Int']>
    productLengthDescriptionValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productLogoLocation: Maybe<Scalars['String']>
    productLogoLocationValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMarketPlacesDescription: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMarketingStyle: Maybe<Scalars['Int']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMaterialBack: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMaterialFilling: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMaterialFront: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMaterialHood: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMaterialLining: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMaterialPadding: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMaterialShell: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productNumberOfPockets: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productOccassion: Maybe<Scalars['Int']>
    productOccassionValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productOuterPockets: Maybe<Scalars['String']>
    productOuterPocketsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productPaddingType: Maybe<Scalars['Int']>
    productPaddingTypeValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productPattern: Maybe<Scalars['String']>
    productPatternValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productPocketType: Maybe<Scalars['String']>
    productPocketTypeValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productRelevance: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productSeasonStatus: Maybe<Scalars['Int']>
    productSeasonStatusValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productShape: Maybe<Scalars['Int']>
    productShapeValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productSizeTable: Maybe<Scalars['Int']>
    productSizeTableValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productSleeveCuffDetails: Maybe<Scalars['Int']>
    productSleeveCuffDetailsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productSleeveDetails: Maybe<Scalars['Int']>
    productSleeveDetailsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productSleeveLength: Maybe<Scalars['Int']>
    productSleeveLengthValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productSustainable: Maybe<Scalars['String']>
    productSustainableValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productType: Maybe<Scalars['Int']>
    productTypeValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productVolume: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productWaistRise: Maybe<Scalars['Int']>
    productWaistRiseValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productWashingInstructionsClotex: Maybe<Scalars['String']>
    productWashingInstructionsClotexValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productWidth: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    sizeTableIdentifier: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    washingSymbolDescription: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    washingSymbolMedia: Maybe<Scalars['String']>
    /**
     * The attribute set assigned to the product.
     * @deprecated The field should not be used on the storefront.
     */
    attributeSetId: Maybe<Scalars['Int']>
    /** @deprecated Use the `custom_attributes` field instead. */
    baseColor: Maybe<Scalars['String']>
    baseColorValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    brand: Maybe<Scalars['String']>
    /**
     * The relative canonical URL. This value is returned only if the system setting
     * 'Use Canonical Link Meta Tag For Products' is enabled.
     */
    canonicalUrl: Maybe<Scalars['String']>
    /** The categories assigned to a product. */
    categories: Maybe<Array<Maybe<CategoryInterface>>>
    categoryIds: Maybe<Array<Maybe<Scalars['Int']>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    color: Maybe<Scalars['String']>
    colorValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    configAttributes: Maybe<Scalars['String']>
    /** Product bottom content */
    contentBottomContent: Maybe<Scalars['String']>
    /** Product top image one */
    contentBottomImageOne: Maybe<Scalars['String']>
    /** Product top image two */
    contentBottomImageTwo: Maybe<Scalars['String']>
    /** Product bottom title */
    contentBottomTitle: Maybe<Scalars['String']>
    contentBottomUsp: Maybe<Scalars['String']>
    /** Product middle content */
    contentMiddleContent: Maybe<Scalars['String']>
    /** Product top image one */
    contentMiddleImageOne: Maybe<Scalars['String']>
    /** Product top image two */
    contentMiddleImageTwo: Maybe<Scalars['String']>
    /** Product middle title */
    contentMiddleTitle: Maybe<Scalars['String']>
    /** Product size guide image above */
    contentSizeGuideImageAbove: Maybe<Scalars['String']>
    /** Product size guide image below */
    contentSizeGuideImageBelow: Maybe<Scalars['String']>
    /** Product top content */
    contentTopContent: Maybe<Scalars['String']>
    /** Product top image four */
    contentTopImageFour: Maybe<Scalars['String']>
    /** Product top image one */
    contentTopImageOne: Maybe<Scalars['String']>
    /** Product top image three */
    contentTopImageThree: Maybe<Scalars['String']>
    /** Product top image two */
    contentTopImageTwo: Maybe<Scalars['String']>
    /** Product top title */
    contentTopTitle: Maybe<Scalars['String']>
    /** The product's country of origin. */
    countryOfManufacture: Maybe<Scalars['String']>
    /**
     * Timestamp indicating when the product was created.
     * @deprecated The field should not be used on the storefront.
     */
    createdAt: Maybe<Scalars['String']>
    /** Crosssell Products */
    crosssellProducts: Maybe<Array<Maybe<ProductInterface>>>
    /** Product custom attributes. */
    customAttributesV2: Maybe<ProductCustomAttributes>
    /** Product Delivery Message */
    deliveryMessage: Maybe<Scalars['String']>
    /** Detailed information about the product. The value can include simple HTML tags. */
    description: Maybe<ComplexTextValue>
    /** @deprecated Use the `custom_attributes` field instead. */
    discountAmount: Maybe<Scalars['Float']>
    /** @deprecated Use the `custom_attributes` field instead. */
    discountPercentage: Maybe<Scalars['Float']>
    /** @deprecated Use the `custom_attributes` field instead. */
    featureIcons: Maybe<Scalars['String']>
    featureIconsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    features: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    fit: Maybe<Scalars['String']>
    fitValues: Maybe<Array<Maybe<AttributeOption>>>
    /** Indicates whether a gift message is available. */
    giftMessageAvailable: Maybe<Scalars['String']>
    hexColor: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    highlights: Maybe<Scalars['String']>
    highlightsValues: Maybe<Array<Maybe<AttributeOption>>>
    /**
     * The ID number assigned to the product.
     * @deprecated Use the `uid` field instead.
     */
    id: Scalars['Int']
    /** The relative path to the main image on the product page. */
    image: Maybe<ProductImage>
    /** URL for the Care Tab */
    infoTabCareUrl: Maybe<Scalars['String']>
    /** URL for the Features Tab */
    infoTabFeaturesUrl: Maybe<Scalars['String']>
    /** URL for the Fit Tab */
    infoTabFitUrl: Maybe<Scalars['String']>
    /** URL for the Highlights Tab */
    infoTabHighlightsUrl: Maybe<Scalars['String']>
    /** URL for the Materials Tab */
    infoTabMaterialsUrl: Maybe<Scalars['String']>
    /** An array containing grouped product items. */
    items: Maybe<Array<Maybe<GroupedProductItem>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    jeansSizeCombined: Maybe<Scalars['Int']>
    jeansSizeCombinedValues: Maybe<Array<Maybe<AttributeOption>>>
    /**
     * Proxy for ItemLabelTextB2C
     * @deprecated Use the `custom_attributes` field instead.
     */
    labeltekst: Maybe<Scalars['String']>
    /**
     * Proxy for ItemLabelTypeB2C
     * @deprecated Use the `custom_attributes` field instead.
     */
    labeltype: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    length: Maybe<Scalars['String']>
    lengthValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    lowestDate: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    lowestPrice: Maybe<Scalars['Float']>
    /** Main Category */
    mainCategory: Maybe<CategoryInterface>
    /**
     * A number representing the product's manufacturer.
     * @deprecated Use the `custom_attributes` field instead.
     */
    manufacturer: Maybe<Scalars['Int']>
    manufacturerValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    materialInside: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    materialOutside: Maybe<Scalars['String']>
    /** An array of media gallery objects. */
    mediaGallery: Maybe<Array<Maybe<MediaGalleryInterface>>>
    /**
     * An array of MediaGalleryEntry objects.
     * @deprecated Use `media_gallery` instead.
     */
    mediaGalleryEntries: Maybe<Array<Maybe<MediaGalleryEntry>>>
    /** A brief overview of the product for search results listings, maximum 255 characters. */
    metaDescription: Maybe<Scalars['String']>
    /** A comma-separated list of keywords that are visible only to search engines. */
    metaKeyword: Maybe<Scalars['String']>
    metaRobots: Maybe<Scalars['String']>
    /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
    metaTitle: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    modelImage: Maybe<Scalars['String']>
    /** Information related to the Model. */
    modelInfo: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    modelName: Maybe<Scalars['String']>
    /** The product name. Customers use this name to identify the product. */
    name: Scalars['String']
    /** The beginning date for new product listings, and determines if the product is featured as a new product. */
    newFromDate: Maybe<Scalars['String']>
    /** The end date for new product listings. */
    newToDate: Maybe<Scalars['String']>
    /** Product stock only x left count */
    onlyXLeftInStock: Maybe<Scalars['Float']>
    /** If the product has multiple options, determines where they appear on the product page. */
    optionsContainer: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    outletAdviesprijs: Maybe<Scalars['Float']>
    /** @deprecated Use the `custom_attributes` field instead. */
    parentSku: Maybe<Scalars['String']>
    /**
     * Indicates the price of an item.
     * @deprecated Use `price_range` for product price information.
     */
    price: Maybe<ProductPrices>
    /** The range of prices for the product */
    priceRange: PriceRange
    /** An array of `TierPrice` objects. */
    priceTiers: Maybe<Array<Maybe<TierPrice>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productArticleCategoryCode: Maybe<Scalars['Int']>
    productArticleCategoryCodeValues: Maybe<Array<Maybe<AttributeOption>>>
    /**
     * Product type like, jeans, jacket etc.
     * @deprecated Use the `custom_attributes` field instead.
     */
    productGroup: Maybe<Scalars['String']>
    /** An array of `ProductLinks` objects. */
    productLinks: Maybe<Array<Maybe<ProductLinksInterface>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    products: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productsustainable2: Maybe<Scalars['String']>
    productsustainable2Values: Maybe<Array<Maybe<AttributeOption>>>
    /** The average of all the ratings given to the product. */
    ratingSummary: Scalars['Float']
    /** @deprecated Use the `custom_attributes` field instead. */
    recommendations: Maybe<Scalars['String']>
    /**
     * Contains 0 when there is no redirect error. A value of 301 indicates the URL
     * of the requested resource has been changed permanently, while a value of 302
     * indicates a temporary redirect.
     */
    redirectCode: Scalars['Int']
    /** An array of products to be displayed in a Related Products block. */
    relatedProducts: Maybe<Array<Maybe<ProductInterface>>>
    /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
    relativeUrl: Maybe<Scalars['String']>
    /** The total count of all the reviews given to the product. */
    reviewCount: Scalars['Int']
    /** The list of products reviews. */
    reviews: ProductReviews
    /** @deprecated Use the `custom_attributes` field instead. */
    seasonCollection: Maybe<Scalars['String']>
    seasonCollectionValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    shopthelook: Maybe<Scalars['Int']>
    /** A short description of the product. Its use depends on the theme. */
    shortDescription: Maybe<ComplexTextValue>
    /** @deprecated Use the `custom_attributes` field instead. */
    shortDescription2: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    shortDescription3: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    shortDescription4: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    shortDescription5: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    shortDescription6: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    size: Maybe<Scalars['String']>
    sizeValues: Maybe<Array<Maybe<AttributeOption>>>
    /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
    sku: Scalars['String']
    /** The relative path to the small image, which is used on catalog pages. */
    smallImage: Maybe<ProductImage>
    /**
     * The beginning date that a product has a special price.
     * @deprecated The field should not be used on the storefront.
     */
    specialFromDate: Maybe<Scalars['String']>
    /** The discounted price of the product. */
    specialPrice: Maybe<Scalars['Float']>
    /** The end date for a product with a special price. */
    specialToDate: Maybe<Scalars['String']>
    /** Stock status of the product */
    stockStatus: Maybe<ProductStockStatus>
    /** The file name of a swatch image. */
    swatchImage: Maybe<Scalars['String']>
    /** The relative path to the product's thumbnail image. */
    thumbnail: Maybe<ProductImage>
    /**
     * The price when tier pricing is in effect and the items purchased threshold has been reached.
     * @deprecated Use `price_tiers` for product tier price information.
     */
    tierPrice: Maybe<Scalars['Float']>
    /**
     * An array of ProductTierPrices objects.
     * @deprecated Use `price_tiers` for product tier price information.
     */
    tierPrices: Maybe<Array<Maybe<ProductTierPrices>>>
    tweakwiseCrosssellGroupCode: Maybe<Scalars['String']>
    /** Value -1 means the group_code should be used */
    tweakwiseCrosssellTemplate: Maybe<Scalars['Int']>
    tweakwiseUpsellGroupCode: Maybe<Scalars['String']>
    /** Value -1 means the group_code should be used */
    tweakwiseUpsellTemplate: Maybe<Scalars['Int']>
    /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
    type: Maybe<UrlRewriteEntityTypeEnum>
    /**
     * One of simple, virtual, bundle, downloadable, grouped, or configurable.
     * @deprecated Use `__typename` instead.
     */
    typeId: Maybe<Scalars['String']>
    /** The unique ID for a `ProductInterface` object. */
    uid: Scalars['ID']
    /**
     * Timestamp indicating when the product was updated.
     * @deprecated The field should not be used on the storefront.
     */
    updatedAt: Maybe<Scalars['String']>
    /** Upsell Products */
    upsellProducts: Maybe<Array<Maybe<ProductInterface>>>
    /** The part of the URL that identifies the product */
    urlKey: Scalars['String']
    /** @deprecated Use product's `canonical_url` or url rewrites instead */
    urlPath: Maybe<Scalars['String']>
    /** URL rewrites list */
    urlRewrites: Maybe<Array<Maybe<UrlRewrite>>>
    /** The part of the product URL that is appended after the url key */
    urlSuffix: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    uspIcons: Maybe<Scalars['String']>
    uspIconsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** USPs for this specific Product. */
    usps: Array<Maybe<Usp>>
    warehouse: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    washingInstructions: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    washingSymbols: Maybe<Scalars['String']>
    washingSymbolsValues: Maybe<Array<Maybe<AttributeOption>>>
    /**
     * An array of websites in which the product is available.
     * @deprecated Use the `custom_attributes` field instead.
     */
    websites: Maybe<Array<Maybe<Website>>>
    /** The weight of the item, in units defined by the store. */
    weight: Maybe<Scalars['Float']>
}

/** Defines a grouped product, which consists of simple standalone products that are presented as a group. */
export interface GroupedProductCustomAttributesV2Args {
    filters: Maybe<AttributeFilterInput>
}

/** Defines a grouped product, which consists of simple standalone products that are presented as a group. */
export interface GroupedProductReviewsArgs {
    pageSize?: Maybe<Scalars['Int']>
    currentPage?: Maybe<Scalars['Int']>
}

/** Defines a grouped product, which consists of simple standalone products that are presented as a group. */
export interface GroupedProductUspsArgs {
    pageSize?: Maybe<Scalars['Int']>
    currentPage?: Maybe<Scalars['Int']>
}

/** Contains information about an individual grouped product item. */
export interface GroupedProductItem {
    __typename?: 'GroupedProductItem'
    /** The relative position of this item compared to the other group items. */
    position: Maybe<Scalars['Int']>
    /** Details about this product option. */
    product: Maybe<ProductInterface>
    /** The quantity of this grouped product item. */
    qty: Maybe<Scalars['Float']>
}

/** A grouped product wish list item. */
export interface GroupedProductWishlistItem extends WishlistItemInterface {
    __typename?: 'GroupedProductWishlistItem'
    /** The date and time the item was added to the wish list. */
    addedAt: Scalars['String']
    /** Custom options selected for the wish list item. */
    customizableOptions: Array<Maybe<SelectedCustomizableOption>>
    /** The description of the item. */
    description: Maybe<Scalars['String']>
    /** The unique ID for a `WishlistItemInterface` object. */
    id: Scalars['ID']
    /** Product details of the wish list item. */
    product: Maybe<ProductInterface>
    /** The quantity of this wish list item. */
    quantity: Scalars['Float']
}

/** Opening and closing time. */
export interface GTime {
    __typename?: 'GTime'
    close: Maybe<Scalars['String']>
    open: Maybe<Scalars['String']>
}

export interface GuidedSellingPage extends Document, Linkable {
    __typename?: 'GuidedSellingPage'
    urlKey: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    headingStyle: Maybe<Scalars['String']>
    subheading: Maybe<Scalars['String']>
    subheadingStyle: Maybe<Scalars['String']>
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    tweakwiseFunnelId: Maybe<Scalars['String']>
    footerButtonLabel: Maybe<Scalars['String']>
    footerButtonStyle: Maybe<Scalars['String']>
    footerText: Maybe<Scalars['String']>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

/** A connection to a list of items. */
export interface GuidedSellingPageConnectionConnection {
    __typename?: 'GuidedSellingPageConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<GuidedSellingPageConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface GuidedSellingPageConnectionEdge {
    __typename?: 'GuidedSellingPageConnectionEdge'
    /** The item at the end of the edge. */
    node: GuidedSellingPage
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

export interface HeroBanner extends Document, Linkable {
    __typename?: 'HeroBanner'
    storeview: Maybe<Scalars['String']>
    position: Maybe<Scalars['String']>
    size: Maybe<Scalars['String']>
    headingfuss: Maybe<Scalars['Boolean']>
    header: Maybe<Scalars['String']>
    headingVariant: Maybe<Scalars['String']>
    headingElement: Maybe<Scalars['String']>
    subheading: Maybe<Scalars['String']>
    subheadingVariant: Maybe<Scalars['String']>
    subheadingElement: Maybe<Scalars['String']>
    videoId: Maybe<Scalars['String']>
    mobileVideoId: Maybe<Scalars['String']>
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

/** A connection to a list of items. */
export interface HeroBannerConnectionConnection {
    __typename?: 'HeroBannerConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<HeroBannerConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface HeroBannerConnectionEdge {
    __typename?: 'HeroBannerConnectionEdge'
    /** The item at the end of the edge. */
    node: HeroBanner
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

/**
 * Contains a set of relative URLs that PayPal uses in response to various actions
 * during the authorization process. Magento prepends the base URL to this value to
 * create a full URL. For example, if the full URL is
 * https://www.example.com/path/to/page.html, the relative URL is
 * path/to/page.html. Use this input for Payments Pro Hosted Solution payment method.
 */
export interface HostedProInput {
    /**
     * The relative URL of the page that PayPal redirects to when the buyer cancels
     * the transaction in order to choose a different payment method. For example, if
     * the full URL to this page is
     * https://www.example.com/paypal/action/cancel.html, the relative URL is
     * paypal/action/cancel.html.
     */
    cancel_url: Scalars['String']
    /**
     * The relative URL of the final confirmation page that PayPal redirects to upon
     * payment success. For example, if the full URL to this page is
     * https://www.example.com/paypal/action/return.html, the relative URL is
     * paypal/action/return.html.
     */
    return_url: Scalars['String']
}

/** Contains the secure URL used for the Payments Pro Hosted Solution payment method. */
export interface HostedProUrl {
    __typename?: 'HostedProUrl'
    /** The secure URL generated by PayPal. */
    secureFormUrl: Maybe<Scalars['String']>
}

/** Contains the required input to request the secure URL for Payments Pro Hosted Solution payment. */
export interface HostedProUrlInput {
    /** The unique ID that identifies the shopper's cart. */
    cart_id: Scalars['String']
}

/** Contains target path parameters. */
export interface HttpQueryParameter {
    __typename?: 'HttpQueryParameter'
    /** A parameter name. */
    name: Maybe<Scalars['String']>
    /** A parameter value. */
    value: Maybe<Scalars['String']>
}

/** A linked image */
export interface ImageLink extends Linkable {
    __typename?: 'ImageLink'
    name: Scalars['String']
    url: Scalars['String']
    size: Scalars['Long']
    height: Scalars['Int']
    width: Scalars['Int']
    linkType: Maybe<Scalars['String']>
}

export interface ImageSwatchData extends SwatchDataInterface {
    __typename?: 'ImageSwatchData'
    /** The URL assigned to the thumbnail of the swatch image. */
    thumbnail: Maybe<Scalars['String']>
    /** The value can be represented as color (HEX code), image link, or text. */
    value: Maybe<Scalars['String']>
}

/** List of templates/filters applied to customer attribute input. */
export enum InputFilterEnum {
    /** There are no templates or filters to be applied. */
    NONE = 'NONE',
    /** Forces attribute input to follow the date format. */
    DATE = 'DATE',
    /** Strip whitespace (or other characters) from the beginning and end of the input. */
    TRIM = 'TRIM',
    /** Strip HTML Tags. */
    STRIPTAGS = 'STRIPTAGS',
    /** Escape HTML Entities. */
    ESCAPEHTML = 'ESCAPEHTML',
}

/** Contains an error message when an internal error occurred. */
export interface InternalError extends ErrorInterface {
    __typename?: 'InternalError'
    /** The returned error message. */
    message: Scalars['String']
}

/** Contains invoice details. */
export interface Invoice {
    __typename?: 'Invoice'
    /** Comments on the invoice. */
    comments: Maybe<Array<Maybe<SalesCommentItem>>>
    /** The unique ID for a `Invoice` object. */
    id: Scalars['ID']
    /** Invoiced product details. */
    items: Maybe<Array<Maybe<InvoiceItemInterface>>>
    /** Sequential invoice number. */
    number: Scalars['String']
    /** Invoice total amount details. */
    total: Maybe<InvoiceTotal>
}

export interface InvoiceItem extends InvoiceItemInterface {
    __typename?: 'InvoiceItem'
    /** Information about the final discount amount for the base product, including discounts on options. */
    discounts: Maybe<Array<Maybe<Discount>>>
    /** The unique ID for an `InvoiceItemInterface` object. */
    id: Scalars['ID']
    /** Details about an individual order item. */
    orderItem: Maybe<OrderItemInterface>
    /** The name of the base product. */
    productName: Maybe<Scalars['String']>
    /** The sale price for the base product including selected options. */
    productSalePrice: Money
    /** The SKU of the base product. */
    productSku: Scalars['String']
    /** The number of invoiced items. */
    quantityInvoiced: Maybe<Scalars['Float']>
}

/** Contains detailes about invoiced items. */
export interface InvoiceItemInterface {
    /** Information about the final discount amount for the base product, including discounts on options. */
    discounts: Maybe<Array<Maybe<Discount>>>
    /** The unique ID for an `InvoiceItemInterface` object. */
    id: Scalars['ID']
    /** Details about an individual order item. */
    orderItem: Maybe<OrderItemInterface>
    /** The name of the base product. */
    productName: Maybe<Scalars['String']>
    /** The sale price for the base product including selected options. */
    productSalePrice: Money
    /** The SKU of the base product. */
    productSku: Scalars['String']
    /** The number of invoiced items. */
    quantityInvoiced: Maybe<Scalars['Float']>
}

/** Contains price details from an invoice. */
export interface InvoiceTotal {
    __typename?: 'InvoiceTotal'
    /** The final base grand total amount in the base currency. */
    baseGrandTotal: Money
    /** The applied discounts to the invoice. */
    discounts: Maybe<Array<Maybe<Discount>>>
    /** The final total amount, including shipping, discounts, and taxes. */
    grandTotal: Money
    /** Details about the shipping and handling costs for the invoice. */
    shippingHandling: Maybe<ShippingHandling>
    /** The subtotal of the invoice, excluding shipping, discounts, and taxes. */
    subtotal: Money
    /** The invoice tax details. */
    taxes: Maybe<Array<Maybe<TaxItem>>>
    /** The shipping amount for the invoice. */
    totalShipping: Money
    /** The amount of tax applied to the invoice. */
    totalTax: Money
}

/** Contains the result of the `isEmailAvailable` query. */
export interface IsEmailAvailableOutput {
    __typename?: 'IsEmailAvailableOutput'
    /** Indicates whether the specified email address can be used to create a customer. */
    isEmailAvailable: Maybe<Scalars['Boolean']>
}

/** A list of options of the selected bundle product. */
export interface ItemSelectedBundleOption {
    __typename?: 'ItemSelectedBundleOption'
    /**
     * The unique ID for a `ItemSelectedBundleOption` object.
     * @deprecated Use `uid` instead.
     */
    id: Scalars['ID']
    /** The label of the option. */
    label: Scalars['String']
    /** The unique ID for a `ItemSelectedBundleOption` object. */
    uid: Scalars['ID']
    /** A list of products that represent the values of the parent option. */
    values: Maybe<Array<Maybe<ItemSelectedBundleOptionValue>>>
}

/** A list of values for the selected bundle product. */
export interface ItemSelectedBundleOptionValue {
    __typename?: 'ItemSelectedBundleOptionValue'
    /**
     * The unique ID for a `ItemSelectedBundleOptionValue` object.
     * @deprecated Use `uid` instead.
     */
    id: Scalars['ID']
    /** The price of the child bundle product. */
    price: Money
    /** The name of the child bundle product. */
    productName: Scalars['String']
    /** The SKU of the child bundle product. */
    productSku: Scalars['String']
    /** The number of this bundle product that were ordered. */
    quantity: Scalars['Float']
    /** The unique ID for a `ItemSelectedBundleOptionValue` object. */
    uid: Scalars['ID']
}

export interface JbfoFaqPage extends Document, Linkable {
    __typename?: 'JbfoFaqPage'
    urlKey: Maybe<Scalars['String']>
    search: Maybe<Scalars['Boolean']>
    seoTitle: Maybe<Scalars['String']>
    seoDescription: Maybe<Scalars['String']>
    seoKeywords: Maybe<Scalars['String']>
    style: Maybe<Scalars['String']>
    head: Maybe<Scalars['Json']>
    noindex: Maybe<Scalars['Boolean']>
    nofollow: Maybe<Scalars['Boolean']>
    body: Maybe<Array<JbfoFaqPageBody>>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

export type JbfoFaqPageBody = JbfoFaqPageBodyHero | JbfoFaqPageBodyFaqSubject

export interface JbfoFaqPageBodyFaqSubject {
    __typename?: 'JbfoFaqPageBodyFaqSubject'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<JbfoFaqPageBodyFaqSubjectPrimary>
    fields: Maybe<Array<JbfoFaqPageBodyFaqSubjectFields>>
}

export interface JbfoFaqPageBodyFaqSubjectFields {
    __typename?: 'JbfoFaqPageBodyFaqSubjectFields'
    heading: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
}

export interface JbfoFaqPageBodyFaqSubjectPrimary {
    __typename?: 'JbfoFaqPageBodyFaqSubjectPrimary'
    urlKey: Maybe<Scalars['String']>
    seoTitle: Maybe<Scalars['String']>
    seoKeywords: Maybe<Scalars['String']>
    seoDescription: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    icon: Maybe<PrismicImageType>
}

export interface JbfoFaqPageBodyHero {
    __typename?: 'JbfoFaqPageBodyHero'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<JbfoFaqPageBodyHeroPrimary>
}

export interface JbfoFaqPageBodyHeroPrimary {
    __typename?: 'JbfoFaqPageBodyHeroPrimary'
    fullWidth: Maybe<Scalars['Boolean']>
    size: Maybe<Scalars['String']>
    headingfuss: Maybe<Scalars['Boolean']>
    header: Maybe<Scalars['String']>
    headingVariant: Maybe<Scalars['String']>
    headingElement: Maybe<Scalars['String']>
    subheading: Maybe<Scalars['String']>
    subheadingVariant: Maybe<Scalars['String']>
    subheadingElement: Maybe<Scalars['String']>
    videoId: Maybe<Scalars['String']>
    mobileVideoId: Maybe<Scalars['String']>
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
}

/** A connection to a list of items. */
export interface JbfoFaqPageConnectionConnection {
    __typename?: 'JbfoFaqPageConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<JbfoFaqPageConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface JbfoFaqPageConnectionEdge {
    __typename?: 'JbfoFaqPageConnectionEdge'
    /** The item at the end of the edge. */
    node: JbfoFaqPage
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

export interface JbfoLoginPage extends Document, Linkable {
    __typename?: 'JbfoLoginPage'
    urlKey: Maybe<Scalars['String']>
    search: Maybe<Scalars['Boolean']>
    seoTitle: Maybe<Scalars['String']>
    seoDescription: Maybe<Scalars['String']>
    seoKeywords: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
    subTitle: Maybe<Scalars['String']>
    subHeading: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
    backgroundImage: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    logos: Maybe<Array<JbfoLoginPageLogos>>
    body: Maybe<Array<JbfoLoginPageBody>>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

export type JbfoLoginPageBody = JbfoLoginPageBodyFaqSubject

export interface JbfoLoginPageBodyFaqSubject {
    __typename?: 'JbfoLoginPageBodyFaqSubject'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<JbfoLoginPageBodyFaqSubjectPrimary>
    fields: Maybe<Array<JbfoLoginPageBodyFaqSubjectFields>>
}

export interface JbfoLoginPageBodyFaqSubjectFields {
    __typename?: 'JbfoLoginPageBodyFaqSubjectFields'
    heading: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
}

export interface JbfoLoginPageBodyFaqSubjectPrimary {
    __typename?: 'JbfoLoginPageBodyFaqSubjectPrimary'
    urlKey: Maybe<Scalars['String']>
    seoTitle: Maybe<Scalars['String']>
    seoKeywords: Maybe<Scalars['String']>
    seoDescription: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    icon: Maybe<PrismicImageType>
}

/** A connection to a list of items. */
export interface JbfoLoginPageConnectionConnection {
    __typename?: 'JbfoLoginPageConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<JbfoLoginPageConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface JbfoLoginPageConnectionEdge {
    __typename?: 'JbfoLoginPageConnectionEdge'
    /** The item at the end of the edge. */
    node: JbfoLoginPage
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

export interface JbfoLoginPageLogos {
    __typename?: 'JbfoLoginPageLogos'
    logoImage: Maybe<PrismicImageType>
}

export interface JbfoPage extends Document, Linkable {
    __typename?: 'JbfoPage'
    urlKey: Maybe<Scalars['String']>
    search: Maybe<Scalars['Boolean']>
    seoTitle: Maybe<Scalars['String']>
    seoDescription: Maybe<Scalars['String']>
    seoKeywords: Maybe<Scalars['String']>
    style: Maybe<Scalars['String']>
    head: Maybe<Scalars['Json']>
    noindex: Maybe<Scalars['Boolean']>
    nofollow: Maybe<Scalars['Boolean']>
    authRequired: Maybe<Scalars['Boolean']>
    pageBackground: Maybe<Scalars['Json']>
    pageBackgroundColor: Maybe<Scalars['String']>
    body: Maybe<Array<JbfoPageBody>>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

export type JbfoPageBody =
    | JbfoPageBodyHero
    | JbfoPageBodyTextWithMedia
    | JbfoPageBodyTextOnly
    | JbfoPageBodyImageOnly
    | JbfoPageBodyTileSlider
    | JbfoPageBodyTweakwiseSlider
    | JbfoPageBodyBrand
    | JbfoPageBodyTeam
    | JbfoPageBodyStore
    | JbfoPageBodyHtml
    | JbfoPageBodyCategoryElement
    | JbfoPageBodyProductVideoHero
    | JbfoPageBodyFaq
    | JbfoPageBodyBannerBlocks
    | JbfoPageBodyBannerBlocks1
    | JbfoPageBodyBannerBlocks2
    | JbfoPageBodyCategoryList
    | JbfoPageBodyBlog

export interface JbfoPageBodyBannerBlocks {
    __typename?: 'JbfoPageBodyBannerBlocks'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<JbfoPageBodyBannerBlocksPrimary>
    fields: Maybe<Array<JbfoPageBodyBannerBlocksFields>>
}

export interface JbfoPageBodyBannerBlocks1 {
    __typename?: 'JbfoPageBodyBannerBlocks1'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<JbfoPageBodyBannerBlocks1Primary>
    fields: Maybe<Array<JbfoPageBodyBannerBlocks1Fields>>
}

export interface JbfoPageBodyBannerBlocks1Fields {
    __typename?: 'JbfoPageBodyBannerBlocks1Fields'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    subtitle: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

export interface JbfoPageBodyBannerBlocks1Primary {
    __typename?: 'JbfoPageBodyBannerBlocks1Primary'
    style: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
}

export interface JbfoPageBodyBannerBlocks2 {
    __typename?: 'JbfoPageBodyBannerBlocks2'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<JbfoPageBodyBannerBlocks2Primary>
    fields: Maybe<Array<JbfoPageBodyBannerBlocks2Fields>>
}

export interface JbfoPageBodyBannerBlocks2Fields {
    __typename?: 'JbfoPageBodyBannerBlocks2Fields'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    subtitle: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

export interface JbfoPageBodyBannerBlocks2Primary {
    __typename?: 'JbfoPageBodyBannerBlocks2Primary'
    style: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
}

export interface JbfoPageBodyBannerBlocksFields {
    __typename?: 'JbfoPageBodyBannerBlocksFields'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
}

export interface JbfoPageBodyBannerBlocksPrimary {
    __typename?: 'JbfoPageBodyBannerBlocksPrimary'
    style: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
}

export interface JbfoPageBodyBlog {
    __typename?: 'JbfoPageBodyBlog'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<JbfoPageBodyBlogPrimary>
    fields: Maybe<Array<JbfoPageBodyBlogFields>>
}

export interface JbfoPageBodyBlogFields {
    __typename?: 'JbfoPageBodyBlogFields'
    desktopImage: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    header: Maybe<Scalars['String']>
    subHeader: Maybe<Scalars['String']>
    blogText: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
}

export interface JbfoPageBodyBlogPrimary {
    __typename?: 'JbfoPageBodyBlogPrimary'
    title: Maybe<Scalars['String']>
    blogStyle: Maybe<Scalars['String']>
}

export interface JbfoPageBodyBrand {
    __typename?: 'JbfoPageBodyBrand'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<JbfoPageBodyBrandPrimary>
    fields: Maybe<Array<JbfoPageBodyBrandFields>>
}

export interface JbfoPageBodyBrandFields {
    __typename?: 'JbfoPageBodyBrandFields'
    desktopLogoImage: Maybe<PrismicImageType>
    mobileLogoImage: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    image: Maybe<PrismicImageType>
    brandId: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
    content: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

export interface JbfoPageBodyBrandPrimary {
    __typename?: 'JbfoPageBodyBrandPrimary'
    style: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    content: Maybe<Scalars['String']>
}

export interface JbfoPageBodyCategoryElement {
    __typename?: 'JbfoPageBodyCategoryElement'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<JbfoPageBodyCategoryElementPrimary>
    fields: Maybe<Array<JbfoPageBodyCategoryElementFields>>
}

export interface JbfoPageBodyCategoryElementFields {
    __typename?: 'JbfoPageBodyCategoryElementFields'
    title: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
}

export interface JbfoPageBodyCategoryElementPrimary {
    __typename?: 'JbfoPageBodyCategoryElementPrimary'
    style: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
}

export interface JbfoPageBodyCategoryList {
    __typename?: 'JbfoPageBodyCategoryList'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<JbfoPageBodyCategoryListPrimary>
    fields: Maybe<Array<JbfoPageBodyCategoryListFields>>
}

export interface JbfoPageBodyCategoryListFields {
    __typename?: 'JbfoPageBodyCategoryListFields'
    title: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
    list: Maybe<Scalars['String']>
}

export interface JbfoPageBodyCategoryListPrimary {
    __typename?: 'JbfoPageBodyCategoryListPrimary'
    style: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    title1: Maybe<Scalars['String']>
    title2: Maybe<Scalars['String']>
    title3: Maybe<Scalars['String']>
    title4: Maybe<Scalars['String']>
}

export interface JbfoPageBodyFaq {
    __typename?: 'JbfoPageBodyFaq'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<JbfoPageBodyFaqPrimary>
    fields: Maybe<Array<JbfoPageBodyFaqFields>>
}

export interface JbfoPageBodyFaqFields {
    __typename?: 'JbfoPageBodyFaqFields'
    iconimage: Maybe<PrismicImageType>
    category: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
}

export interface JbfoPageBodyFaqPrimary {
    __typename?: 'JbfoPageBodyFaqPrimary'
    style: Maybe<Scalars['String']>
    emptyCategoryText: Maybe<Scalars['String']>
}

export interface JbfoPageBodyHero {
    __typename?: 'JbfoPageBodyHero'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<JbfoPageBodyHeroPrimary>
}

export interface JbfoPageBodyHeroPrimary {
    __typename?: 'JbfoPageBodyHeroPrimary'
    fullWidth: Maybe<Scalars['Boolean']>
    size: Maybe<Scalars['String']>
    headingfuss: Maybe<Scalars['Boolean']>
    header: Maybe<Scalars['String']>
    headingVariant: Maybe<Scalars['String']>
    headingElement: Maybe<Scalars['String']>
    subheading: Maybe<Scalars['String']>
    subheadingVariant: Maybe<Scalars['String']>
    subheadingElement: Maybe<Scalars['String']>
    videoId: Maybe<Scalars['String']>
    mobileVideoId: Maybe<Scalars['String']>
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
}

export interface JbfoPageBodyHtml {
    __typename?: 'JbfoPageBodyHtml'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<JbfoPageBodyHtmlPrimary>
}

export interface JbfoPageBodyHtmlPrimary {
    __typename?: 'JbfoPageBodyHtmlPrimary'
    style: Maybe<Scalars['String']>
    html: Maybe<Scalars['Json']>
}

export interface JbfoPageBodyImageOnly {
    __typename?: 'JbfoPageBodyImageOnly'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<JbfoPageBodyImageOnlyPrimary>
}

export interface JbfoPageBodyImageOnlyPrimary {
    __typename?: 'JbfoPageBodyImageOnlyPrimary'
    style: Maybe<Scalars['String']>
    tablet: Maybe<Scalars['String']>
    desktop: Maybe<Scalars['String']>
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
}

export interface JbfoPageBodyProductVideoHero {
    __typename?: 'JbfoPageBodyProductVideoHero'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<JbfoPageBodyProductVideoHeroPrimary>
}

export interface JbfoPageBodyProductVideoHeroPrimary {
    __typename?: 'JbfoPageBodyProductVideoHeroPrimary'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    content: Maybe<Scalars['String']>
    subheading: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
    button2Url: Maybe<Scalars['String']>
    button2Text: Maybe<Scalars['String']>
    buttonStyle: Maybe<Scalars['String']>
    textStyle: Maybe<Scalars['String']>
    subheadingStyle: Maybe<Scalars['String']>
    videoId: Maybe<Scalars['String']>
    mobileVideoId: Maybe<Scalars['String']>
    productSkus: Maybe<Scalars['String']>
    productCueTimes: Maybe<Scalars['String']>
}

export interface JbfoPageBodyStore {
    __typename?: 'JbfoPageBodyStore'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<JbfoPageBodyStorePrimary>
    fields: Maybe<Array<JbfoPageBodyStoreFields>>
}

export interface JbfoPageBodyStoreFields {
    __typename?: 'JbfoPageBodyStoreFields'
    image: Maybe<PrismicImageType>
    mobileimage: Maybe<PrismicImageType>
    url: Maybe<Scalars['String']>
    name: Maybe<Scalars['String']>
    address: Maybe<Scalars['String']>
}

export interface JbfoPageBodyStorePrimary {
    __typename?: 'JbfoPageBodyStorePrimary'
    buttontext: Maybe<Scalars['String']>
    buttonurl: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    subheading: Maybe<Scalars['String']>
}

export interface JbfoPageBodyTeam {
    __typename?: 'JbfoPageBodyTeam'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<JbfoPageBodyTeamPrimary>
    fields: Maybe<Array<JbfoPageBodyTeamFields>>
}

export interface JbfoPageBodyTeamFields {
    __typename?: 'JbfoPageBodyTeamFields'
    bigimage: Maybe<PrismicImageType>
    bigmobileimage: Maybe<PrismicImageType>
    smallimage: Maybe<PrismicImageType>
    portraitimage: Maybe<PrismicImageType>
    firstname: Maybe<Scalars['String']>
    fullname: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    jobtitle: Maybe<Scalars['String']>
    productid: Maybe<Scalars['String']>
    quote: Maybe<Scalars['String']>
    subheading: Maybe<Scalars['String']>
}

export interface JbfoPageBodyTeamPrimary {
    __typename?: 'JbfoPageBodyTeamPrimary'
    heading: Maybe<Scalars['String']>
    subheading: Maybe<Scalars['String']>
}

export interface JbfoPageBodyTextOnly {
    __typename?: 'JbfoPageBodyTextOnly'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<JbfoPageBodyTextOnlyPrimary>
}

export interface JbfoPageBodyTextOnlyPrimary {
    __typename?: 'JbfoPageBodyTextOnlyPrimary'
    style: Maybe<Scalars['String']>
    visibility: Maybe<Scalars['String']>
    tablet: Maybe<Scalars['String']>
    desktop: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    headingVariant: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
    blockBackground: Maybe<Scalars['Json']>
    blockBackgroundColor: Maybe<Scalars['String']>
}

export interface JbfoPageBodyTextWithMedia {
    __typename?: 'JbfoPageBodyTextWithMedia'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<JbfoPageBodyTextWithMediaPrimary>
    fields: Maybe<Array<JbfoPageBodyTextWithMediaFields>>
}

export interface JbfoPageBodyTextWithMediaFields {
    __typename?: 'JbfoPageBodyTextWithMediaFields'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
}

export interface JbfoPageBodyTextWithMediaPrimary {
    __typename?: 'JbfoPageBodyTextWithMediaPrimary'
    style: Maybe<Scalars['String']>
    desktopMediaPosition: Maybe<Scalars['String']>
    mobileMediaPosition: Maybe<Scalars['String']>
    alignment: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    headingfuss: Maybe<Scalars['Boolean']>
    headingVariant: Maybe<Scalars['String']>
    headingElement: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
    buttonStyle: Maybe<Scalars['String']>
    button2Url: Maybe<Scalars['String']>
    button2Text: Maybe<Scalars['String']>
    button2Style: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
    videoId: Maybe<Scalars['String']>
    mobileVideoId: Maybe<Scalars['String']>
    blockBackground: Maybe<Scalars['Json']>
    blockBackgroundColor: Maybe<Scalars['String']>
}

export interface JbfoPageBodyTileSlider {
    __typename?: 'JbfoPageBodyTileSlider'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<JbfoPageBodyTileSliderPrimary>
    fields: Maybe<Array<JbfoPageBodyTileSliderFields>>
}

export interface JbfoPageBodyTileSliderFields {
    __typename?: 'JbfoPageBodyTileSliderFields'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    subtitle: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

export interface JbfoPageBodyTileSliderPrimary {
    __typename?: 'JbfoPageBodyTileSliderPrimary'
    style: Maybe<Scalars['String']>
    variant: Maybe<Scalars['String']>
    header: Maybe<Scalars['String']>
    content: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

export interface JbfoPageBodyTweakwiseSlider {
    __typename?: 'JbfoPageBodyTweakwiseSlider'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<JbfoPageBodyTweakwiseSliderPrimary>
}

export interface JbfoPageBodyTweakwiseSliderPrimary {
    __typename?: 'JbfoPageBodyTweakwiseSliderPrimary'
    blueconicPosition: Maybe<Scalars['String']>
    style: Maybe<Scalars['String']>
    header: Maybe<Scalars['String']>
    content: Maybe<Scalars['String']>
    recommendationsIdentifier: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

/** A connection to a list of items. */
export interface JbfoPageConnectionConnection {
    __typename?: 'JbfoPageConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<JbfoPageConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface JbfoPageConnectionEdge {
    __typename?: 'JbfoPageConnectionEdge'
    /** The item at the end of the edge. */
    node: JbfoPage
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

export interface JustbrandsFaqPage extends Document, Linkable {
    __typename?: 'JustbrandsFaqPage'
    urlKey: Maybe<Scalars['String']>
    search: Maybe<Scalars['Boolean']>
    seoTitle: Maybe<Scalars['String']>
    seoDescription: Maybe<Scalars['String']>
    seoKeywords: Maybe<Scalars['String']>
    style: Maybe<Scalars['String']>
    head: Maybe<Scalars['Json']>
    noindex: Maybe<Scalars['Boolean']>
    nofollow: Maybe<Scalars['Boolean']>
    body: Maybe<Array<JustbrandsFaqPageBody>>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

export type JustbrandsFaqPageBody =
    | JustbrandsFaqPageBodyHero
    | JustbrandsFaqPageBodyFaqSubject

export interface JustbrandsFaqPageBodyFaqSubject {
    __typename?: 'JustbrandsFaqPageBodyFaqSubject'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<JustbrandsFaqPageBodyFaqSubjectPrimary>
    fields: Maybe<Array<JustbrandsFaqPageBodyFaqSubjectFields>>
}

export interface JustbrandsFaqPageBodyFaqSubjectFields {
    __typename?: 'JustbrandsFaqPageBodyFaqSubjectFields'
    heading: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
}

export interface JustbrandsFaqPageBodyFaqSubjectPrimary {
    __typename?: 'JustbrandsFaqPageBodyFaqSubjectPrimary'
    urlKey: Maybe<Scalars['String']>
    seoTitle: Maybe<Scalars['String']>
    seoKeywords: Maybe<Scalars['String']>
    seoDescription: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    icon: Maybe<PrismicImageType>
}

export interface JustbrandsFaqPageBodyHero {
    __typename?: 'JustbrandsFaqPageBodyHero'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<JustbrandsFaqPageBodyHeroPrimary>
}

export interface JustbrandsFaqPageBodyHeroPrimary {
    __typename?: 'JustbrandsFaqPageBodyHeroPrimary'
    fullWidth: Maybe<Scalars['Boolean']>
    size: Maybe<Scalars['String']>
    headingfuss: Maybe<Scalars['Boolean']>
    header: Maybe<Scalars['String']>
    headingVariant: Maybe<Scalars['String']>
    headingElement: Maybe<Scalars['String']>
    subheading: Maybe<Scalars['String']>
    subheadingVariant: Maybe<Scalars['String']>
    subheadingElement: Maybe<Scalars['String']>
    videoId: Maybe<Scalars['String']>
    mobileVideoId: Maybe<Scalars['String']>
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
}

/** A connection to a list of items. */
export interface JustbrandsFaqPageConnectionConnection {
    __typename?: 'JustbrandsFaqPageConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<JustbrandsFaqPageConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface JustbrandsFaqPageConnectionEdge {
    __typename?: 'JustbrandsFaqPageConnectionEdge'
    /** The item at the end of the edge. */
    node: JustbrandsFaqPage
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

export interface JustBrandsLogin extends Document, Linkable {
    __typename?: 'JustBrandsLogin'
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

/** A connection to a list of items. */
export interface JustBrandsLoginConnectionConnection {
    __typename?: 'JustBrandsLoginConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<JustBrandsLoginConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface JustBrandsLoginConnectionEdge {
    __typename?: 'JustBrandsLoginConnectionEdge'
    /** The item at the end of the edge. */
    node: JustBrandsLogin
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

export interface JustbrandsPage extends Document, Linkable {
    __typename?: 'JustbrandsPage'
    urlKey: Maybe<Scalars['String']>
    search: Maybe<Scalars['Boolean']>
    seoTitle: Maybe<Scalars['String']>
    seoDescription: Maybe<Scalars['String']>
    seoKeywords: Maybe<Scalars['String']>
    style: Maybe<Scalars['String']>
    head: Maybe<Scalars['Json']>
    noindex: Maybe<Scalars['Boolean']>
    nofollow: Maybe<Scalars['Boolean']>
    authRequired: Maybe<Scalars['Boolean']>
    pageType: Maybe<Scalars['String']>
    storyTag: Maybe<PrismicHyperlink>
    storyDescription: Maybe<Scalars['String']>
    storyTitle: Maybe<Scalars['String']>
    publicationField: Maybe<Scalars['Date']>
    thumbnailImage: Maybe<PrismicImageType>
    pageBackground: Maybe<Scalars['Json']>
    pageBackgroundColor: Maybe<Scalars['String']>
    body: Maybe<Array<JustbrandsPageBody>>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

export type JustbrandsPageBody =
    | JustbrandsPageBodyProductHero
    | JustbrandsPageBodyTweakwiseSlider
    | JustbrandsPageBodyImageSlider
    | JustbrandsPageBodyHero
    | JustbrandsPageBodyTextOnly
    | JustbrandsPageBodyImageOnly
    | JustbrandsPageBodyTextWithMedia
    | JustbrandsPageBodyHtml
    | JustbrandsPageBodyBrand
    | JustbrandsPageBodyTeam
    | JustbrandsPageBodyStore
    | JustbrandsPageBodyCategoryElement
    | JustbrandsPageBodyFaq
    | JustbrandsPageBodyBannerBlocks
    | JustbrandsPageBodyBannerBlocks1
    | JustbrandsPageBodyBannerBlocks2
    | JustbrandsPageBodyCategoryList
    | JustbrandsPageBodyStoriesOverviewHeader
    | JustbrandsPageBodyStoryOverviewPage
    | JustbrandsPageBodyVisualFilter
    | JustbrandsPageBodyForm
    | JustbrandsPageBodyBlog

export interface JustbrandsPageBodyBannerBlocks {
    __typename?: 'JustbrandsPageBodyBannerBlocks'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<JustbrandsPageBodyBannerBlocksPrimary>
    fields: Maybe<Array<JustbrandsPageBodyBannerBlocksFields>>
}

export interface JustbrandsPageBodyBannerBlocks1 {
    __typename?: 'JustbrandsPageBodyBannerBlocks1'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<JustbrandsPageBodyBannerBlocks1Primary>
    fields: Maybe<Array<JustbrandsPageBodyBannerBlocks1Fields>>
}

export interface JustbrandsPageBodyBannerBlocks1Fields {
    __typename?: 'JustbrandsPageBodyBannerBlocks1Fields'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    subtitle: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

export interface JustbrandsPageBodyBannerBlocks1Primary {
    __typename?: 'JustbrandsPageBodyBannerBlocks1Primary'
    style: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
}

export interface JustbrandsPageBodyBannerBlocks2 {
    __typename?: 'JustbrandsPageBodyBannerBlocks2'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<JustbrandsPageBodyBannerBlocks2Primary>
    fields: Maybe<Array<JustbrandsPageBodyBannerBlocks2Fields>>
}

export interface JustbrandsPageBodyBannerBlocks2Fields {
    __typename?: 'JustbrandsPageBodyBannerBlocks2Fields'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    subtitle: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

export interface JustbrandsPageBodyBannerBlocks2Primary {
    __typename?: 'JustbrandsPageBodyBannerBlocks2Primary'
    style: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
}

export interface JustbrandsPageBodyBannerBlocksFields {
    __typename?: 'JustbrandsPageBodyBannerBlocksFields'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
}

export interface JustbrandsPageBodyBannerBlocksPrimary {
    __typename?: 'JustbrandsPageBodyBannerBlocksPrimary'
    style: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
}

export interface JustbrandsPageBodyBlog {
    __typename?: 'JustbrandsPageBodyBlog'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<JustbrandsPageBodyBlogPrimary>
    fields: Maybe<Array<JustbrandsPageBodyBlogFields>>
}

export interface JustbrandsPageBodyBlogFields {
    __typename?: 'JustbrandsPageBodyBlogFields'
    desktopImage: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    header: Maybe<Scalars['String']>
    subHeader: Maybe<Scalars['String']>
    blogText: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
}

export interface JustbrandsPageBodyBlogPrimary {
    __typename?: 'JustbrandsPageBodyBlogPrimary'
    title: Maybe<Scalars['String']>
    blogStyle: Maybe<Scalars['String']>
}

export interface JustbrandsPageBodyBrand {
    __typename?: 'JustbrandsPageBodyBrand'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<JustbrandsPageBodyBrandPrimary>
    fields: Maybe<Array<JustbrandsPageBodyBrandFields>>
}

export interface JustbrandsPageBodyBrandFields {
    __typename?: 'JustbrandsPageBodyBrandFields'
    desktopLogoImage: Maybe<PrismicImageType>
    mobileLogoImage: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    image: Maybe<PrismicImageType>
    brandId: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
    content: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

export interface JustbrandsPageBodyBrandPrimary {
    __typename?: 'JustbrandsPageBodyBrandPrimary'
    style: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    content: Maybe<Scalars['String']>
}

export interface JustbrandsPageBodyCategoryElement {
    __typename?: 'JustbrandsPageBodyCategoryElement'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<JustbrandsPageBodyCategoryElementPrimary>
    fields: Maybe<Array<JustbrandsPageBodyCategoryElementFields>>
}

export interface JustbrandsPageBodyCategoryElementFields {
    __typename?: 'JustbrandsPageBodyCategoryElementFields'
    title: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
}

export interface JustbrandsPageBodyCategoryElementPrimary {
    __typename?: 'JustbrandsPageBodyCategoryElementPrimary'
    style: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
}

export interface JustbrandsPageBodyCategoryList {
    __typename?: 'JustbrandsPageBodyCategoryList'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<JustbrandsPageBodyCategoryListPrimary>
    fields: Maybe<Array<JustbrandsPageBodyCategoryListFields>>
}

export interface JustbrandsPageBodyCategoryListFields {
    __typename?: 'JustbrandsPageBodyCategoryListFields'
    title: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
    list: Maybe<Scalars['String']>
}

export interface JustbrandsPageBodyCategoryListPrimary {
    __typename?: 'JustbrandsPageBodyCategoryListPrimary'
    style: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    title1: Maybe<Scalars['String']>
    title2: Maybe<Scalars['String']>
    title3: Maybe<Scalars['String']>
    title4: Maybe<Scalars['String']>
}

export interface JustbrandsPageBodyFaq {
    __typename?: 'JustbrandsPageBodyFaq'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<JustbrandsPageBodyFaqPrimary>
    fields: Maybe<Array<JustbrandsPageBodyFaqFields>>
}

export interface JustbrandsPageBodyFaqFields {
    __typename?: 'JustbrandsPageBodyFaqFields'
    iconimage: Maybe<PrismicImageType>
    category: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
}

export interface JustbrandsPageBodyFaqPrimary {
    __typename?: 'JustbrandsPageBodyFaqPrimary'
    style: Maybe<Scalars['String']>
    emptyCategoryText: Maybe<Scalars['String']>
}

export interface JustbrandsPageBodyForm {
    __typename?: 'JustbrandsPageBodyForm'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<JustbrandsPageBodyFormPrimary>
}

export interface JustbrandsPageBodyFormPrimary {
    __typename?: 'JustbrandsPageBodyFormPrimary'
    formId: Maybe<Scalars['String']>
    formHeader: Maybe<Scalars['String']>
    formSubheader: Maybe<Scalars['String']>
    buttonSubmitLabel: Maybe<Scalars['String']>
}

export interface JustbrandsPageBodyHero {
    __typename?: 'JustbrandsPageBodyHero'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<JustbrandsPageBodyHeroPrimary>
}

export interface JustbrandsPageBodyHeroPrimary {
    __typename?: 'JustbrandsPageBodyHeroPrimary'
    fullWidth: Maybe<Scalars['Boolean']>
    size: Maybe<Scalars['String']>
    headingfuss: Maybe<Scalars['Boolean']>
    header: Maybe<Scalars['String']>
    headingVariant: Maybe<Scalars['String']>
    headingElement: Maybe<Scalars['String']>
    subheading: Maybe<Scalars['String']>
    subheadingVariant: Maybe<Scalars['String']>
    subheadingElement: Maybe<Scalars['String']>
    videoId: Maybe<Scalars['String']>
    mobileVideoId: Maybe<Scalars['String']>
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
}

export interface JustbrandsPageBodyHtml {
    __typename?: 'JustbrandsPageBodyHtml'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<JustbrandsPageBodyHtmlPrimary>
}

export interface JustbrandsPageBodyHtmlPrimary {
    __typename?: 'JustbrandsPageBodyHtmlPrimary'
    style: Maybe<Scalars['String']>
    html: Maybe<Scalars['Json']>
    tablet: Maybe<Scalars['String']>
    desktop: Maybe<Scalars['String']>
}

export interface JustbrandsPageBodyImageOnly {
    __typename?: 'JustbrandsPageBodyImageOnly'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<JustbrandsPageBodyImageOnlyPrimary>
}

export interface JustbrandsPageBodyImageOnlyPrimary {
    __typename?: 'JustbrandsPageBodyImageOnlyPrimary'
    style: Maybe<Scalars['String']>
    tablet: Maybe<Scalars['String']>
    desktop: Maybe<Scalars['String']>
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
}

export interface JustbrandsPageBodyImageSlider {
    __typename?: 'JustbrandsPageBodyImageSlider'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<JustbrandsPageBodyImageSliderPrimary>
    fields: Maybe<Array<JustbrandsPageBodyImageSliderFields>>
}

export interface JustbrandsPageBodyImageSliderFields {
    __typename?: 'JustbrandsPageBodyImageSliderFields'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    subtitle: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

export interface JustbrandsPageBodyImageSliderPrimary {
    __typename?: 'JustbrandsPageBodyImageSliderPrimary'
    style: Maybe<Scalars['String']>
    variant: Maybe<Scalars['String']>
    header: Maybe<Scalars['String']>
    content: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

export interface JustbrandsPageBodyProductHero {
    __typename?: 'JustbrandsPageBodyProductHero'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<JustbrandsPageBodyProductHeroPrimary>
}

export interface JustbrandsPageBodyProductHeroPrimary {
    __typename?: 'JustbrandsPageBodyProductHeroPrimary'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    content: Maybe<Scalars['String']>
    subheading: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
    button2Url: Maybe<Scalars['String']>
    button2Text: Maybe<Scalars['String']>
    buttonStyle: Maybe<Scalars['String']>
    textStyle: Maybe<Scalars['String']>
    subheadingStyle: Maybe<Scalars['String']>
    videoId: Maybe<Scalars['String']>
    mobileVideoId: Maybe<Scalars['String']>
    productSkus: Maybe<Scalars['String']>
    productCueTimes: Maybe<Scalars['String']>
}

export interface JustbrandsPageBodyStore {
    __typename?: 'JustbrandsPageBodyStore'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<JustbrandsPageBodyStorePrimary>
    fields: Maybe<Array<JustbrandsPageBodyStoreFields>>
}

export interface JustbrandsPageBodyStoreFields {
    __typename?: 'JustbrandsPageBodyStoreFields'
    image: Maybe<PrismicImageType>
    mobileimage: Maybe<PrismicImageType>
    url: Maybe<Scalars['String']>
    name: Maybe<Scalars['String']>
    address: Maybe<Scalars['String']>
}

export interface JustbrandsPageBodyStorePrimary {
    __typename?: 'JustbrandsPageBodyStorePrimary'
    buttontext: Maybe<Scalars['String']>
    buttonurl: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    subheading: Maybe<Scalars['String']>
}

export interface JustbrandsPageBodyStoriesOverviewHeader {
    __typename?: 'JustbrandsPageBodyStoriesOverviewHeader'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    fields: Maybe<Array<JustbrandsPageBodyStoriesOverviewHeaderFields>>
}

export interface JustbrandsPageBodyStoriesOverviewHeaderFields {
    __typename?: 'JustbrandsPageBodyStoriesOverviewHeaderFields'
    desktopImage: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    subtitle: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

export interface JustbrandsPageBodyStoryOverviewPage {
    __typename?: 'JustbrandsPageBodyStoryOverviewPage'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<JustbrandsPageBodyStoryOverviewPagePrimary>
}

export interface JustbrandsPageBodyStoryOverviewPagePrimary {
    __typename?: 'JustbrandsPageBodyStoryOverviewPagePrimary'
    showStories: Maybe<Scalars['Boolean']>
}

export interface JustbrandsPageBodyTeam {
    __typename?: 'JustbrandsPageBodyTeam'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<JustbrandsPageBodyTeamPrimary>
    fields: Maybe<Array<JustbrandsPageBodyTeamFields>>
}

export interface JustbrandsPageBodyTeamFields {
    __typename?: 'JustbrandsPageBodyTeamFields'
    bigimage: Maybe<PrismicImageType>
    bigmobileimage: Maybe<PrismicImageType>
    smallimage: Maybe<PrismicImageType>
    portraitimage: Maybe<PrismicImageType>
    firstname: Maybe<Scalars['String']>
    fullname: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    jobtitle: Maybe<Scalars['String']>
    productid: Maybe<Scalars['String']>
    quote: Maybe<Scalars['String']>
    subheading: Maybe<Scalars['String']>
}

export interface JustbrandsPageBodyTeamPrimary {
    __typename?: 'JustbrandsPageBodyTeamPrimary'
    heading: Maybe<Scalars['String']>
    subheading: Maybe<Scalars['String']>
}

export interface JustbrandsPageBodyTextOnly {
    __typename?: 'JustbrandsPageBodyTextOnly'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<JustbrandsPageBodyTextOnlyPrimary>
}

export interface JustbrandsPageBodyTextOnlyPrimary {
    __typename?: 'JustbrandsPageBodyTextOnlyPrimary'
    style: Maybe<Scalars['String']>
    visibility: Maybe<Scalars['String']>
    tablet: Maybe<Scalars['String']>
    desktop: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    headingVariant: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
    buttonText: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    blockBackground: Maybe<Scalars['Json']>
    blockBackgroundColor: Maybe<Scalars['String']>
}

export interface JustbrandsPageBodyTextWithMedia {
    __typename?: 'JustbrandsPageBodyTextWithMedia'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<JustbrandsPageBodyTextWithMediaPrimary>
    fields: Maybe<Array<JustbrandsPageBodyTextWithMediaFields>>
}

export interface JustbrandsPageBodyTextWithMediaFields {
    __typename?: 'JustbrandsPageBodyTextWithMediaFields'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
}

export interface JustbrandsPageBodyTextWithMediaPrimary {
    __typename?: 'JustbrandsPageBodyTextWithMediaPrimary'
    style: Maybe<Scalars['String']>
    desktopMediaPosition: Maybe<Scalars['String']>
    mobileMediaPosition: Maybe<Scalars['String']>
    alignment: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    headingfuss: Maybe<Scalars['Boolean']>
    headingVariant: Maybe<Scalars['String']>
    headingElement: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
    buttonStyle: Maybe<Scalars['String']>
    button2Url: Maybe<Scalars['String']>
    button2Text: Maybe<Scalars['String']>
    button2Style: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
    videoId: Maybe<Scalars['String']>
    mobileVideoId: Maybe<Scalars['String']>
    blockBackground: Maybe<Scalars['Json']>
    blockBackgroundColor: Maybe<Scalars['String']>
}

export interface JustbrandsPageBodyTweakwiseSlider {
    __typename?: 'JustbrandsPageBodyTweakwiseSlider'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<JustbrandsPageBodyTweakwiseSliderPrimary>
}

export interface JustbrandsPageBodyTweakwiseSliderPrimary {
    __typename?: 'JustbrandsPageBodyTweakwiseSliderPrimary'
    blueconicPosition: Maybe<Scalars['String']>
    style: Maybe<Scalars['String']>
    header: Maybe<Scalars['String']>
    content: Maybe<Scalars['String']>
    recommendationsIdentifier: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

export interface JustbrandsPageBodyVisualFilter {
    __typename?: 'JustbrandsPageBodyVisualFilter'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<JustbrandsPageBodyVisualFilterPrimary>
}

export interface JustbrandsPageBodyVisualFilterPrimary {
    __typename?: 'JustbrandsPageBodyVisualFilterPrimary'
    visualFilter: Maybe<PrismicHyperlink>
}

/** A connection to a list of items. */
export interface JustbrandsPageConnectionConnection {
    __typename?: 'JustbrandsPageConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<JustbrandsPageConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface JustbrandsPageConnectionEdge {
    __typename?: 'JustbrandsPageConnectionEdge'
    /** The item at the end of the edge. */
    node: JustbrandsPage
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

export interface JustbrandsTag extends Document, Linkable {
    __typename?: 'JustbrandsTag'
    tagId: Maybe<Scalars['String']>
    tagName: Maybe<Scalars['String']>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

/** A connection to a list of items. */
export interface JustbrandsTagConnectionConnection {
    __typename?: 'JustbrandsTagConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<JustbrandsTagConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface JustbrandsTagConnectionEdge {
    __typename?: 'JustbrandsTagConnectionEdge'
    /** The item at the end of the edge. */
    node: JustbrandsTag
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

/** Contains a key-value pair. */
export interface KeyValue {
    __typename?: 'KeyValue'
    /** The name part of the key/value pair. */
    name: Maybe<Scalars['String']>
    /** The value part of the key/value pair. */
    value: Maybe<Scalars['String']>
}

/** Contains information for rendering layered navigation. */
export interface LayerFilter {
    __typename?: 'LayerFilter'
    /**
     * An array of filter items.
     * @deprecated Use `Aggregation.options` instead.
     */
    filterItems: Maybe<Array<Maybe<LayerFilterItemInterface>>>
    /**
     * The count of filter items in filter group.
     * @deprecated Use `Aggregation.count` instead.
     */
    filterItemsCount: Maybe<Scalars['Int']>
    /**
     * The name of a layered navigation filter.
     * @deprecated Use `Aggregation.label` instead.
     */
    name: Maybe<Scalars['String']>
    /**
     * The request variable name for a filter query.
     * @deprecated Use `Aggregation.attribute_code` instead.
     */
    requestVar: Maybe<Scalars['String']>
}

export interface LayerFilterItem extends LayerFilterItemInterface {
    __typename?: 'LayerFilterItem'
    /**
     * The count of items per filter.
     * @deprecated Use `AggregationOption.count` instead.
     */
    itemsCount: Maybe<Scalars['Int']>
    /**
     * The label for a filter.
     * @deprecated Use `AggregationOption.label` instead.
     */
    label: Maybe<Scalars['String']>
    /**
     * The value of a filter request variable to be used in query.
     * @deprecated Use `AggregationOption.value` instead.
     */
    valueString: Maybe<Scalars['String']>
}

export interface LayerFilterItemInterface {
    /**
     * The count of items per filter.
     * @deprecated Use `AggregationOption.count` instead.
     */
    itemsCount: Maybe<Scalars['Int']>
    /**
     * The label for a filter.
     * @deprecated Use `AggregationOption.label` instead.
     */
    label: Maybe<Scalars['String']>
    /**
     * The value of a filter request variable to be used in query.
     * @deprecated Use `AggregationOption.value` instead.
     */
    valueString: Maybe<Scalars['String']>
}

/** A prismic link */
export interface Linkable {
    linkType: Maybe<Scalars['String']>
}

export interface LoyaltyCard extends Document, Linkable {
    __typename?: 'LoyaltyCard'
    style: Maybe<Scalars['String']>
    header: Maybe<Scalars['String']>
    buttonLabel: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonStyle: Maybe<Scalars['String']>
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    usp: Maybe<PrismicHyperlink>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

/** A connection to a list of items. */
export interface LoyaltyCardConnectionConnection {
    __typename?: 'LoyaltyCardConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<LoyaltyCardConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface LoyaltyCardConnectionEdge {
    __typename?: 'LoyaltyCardConnectionEdge'
    /** The item at the end of the edge. */
    node: LoyaltyCard
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

export interface MatchedSubstring {
    __typename?: 'MatchedSubstring'
    length: Scalars['Int']
    offset: Scalars['Int']
}

/** Contains a link to a video file and basic information about the video. */
export interface MaveData {
    __typename?: 'MaveData'
    /** Mave ID */
    id: Maybe<Scalars['String']>
    /** Orientation of the video */
    orientation: Maybe<Scalars['String']>
    /** Available qualities of the video */
    qualities: Maybe<Array<Maybe<Scalars['String']>>>
}

/** Defines characteristics about images and videos associated with a specific product. */
export interface MediaGalleryEntry {
    __typename?: 'MediaGalleryEntry'
    /** Details about the content of the media gallery item. */
    content: Maybe<ProductMediaGalleryEntriesContent>
    /** Indicates whether the image is hidden from view. */
    disabled: Maybe<Scalars['Boolean']>
    /** The path of the image on the server. */
    file: Maybe<Scalars['String']>
    /**
     * The identifier assigned to the object.
     * @deprecated Use `uid` instead.
     */
    id: Maybe<Scalars['Int']>
    /** The alt text displayed on the storefront when the user points to the image. */
    label: Maybe<Scalars['String']>
    /** Additional Mave data about the video */
    mave: Maybe<MaveData>
    /** Either `image` or `video`. */
    mediaType: Maybe<Scalars['String']>
    /** The media item's position after it has been sorted. */
    position: Maybe<Scalars['Int']>
    /** Array of image types. It can have the following values: image, small_image, thumbnail. */
    types: Maybe<Array<Maybe<Scalars['String']>>>
    /** The unique ID for a `MediaGalleryEntry` object. */
    uid: Scalars['ID']
    /** Details about the content of a video item. */
    videoContent: Maybe<ProductMediaGalleryEntriesVideoContent>
}

/** Contains basic information about a product image or video. */
export interface MediaGalleryInterface {
    /** Indicates whether the image is hidden from view. */
    disabled: Maybe<Scalars['Boolean']>
    /** The label of the product image or video. */
    label: Maybe<Scalars['String']>
    /** The media item's position after it has been sorted. */
    position: Maybe<Scalars['Int']>
    /** The URL of the product image or video. */
    url: Maybe<Scalars['String']>
}

export interface MemberUsp extends Document, Linkable {
    __typename?: 'MemberUsp'
    storeview: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    headingVariant: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    usps: Maybe<Array<MemberUspUsps>>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

/** A connection to a list of items. */
export interface MemberUspConnectionConnection {
    __typename?: 'MemberUspConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<MemberUspConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface MemberUspConnectionEdge {
    __typename?: 'MemberUspConnectionEdge'
    /** The item at the end of the edge. */
    node: MemberUsp
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

export interface MemberUsps extends Document, Linkable {
    __typename?: 'MemberUsps'
    storeview: Maybe<Scalars['String']>
    type: Maybe<Scalars['String']>
    showPriviligesLogo: Maybe<Scalars['Boolean']>
    heading: Maybe<Scalars['String']>
    headingVariant: Maybe<Scalars['String']>
    content: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonStyle: Maybe<Scalars['String']>
    button2Text: Maybe<Scalars['String']>
    button2Url: Maybe<Scalars['String']>
    button2Style: Maybe<Scalars['String']>
    mobile: Maybe<Scalars['String']>
    tablet: Maybe<Scalars['String']>
    desktop: Maybe<Scalars['String']>
    usps: Maybe<Array<MemberUspsUsps>>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

/** A connection to a list of items. */
export interface MemberUspsConnectionConnection {
    __typename?: 'MemberUspsConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<MemberUspsConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface MemberUspsConnectionEdge {
    __typename?: 'MemberUspsConnectionEdge'
    /** The item at the end of the edge. */
    node: MemberUsps
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

export interface MemberUspsUsps {
    __typename?: 'MemberUspsUsps'
    label: Maybe<Scalars['String']>
    icon: Maybe<Scalars['String']>
}

export interface MemberUspUsps {
    __typename?: 'MemberUspUsps'
    label: Maybe<Scalars['String']>
    icon: Maybe<Scalars['String']>
}

export interface MergeWishlistsOutput {
    __typename?: 'MergeWishlistsOutput'
    /** An array of errors encountered while merging the wish lists */
    userErrors: Array<Maybe<WishListUserInputError>>
    /** Contains the destination wish list */
    wishlist: Wishlist
}

export interface Meta {
    __typename?: 'Meta'
    /** The id of the document. */
    id: Scalars['String']
    /** The uid of the document. */
    uid: Maybe<Scalars['String']>
    /** The type of the document. */
    type: Scalars['String']
    /** The tags of the document. */
    tags: Array<Scalars['String']>
    /** The language of the document. */
    lang: Scalars['String']
    /** Alternate languages the document. */
    alternateLanguages: Array<RelatedDocument>
    /** The first publication date of the document. */
    firstPublicationDate: Maybe<Scalars['DateTime']>
    /** The last publication date of the document. */
    lastPublicationDate: Maybe<Scalars['DateTime']>
}

/** Defines a monetary value, including a numeric value and a currency code. */
export interface Money {
    __typename?: 'Money'
    /** A three-letter currency code, such as USD or EUR. */
    currency: CurrencyEnum
    /** A number expressing a monetary value. */
    value: Scalars['Float']
}

export interface MonstersaleFaqPage extends Document, Linkable {
    __typename?: 'MonstersaleFaqPage'
    urlKey: Maybe<Scalars['String']>
    search: Maybe<Scalars['Boolean']>
    seoTitle: Maybe<Scalars['String']>
    seoDescription: Maybe<Scalars['String']>
    seoKeywords: Maybe<Scalars['String']>
    style: Maybe<Scalars['String']>
    head: Maybe<Scalars['Json']>
    noindex: Maybe<Scalars['Boolean']>
    nofollow: Maybe<Scalars['Boolean']>
    body: Maybe<Array<MonstersaleFaqPageBody>>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

export type MonstersaleFaqPageBody =
    | MonstersaleFaqPageBodyHero
    | MonstersaleFaqPageBodyFaqSubject

export interface MonstersaleFaqPageBodyFaqSubject {
    __typename?: 'MonstersaleFaqPageBodyFaqSubject'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<MonstersaleFaqPageBodyFaqSubjectPrimary>
    fields: Maybe<Array<MonstersaleFaqPageBodyFaqSubjectFields>>
}

export interface MonstersaleFaqPageBodyFaqSubjectFields {
    __typename?: 'MonstersaleFaqPageBodyFaqSubjectFields'
    heading: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
}

export interface MonstersaleFaqPageBodyFaqSubjectPrimary {
    __typename?: 'MonstersaleFaqPageBodyFaqSubjectPrimary'
    urlKey: Maybe<Scalars['String']>
    seoTitle: Maybe<Scalars['String']>
    seoKeywords: Maybe<Scalars['String']>
    seoDescription: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    icon: Maybe<PrismicImageType>
}

export interface MonstersaleFaqPageBodyHero {
    __typename?: 'MonstersaleFaqPageBodyHero'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<MonstersaleFaqPageBodyHeroPrimary>
}

export interface MonstersaleFaqPageBodyHeroPrimary {
    __typename?: 'MonstersaleFaqPageBodyHeroPrimary'
    fullWidth: Maybe<Scalars['Boolean']>
    size: Maybe<Scalars['String']>
    headingfuss: Maybe<Scalars['Boolean']>
    header: Maybe<Scalars['String']>
    headingVariant: Maybe<Scalars['String']>
    headingElement: Maybe<Scalars['String']>
    subheading: Maybe<Scalars['String']>
    subheadingVariant: Maybe<Scalars['String']>
    subheadingElement: Maybe<Scalars['String']>
    videoId: Maybe<Scalars['String']>
    mobileVideoId: Maybe<Scalars['String']>
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
}

/** A connection to a list of items. */
export interface MonstersaleFaqPageConnectionConnection {
    __typename?: 'MonstersaleFaqPageConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<MonstersaleFaqPageConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface MonstersaleFaqPageConnectionEdge {
    __typename?: 'MonstersaleFaqPageConnectionEdge'
    /** The item at the end of the edge. */
    node: MonstersaleFaqPage
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

export interface MonstersaleLoginPage extends Document, Linkable {
    __typename?: 'MonstersaleLoginPage'
    urlKey: Maybe<Scalars['String']>
    search: Maybe<Scalars['Boolean']>
    seoTitle: Maybe<Scalars['String']>
    seoDescription: Maybe<Scalars['String']>
    seoKeywords: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
    subTitle: Maybe<Scalars['String']>
    subHeading: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
    backgroundImage: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    logos: Maybe<Array<MonstersaleLoginPageLogos>>
    body: Maybe<Array<MonstersaleLoginPageBody>>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

export type MonstersaleLoginPageBody = MonstersaleLoginPageBodyFaqSubject

export interface MonstersaleLoginPageBodyFaqSubject {
    __typename?: 'MonstersaleLoginPageBodyFaqSubject'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<MonstersaleLoginPageBodyFaqSubjectPrimary>
    fields: Maybe<Array<MonstersaleLoginPageBodyFaqSubjectFields>>
}

export interface MonstersaleLoginPageBodyFaqSubjectFields {
    __typename?: 'MonstersaleLoginPageBodyFaqSubjectFields'
    heading: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
}

export interface MonstersaleLoginPageBodyFaqSubjectPrimary {
    __typename?: 'MonstersaleLoginPageBodyFaqSubjectPrimary'
    urlKey: Maybe<Scalars['String']>
    seoTitle: Maybe<Scalars['String']>
    seoKeywords: Maybe<Scalars['String']>
    seoDescription: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    icon: Maybe<PrismicImageType>
}

/** A connection to a list of items. */
export interface MonstersaleLoginPageConnectionConnection {
    __typename?: 'MonstersaleLoginPageConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<MonstersaleLoginPageConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface MonstersaleLoginPageConnectionEdge {
    __typename?: 'MonstersaleLoginPageConnectionEdge'
    /** The item at the end of the edge. */
    node: MonstersaleLoginPage
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

export interface MonstersaleLoginPageLogos {
    __typename?: 'MonstersaleLoginPageLogos'
    logoImage: Maybe<PrismicImageType>
}

export interface MonstersalePage extends Document, Linkable {
    __typename?: 'MonstersalePage'
    urlKey: Maybe<Scalars['String']>
    search: Maybe<Scalars['Boolean']>
    seoTitle: Maybe<Scalars['String']>
    seoDescription: Maybe<Scalars['String']>
    seoKeywords: Maybe<Scalars['String']>
    style: Maybe<Scalars['String']>
    head: Maybe<Scalars['Json']>
    noindex: Maybe<Scalars['Boolean']>
    nofollow: Maybe<Scalars['Boolean']>
    authRequired: Maybe<Scalars['Boolean']>
    pageBackground: Maybe<Scalars['Json']>
    pageBackgroundColor: Maybe<Scalars['String']>
    body: Maybe<Array<MonstersalePageBody>>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

export type MonstersalePageBody =
    | MonstersalePageBodyHero
    | MonstersalePageBodyTextOnly
    | MonstersalePageBodyTextWithMedia
    | MonstersalePageBodyTweakwiseSlider
    | MonstersalePageBodyTileSlider
    | MonstersalePageBodyImageOnly
    | MonstersalePageBodyHtml
    | MonstersalePageBodyCategoryElement
    | MonstersalePageBodyProductVideoHero
    | MonstersalePageBodyFaq
    | MonstersalePageBodyBannerBlocks
    | MonstersalePageBodyBannerBlocks1
    | MonstersalePageBodyBannerBlocks2
    | MonstersalePageBodyCategoryList

export interface MonstersalePageBodyBannerBlocks {
    __typename?: 'MonstersalePageBodyBannerBlocks'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<MonstersalePageBodyBannerBlocksPrimary>
    fields: Maybe<Array<MonstersalePageBodyBannerBlocksFields>>
}

export interface MonstersalePageBodyBannerBlocks1 {
    __typename?: 'MonstersalePageBodyBannerBlocks1'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<MonstersalePageBodyBannerBlocks1Primary>
    fields: Maybe<Array<MonstersalePageBodyBannerBlocks1Fields>>
}

export interface MonstersalePageBodyBannerBlocks1Fields {
    __typename?: 'MonstersalePageBodyBannerBlocks1Fields'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    subtitle: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

export interface MonstersalePageBodyBannerBlocks1Primary {
    __typename?: 'MonstersalePageBodyBannerBlocks1Primary'
    style: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
    buttonStyle: Maybe<Scalars['String']>
}

export interface MonstersalePageBodyBannerBlocks2 {
    __typename?: 'MonstersalePageBodyBannerBlocks2'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<MonstersalePageBodyBannerBlocks2Primary>
    fields: Maybe<Array<MonstersalePageBodyBannerBlocks2Fields>>
}

export interface MonstersalePageBodyBannerBlocks2Fields {
    __typename?: 'MonstersalePageBodyBannerBlocks2Fields'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    subtitle: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

export interface MonstersalePageBodyBannerBlocks2Primary {
    __typename?: 'MonstersalePageBodyBannerBlocks2Primary'
    style: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
    buttonStyle: Maybe<Scalars['String']>
}

export interface MonstersalePageBodyBannerBlocksFields {
    __typename?: 'MonstersalePageBodyBannerBlocksFields'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
}

export interface MonstersalePageBodyBannerBlocksPrimary {
    __typename?: 'MonstersalePageBodyBannerBlocksPrimary'
    style: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
    buttonStyle: Maybe<Scalars['String']>
}

export interface MonstersalePageBodyCategoryElement {
    __typename?: 'MonstersalePageBodyCategoryElement'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<MonstersalePageBodyCategoryElementPrimary>
    fields: Maybe<Array<MonstersalePageBodyCategoryElementFields>>
}

export interface MonstersalePageBodyCategoryElementFields {
    __typename?: 'MonstersalePageBodyCategoryElementFields'
    title: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
}

export interface MonstersalePageBodyCategoryElementPrimary {
    __typename?: 'MonstersalePageBodyCategoryElementPrimary'
    style: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
}

export interface MonstersalePageBodyCategoryList {
    __typename?: 'MonstersalePageBodyCategoryList'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<MonstersalePageBodyCategoryListPrimary>
    fields: Maybe<Array<MonstersalePageBodyCategoryListFields>>
}

export interface MonstersalePageBodyCategoryListFields {
    __typename?: 'MonstersalePageBodyCategoryListFields'
    title: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
    list: Maybe<Scalars['String']>
}

export interface MonstersalePageBodyCategoryListPrimary {
    __typename?: 'MonstersalePageBodyCategoryListPrimary'
    style: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    title1: Maybe<Scalars['String']>
    title2: Maybe<Scalars['String']>
    title3: Maybe<Scalars['String']>
    title4: Maybe<Scalars['String']>
}

export interface MonstersalePageBodyFaq {
    __typename?: 'MonstersalePageBodyFaq'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<MonstersalePageBodyFaqPrimary>
    fields: Maybe<Array<MonstersalePageBodyFaqFields>>
}

export interface MonstersalePageBodyFaqFields {
    __typename?: 'MonstersalePageBodyFaqFields'
    iconimage: Maybe<PrismicImageType>
    category: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
}

export interface MonstersalePageBodyFaqPrimary {
    __typename?: 'MonstersalePageBodyFaqPrimary'
    style: Maybe<Scalars['String']>
    emptyCategoryText: Maybe<Scalars['String']>
}

export interface MonstersalePageBodyHero {
    __typename?: 'MonstersalePageBodyHero'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<MonstersalePageBodyHeroPrimary>
}

export interface MonstersalePageBodyHeroPrimary {
    __typename?: 'MonstersalePageBodyHeroPrimary'
    fullWidth: Maybe<Scalars['Boolean']>
    size: Maybe<Scalars['String']>
    headingfuss: Maybe<Scalars['Boolean']>
    header: Maybe<Scalars['String']>
    headingVariant: Maybe<Scalars['String']>
    headingElement: Maybe<Scalars['String']>
    subheading: Maybe<Scalars['String']>
    subheadingVariant: Maybe<Scalars['String']>
    subheadingElement: Maybe<Scalars['String']>
    videoId: Maybe<Scalars['String']>
    mobileVideoId: Maybe<Scalars['String']>
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
}

export interface MonstersalePageBodyHtml {
    __typename?: 'MonstersalePageBodyHtml'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<MonstersalePageBodyHtmlPrimary>
}

export interface MonstersalePageBodyHtmlPrimary {
    __typename?: 'MonstersalePageBodyHtmlPrimary'
    style: Maybe<Scalars['String']>
    html: Maybe<Scalars['Json']>
}

export interface MonstersalePageBodyImageOnly {
    __typename?: 'MonstersalePageBodyImageOnly'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<MonstersalePageBodyImageOnlyPrimary>
}

export interface MonstersalePageBodyImageOnlyPrimary {
    __typename?: 'MonstersalePageBodyImageOnlyPrimary'
    style: Maybe<Scalars['String']>
    tablet: Maybe<Scalars['String']>
    desktop: Maybe<Scalars['String']>
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
}

export interface MonstersalePageBodyProductVideoHero {
    __typename?: 'MonstersalePageBodyProductVideoHero'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<MonstersalePageBodyProductVideoHeroPrimary>
}

export interface MonstersalePageBodyProductVideoHeroPrimary {
    __typename?: 'MonstersalePageBodyProductVideoHeroPrimary'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    content: Maybe<Scalars['String']>
    subheading: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
    button2Url: Maybe<Scalars['String']>
    button2Text: Maybe<Scalars['String']>
    buttonStyle: Maybe<Scalars['String']>
    textStyle: Maybe<Scalars['String']>
    subheadingStyle: Maybe<Scalars['String']>
    videoId: Maybe<Scalars['String']>
    mobileVideoId: Maybe<Scalars['String']>
    productSkus: Maybe<Scalars['String']>
    productCueTimes: Maybe<Scalars['String']>
}

export interface MonstersalePageBodyTextOnly {
    __typename?: 'MonstersalePageBodyTextOnly'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<MonstersalePageBodyTextOnlyPrimary>
}

export interface MonstersalePageBodyTextOnlyPrimary {
    __typename?: 'MonstersalePageBodyTextOnlyPrimary'
    style: Maybe<Scalars['String']>
    visibility: Maybe<Scalars['String']>
    tablet: Maybe<Scalars['String']>
    desktop: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    headingVariant: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
    blockBackground: Maybe<Scalars['Json']>
    blockBackgroundColor: Maybe<Scalars['String']>
}

export interface MonstersalePageBodyTextWithMedia {
    __typename?: 'MonstersalePageBodyTextWithMedia'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<MonstersalePageBodyTextWithMediaPrimary>
    fields: Maybe<Array<MonstersalePageBodyTextWithMediaFields>>
}

export interface MonstersalePageBodyTextWithMediaFields {
    __typename?: 'MonstersalePageBodyTextWithMediaFields'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
}

export interface MonstersalePageBodyTextWithMediaPrimary {
    __typename?: 'MonstersalePageBodyTextWithMediaPrimary'
    style: Maybe<Scalars['String']>
    desktopMediaPosition: Maybe<Scalars['String']>
    mobileMediaPosition: Maybe<Scalars['String']>
    alignment: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    headingfuss: Maybe<Scalars['Boolean']>
    headingVariant: Maybe<Scalars['String']>
    headingElement: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
    buttonStyle: Maybe<Scalars['String']>
    button2Url: Maybe<Scalars['String']>
    button2Text: Maybe<Scalars['String']>
    button2Style: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
    videoId: Maybe<Scalars['String']>
    mobileVideoId: Maybe<Scalars['String']>
    blockBackground: Maybe<Scalars['Json']>
    blockBackgroundColor: Maybe<Scalars['String']>
}

export interface MonstersalePageBodyTileSlider {
    __typename?: 'MonstersalePageBodyTileSlider'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<MonstersalePageBodyTileSliderPrimary>
    fields: Maybe<Array<MonstersalePageBodyTileSliderFields>>
}

export interface MonstersalePageBodyTileSliderFields {
    __typename?: 'MonstersalePageBodyTileSliderFields'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    subtitle: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
    buttonStyle: Maybe<Scalars['String']>
}

export interface MonstersalePageBodyTileSliderPrimary {
    __typename?: 'MonstersalePageBodyTileSliderPrimary'
    style: Maybe<Scalars['String']>
    variant: Maybe<Scalars['String']>
    header: Maybe<Scalars['String']>
    content: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
    buttonStyle: Maybe<Scalars['String']>
}

export interface MonstersalePageBodyTweakwiseSlider {
    __typename?: 'MonstersalePageBodyTweakwiseSlider'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<MonstersalePageBodyTweakwiseSliderPrimary>
}

export interface MonstersalePageBodyTweakwiseSliderPrimary {
    __typename?: 'MonstersalePageBodyTweakwiseSliderPrimary'
    blueconicPosition: Maybe<Scalars['String']>
    style: Maybe<Scalars['String']>
    header: Maybe<Scalars['String']>
    content: Maybe<Scalars['String']>
    recommendationsIdentifier: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
    buttonStyle: Maybe<Scalars['String']>
}

/** A connection to a list of items. */
export interface MonstersalePageConnectionConnection {
    __typename?: 'MonstersalePageConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<MonstersalePageConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface MonstersalePageConnectionEdge {
    __typename?: 'MonstersalePageConnectionEdge'
    /** The item at the end of the edge. */
    node: MonstersalePage
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

export interface Mutation {
    __typename?: 'Mutation'
    /** Add one or more bundle products to the specified cart. We recommend using `addProductsToCart` instead. */
    addBundleProductsToCart: Maybe<AddBundleProductsToCartOutput>
    /** Add one or more configurable products to the specified cart. We recommend using `addProductsToCart` instead. */
    addConfigurableProductsToCart: Maybe<AddConfigurableProductsToCartOutput>
    /** Add any type of product to the cart. */
    addProductsToCart: Maybe<AddProductsToCartOutput>
    /** Add products to the specified compare list. */
    addProductsToCompareList: Maybe<CompareList>
    /** Add one or more products to the specified wish list. This mutation supports all product types. */
    addProductsToWishlist: Maybe<AddProductsToWishlistOutput>
    /** Add one or more simple products to the specified cart. We recommend using `addProductsToCart` instead. */
    addSimpleProductsToCart: Maybe<AddSimpleProductsToCartOutput>
    /** Add one or more virtual products to the specified cart. We recommend using `addProductsToCart` instead. */
    addVirtualProductsToCart: Maybe<AddVirtualProductsToCartOutput>
    /** Add items in the specified wishlist to the customer's cart. */
    addWishlistItemsToCart: Maybe<AddWishlistItemsToCartOutput>
    adyenPaymentDetails: Maybe<AdyenPaymentStatus>
    adyenRemoveStateData: Maybe<AdyenStateData>
    adyenSaveStateData: Maybe<AdyenStateData>
    /** Apply a pre-defined coupon code to the specified cart. */
    applyCouponToCart: Maybe<ApplyCouponToCartOutput>
    /** Assign the specified compare list to the logged in customer. */
    assignCompareListToCustomer: Maybe<AssignCompareListToCustomerOutput>
    /** Assign a logged-in customer to the specified guest shopping cart. */
    assignCustomerToGuestCart: Cart
    /** Cancel the specified customer order. */
    cancelOrder: Maybe<CancelOrderOutput>
    /** Change the password for the logged-in customer. */
    changeCustomerPassword: Maybe<Customer>
    /** Confirms the email address for a customer. */
    confirmEmail: Maybe<CustomerOutput>
    /** Send a 'Contact Us' email to the merchant. */
    contactUs: Maybe<ContactUsOutput>
    /** Create a new compare list. The compare list is saved for logged in customers. */
    createCompareList: Maybe<CompareList>
    /** Use `createCustomerV2` instead. */
    createCustomer: Maybe<CustomerOutput>
    /** Create a billing or shipping address for a customer or guest. */
    createCustomerAddress: Maybe<CustomerAddress>
    /** Create a customer account. */
    createCustomerV2: Maybe<CustomerOutput>
    /**
     * Create an empty shopping cart for a guest or logged in user
     * @deprecated Use `Mutation.createGuestCart` or `Query.customerCart` for logged in customer
     */
    createEmptyCart: Maybe<Scalars['String']>
    /** Create a new shopping cart */
    createGuestCart: Maybe<CreateGuestCartOutput>
    /** Create a guest wish list. */
    createGuestWishlist: Maybe<CreateGuestWishlistOutput>
    /** Initiate a transaction and receive a token. Use this mutation for Payflow Pro and Payments Pro payment methods */
    createPayflowProToken: Maybe<CreatePayflowProTokenOutput>
    /**
     * Initiate an Express Checkout transaction and receive a token. Use this
     * mutation for Express Checkout and Payments Standard payment methods.
     */
    createPaypalExpressToken: Maybe<PaypalExpressTokenOutput>
    /** Create a product review for the specified product. */
    createProductReview: CreateProductReviewOutput
    /** Remove coupon from cart. Will return graphql error on failure. */
    customerCouponRemove: Maybe<CustomerCouponRemoveResult>
    /** Delete the specified compare list. */
    deleteCompareList: Maybe<DeleteCompareListOutput>
    /** Delete customer account */
    deleteCustomer: Maybe<Scalars['Boolean']>
    /** Delete the billing or shipping address of a customer. */
    deleteCustomerAddress: Maybe<Scalars['Boolean']>
    /** Delete a customer's payment token. */
    deletePaymentToken: Maybe<DeletePaymentTokenOutput>
    /** Estimate shipping method(s) for cart based on address */
    estimateShippingMethods: Maybe<Array<Maybe<AvailableShippingMethod>>>
    /** Estimate totals for cart based on the address */
    estimateTotals: EstimateTotalsOutput
    /** Generate a token for specified customer. */
    generateCustomerToken: Maybe<CustomerToken>
    /** Request a customer token so that an administrator can perform remote shopping assistance. */
    generateCustomerTokenAsAdmin: Maybe<GenerateCustomerTokenAsAdminOutput>
    /**
     * Handle a payment response and save the payment in Quote. Use this mutation for
     * Payflow Pro and Payments Pro payment methods.
     */
    handlePayflowProResponse: Maybe<PayflowProResponseOutput>
    /** Transfer the contents of a guest cart into the cart of a logged-in customer. */
    mergeCarts: Cart
    /** Merge wish lists. If using a non-masked ID a Customer session is required */
    mergeWishlists: Maybe<MergeWishlistsOutput>
    /** Convert the quote into an order. */
    placeOrder: Maybe<PlaceOrderOutput>
    /** Remove a previously-applied coupon from the cart. The cart must contain at least one item in order to remove the coupon. */
    removeCouponFromCart: Maybe<RemoveCouponFromCartOutput>
    /**
     * Delete the entire quantity of a specified item from the cart. If you remove
     * all items from the cart, the cart continues to exist.
     */
    removeItemFromCart: Maybe<RemoveItemFromCartOutput>
    /** Remove products from the specified compare list. */
    removeProductsFromCompareList: Maybe<CompareList>
    /** Remove one or more products from the specified wish list. */
    removeProductsFromWishlist: Maybe<RemoveProductsFromWishlistOutput>
    /** Add all products from a customer's previous order to the cart. */
    reorderItems: Maybe<ReorderItemsOutput>
    /** Request an email with a reset password token for the registered customer identified by the specified email. */
    requestPasswordResetEmail: Maybe<Scalars['Boolean']>
    /**
     * Reset a customer's password using the reset password token that the customer
     * received in an email after requesting it using `requestPasswordResetEmail`.
     */
    resetPassword: Maybe<Scalars['Boolean']>
    /** Revoke the customer token. */
    revokeCustomerToken: Maybe<RevokeCustomerTokenOutput>
    /** Send a message on behalf of a customer to the specified email addresses. */
    sendEmailToFriend: Maybe<SendEmailToFriendOutput>
    /** Set the billing address on a specific cart. */
    setBillingAddressOnCart: Maybe<SetBillingAddressOnCartOutput>
    /** Assign the email address of a guest to the cart. */
    setGuestEmailOnCart: Maybe<SetGuestEmailOnCartOutput>
    /**
     * Set the cart payment method and convert the cart into an order.
     * @deprecated Should use setPaymentMethodOnCart and placeOrder mutations in single request.
     */
    setPaymentMethodAndPlaceOrder: Maybe<PlaceOrderOutput>
    /** Apply a payment method to the cart. */
    setPaymentMethodOnCart: Maybe<SetPaymentMethodOnCartOutput>
    /** Set one or more shipping addresses on a specific cart. */
    setShippingAddressesOnCart: Maybe<SetShippingAddressesOnCartOutput>
    /** DEPRECATED: Set DHL Pickup Point option (Highstreet only) */
    setShippingMethodOption: SetShippingMethodOptionOutput
    /** Set one or more delivery methods on a cart. */
    setShippingMethodsOnCart: Maybe<SetShippingMethodsOnCartOutput>
    /** Subscribe the specified email to the store's newsletter. */
    subscribeEmailToNewsletter: Maybe<SubscribeEmailToNewsletterOutput>
    /** Modify items in the cart. */
    updateCartItems: Maybe<UpdateCartItemsOutput>
    /** Use `updateCustomerV2` instead. */
    updateCustomer: Maybe<CustomerOutput>
    /** Update the billing or shipping address of a customer or guest. */
    updateCustomerAddress: Maybe<CustomerAddress>
    /** Change the email address for the logged-in customer. */
    updateCustomerEmail: Maybe<CustomerOutput>
    /** Update the customer's personal information. */
    updateCustomerV2: Maybe<CustomerOutput>
    /** Update one or more products in the specified wish list. */
    updateProductsInWishlist: Maybe<UpdateProductsInWishlistOutput>
}

export interface MutationAddBundleProductsToCartArgs {
    input: Maybe<AddBundleProductsToCartInput>
}

export interface MutationAddConfigurableProductsToCartArgs {
    input: Maybe<AddConfigurableProductsToCartInput>
}

export interface MutationAddProductsToCartArgs {
    cartId: Scalars['String']
    cartItems: Array<CartItemInput>
}

export interface MutationAddProductsToCompareListArgs {
    input: Maybe<AddProductsToCompareListInput>
}

export interface MutationAddProductsToWishlistArgs {
    wishlistId: Scalars['ID']
    wishlistItems: Array<WishlistItemInput>
}

export interface MutationAddSimpleProductsToCartArgs {
    input: Maybe<AddSimpleProductsToCartInput>
}

export interface MutationAddVirtualProductsToCartArgs {
    input: Maybe<AddVirtualProductsToCartInput>
}

export interface MutationAddWishlistItemsToCartArgs {
    wishlistId: Scalars['ID']
    wishlistItemIds: Maybe<Array<Scalars['ID']>>
}

export interface MutationAdyenPaymentDetailsArgs {
    payload: Scalars['String']
    cart_id: Scalars['String']
}

export interface MutationAdyenRemoveStateDataArgs {
    stateDataId: Scalars['Int']
    cartId: Scalars['String']
}

export interface MutationAdyenSaveStateDataArgs {
    stateData: Scalars['String']
    cartId: Scalars['String']
}

export interface MutationApplyCouponToCartArgs {
    input: Maybe<ApplyCouponToCartInput>
}

export interface MutationAssignCompareListToCustomerArgs {
    uid: Scalars['ID']
}

export interface MutationAssignCustomerToGuestCartArgs {
    cart_id: Scalars['String']
}

export interface MutationCancelOrderArgs {
    input: CancelOrderInput
}

export interface MutationChangeCustomerPasswordArgs {
    currentPassword: Scalars['String']
    newPassword: Scalars['String']
}

export interface MutationConfirmEmailArgs {
    input: ConfirmEmailInput
}

export interface MutationContactUsArgs {
    input: ContactUsInput
}

export interface MutationCreateCompareListArgs {
    input: Maybe<CreateCompareListInput>
}

export interface MutationCreateCustomerArgs {
    input: CustomerInput
}

export interface MutationCreateCustomerAddressArgs {
    input: CustomerAddressInput
}

export interface MutationCreateCustomerV2Args {
    input: CustomerCreateInput
}

export interface MutationCreateEmptyCartArgs {
    input: Maybe<CreateEmptyCartInput>
}

export interface MutationCreateGuestCartArgs {
    input: Maybe<CreateGuestCartInput>
}

export interface MutationCreatePayflowProTokenArgs {
    input: PayflowProTokenInput
}

export interface MutationCreatePaypalExpressTokenArgs {
    input: PaypalExpressTokenInput
}

export interface MutationCreateProductReviewArgs {
    input: CreateProductReviewInput
}

export interface MutationCustomerCouponRemoveArgs {
    cart_id: Maybe<Scalars['String']>
    code: Scalars['String']
}

export interface MutationDeleteCompareListArgs {
    uid: Scalars['ID']
}

export interface MutationDeleteCustomerAddressArgs {
    id: Scalars['Int']
}

export interface MutationDeletePaymentTokenArgs {
    public_hash: Scalars['String']
}

export interface MutationEstimateShippingMethodsArgs {
    input: EstimateTotalsInput
}

export interface MutationEstimateTotalsArgs {
    input: EstimateTotalsInput
}

export interface MutationGenerateCustomerTokenArgs {
    email: Scalars['String']
    password: Scalars['String']
}

export interface MutationGenerateCustomerTokenAsAdminArgs {
    input: GenerateCustomerTokenAsAdminInput
}

export interface MutationHandlePayflowProResponseArgs {
    input: PayflowProResponseInput
}

export interface MutationMergeCartsArgs {
    source_cart_id: Scalars['String']
    destination_cart_id: Maybe<Scalars['String']>
}

export interface MutationMergeWishlistsArgs {
    sourceWishlistId: Scalars['ID']
    destinationWishlistId: Scalars['ID']
}

export interface MutationPlaceOrderArgs {
    input: Maybe<PlaceOrderInput>
}

export interface MutationRemoveCouponFromCartArgs {
    input: Maybe<RemoveCouponFromCartInput>
}

export interface MutationRemoveItemFromCartArgs {
    input: Maybe<RemoveItemFromCartInput>
}

export interface MutationRemoveProductsFromCompareListArgs {
    input: Maybe<RemoveProductsFromCompareListInput>
}

export interface MutationRemoveProductsFromWishlistArgs {
    wishlistId: Scalars['ID']
    wishlistItemsIds: Array<Scalars['ID']>
}

export interface MutationReorderItemsArgs {
    orderNumber: Scalars['String']
}

export interface MutationRequestPasswordResetEmailArgs {
    email: Scalars['String']
}

export interface MutationResetPasswordArgs {
    email: Scalars['String']
    resetPasswordToken: Scalars['String']
    newPassword: Scalars['String']
}

export interface MutationSendEmailToFriendArgs {
    input: Maybe<SendEmailToFriendInput>
}

export interface MutationSetBillingAddressOnCartArgs {
    input: Maybe<SetBillingAddressOnCartInput>
}

export interface MutationSetGuestEmailOnCartArgs {
    input: Maybe<SetGuestEmailOnCartInput>
}

export interface MutationSetPaymentMethodAndPlaceOrderArgs {
    input: Maybe<SetPaymentMethodAndPlaceOrderInput>
}

export interface MutationSetPaymentMethodOnCartArgs {
    input: Maybe<SetPaymentMethodOnCartInput>
}

export interface MutationSetShippingAddressesOnCartArgs {
    input: Maybe<SetShippingAddressesOnCartInput>
}

export interface MutationSetShippingMethodOptionArgs {
    input: SetShippingMethodOptionInput
}

export interface MutationSetShippingMethodsOnCartArgs {
    input: Maybe<SetShippingMethodsOnCartInput>
}

export interface MutationSubscribeEmailToNewsletterArgs {
    email: Scalars['String']
    source: Maybe<Scalars['String']>
}

export interface MutationUpdateCartItemsArgs {
    input: Maybe<UpdateCartItemsInput>
}

export interface MutationUpdateCustomerArgs {
    input: CustomerInput
}

export interface MutationUpdateCustomerAddressArgs {
    id: Scalars['Int']
    input: Maybe<CustomerAddressInput>
}

export interface MutationUpdateCustomerEmailArgs {
    email: Scalars['String']
    password: Scalars['String']
}

export interface MutationUpdateCustomerV2Args {
    input: CustomerUpdateInput
}

export interface MutationUpdateProductsInWishlistArgs {
    wishlistId: Scalars['ID']
    wishlistItems: Array<WishlistItemUpdateInput>
}

/** Navigation object */
export interface Navigation {
    __typename?: 'Navigation'
    /** Code (unique) */
    code: Scalars['String']
    /** Navigation ID (unique) */
    id: Scalars['ID']
    /** Array containing root items */
    items: Maybe<Array<Maybe<NavigationItem>>>
}

/** Navigation Item object */
export interface NavigationItem {
    __typename?: 'NavigationItem'
    /** Catalog Category */
    category: Maybe<CategoryTree>
    /** Array containing child items */
    children: Maybe<Array<Maybe<NavigationItem>>>
    /** Cms Page */
    cmsPage: Maybe<CmsPage>
    /** Href */
    href: Maybe<Scalars['String']>
    /** Item ID (unique) */
    id: Scalars['ID']
    /** Label */
    label: Scalars['String']
    /** Catalog Product */
    product: Maybe<ProductInterface>
    /** Promotion Background Image */
    promotionBackgroundImage: Maybe<Scalars['String']>
    /** 2nd Promotion Background Image */
    promotionBackgroundImage2: Maybe<Scalars['String']>
    /** Promotion Button Link */
    promotionButtonLink: Maybe<Scalars['String']>
    /** 2nd Promotion Button Link */
    promotionButtonLink2: Maybe<Scalars['String']>
    /** Promotion Button Text */
    promotionButtonText: Maybe<Scalars['String']>
    /** 2nd Promotion Button Text */
    promotionButtonText2: Maybe<Scalars['String']>
    /** Promotion Link */
    promotionLink: Maybe<Scalars['String']>
    /** 2nd Promotion Link */
    promotionLink2: Maybe<Scalars['String']>
    /** Promotion Text */
    promotionText: Maybe<Scalars['String']>
    /** 2nd Promotion Text */
    promotionText2: Maybe<Scalars['String']>
    /** Sort Order */
    sortOrder: Maybe<Scalars['Int']>
    /** Target */
    target: Maybe<Scalars['String']>
}

/** Contains an error message when an invalid UID was specified. */
export interface NoSuchEntityUidError extends ErrorInterface {
    __typename?: 'NoSuchEntityUidError'
    /** The returned error message. */
    message: Scalars['String']
    /** The specified invalid unique ID of an object. */
    uid: Scalars['ID']
}

/** Contains the order ID. */
export interface Order {
    __typename?: 'Order'
    adyenPaymentStatus: Maybe<AdyenPaymentStatus>
    /** Cart ID. */
    cartId: Scalars['String']
    maskedOrderId: Maybe<Scalars['String']>
    /** @deprecated Use `order_number` instead. */
    orderId: Maybe<Scalars['String']>
    /** The unique ID for an `Order` object. */
    orderNumber: Scalars['String']
    /** URL for the Customer to complete the Payment. */
    paymentUrl: Maybe<Scalars['String']>
}

/** Contains detailed information about an order's billing and shipping addresses. */
export interface OrderAddress {
    __typename?: 'OrderAddress'
    /** The city or town. */
    city: Scalars['String']
    /** The customer's company. */
    company: Maybe<Scalars['String']>
    /** The customer's country. */
    countryCode: Maybe<CountryCodeEnum>
    /** The fax number. */
    fax: Maybe<Scalars['String']>
    /** The first name of the person associated with the shipping/billing address. */
    firstname: Scalars['String']
    /** The family name of the person associated with the shipping/billing address. */
    lastname: Scalars['String']
    /** The middle name of the person associated with the shipping/billing address. */
    middlename: Maybe<Scalars['String']>
    /** The customer's ZIP or postal code. */
    postcode: Maybe<Scalars['String']>
    /** An honorific, such as Dr., Mr., or Mrs. */
    prefix: Maybe<Scalars['String']>
    /** The state or province name. */
    region: Maybe<Scalars['String']>
    /** The unique ID for a `Region` object of a pre-defined region. */
    regionId: Maybe<Scalars['ID']>
    /** An array of strings that define the street number and name. */
    street: Array<Maybe<Scalars['String']>>
    /** A value such as Sr., Jr., or III. */
    suffix: Maybe<Scalars['String']>
    /** The telephone number. */
    telephone: Maybe<Scalars['String']>
    /** The customer's Value-added tax (VAT) number (for corporate customers). */
    vatId: Maybe<Scalars['String']>
}

/** Input to retrieve an order based on details. */
export interface OrderInformationInput {
    /** Order billing address email. */
    email: Scalars['String']
    /** Order number. */
    number: Scalars['String']
    /** Order billing address postcode. */
    postcode: Scalars['String']
}

export interface OrderItem extends OrderItemInterface {
    __typename?: 'OrderItem'
    baseNetPrice: Maybe<Money>
    /** The final discount information for the product. */
    discounts: Maybe<Array<Maybe<Discount>>>
    /** The entered option for the base product, such as a logo or image. */
    enteredOptions: Maybe<Array<Maybe<OrderItemOption>>>
    /** The selected gift message for the order item */
    giftMessage: Maybe<GiftMessage>
    /** The unique ID for an `OrderItemInterface` object. */
    id: Scalars['ID']
    netPrice: Maybe<Money>
    parentItemId: Maybe<Scalars['Int']>
    priceInclTax: Maybe<Money>
    /** The ProductInterface object, which contains details about the base product */
    product: Maybe<ProductInterface>
    /** The name of the base product. */
    productName: Maybe<Scalars['String']>
    /** The original price of the base product, including selected options. */
    productOriginalPrice: Money
    /** The sale price of the base product, including selected options. */
    productSalePrice: Money
    /** The SKU of the base product. */
    productSku: Scalars['String']
    /** The type of product, such as simple, configurable, etc. */
    productType: Maybe<Scalars['String']>
    /** URL key of the base product. */
    productUrlKey: Maybe<Scalars['String']>
    /** The number of canceled items. */
    quantityCanceled: Maybe<Scalars['Float']>
    /** The number of invoiced items. */
    quantityInvoiced: Maybe<Scalars['Float']>
    /** The number of units ordered for this item. */
    quantityOrdered: Maybe<Scalars['Float']>
    /** The number of refunded items. */
    quantityRefunded: Maybe<Scalars['Float']>
    /** The number of returned items. */
    quantityReturned: Maybe<Scalars['Float']>
    /** The number of shipped items. */
    quantityShipped: Maybe<Scalars['Float']>
    /** The selected options for the base product, such as color or size. */
    selectedOptions: Maybe<Array<Maybe<OrderItemOption>>>
    /** The status of the order item. */
    status: Maybe<Scalars['String']>
}

/** Order item details. */
export interface OrderItemInterface {
    baseNetPrice: Maybe<Money>
    /** The final discount information for the product. */
    discounts: Maybe<Array<Maybe<Discount>>>
    /** The entered option for the base product, such as a logo or image. */
    enteredOptions: Maybe<Array<Maybe<OrderItemOption>>>
    /** The selected gift message for the order item */
    giftMessage: Maybe<GiftMessage>
    /** The unique ID for an `OrderItemInterface` object. */
    id: Scalars['ID']
    netPrice: Maybe<Money>
    parentItemId: Maybe<Scalars['Int']>
    priceInclTax: Maybe<Money>
    /** The ProductInterface object, which contains details about the base product */
    product: Maybe<ProductInterface>
    /** The name of the base product. */
    productName: Maybe<Scalars['String']>
    /** The original price of the base product, including selected options. */
    productOriginalPrice: Money
    /** The sale price of the base product, including selected options. */
    productSalePrice: Money
    /** The SKU of the base product. */
    productSku: Scalars['String']
    /** The type of product, such as simple, configurable, etc. */
    productType: Maybe<Scalars['String']>
    /** URL key of the base product. */
    productUrlKey: Maybe<Scalars['String']>
    /** The number of canceled items. */
    quantityCanceled: Maybe<Scalars['Float']>
    /** The number of invoiced items. */
    quantityInvoiced: Maybe<Scalars['Float']>
    /** The number of units ordered for this item. */
    quantityOrdered: Maybe<Scalars['Float']>
    /** The number of refunded items. */
    quantityRefunded: Maybe<Scalars['Float']>
    /** The number of returned items. */
    quantityReturned: Maybe<Scalars['Float']>
    /** The number of shipped items. */
    quantityShipped: Maybe<Scalars['Float']>
    /** The selected options for the base product, such as color or size. */
    selectedOptions: Maybe<Array<Maybe<OrderItemOption>>>
    /** The status of the order item. */
    status: Maybe<Scalars['String']>
}

/** Represents order item options like selected or entered. */
export interface OrderItemOption {
    __typename?: 'OrderItemOption'
    /** The name of the option. */
    label: Scalars['String']
    /** The value of the option. */
    value: Scalars['String']
}

/** Contains details about the payment method used to pay for the order. */
export interface OrderPaymentMethod {
    __typename?: 'OrderPaymentMethod'
    /** Additional data per payment method type. */
    additionalData: Maybe<Array<Maybe<KeyValue>>>
    /** The label that describes the payment method. */
    name: Scalars['String']
    /** The payment method code that indicates how the order was paid for. */
    type: Scalars['String']
}

/** Contains order shipment details. */
export interface OrderShipment {
    __typename?: 'OrderShipment'
    /** Comments added to the shipment. */
    comments: Maybe<Array<Maybe<SalesCommentItem>>>
    /** The unique ID for a `OrderShipment` object. */
    id: Scalars['ID']
    /** An array of items included in the shipment. */
    items: Maybe<Array<Maybe<ShipmentItemInterface>>>
    /** The sequential credit shipment number. */
    number: Scalars['String']
    /** An array of shipment tracking details. */
    tracking: Maybe<Array<Maybe<ShipmentTracking>>>
}

/** Input to retrieve an order based on token. */
export interface OrderTokenInput {
    /** Order token. */
    token: Scalars['String']
}

/** Contains details about the sales total amounts used to calculate the final price. */
export interface OrderTotal {
    __typename?: 'OrderTotal'
    /** The final base grand total amount in the base currency. */
    baseGrandTotal: Money
    /** The applied discounts to the order. */
    discounts: Maybe<Array<Maybe<Discount>>>
    /** The final total amount, including shipping, discounts, and taxes. */
    grandTotal: Money
    /** Details about the shipping and handling costs for the order. */
    shippingHandling: Maybe<ShippingHandling>
    /** The subtotal of the order, excluding shipping, discounts, and taxes. */
    subtotal: Money
    /** The order tax details. */
    taxes: Maybe<Array<Maybe<TaxItem>>>
    /** The shipping amount for the order. */
    totalShipping: Money
    /** The amount of tax applied to the order. */
    totalTax: Money
}

export interface Page extends Document, Linkable {
    __typename?: 'Page'
    urlKey: Maybe<Scalars['String']>
    search: Maybe<Scalars['Boolean']>
    seoTitle: Maybe<Scalars['String']>
    seoDescription: Maybe<Scalars['String']>
    seoKeywords: Maybe<Scalars['String']>
    style: Maybe<Scalars['String']>
    head: Maybe<Scalars['Json']>
    noindex: Maybe<Scalars['Boolean']>
    nofollow: Maybe<Scalars['Boolean']>
    authRequired: Maybe<Scalars['Boolean']>
    hideLayout: Maybe<Scalars['Boolean']>
    pageType: Maybe<Scalars['String']>
    tweakwiseFunnelId: Maybe<Scalars['String']>
    storyTag: Maybe<PrismicHyperlink>
    storyDescription: Maybe<Scalars['String']>
    storyTitle: Maybe<Scalars['String']>
    publicationField: Maybe<Scalars['Date']>
    thumbnailImage: Maybe<PrismicImageType>
    pageBackground: Maybe<Scalars['Json']>
    pageBackgroundColor: Maybe<Scalars['String']>
    body: Maybe<Array<PageBody>>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

export type PageBody =
    | PageBodyProductHero
    | PageBodyTweakwiseSlider
    | PageBodyImageSlider
    | PageBodyHero
    | PageBodyTextOnly
    | PageBodyImageOnly
    | PageBodyTextWithMedia
    | PageBodyHtml
    | PageBodyCategoryElement
    | PageBodyFaq
    | PageBodyBannerBlocks
    | PageBodyBannerBlocks1
    | PageBodyBannerBlocks2
    | PageBodyCategoryList
    | PageBodyStoriesOverviewHeader
    | PageBodyStoryOverviewPage
    | PageBodyMemberUspS
    | PageBodyVisualFilter
    | PageBodyBecomeMemberUsp
    | PageBodyFaqSubject
    | PageBodyForm
    | PageBodyBlog

export interface PageBodyBannerBlocks {
    __typename?: 'PageBodyBannerBlocks'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<PageBodyBannerBlocksPrimary>
    fields: Maybe<Array<PageBodyBannerBlocksFields>>
}

export interface PageBodyBannerBlocks1 {
    __typename?: 'PageBodyBannerBlocks1'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<PageBodyBannerBlocks1Primary>
    fields: Maybe<Array<PageBodyBannerBlocks1Fields>>
}

export interface PageBodyBannerBlocks1Fields {
    __typename?: 'PageBodyBannerBlocks1Fields'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    subtitle: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

export interface PageBodyBannerBlocks1Primary {
    __typename?: 'PageBodyBannerBlocks1Primary'
    style: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
}

export interface PageBodyBannerBlocks2 {
    __typename?: 'PageBodyBannerBlocks2'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<PageBodyBannerBlocks2Primary>
    fields: Maybe<Array<PageBodyBannerBlocks2Fields>>
}

export interface PageBodyBannerBlocks2Fields {
    __typename?: 'PageBodyBannerBlocks2Fields'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    subtitle: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

export interface PageBodyBannerBlocks2Primary {
    __typename?: 'PageBodyBannerBlocks2Primary'
    style: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
}

export interface PageBodyBannerBlocksFields {
    __typename?: 'PageBodyBannerBlocksFields'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
}

export interface PageBodyBannerBlocksPrimary {
    __typename?: 'PageBodyBannerBlocksPrimary'
    style: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
}

export interface PageBodyBecomeMemberUsp {
    __typename?: 'PageBodyBecomeMemberUsp'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<PageBodyBecomeMemberUspPrimary>
}

export interface PageBodyBecomeMemberUspPrimary {
    __typename?: 'PageBodyBecomeMemberUspPrimary'
    show: Maybe<Scalars['Boolean']>
    header: Maybe<Scalars['String']>
    backgroundImage: Maybe<PrismicImageType>
    mobile: Maybe<Scalars['String']>
    tablet: Maybe<Scalars['String']>
    desktop: Maybe<Scalars['String']>
    buttonLabel: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonStyle: Maybe<Scalars['String']>
    memberUspsLink: Maybe<PrismicHyperlink>
}

export interface PageBodyBlog {
    __typename?: 'PageBodyBlog'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<PageBodyBlogPrimary>
    fields: Maybe<Array<PageBodyBlogFields>>
}

export interface PageBodyBlogFields {
    __typename?: 'PageBodyBlogFields'
    desktopImage: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    header: Maybe<Scalars['String']>
    subHeader: Maybe<Scalars['String']>
    blogText: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
}

export interface PageBodyBlogPrimary {
    __typename?: 'PageBodyBlogPrimary'
    title: Maybe<Scalars['String']>
    blogStyle: Maybe<Scalars['String']>
}

export interface PageBodyCategoryElement {
    __typename?: 'PageBodyCategoryElement'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<PageBodyCategoryElementPrimary>
    fields: Maybe<Array<PageBodyCategoryElementFields>>
}

export interface PageBodyCategoryElementFields {
    __typename?: 'PageBodyCategoryElementFields'
    title: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
}

export interface PageBodyCategoryElementPrimary {
    __typename?: 'PageBodyCategoryElementPrimary'
    style: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
}

export interface PageBodyCategoryList {
    __typename?: 'PageBodyCategoryList'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<PageBodyCategoryListPrimary>
    fields: Maybe<Array<PageBodyCategoryListFields>>
}

export interface PageBodyCategoryListFields {
    __typename?: 'PageBodyCategoryListFields'
    title: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
    list: Maybe<Scalars['String']>
}

export interface PageBodyCategoryListPrimary {
    __typename?: 'PageBodyCategoryListPrimary'
    style: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    title1: Maybe<Scalars['String']>
    title2: Maybe<Scalars['String']>
    title3: Maybe<Scalars['String']>
    title4: Maybe<Scalars['String']>
}

export interface PageBodyFaq {
    __typename?: 'PageBodyFaq'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<PageBodyFaqPrimary>
    fields: Maybe<Array<PageBodyFaqFields>>
}

export interface PageBodyFaqFields {
    __typename?: 'PageBodyFaqFields'
    iconimage: Maybe<PrismicImageType>
    category: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
}

export interface PageBodyFaqPrimary {
    __typename?: 'PageBodyFaqPrimary'
    style: Maybe<Scalars['String']>
    emptyCategoryText: Maybe<Scalars['String']>
}

export interface PageBodyFaqSubject {
    __typename?: 'PageBodyFaqSubject'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<PageBodyFaqSubjectPrimary>
    fields: Maybe<Array<PageBodyFaqSubjectFields>>
}

export interface PageBodyFaqSubjectFields {
    __typename?: 'PageBodyFaqSubjectFields'
    heading: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
}

export interface PageBodyFaqSubjectPrimary {
    __typename?: 'PageBodyFaqSubjectPrimary'
    urlKey: Maybe<Scalars['String']>
    seoTitle: Maybe<Scalars['String']>
    seoKeywords: Maybe<Scalars['String']>
    seoDescription: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    icon: Maybe<PrismicImageType>
}

export interface PageBodyForm {
    __typename?: 'PageBodyForm'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<PageBodyFormPrimary>
}

export interface PageBodyFormPrimary {
    __typename?: 'PageBodyFormPrimary'
    formId: Maybe<Scalars['String']>
    formHeader: Maybe<Scalars['String']>
    formSubheader: Maybe<Scalars['String']>
    buttonSubmitLabel: Maybe<Scalars['String']>
}

export interface PageBodyHero {
    __typename?: 'PageBodyHero'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<PageBodyHeroPrimary>
}

export interface PageBodyHeroPrimary {
    __typename?: 'PageBodyHeroPrimary'
    fullWidth: Maybe<Scalars['Boolean']>
    size: Maybe<Scalars['String']>
    headingfuss: Maybe<Scalars['Boolean']>
    header: Maybe<Scalars['String']>
    headingVariant: Maybe<Scalars['String']>
    headingElement: Maybe<Scalars['String']>
    subheading: Maybe<Scalars['String']>
    subheadingVariant: Maybe<Scalars['String']>
    subheadingElement: Maybe<Scalars['String']>
    videoId: Maybe<Scalars['String']>
    mobileVideoId: Maybe<Scalars['String']>
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
}

export interface PageBodyHtml {
    __typename?: 'PageBodyHtml'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<PageBodyHtmlPrimary>
}

export interface PageBodyHtmlPrimary {
    __typename?: 'PageBodyHtmlPrimary'
    style: Maybe<Scalars['String']>
    html: Maybe<Scalars['Json']>
    tablet: Maybe<Scalars['String']>
    desktop: Maybe<Scalars['String']>
}

export interface PageBodyImageOnly {
    __typename?: 'PageBodyImageOnly'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<PageBodyImageOnlyPrimary>
}

export interface PageBodyImageOnlyPrimary {
    __typename?: 'PageBodyImageOnlyPrimary'
    style: Maybe<Scalars['String']>
    tablet: Maybe<Scalars['String']>
    desktop: Maybe<Scalars['String']>
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
}

export interface PageBodyImageSlider {
    __typename?: 'PageBodyImageSlider'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<PageBodyImageSliderPrimary>
    fields: Maybe<Array<PageBodyImageSliderFields>>
}

export interface PageBodyImageSliderFields {
    __typename?: 'PageBodyImageSliderFields'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    subtitle: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

export interface PageBodyImageSliderPrimary {
    __typename?: 'PageBodyImageSliderPrimary'
    style: Maybe<Scalars['String']>
    variant: Maybe<Scalars['String']>
    header: Maybe<Scalars['String']>
    content: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

export interface PageBodyMemberUspS {
    __typename?: 'PageBodyMemberUspS'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<PageBodyMemberUspSPrimary>
    fields: Maybe<Array<PageBodyMemberUspSFields>>
}

export interface PageBodyMemberUspSFields {
    __typename?: 'PageBodyMemberUspSFields'
    label: Maybe<Scalars['String']>
    icon: Maybe<Scalars['String']>
}

export interface PageBodyMemberUspSPrimary {
    __typename?: 'PageBodyMemberUspSPrimary'
    heading: Maybe<Scalars['String']>
    headingVariant: Maybe<Scalars['String']>
    content: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonStyle: Maybe<Scalars['String']>
    button2Text: Maybe<Scalars['String']>
    button2Url: Maybe<Scalars['String']>
    button2Style: Maybe<Scalars['String']>
}

export interface PageBodyProductHero {
    __typename?: 'PageBodyProductHero'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<PageBodyProductHeroPrimary>
}

export interface PageBodyProductHeroPrimary {
    __typename?: 'PageBodyProductHeroPrimary'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    content: Maybe<Scalars['String']>
    subheading: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
    button2Url: Maybe<Scalars['String']>
    button2Text: Maybe<Scalars['String']>
    buttonStyle: Maybe<Scalars['String']>
    textStyle: Maybe<Scalars['String']>
    subheadingStyle: Maybe<Scalars['String']>
    videoId: Maybe<Scalars['String']>
    mobileVideoId: Maybe<Scalars['String']>
    productSkus: Maybe<Scalars['String']>
    productCueTimes: Maybe<Scalars['String']>
}

export interface PageBodyStoriesOverviewHeader {
    __typename?: 'PageBodyStoriesOverviewHeader'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    fields: Maybe<Array<PageBodyStoriesOverviewHeaderFields>>
}

export interface PageBodyStoriesOverviewHeaderFields {
    __typename?: 'PageBodyStoriesOverviewHeaderFields'
    desktopImage: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    subtitle: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

export interface PageBodyStoryOverviewPage {
    __typename?: 'PageBodyStoryOverviewPage'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<PageBodyStoryOverviewPagePrimary>
}

export interface PageBodyStoryOverviewPagePrimary {
    __typename?: 'PageBodyStoryOverviewPagePrimary'
    showStories: Maybe<Scalars['Boolean']>
}

export interface PageBodyTextOnly {
    __typename?: 'PageBodyTextOnly'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<PageBodyTextOnlyPrimary>
}

export interface PageBodyTextOnlyPrimary {
    __typename?: 'PageBodyTextOnlyPrimary'
    style: Maybe<Scalars['String']>
    visibility: Maybe<Scalars['String']>
    tablet: Maybe<Scalars['String']>
    desktop: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    headingVariant: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
    blockBackground: Maybe<Scalars['Json']>
    blockBackgroundColor: Maybe<Scalars['String']>
}

export interface PageBodyTextWithMedia {
    __typename?: 'PageBodyTextWithMedia'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<PageBodyTextWithMediaPrimary>
    fields: Maybe<Array<PageBodyTextWithMediaFields>>
}

export interface PageBodyTextWithMediaFields {
    __typename?: 'PageBodyTextWithMediaFields'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
}

export interface PageBodyTextWithMediaPrimary {
    __typename?: 'PageBodyTextWithMediaPrimary'
    style: Maybe<Scalars['String']>
    desktopMediaPosition: Maybe<Scalars['String']>
    mobileMediaPosition: Maybe<Scalars['String']>
    alignment: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    headingfuss: Maybe<Scalars['Boolean']>
    headingVariant: Maybe<Scalars['String']>
    headingElement: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
    buttonStyle: Maybe<Scalars['String']>
    button2Url: Maybe<Scalars['String']>
    button2Text: Maybe<Scalars['String']>
    button2Style: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
    videoId: Maybe<Scalars['String']>
    mobileVideoId: Maybe<Scalars['String']>
    blockBackground: Maybe<Scalars['Json']>
    blockBackgroundColor: Maybe<Scalars['String']>
}

export interface PageBodyTweakwiseSlider {
    __typename?: 'PageBodyTweakwiseSlider'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<PageBodyTweakwiseSliderPrimary>
}

export interface PageBodyTweakwiseSliderPrimary {
    __typename?: 'PageBodyTweakwiseSliderPrimary'
    blueconicPosition: Maybe<Scalars['String']>
    style: Maybe<Scalars['String']>
    header: Maybe<Scalars['String']>
    content: Maybe<Scalars['String']>
    recommendationsIdentifier: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

export interface PageBodyVisualFilter {
    __typename?: 'PageBodyVisualFilter'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<PageBodyVisualFilterPrimary>
}

export interface PageBodyVisualFilterPrimary {
    __typename?: 'PageBodyVisualFilterPrimary'
    visualFilter: Maybe<PrismicHyperlink>
}

/** A connection to a list of items. */
export interface PageConnectionConnection {
    __typename?: 'PageConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<PageConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface PageConnectionEdge {
    __typename?: 'PageConnectionEdge'
    /** The item at the end of the edge. */
    node: Page
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

/** Information about pagination in a connection. */
export interface PageInfo {
    __typename?: 'PageInfo'
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']
    /** When paginating backwards, the cursor to continue. */
    startCursor: Maybe<Scalars['String']>
    /** When paginating forwards, the cursor to continue. */
    endCursor: Maybe<Scalars['String']>
}

/** Contains required input for Payflow Express Checkout payments. */
export interface PayflowExpressInput {
    /** The unique ID of the PayPal user. */
    payer_id: Scalars['String']
    /** The token returned by the createPaypalExpressToken mutation. */
    token: Scalars['String']
}

/**
 * A set of relative URLs that PayPal uses in response to various actions during
 * the authorization process. Adobe Commerce prepends the base URL to this value to
 * create a full URL. For example, if the full URL is
 * https://www.example.com/path/to/page.html, the relative URL is
 * path/to/page.html. Use this input for Payflow Link and Payments Advanced payment methods.
 */
export interface PayflowLinkInput {
    /**
     * The relative URL of the page that PayPal redirects to when the buyer cancels
     * the transaction in order to choose a different payment method. If the full URL
     * to this page is https://www.example.com/paypal/action/cancel.html, the
     * relative URL is paypal/action/cancel.html.
     */
    cancel_url: Scalars['String']
    /**
     * The relative URL of the transaction error page that PayPal redirects to upon
     * payment error. If the full URL to this page is
     * https://www.example.com/paypal/action/error.html, the relative URL is
     * paypal/action/error.html.
     */
    error_url: Scalars['String']
    /**
     * The relative URL of the order confirmation page that PayPal redirects to when
     * the payment is successful and additional confirmation is not needed. If the
     * full URL to this page is https://www.example.com/paypal/action/return.html,
     * the relative URL is paypal/action/return.html.
     */
    return_url: Scalars['String']
}

/** Indicates the mode for payment. Applies to the Payflow Link and Payments Advanced payment methods. */
export enum PayflowLinkMode {
    TEST = 'TEST',
    LIVE = 'LIVE',
}

/**
 * Contains information used to generate PayPal iframe for transaction. Applies to
 * Payflow Link and Payments Advanced payment methods.
 */
export interface PayflowLinkToken {
    __typename?: 'PayflowLinkToken'
    /** The mode for the Payflow transaction. */
    mode: Maybe<PayflowLinkMode>
    /** The PayPal URL used for requesting a Payflow form. */
    paypalUrl: Maybe<Scalars['String']>
    /** The secure token generated by PayPal. */
    secureToken: Maybe<Scalars['String']>
    /** The secure token ID generated by PayPal. */
    secureTokenId: Maybe<Scalars['String']>
}

/** Contains information required to fetch payment token information for the Payflow Link and Payments Advanced payment methods. */
export interface PayflowLinkTokenInput {
    /** The unique ID that identifies the customer's cart. */
    cart_id: Scalars['String']
}

/** Contains input for the Payflow Pro and Payments Pro payment methods. */
export interface PayflowProInput {
    /** Required input for credit card related information. */
    cc_details: CreditCardDetailsInput
    /**
     * Indicates whether details about the shopper's credit/debit card should be
     * tokenized for later usage. Required only if Vault is enabled for the PayPal
     * Payflow Pro payment integration.
     */
    is_active_payment_token_enabler?: Maybe<Scalars['Boolean']>
}

/** Input required to complete payment. Applies to Payflow Pro and Payments Pro payment methods. */
export interface PayflowProResponseInput {
    /** The unique ID that identifies the shopper's cart. */
    cart_id: Scalars['String']
    /** The payload returned from PayPal. */
    paypal_payload: Scalars['String']
}

export interface PayflowProResponseOutput {
    __typename?: 'PayflowProResponseOutput'
    /** The cart with the updated selected payment method. */
    cart: Cart
}

/** Contains the secure information used to authorize transaction. Applies to Payflow Pro and Payments Pro payment methods. */
export interface PayflowProToken {
    __typename?: 'PayflowProToken'
    /** The RESPMSG returned by PayPal. If the `result` is `0`, then `response_message` is `Approved`. */
    responseMessage: Scalars['String']
    /** A non-zero value if any errors occurred. */
    result: Scalars['Int']
    /** The RESULT returned by PayPal. A value of `0` indicates the transaction was approved. */
    resultCode: Scalars['Int']
    /** A secure token generated by PayPal. */
    secureToken: Scalars['String']
    /** A secure token ID generated by PayPal. */
    secureTokenId: Scalars['String']
}

/** Contains input required to fetch payment token information for the Payflow Pro and Payments Pro payment methods. */
export interface PayflowProTokenInput {
    /** The unique ID that identifies the shopper's cart. */
    cart_id: Scalars['String']
    /** A set of relative URLs that PayPal uses for callback. */
    urls: PayflowProUrlInput
}

/**
 * Contains a set of relative URLs that PayPal uses in response to various actions
 * during the authorization process. Magento prepends the base URL to this value to
 * create a full URL. For example, if the full URL is
 * https://www.example.com/path/to/page.html, the relative URL is
 * path/to/page.html. Use this input for the Payflow Pro and Payment Pro payment methods.
 */
export interface PayflowProUrlInput {
    /**
     * The relative URL of the page that PayPal redirects to when the buyer cancels
     * the transaction in order to choose a different payment method. If the full URL
     * to this page is https://www.example.com/paypal/action/cancel.html, the
     * relative URL is paypal/action/cancel.html.
     */
    cancel_url: Scalars['String']
    /**
     * The relative URL of the transaction error page that PayPal redirects to upon
     * payment error. If the full URL to this page is
     * https://www.example.com/paypal/action/error.html, the relative URL is
     * paypal/action/error.html.
     */
    error_url: Scalars['String']
    /**
     * The relative URL of the final confirmation page that PayPal redirects to upon
     * payment success. If the full URL to this page is
     * https://www.example.com/paypal/action/return.html, the relative URL is
     * paypal/action/return.html.
     */
    return_url: Scalars['String']
}

export interface PaymentMethodBalance {
    __typename?: 'PaymentMethodBalance'
    /** Cart currency */
    currency: Maybe<Scalars['String']>
    /** Total cart amount */
    value: Maybe<Scalars['String']>
}

/** Defines the payment method. */
export interface PaymentMethodInput {
    /** Required input for Adyen alternative payment methods. */
    adyen_additional_data?: Maybe<AdyenAdditionalData>
    /** Required input for Adyen CC payments. */
    adyen_additional_data_cc?: Maybe<AdyenAdditionalDataCc>
    /** Required input for Adyen POS Cloud payments. */
    adyen_additional_data_pos_cloud?: Maybe<AdyenAdditionalDataPosCloud>
    /** The internal name for the payment method. */
    code: Scalars['String']
    /** Required input for PayPal Hosted pro payments. */
    hosted_pro?: Maybe<HostedProInput>
    /** Required input for Payflow Express Checkout payments. */
    payflow_express?: Maybe<PayflowExpressInput>
    /** Required input for PayPal Payflow Link and Payments Advanced payments. */
    payflow_link?: Maybe<PayflowLinkInput>
    /** Required input for PayPal Payflow Pro and Payment Pro payments. */
    payflowpro?: Maybe<PayflowProInput>
    /** Required input for PayPal Payflow Pro vault payments. */
    payflowpro_cc_vault?: Maybe<VaultTokenInput>
    /** Required input for Express Checkout and Payments Standard payments. */
    paypal_express?: Maybe<PaypalExpressInput>
    /** The purchase order number. Optional for most payment methods. */
    purchase_order_number?: Maybe<Scalars['String']>
}

/** The stored payment method available to the customer. */
export interface PaymentToken {
    __typename?: 'PaymentToken'
    /** A description of the stored account details. */
    details: Maybe<Scalars['String']>
    /** The payment method code associated with the token. */
    paymentMethodCode: Scalars['String']
    /** The public hash of the token. */
    publicHash: Scalars['String']
    /** Specifies the payment token type. */
    type: PaymentTokenTypeEnum
}

/** The list of available payment token types. */
export enum PaymentTokenTypeEnum {
    /** phpcs:ignore Magento2.GraphQL.ValidArgumentName */
    CARD = 'card',
    /** phpcs:ignore Magento2.GraphQL.ValidArgumentName */
    ACCOUNT = 'account',
}

/** Contains required input for Express Checkout and Payments Standard payments. */
export interface PaypalExpressInput {
    /** The unique ID of the PayPal user. */
    payer_id: Scalars['String']
    /** The token returned by the `createPaypalExpressToken` mutation. */
    token: Scalars['String']
}

/** Deprecated. Use `PaypalExpressTokenOutput` instead. */
export interface PaypalExpressToken {
    __typename?: 'PaypalExpressToken'
    /**
     * A set of URLs that allow the buyer to authorize payment and adjust checkout details.
     * @deprecated Use `PaypalExpressTokenOutput.paypal_urls` instead.
     */
    paypalUrls: Maybe<PaypalExpressUrlList>
    /**
     * The token returned by PayPal.
     * @deprecated Use `PaypalExpressTokenOutput.token` instead.
     */
    token: Maybe<Scalars['String']>
}

/** Defines the attributes required to receive a payment token for Express Checkout and Payments Standard payment methods. */
export interface PaypalExpressTokenInput {
    /** The unique ID that identifies the customer's cart. */
    cart_id: Scalars['String']
    /** The payment method code. */
    code: Scalars['String']
    /** Indicates whether the buyer selected the quick checkout button. The default value is false. */
    express_button?: Maybe<Scalars['Boolean']>
    /** A set of relative URLs that PayPal uses in response to various actions during the authorization process. */
    urls: PaypalExpressUrlsInput
    /** Indicates whether the buyer clicked the PayPal credit button. The default value is false. */
    use_paypal_credit?: Maybe<Scalars['Boolean']>
}

/**
 * Contains the token returned by PayPal and a set of URLs that allow the buyer to
 * authorize payment and adjust checkout details. Applies to Express Checkout and
 * Payments Standard payment methods.
 */
export interface PaypalExpressTokenOutput {
    __typename?: 'PaypalExpressTokenOutput'
    /** A set of URLs that allow the buyer to authorize payment and adjust checkout details. */
    paypalUrls: Maybe<PaypalExpressUrlList>
    /** The token returned by PayPal. */
    token: Maybe<Scalars['String']>
}

/**
 * Contains a set of URLs that allow the buyer to authorize payment and adjust
 * checkout details for Express Checkout and Payments Standard transactions.
 */
export interface PaypalExpressUrlList {
    __typename?: 'PaypalExpressUrlList'
    /** The PayPal URL that allows the buyer to edit their checkout details. */
    edit: Maybe<Scalars['String']>
    /** The URL to the PayPal login page. */
    start: Maybe<Scalars['String']>
}

/**
 * Contains a set of relative URLs that PayPal uses in response to various actions
 * during the authorization process. Magento prepends the base URL to this value to
 * create a full URL. For example, if the full URL is
 * https://www.example.com/path/to/page.html, the relative URL is
 * path/to/page.html. Use this input for Express Checkout and Payments Standard
 * payment methods.
 */
export interface PaypalExpressUrlsInput {
    /**
     * The relative URL of the page that PayPal redirects to when the buyer cancels
     * the transaction in order to choose a different payment method. If the full URL
     * to this page is https://www.example.com/paypal/action/cancel.html, the
     * relative URL is paypal/action/cancel.html.
     */
    cancel_url: Scalars['String']
    /**
     * The relative URL of the page that PayPal redirects to when the payment has
     * been put on hold for additional review. This condition mostly applies to ACH
     * transactions, and is not applicable to most PayPal solutions. If the full URL
     * to this page is https://www.example.com/paypal/action/success_pending.html,
     * the relative URL is paypal/action/success_pending.html.
     */
    pending_url?: Maybe<Scalars['String']>
    /**
     * The relative URL of the final confirmation page that PayPal redirects to upon
     * payment success. If the full URL to this page is
     * https://www.example.com/paypal/action/return.html, the relative URL is
     * paypal/action/return.html.
     */
    return_url: Scalars['String']
    /**
     * The relative URL of the order confirmation page that PayPal redirects to when
     * the payment is successful and additional confirmation is not needed. Not
     * applicable to most PayPal solutions. If the full URL to this page is
     * https://www.example.com/paypal/action/success.html, the relative URL is
     * paypal/action/success.html.
     */
    success_url?: Maybe<Scalars['String']>
}

/** Personal Shopper Box */
export interface PersonalShopperBox {
    __typename?: 'PersonalShopperBox'
    /** Box Description */
    description: Maybe<Scalars['String']>
    /** The date after which this Personal Shopper Box will expire */
    expirationDate: Maybe<Scalars['String']>
    /** Box Items */
    items: Array<Maybe<PersonalShopperBoxItem>>
    /** Title */
    title: Scalars['String']
    /** The unique ID for a `PersonalShopperBox` object. */
    uid: Scalars['ID']
}

/** Personal Shopper Box Item */
export interface PersonalShopperBoxItem {
    __typename?: 'PersonalShopperBoxItem'
    /** The Box Item ID used for REST cart item calls (GraphQL should use uid instead). */
    boxItemId: Scalars['Int']
    /** Item Description */
    description: Maybe<Scalars['String']>
    /** Catalog Product (configurable parent when available) */
    product: ProductInterface
    /** SKU (in case of a Configurable Product this is the preferred SKU */
    productSku: Scalars['String']
    /** The suggested quantity */
    qty: Scalars['Float']
    /** The unique ID for a `PersonalShopperBoxItem` object. */
    uid: Scalars['ID']
}

/** Contains attributes specific to tangible products. */
export interface PhysicalProductInterface {
    /** The weight of the item, in units defined by the store. */
    weight: Maybe<Scalars['Float']>
}

/** Defines Pickup Location information. */
export interface PickupLocation {
    __typename?: 'PickupLocation'
    city: Maybe<Scalars['String']>
    contactName: Maybe<Scalars['String']>
    countryId: Maybe<Scalars['String']>
    description: Maybe<Scalars['String']>
    email: Maybe<Scalars['String']>
    fax: Maybe<Scalars['String']>
    latitude: Maybe<Scalars['Float']>
    longitude: Maybe<Scalars['Float']>
    name: Maybe<Scalars['String']>
    phone: Maybe<Scalars['String']>
    pickupLocationCode: Maybe<Scalars['String']>
    postcode: Maybe<Scalars['String']>
    region: Maybe<Scalars['String']>
    regionId: Maybe<Scalars['Int']>
    street: Maybe<Scalars['String']>
}

/** PickupLocationFilterInput defines the list of attributes and filters for the search. */
export interface PickupLocationFilterInput {
    /** Filter by city. */
    city?: Maybe<FilterTypeInput>
    /** Filter by country. */
    country_id?: Maybe<FilterTypeInput>
    /** Filter by pickup location name. */
    name?: Maybe<FilterTypeInput>
    /** Filter by pickup location code. */
    pickup_location_code?: Maybe<FilterTypeInput>
    /** Filter by postcode. */
    postcode?: Maybe<FilterTypeInput>
    /** Filter by region. */
    region?: Maybe<FilterTypeInput>
    /** Filter by region id. */
    region_id?: Maybe<FilterTypeInput>
    /** Filter by street. */
    street?: Maybe<FilterTypeInput>
}

/** Top level object returned in a pickup locations search. */
export interface PickupLocations {
    __typename?: 'PickupLocations'
    /** An array of pickup locations that match the specific search request. */
    items: Maybe<Array<Maybe<PickupLocation>>>
    /** An object that includes the page_info and currentPage values specified in the query. */
    pageInfo: Maybe<SearchResultPageInfo>
    /** The number of products returned. */
    totalCount: Maybe<Scalars['Int']>
}

/**
 * PickupLocationSortInput specifies attribute to use for sorting search results
 * and indicates whether the results are sorted in ascending or descending order.
 */
export interface PickupLocationSortInput {
    /** City where pickup location is placed. */
    city?: Maybe<SortEnum>
    /** Name of the contact person. */
    contact_name?: Maybe<SortEnum>
    /** Id of the country in two letters. */
    country_id?: Maybe<SortEnum>
    /** Description of the pickup location. */
    description?: Maybe<SortEnum>
    /**
     * Distance to the address, requested by distance filter. Applicable only with
     * distance filter. If distance sort order is present, all other sort orders will be ignored.
     */
    distance?: Maybe<SortEnum>
    /** Contact email of the pickup location. */
    email?: Maybe<SortEnum>
    /** Contact fax of the pickup location. */
    fax?: Maybe<SortEnum>
    /** Geographic latitude where pickup location is placed. */
    latitude?: Maybe<SortEnum>
    /** Geographic longitude where pickup location is placed. */
    longitude?: Maybe<SortEnum>
    /** The pickup location name. Customer use this to identify the pickup location. */
    name?: Maybe<SortEnum>
    /** Contact phone number of the pickup location. */
    phone?: Maybe<SortEnum>
    /** A code assigned to pickup location to identify the source. */
    pickup_location_code?: Maybe<SortEnum>
    /** Postcode where pickup location is placed. */
    postcode?: Maybe<SortEnum>
    /** Name of the region. */
    region?: Maybe<SortEnum>
    /** Id of the region. */
    region_id?: Maybe<SortEnum>
    /** Street where pickup location is placed. */
    street?: Maybe<SortEnum>
}

export interface PickupPoint {
    __typename?: 'PickupPoint'
    /** Pickup point cost, current taken from the shipping method value */
    amount: Money
    /** DHL pickup point ID */
    optionCode: Scalars['String']
    /** DHL pickup point Name */
    optionTitle: Scalars['String']
}

export interface PickupPointFilterInput {
    /** Country code to filter by */
    country?: Maybe<Scalars['String']>
    /** Amount of pickup points to return */
    limit?: Maybe<Scalars['Int']>
    /** Postalcode to filter by */
    postalCode?: Maybe<Scalars['String']>
}

/** An error encountered while placing an order. */
export interface PlaceOrderError {
    __typename?: 'PlaceOrderError'
    /** An error code that is specific to place order. */
    code: PlaceOrderErrorCodes
    /** A localized error message. */
    message: Scalars['String']
}

export enum PlaceOrderErrorCodes {
    CART_NOT_FOUND = 'CART_NOT_FOUND',
    CART_NOT_ACTIVE = 'CART_NOT_ACTIVE',
    GUEST_EMAIL_MISSING = 'GUEST_EMAIL_MISSING',
    UNABLE_TO_PLACE_ORDER = 'UNABLE_TO_PLACE_ORDER',
    UNDEFINED = 'UNDEFINED',
}

/** Specifies the quote to be converted to an order. */
export interface PlaceOrderInput {
    /** The unique ID of a `Cart` object. */
    cart_id: Scalars['String']
}

/** Contains the results of the request to place an order. */
export interface PlaceOrderOutput {
    __typename?: 'PlaceOrderOutput'
    /** An array of place order errors. */
    errors: Array<Maybe<PlaceOrderError>>
    /**
     * The ID of the order.
     * @deprecated Use `orderV2` instead.
     */
    order: Maybe<Order>
    /** Full order information. */
    orderV2: Maybe<CustomerOrder>
}

export interface PlpMemberUsps extends Document, Linkable {
    __typename?: 'PlpMemberUsps'
    storeview: Maybe<Scalars['String']>
    type: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    headingVariant: Maybe<Scalars['String']>
    content: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonStyle: Maybe<Scalars['String']>
    usps: Maybe<Array<PlpMemberUspsUsps>>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

/** A connection to a list of items. */
export interface PlpMemberUspsConnectionConnection {
    __typename?: 'PlpMemberUspsConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<PlpMemberUspsConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface PlpMemberUspsConnectionEdge {
    __typename?: 'PlpMemberUspsConnectionEdge'
    /** The item at the end of the edge. */
    node: PlpMemberUsps
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

export interface PlpMemberUspsUsps {
    __typename?: 'PlpMemberUspsUsps'
    label: Maybe<Scalars['String']>
    icon: Maybe<Scalars['String']>
}

export interface PmeOutletFaqPage extends Document, Linkable {
    __typename?: 'PmeOutletFaqPage'
    urlKey: Maybe<Scalars['String']>
    search: Maybe<Scalars['Boolean']>
    seoTitle: Maybe<Scalars['String']>
    seoDescription: Maybe<Scalars['String']>
    seoKeywords: Maybe<Scalars['String']>
    style: Maybe<Scalars['String']>
    head: Maybe<Scalars['Json']>
    noindex: Maybe<Scalars['Boolean']>
    nofollow: Maybe<Scalars['Boolean']>
    body: Maybe<Array<PmeOutletFaqPageBody>>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

export type PmeOutletFaqPageBody =
    | PmeOutletFaqPageBodyHero
    | PmeOutletFaqPageBodyFaqSubject

export interface PmeOutletFaqPageBodyFaqSubject {
    __typename?: 'PmeOutletFaqPageBodyFaqSubject'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<PmeOutletFaqPageBodyFaqSubjectPrimary>
    fields: Maybe<Array<PmeOutletFaqPageBodyFaqSubjectFields>>
}

export interface PmeOutletFaqPageBodyFaqSubjectFields {
    __typename?: 'PmeOutletFaqPageBodyFaqSubjectFields'
    heading: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
}

export interface PmeOutletFaqPageBodyFaqSubjectPrimary {
    __typename?: 'PmeOutletFaqPageBodyFaqSubjectPrimary'
    urlKey: Maybe<Scalars['String']>
    seoTitle: Maybe<Scalars['String']>
    seoKeywords: Maybe<Scalars['String']>
    seoDescription: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    icon: Maybe<PrismicImageType>
}

export interface PmeOutletFaqPageBodyHero {
    __typename?: 'PmeOutletFaqPageBodyHero'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<PmeOutletFaqPageBodyHeroPrimary>
}

export interface PmeOutletFaqPageBodyHeroPrimary {
    __typename?: 'PmeOutletFaqPageBodyHeroPrimary'
    fullWidth: Maybe<Scalars['Boolean']>
    size: Maybe<Scalars['String']>
    headingfuss: Maybe<Scalars['Boolean']>
    header: Maybe<Scalars['String']>
    headingVariant: Maybe<Scalars['String']>
    headingElement: Maybe<Scalars['String']>
    subheading: Maybe<Scalars['String']>
    subheadingVariant: Maybe<Scalars['String']>
    subheadingElement: Maybe<Scalars['String']>
    videoId: Maybe<Scalars['String']>
    mobileVideoId: Maybe<Scalars['String']>
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
}

/** A connection to a list of items. */
export interface PmeOutletFaqPageConnectionConnection {
    __typename?: 'PmeOutletFaqPageConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<PmeOutletFaqPageConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface PmeOutletFaqPageConnectionEdge {
    __typename?: 'PmeOutletFaqPageConnectionEdge'
    /** The item at the end of the edge. */
    node: PmeOutletFaqPage
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

export interface PmeOutletLoginPage extends Document, Linkable {
    __typename?: 'PmeOutletLoginPage'
    urlKey: Maybe<Scalars['String']>
    search: Maybe<Scalars['Boolean']>
    seoTitle: Maybe<Scalars['String']>
    seoDescription: Maybe<Scalars['String']>
    seoKeywords: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
    subTitle: Maybe<Scalars['String']>
    subHeading: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
    backgroundImage: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    logos: Maybe<Array<PmeOutletLoginPageLogos>>
    body: Maybe<Array<PmeOutletLoginPageBody>>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

export type PmeOutletLoginPageBody = PmeOutletLoginPageBodyFaqSubject

export interface PmeOutletLoginPageBodyFaqSubject {
    __typename?: 'PmeOutletLoginPageBodyFaqSubject'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<PmeOutletLoginPageBodyFaqSubjectPrimary>
    fields: Maybe<Array<PmeOutletLoginPageBodyFaqSubjectFields>>
}

export interface PmeOutletLoginPageBodyFaqSubjectFields {
    __typename?: 'PmeOutletLoginPageBodyFaqSubjectFields'
    heading: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
}

export interface PmeOutletLoginPageBodyFaqSubjectPrimary {
    __typename?: 'PmeOutletLoginPageBodyFaqSubjectPrimary'
    urlKey: Maybe<Scalars['String']>
    seoTitle: Maybe<Scalars['String']>
    seoKeywords: Maybe<Scalars['String']>
    seoDescription: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    icon: Maybe<PrismicImageType>
}

/** A connection to a list of items. */
export interface PmeOutletLoginPageConnectionConnection {
    __typename?: 'PmeOutletLoginPageConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<PmeOutletLoginPageConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface PmeOutletLoginPageConnectionEdge {
    __typename?: 'PmeOutletLoginPageConnectionEdge'
    /** The item at the end of the edge. */
    node: PmeOutletLoginPage
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

export interface PmeOutletLoginPageLogos {
    __typename?: 'PmeOutletLoginPageLogos'
    logoImage: Maybe<PrismicImageType>
}

export interface PmeOutletPage extends Document, Linkable {
    __typename?: 'PmeOutletPage'
    urlKey: Maybe<Scalars['String']>
    search: Maybe<Scalars['Boolean']>
    seoTitle: Maybe<Scalars['String']>
    seoDescription: Maybe<Scalars['String']>
    seoKeywords: Maybe<Scalars['String']>
    style: Maybe<Scalars['String']>
    head: Maybe<Scalars['Json']>
    noindex: Maybe<Scalars['Boolean']>
    nofollow: Maybe<Scalars['Boolean']>
    authRequired: Maybe<Scalars['Boolean']>
    pageBackground: Maybe<Scalars['Json']>
    pageBackgroundColor: Maybe<Scalars['String']>
    body: Maybe<Array<PmeOutletPageBody>>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

export type PmeOutletPageBody =
    | PmeOutletPageBodyProductHero
    | PmeOutletPageBodyTweakwiseSlider
    | PmeOutletPageBodyImageSlider
    | PmeOutletPageBodyHero
    | PmeOutletPageBodyTextOnly
    | PmeOutletPageBodyImageOnly
    | PmeOutletPageBodyTextWithMedia
    | PmeOutletPageBodyHtml
    | PmeOutletPageBodyCategoryElement
    | PmeOutletPageBodyBannerBlocks
    | PmeOutletPageBodyBannerBlocks1
    | PmeOutletPageBodyBannerBlocks2
    | PmeOutletPageBodyFaq
    | PmeOutletPageBodyCategoryList

export interface PmeOutletPageBodyBannerBlocks {
    __typename?: 'PmeOutletPageBodyBannerBlocks'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<PmeOutletPageBodyBannerBlocksPrimary>
    fields: Maybe<Array<PmeOutletPageBodyBannerBlocksFields>>
}

export interface PmeOutletPageBodyBannerBlocks1 {
    __typename?: 'PmeOutletPageBodyBannerBlocks1'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<PmeOutletPageBodyBannerBlocks1Primary>
    fields: Maybe<Array<PmeOutletPageBodyBannerBlocks1Fields>>
}

export interface PmeOutletPageBodyBannerBlocks1Fields {
    __typename?: 'PmeOutletPageBodyBannerBlocks1Fields'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    subtitle: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

export interface PmeOutletPageBodyBannerBlocks1Primary {
    __typename?: 'PmeOutletPageBodyBannerBlocks1Primary'
    style: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
}

export interface PmeOutletPageBodyBannerBlocks2 {
    __typename?: 'PmeOutletPageBodyBannerBlocks2'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<PmeOutletPageBodyBannerBlocks2Primary>
    fields: Maybe<Array<PmeOutletPageBodyBannerBlocks2Fields>>
}

export interface PmeOutletPageBodyBannerBlocks2Fields {
    __typename?: 'PmeOutletPageBodyBannerBlocks2Fields'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    subtitle: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

export interface PmeOutletPageBodyBannerBlocks2Primary {
    __typename?: 'PmeOutletPageBodyBannerBlocks2Primary'
    style: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
}

export interface PmeOutletPageBodyBannerBlocksFields {
    __typename?: 'PmeOutletPageBodyBannerBlocksFields'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
}

export interface PmeOutletPageBodyBannerBlocksPrimary {
    __typename?: 'PmeOutletPageBodyBannerBlocksPrimary'
    style: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
}

export interface PmeOutletPageBodyCategoryElement {
    __typename?: 'PmeOutletPageBodyCategoryElement'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<PmeOutletPageBodyCategoryElementPrimary>
    fields: Maybe<Array<PmeOutletPageBodyCategoryElementFields>>
}

export interface PmeOutletPageBodyCategoryElementFields {
    __typename?: 'PmeOutletPageBodyCategoryElementFields'
    title: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
}

export interface PmeOutletPageBodyCategoryElementPrimary {
    __typename?: 'PmeOutletPageBodyCategoryElementPrimary'
    style: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
}

export interface PmeOutletPageBodyCategoryList {
    __typename?: 'PmeOutletPageBodyCategoryList'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<PmeOutletPageBodyCategoryListPrimary>
    fields: Maybe<Array<PmeOutletPageBodyCategoryListFields>>
}

export interface PmeOutletPageBodyCategoryListFields {
    __typename?: 'PmeOutletPageBodyCategoryListFields'
    title: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
    list: Maybe<Scalars['String']>
}

export interface PmeOutletPageBodyCategoryListPrimary {
    __typename?: 'PmeOutletPageBodyCategoryListPrimary'
    style: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    title1: Maybe<Scalars['String']>
    title2: Maybe<Scalars['String']>
    title3: Maybe<Scalars['String']>
    title4: Maybe<Scalars['String']>
}

export interface PmeOutletPageBodyFaq {
    __typename?: 'PmeOutletPageBodyFaq'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<PmeOutletPageBodyFaqPrimary>
    fields: Maybe<Array<PmeOutletPageBodyFaqFields>>
}

export interface PmeOutletPageBodyFaqFields {
    __typename?: 'PmeOutletPageBodyFaqFields'
    iconimage: Maybe<PrismicImageType>
    category: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
}

export interface PmeOutletPageBodyFaqPrimary {
    __typename?: 'PmeOutletPageBodyFaqPrimary'
    style: Maybe<Scalars['String']>
    emptyCategoryText: Maybe<Scalars['String']>
}

export interface PmeOutletPageBodyHero {
    __typename?: 'PmeOutletPageBodyHero'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<PmeOutletPageBodyHeroPrimary>
}

export interface PmeOutletPageBodyHeroPrimary {
    __typename?: 'PmeOutletPageBodyHeroPrimary'
    fullWidth: Maybe<Scalars['Boolean']>
    size: Maybe<Scalars['String']>
    headingfuss: Maybe<Scalars['Boolean']>
    header: Maybe<Scalars['String']>
    headingVariant: Maybe<Scalars['String']>
    headingElement: Maybe<Scalars['String']>
    subheading: Maybe<Scalars['String']>
    subheadingVariant: Maybe<Scalars['String']>
    subheadingElement: Maybe<Scalars['String']>
    videoId: Maybe<Scalars['String']>
    mobileVideoId: Maybe<Scalars['String']>
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
}

export interface PmeOutletPageBodyHtml {
    __typename?: 'PmeOutletPageBodyHtml'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<PmeOutletPageBodyHtmlPrimary>
}

export interface PmeOutletPageBodyHtmlPrimary {
    __typename?: 'PmeOutletPageBodyHtmlPrimary'
    style: Maybe<Scalars['String']>
    html: Maybe<Scalars['Json']>
    tablet: Maybe<Scalars['String']>
    desktop: Maybe<Scalars['String']>
}

export interface PmeOutletPageBodyImageOnly {
    __typename?: 'PmeOutletPageBodyImageOnly'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<PmeOutletPageBodyImageOnlyPrimary>
}

export interface PmeOutletPageBodyImageOnlyPrimary {
    __typename?: 'PmeOutletPageBodyImageOnlyPrimary'
    style: Maybe<Scalars['String']>
    tablet: Maybe<Scalars['String']>
    desktop: Maybe<Scalars['String']>
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
}

export interface PmeOutletPageBodyImageSlider {
    __typename?: 'PmeOutletPageBodyImageSlider'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<PmeOutletPageBodyImageSliderPrimary>
    fields: Maybe<Array<PmeOutletPageBodyImageSliderFields>>
}

export interface PmeOutletPageBodyImageSliderFields {
    __typename?: 'PmeOutletPageBodyImageSliderFields'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    subtitle: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

export interface PmeOutletPageBodyImageSliderPrimary {
    __typename?: 'PmeOutletPageBodyImageSliderPrimary'
    style: Maybe<Scalars['String']>
    variant: Maybe<Scalars['String']>
    header: Maybe<Scalars['String']>
    content: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

export interface PmeOutletPageBodyProductHero {
    __typename?: 'PmeOutletPageBodyProductHero'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<PmeOutletPageBodyProductHeroPrimary>
}

export interface PmeOutletPageBodyProductHeroPrimary {
    __typename?: 'PmeOutletPageBodyProductHeroPrimary'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    content: Maybe<Scalars['String']>
    subheading: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
    button2Url: Maybe<Scalars['String']>
    button2Text: Maybe<Scalars['String']>
    buttonStyle: Maybe<Scalars['String']>
    textStyle: Maybe<Scalars['String']>
    subheadingStyle: Maybe<Scalars['String']>
    videoId: Maybe<Scalars['String']>
    mobileVideoId: Maybe<Scalars['String']>
    productSkus: Maybe<Scalars['String']>
    productCueTimes: Maybe<Scalars['String']>
}

export interface PmeOutletPageBodyTextOnly {
    __typename?: 'PmeOutletPageBodyTextOnly'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<PmeOutletPageBodyTextOnlyPrimary>
}

export interface PmeOutletPageBodyTextOnlyPrimary {
    __typename?: 'PmeOutletPageBodyTextOnlyPrimary'
    style: Maybe<Scalars['String']>
    visibility: Maybe<Scalars['String']>
    tablet: Maybe<Scalars['String']>
    desktop: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    headingVariant: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
    buttonText: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    blockBackground: Maybe<Scalars['Json']>
    blockBackgroundColor: Maybe<Scalars['String']>
}

export interface PmeOutletPageBodyTextWithMedia {
    __typename?: 'PmeOutletPageBodyTextWithMedia'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<PmeOutletPageBodyTextWithMediaPrimary>
    fields: Maybe<Array<PmeOutletPageBodyTextWithMediaFields>>
}

export interface PmeOutletPageBodyTextWithMediaFields {
    __typename?: 'PmeOutletPageBodyTextWithMediaFields'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
}

export interface PmeOutletPageBodyTextWithMediaPrimary {
    __typename?: 'PmeOutletPageBodyTextWithMediaPrimary'
    style: Maybe<Scalars['String']>
    desktopMediaPosition: Maybe<Scalars['String']>
    mobileMediaPosition: Maybe<Scalars['String']>
    alignment: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    headingfuss: Maybe<Scalars['Boolean']>
    headingVariant: Maybe<Scalars['String']>
    headingElement: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
    buttonStyle: Maybe<Scalars['String']>
    button2Url: Maybe<Scalars['String']>
    button2Text: Maybe<Scalars['String']>
    button2Style: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
    videoId: Maybe<Scalars['String']>
    mobileVideoId: Maybe<Scalars['String']>
}

export interface PmeOutletPageBodyTweakwiseSlider {
    __typename?: 'PmeOutletPageBodyTweakwiseSlider'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<PmeOutletPageBodyTweakwiseSliderPrimary>
}

export interface PmeOutletPageBodyTweakwiseSliderPrimary {
    __typename?: 'PmeOutletPageBodyTweakwiseSliderPrimary'
    blueconicPosition: Maybe<Scalars['String']>
    style: Maybe<Scalars['String']>
    header: Maybe<Scalars['String']>
    content: Maybe<Scalars['String']>
    recommendationsIdentifier: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

/** A connection to a list of items. */
export interface PmeOutletPageConnectionConnection {
    __typename?: 'PmeOutletPageConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<PmeOutletPageConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface PmeOutletPageConnectionEdge {
    __typename?: 'PmeOutletPageConnectionEdge'
    /** The item at the end of the edge. */
    node: PmeOutletPage
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

/** Deprecated. Use `ProductPrice` instead. Defines the price of a product as well as any tax-related adjustments. */
export interface Price {
    __typename?: 'Price'
    /**
     * An array that provides information about tax, weee, or weee_tax adjustments.
     * @deprecated Use `ProductPrice` instead.
     */
    adjustments: Maybe<Array<Maybe<PriceAdjustment>>>
    /**
     * The price of a product plus a three-letter currency code.
     * @deprecated Use `ProductPrice` instead.
     */
    amount: Maybe<Money>
}

/**
 * Deprecated. Taxes will be included or excluded in the price. Defines the amount
 * of money to apply as an adjustment, the type of adjustment to apply, and whether
 * the item is included or excluded from the adjustment.
 */
export interface PriceAdjustment {
    __typename?: 'PriceAdjustment'
    /** The amount of the price adjustment and its currency code. */
    amount: Maybe<Money>
    /**
     * Indicates whether the adjustment involves tax, weee, or weee_tax.
     * @deprecated `PriceAdjustment` is deprecated.
     */
    code: Maybe<PriceAdjustmentCodesEnum>
    /**
     * Indicates whether the entity described by the code attribute is included or excluded from the adjustment.
     * @deprecated `PriceAdjustment` is deprecated.
     */
    description: Maybe<PriceAdjustmentDescriptionEnum>
}

/** `PriceAdjustment.code` is deprecated. */
export enum PriceAdjustmentCodesEnum {
    TAX = 'TAX',
    WEEE = 'WEEE',
    WEEE_TAX = 'WEEE_TAX',
}

/** `PriceAdjustmentDescriptionEnum` is deprecated. States whether a price adjustment is included or excluded. */
export enum PriceAdjustmentDescriptionEnum {
    INCLUDED = 'INCLUDED',
    EXCLUDED = 'EXCLUDED',
}

/** Can be used to retrieve the main price details in case of bundle product */
export interface PriceDetails {
    __typename?: 'PriceDetails'
    /** The percentage of discount applied to the main product price */
    discountPercentage: Maybe<Scalars['Float']>
    /** The final price after applying the discount to the main product */
    mainFinalPrice: Maybe<Scalars['Float']>
    /** The regular price of the main product */
    mainPrice: Maybe<Scalars['Float']>
}

/** Contains the price range for a product. If the product has a single price, the minimum and maximum price will be the same. */
export interface PriceRange {
    __typename?: 'PriceRange'
    /** The highest possible price for the product. */
    maximumPrice: Maybe<ProductPrice>
    /** The lowest possible price for the product. */
    minimumPrice: ProductPrice
}

/** Defines the price type. */
export enum PriceTypeEnum {
    FIXED = 'FIXED',
    PERCENT = 'PERCENT',
    DYNAMIC = 'DYNAMIC',
}

/** Defines whether a bundle product's price is displayed as the lowest possible value or as a range. */
export enum PriceViewEnum {
    PRICE_RANGE = 'PRICE_RANGE',
    AS_LOW_AS = 'AS_LOW_AS',
}

/** Prismic Document */
export interface PrismicDocument {
    __typename?: 'PrismicDocument'
    data: Maybe<Scalars['Json']>
    documentId: Scalars['String']
    firstPublicationDate: Maybe<Scalars['String']>
    lastPublicationDate: Maybe<Scalars['String']>
    tags: Array<Maybe<Scalars['String']>>
}

/** Prismic Predicate with multiple values */
export interface PrismicPredicateManyValues {
    key: Scalars['String']
    values: Array<Maybe<Scalars['String']>>
}

/** Prismic Predicate with one value */
export interface PrismicPredicateOneValue {
    key: Scalars['String']
    value: Scalars['String']
}

export interface PrivilegesPage extends Document, Linkable {
    __typename?: 'PrivilegesPage'
    storeview: Maybe<Scalars['String']>
    body: Maybe<Array<PrivilegesPageBody>>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

export type PrivilegesPageBody =
    | PrivilegesPageBodyPerks
    | PrivilegesPageBodyPromoUsps
    | PrivilegesPageBodyHero
    | PrivilegesPageBodyFaqSubject
    | PrivilegesPageBodyFooter

export interface PrivilegesPageBodyFaqSubject {
    __typename?: 'PrivilegesPageBodyFaqSubject'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<PrivilegesPageBodyFaqSubjectPrimary>
    fields: Maybe<Array<PrivilegesPageBodyFaqSubjectFields>>
}

export interface PrivilegesPageBodyFaqSubjectFields {
    __typename?: 'PrivilegesPageBodyFaqSubjectFields'
    heading: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
}

export interface PrivilegesPageBodyFaqSubjectPrimary {
    __typename?: 'PrivilegesPageBodyFaqSubjectPrimary'
    urlKey: Maybe<Scalars['String']>
    seoTitle: Maybe<Scalars['String']>
    seoKeywords: Maybe<Scalars['String']>
    seoDescription: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    icon: Maybe<PrismicImageType>
}

export interface PrivilegesPageBodyFooter {
    __typename?: 'PrivilegesPageBodyFooter'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<PrivilegesPageBodyFooterPrimary>
}

export interface PrivilegesPageBodyFooterPrimary {
    __typename?: 'PrivilegesPageBodyFooterPrimary'
    footerImage: Maybe<PrismicImageType>
    footerMobileImage: Maybe<PrismicImageType>
}

export interface PrivilegesPageBodyHero {
    __typename?: 'PrivilegesPageBodyHero'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<PrivilegesPageBodyHeroPrimary>
}

export interface PrivilegesPageBodyHeroPrimary {
    __typename?: 'PrivilegesPageBodyHeroPrimary'
    fullWidth: Maybe<Scalars['Boolean']>
    size: Maybe<Scalars['String']>
    headingfuss: Maybe<Scalars['Boolean']>
    header: Maybe<Scalars['String']>
    headingVariant: Maybe<Scalars['String']>
    headingElement: Maybe<Scalars['String']>
    subheading: Maybe<Scalars['String']>
    subheadingVariant: Maybe<Scalars['String']>
    subheadingElement: Maybe<Scalars['String']>
    videoId: Maybe<Scalars['String']>
    mobileVideoId: Maybe<Scalars['String']>
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
}

export interface PrivilegesPageBodyPerks {
    __typename?: 'PrivilegesPageBodyPerks'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<PrivilegesPageBodyPerksPrimary>
    fields: Maybe<Array<PrivilegesPageBodyPerksFields>>
}

export interface PrivilegesPageBodyPerksFields {
    __typename?: 'PrivilegesPageBodyPerksFields'
    header: Maybe<Scalars['String']>
    subheader: Maybe<Scalars['String']>
    iconInactive: Maybe<Scalars['String']>
    iconActive: Maybe<Scalars['String']>
}

export interface PrivilegesPageBodyPerksPrimary {
    __typename?: 'PrivilegesPageBodyPerksPrimary'
    footerText: Maybe<Scalars['String']>
    buttonLabel: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
}

export interface PrivilegesPageBodyPromoUsps {
    __typename?: 'PrivilegesPageBodyPromoUsps'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<PrivilegesPageBodyPromoUspsPrimary>
    fields: Maybe<Array<PrivilegesPageBodyPromoUspsFields>>
}

export interface PrivilegesPageBodyPromoUspsFields {
    __typename?: 'PrivilegesPageBodyPromoUspsFields'
    label: Maybe<Scalars['String']>
    icon: Maybe<Scalars['String']>
}

export interface PrivilegesPageBodyPromoUspsPrimary {
    __typename?: 'PrivilegesPageBodyPromoUspsPrimary'
    promoLeftTitle: Maybe<Scalars['Json']>
    promoText: Maybe<Scalars['String']>
    buttonLabel: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
}

/** A connection to a list of items. */
export interface PrivilegesPageConnectionConnection {
    __typename?: 'PrivilegesPageConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<PrivilegesPageConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface PrivilegesPageConnectionEdge {
    __typename?: 'PrivilegesPageConnectionEdge'
    /** The item at the end of the edge. */
    node: PrivilegesPage
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

export interface PrivilegesPerks extends Document, Linkable {
    __typename?: 'PrivilegesPerks'
    storeview: Maybe<Scalars['String']>
    footerText: Maybe<Scalars['String']>
    buttonLabel: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonStyle: Maybe<Scalars['String']>
    perks: Maybe<Array<PrivilegesPerksPerks>>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

/** A connection to a list of items. */
export interface PrivilegesPerksConnectionConnection {
    __typename?: 'PrivilegesPerksConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<PrivilegesPerksConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface PrivilegesPerksConnectionEdge {
    __typename?: 'PrivilegesPerksConnectionEdge'
    /** The item at the end of the edge. */
    node: PrivilegesPerks
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

export interface PrivilegesPerksPerks {
    __typename?: 'PrivilegesPerksPerks'
    header: Maybe<Scalars['String']>
    subheader: Maybe<Scalars['String']>
    iconInactive: Maybe<Scalars['String']>
    iconActive: Maybe<Scalars['String']>
}

export interface PrivilegesUsps extends Document, Linkable {
    __typename?: 'PrivilegesUsps'
    storeview: Maybe<Scalars['String']>
    contents: Maybe<Scalars['String']>
    buttonLabel: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonStyle: Maybe<Scalars['String']>
    usps: Maybe<Array<PrivilegesUspsUsps>>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

/** A connection to a list of items. */
export interface PrivilegesUspsConnectionConnection {
    __typename?: 'PrivilegesUspsConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<PrivilegesUspsConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface PrivilegesUspsConnectionEdge {
    __typename?: 'PrivilegesUspsConnectionEdge'
    /** The item at the end of the edge. */
    node: PrivilegesUsps
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

export interface PrivilegesUspsUsps {
    __typename?: 'PrivilegesUspsUsps'
    label: Maybe<Scalars['String']>
    icon: Maybe<Scalars['String']>
}

/** Contains a product attribute code and value. */
export interface ProductAttribute {
    __typename?: 'ProductAttribute'
    /** The unique identifier for a product attribute code. */
    code: Scalars['String']
    /** The display value of the attribute. */
    value: Scalars['String']
}

/**
 * Defines the filters to be used in the search. A filter contains at least one
 * attribute, a comparison operator, and the value that is being searched for.
 */
export interface ProductAttributeFilterInput {
    /** Attribute label: ProductClosure */
    ProductClosure?: Maybe<FilterEqualTypeInput>
    /** Attribute label: Product Sleeve Length */
    ProductSleeveLength?: Maybe<FilterEqualTypeInput>
    /** Attribute label: Artikelnummer */
    articlenumber?: Maybe<FilterMatchTypeInput>
    /** Deprecated: use `category_uid` to filter product by category ID. */
    category_id?: Maybe<FilterEqualTypeInput>
    /** Filter product by the unique ID for a `CategoryInterface` object. */
    category_uid?: Maybe<FilterEqualTypeInput>
    /** Filter product by category URL path. */
    category_url_path?: Maybe<FilterEqualTypeInput>
    /** Attribute label: Color */
    color?: Maybe<FilterEqualTypeInput>
    /** Attribute label: Description */
    description?: Maybe<FilterMatchTypeInput>
    /** Attribute label: jeans_size_combined */
    jeans_size_combined?: Maybe<FilterEqualTypeInput>
    /** Attribute label: length */
    length?: Maybe<FilterEqualTypeInput>
    /** Attribute label: Product Name */
    name?: Maybe<FilterMatchTypeInput>
    /** Attribute label: Price */
    price?: Maybe<FilterRangeTypeInput>
    /** Attribute label: season_collection */
    season_collection?: Maybe<FilterEqualTypeInput>
    /** Attribute label: Short Description */
    short_description?: Maybe<FilterMatchTypeInput>
    /** Attribute label: size */
    size?: Maybe<FilterEqualTypeInput>
    /** Attribute label: SKU */
    sku?: Maybe<FilterEqualTypeInput>
    /** The part of the URL that identifies the product */
    url_key?: Maybe<FilterEqualTypeInput>
}

/**
 * Specifies the attribute to use for sorting search results and indicates whether
 * the results are sorted in ascending or descending order. It's possible to sort
 * products using searchable attributes with enabled 'Use in Filter Options' option
 */
export interface ProductAttributeSortInput {
    /** Attribute label: Product Height CM */
    ProductHeight?: Maybe<SortEnum>
    /** Attribute label: Color */
    color?: Maybe<SortEnum>
    /** Attribute label: Product Name */
    name?: Maybe<SortEnum>
    /** Sort by the position assigned to each product. */
    position?: Maybe<SortEnum>
    /** Attribute label: Price */
    price?: Maybe<SortEnum>
    /** Sort by the search relevance score (default). */
    relevance?: Maybe<SortEnum>
}

/** Product custom attributes */
export interface ProductCustomAttributes {
    __typename?: 'ProductCustomAttributes'
    /** Errors when retrieving custom attributes metadata. */
    errors: Array<Maybe<AttributeMetadataError>>
    /** Requested custom attributes */
    items: Array<Maybe<AttributeValueInterface>>
}

/** Contains the discount applied to a product price. */
export interface ProductDiscount {
    __typename?: 'ProductDiscount'
    /** The actual value of the discount. */
    amountOff: Maybe<Scalars['Float']>
    /** The discount expressed a percentage. */
    percentOff: Maybe<Scalars['Float']>
}

/**
 * ProductFilterInput is deprecated, use @ProductAttributeFilterInput instead.
 * ProductFilterInput defines the filters to be used in the search. A filter
 * contains at least one attribute, a comparison operator, and the value that is
 * being searched for.
 */
export interface ProductFilterInput {
    /** The category ID the product belongs to. */
    category_id?: Maybe<FilterTypeInput>
    /** The product's country of origin. */
    country_of_manufacture?: Maybe<FilterTypeInput>
    /** The timestamp indicating when the product was created. */
    created_at?: Maybe<FilterTypeInput>
    /** The name of a custom layout. */
    custom_layout?: Maybe<FilterTypeInput>
    /** XML code that is applied as a layout update to the product page. */
    custom_layout_update?: Maybe<FilterTypeInput>
    /** Detailed information about the product. The value can include simple HTML tags. */
    description?: Maybe<FilterTypeInput>
    /** Indicates whether a gift message is available. */
    gift_message_available?: Maybe<FilterTypeInput>
    /** Indicates whether additional attributes have been created for the product. */
    has_options?: Maybe<FilterTypeInput>
    /** The relative path to the main image on the product page. */
    image?: Maybe<FilterTypeInput>
    /** The label assigned to a product image. */
    image_label?: Maybe<FilterTypeInput>
    /** A number representing the product's manufacturer. */
    manufacturer?: Maybe<FilterTypeInput>
    /** The numeric maximal price of the product. Do not include the currency code. */
    max_price?: Maybe<FilterTypeInput>
    /** A brief overview of the product for search results listings, maximum 255 characters. */
    meta_description?: Maybe<FilterTypeInput>
    /** A comma-separated list of keywords that are visible only to search engines. */
    meta_keyword?: Maybe<FilterTypeInput>
    /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
    meta_title?: Maybe<FilterTypeInput>
    /** The numeric minimal price of the product. Do not include the currency code. */
    min_price?: Maybe<FilterTypeInput>
    /** The product name. Customers use this name to identify the product. */
    name?: Maybe<FilterTypeInput>
    /** The beginning date for new product listings, and determines if the product is featured as a new product. */
    news_from_date?: Maybe<FilterTypeInput>
    /** The end date for new product listings. */
    news_to_date?: Maybe<FilterTypeInput>
    /** If the product has multiple options, determines where they appear on the product page. */
    options_container?: Maybe<FilterTypeInput>
    /** The keyword required to perform a logical OR comparison. */
    or?: Maybe<ProductFilterInput>
    /** The price of an item. */
    price?: Maybe<FilterTypeInput>
    /** Indicates whether the product has required options. */
    required_options?: Maybe<FilterTypeInput>
    /** A short description of the product. Its use depends on the theme. */
    short_description?: Maybe<FilterTypeInput>
    /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
    sku?: Maybe<FilterTypeInput>
    /** The relative path to the small image, which is used on catalog pages. */
    small_image?: Maybe<FilterTypeInput>
    /** The label assigned to a product's small image. */
    small_image_label?: Maybe<FilterTypeInput>
    /** The beginning date that a product has a special price. */
    special_from_date?: Maybe<FilterTypeInput>
    /** The discounted price of the product. Do not include the currency code. */
    special_price?: Maybe<FilterTypeInput>
    /** The end date that a product has a special price. */
    special_to_date?: Maybe<FilterTypeInput>
    /** The file name of a swatch image. */
    swatch_image?: Maybe<FilterTypeInput>
    /** The relative path to the product's thumbnail image. */
    thumbnail?: Maybe<FilterTypeInput>
    /** The label assigned to a product's thumbnail image. */
    thumbnail_label?: Maybe<FilterTypeInput>
    /** The price when tier pricing is in effect and the items purchased threshold has been reached. */
    tier_price?: Maybe<FilterTypeInput>
    /** The timestamp indicating when the product was updated. */
    updated_at?: Maybe<FilterTypeInput>
    /** The part of the URL that identifies the product */
    url_key?: Maybe<FilterTypeInput>
    url_path?: Maybe<FilterTypeInput>
    /** The weight of the item, in units defined by the store. */
    weight?: Maybe<FilterTypeInput>
}

/** Contains product image information, including the image URL and label. */
export interface ProductImage extends MediaGalleryInterface {
    __typename?: 'ProductImage'
    /** Indicates whether the image is hidden from view. */
    disabled: Maybe<Scalars['Boolean']>
    /** The label of the product image or video. */
    label: Maybe<Scalars['String']>
    /** The media item's position after it has been sorted. */
    position: Maybe<Scalars['Int']>
    /** The URL of the product image or video. */
    url: Maybe<Scalars['String']>
}

/** Product Information used for Pickup Locations search. */
export interface ProductInfoInput {
    /** Product SKU. */
    sku: Scalars['String']
}

/** Contains fields that are common to all types of products. */
export interface ProductInterface {
    /** @deprecated Use the `custom_attributes` field instead. */
    baseColorHexCode: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    brandAlias: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    brandMediaPdpBottom: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    brandMediaPdpTop: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    brandName: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    brandPdpDescription: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    brandPdpTitle: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    featureDescription: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    featureMedia: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    featureName: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    itemLabelTextB2C: Maybe<Scalars['Int']>
    itemLabelTextB2CValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    itemLabelTypeB2C: Maybe<Scalars['Int']>
    itemLabelTypeB2CValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    itemMaterialBody: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    itemMaterialBodyLining: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    itemMaterialLining: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    itemMaterialShell: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    itemSizeAdvice: Maybe<Scalars['Int']>
    itemSizeAdviceValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    modelAdvice: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productArticleNumber: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productBeltLoops: Maybe<Scalars['Int']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productBodyShell: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productChestPockets: Maybe<Scalars['Int']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productClosure: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productClosureDetails: Maybe<Scalars['String']>
    productClosureDetailsValues: Maybe<Array<Maybe<AttributeOption>>>
    productClosureValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productCollar: Maybe<Scalars['Int']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productCollarDetails: Maybe<Scalars['String']>
    productCollarDetailsValues: Maybe<Array<Maybe<AttributeOption>>>
    productCollarValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productContainsNonTextilePartsOfAnimalOrigin: Maybe<Scalars['Int']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productCuffs: Maybe<Scalars['String']>
    productCuffsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productDimensions: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productFabric: Maybe<Scalars['String']>
    productFabricValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productFashionColor: Maybe<Scalars['Int']>
    productFashionColorValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productFit: Maybe<Scalars['Int']>
    productFitValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productHeight: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productHood: Maybe<Scalars['Int']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productHoodDetails: Maybe<Scalars['Int']>
    productHoodDetailsValues: Maybe<Array<Maybe<AttributeOption>>>
    productHoodValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productInnerJacketDetails: Maybe<Scalars['String']>
    productInnerJacketDetailsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productInnerPockets: Maybe<Scalars['Int']>
    productInnerPocketsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productJacketDetails: Maybe<Scalars['String']>
    productJacketDetailsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productJacketType: Maybe<Scalars['Int']>
    productJacketTypeValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productJacketlayers: Maybe<Scalars['Int']>
    productJacketlayersValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productLength: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productLengthDescription: Maybe<Scalars['Int']>
    productLengthDescriptionValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productLogoLocation: Maybe<Scalars['String']>
    productLogoLocationValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMarketPlacesDescription: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMarketingStyle: Maybe<Scalars['Int']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMaterialBack: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMaterialFilling: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMaterialFront: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMaterialHood: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMaterialLining: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMaterialPadding: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMaterialShell: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productNumberOfPockets: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productOccassion: Maybe<Scalars['Int']>
    productOccassionValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productOuterPockets: Maybe<Scalars['String']>
    productOuterPocketsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productPaddingType: Maybe<Scalars['Int']>
    productPaddingTypeValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productPattern: Maybe<Scalars['String']>
    productPatternValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productPocketType: Maybe<Scalars['String']>
    productPocketTypeValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productRelevance: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productSeasonStatus: Maybe<Scalars['Int']>
    productSeasonStatusValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productShape: Maybe<Scalars['Int']>
    productShapeValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productSizeTable: Maybe<Scalars['Int']>
    productSizeTableValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productSleeveCuffDetails: Maybe<Scalars['Int']>
    productSleeveCuffDetailsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productSleeveDetails: Maybe<Scalars['Int']>
    productSleeveDetailsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productSleeveLength: Maybe<Scalars['Int']>
    productSleeveLengthValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productSustainable: Maybe<Scalars['String']>
    productSustainableValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productType: Maybe<Scalars['Int']>
    productTypeValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productVolume: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productWaistRise: Maybe<Scalars['Int']>
    productWaistRiseValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productWashingInstructionsClotex: Maybe<Scalars['String']>
    productWashingInstructionsClotexValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productWidth: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    sizeTableIdentifier: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    washingSymbolDescription: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    washingSymbolMedia: Maybe<Scalars['String']>
    /**
     * The attribute set assigned to the product.
     * @deprecated The field should not be used on the storefront.
     */
    attributeSetId: Maybe<Scalars['Int']>
    /** @deprecated Use the `custom_attributes` field instead. */
    baseColor: Maybe<Scalars['String']>
    baseColorValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    brand: Maybe<Scalars['String']>
    /**
     * The relative canonical URL. This value is returned only if the system setting
     * 'Use Canonical Link Meta Tag For Products' is enabled.
     */
    canonicalUrl: Maybe<Scalars['String']>
    /** The categories assigned to a product. */
    categories: Maybe<Array<Maybe<CategoryInterface>>>
    categoryIds: Maybe<Array<Maybe<Scalars['Int']>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    color: Maybe<Scalars['String']>
    colorValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    configAttributes: Maybe<Scalars['String']>
    /** Product bottom content */
    contentBottomContent: Maybe<Scalars['String']>
    /** Product top image one */
    contentBottomImageOne: Maybe<Scalars['String']>
    /** Product top image two */
    contentBottomImageTwo: Maybe<Scalars['String']>
    /** Product bottom title */
    contentBottomTitle: Maybe<Scalars['String']>
    contentBottomUsp: Maybe<Scalars['String']>
    /** Product middle content */
    contentMiddleContent: Maybe<Scalars['String']>
    /** Product top image one */
    contentMiddleImageOne: Maybe<Scalars['String']>
    /** Product top image two */
    contentMiddleImageTwo: Maybe<Scalars['String']>
    /** Product middle title */
    contentMiddleTitle: Maybe<Scalars['String']>
    /** Product size guide image above */
    contentSizeGuideImageAbove: Maybe<Scalars['String']>
    /** Product size guide image below */
    contentSizeGuideImageBelow: Maybe<Scalars['String']>
    /** Product top content */
    contentTopContent: Maybe<Scalars['String']>
    /** Product top image four */
    contentTopImageFour: Maybe<Scalars['String']>
    /** Product top image one */
    contentTopImageOne: Maybe<Scalars['String']>
    /** Product top image three */
    contentTopImageThree: Maybe<Scalars['String']>
    /** Product top image two */
    contentTopImageTwo: Maybe<Scalars['String']>
    /** Product top title */
    contentTopTitle: Maybe<Scalars['String']>
    /** The product's country of origin. */
    countryOfManufacture: Maybe<Scalars['String']>
    /**
     * Timestamp indicating when the product was created.
     * @deprecated The field should not be used on the storefront.
     */
    createdAt: Maybe<Scalars['String']>
    /** Crosssell Products */
    crosssellProducts: Maybe<Array<Maybe<ProductInterface>>>
    /** Product custom attributes. */
    customAttributesV2: Maybe<ProductCustomAttributes>
    /** Product Delivery Message */
    deliveryMessage: Maybe<Scalars['String']>
    /** Detailed information about the product. The value can include simple HTML tags. */
    description: Maybe<ComplexTextValue>
    /** @deprecated Use the `custom_attributes` field instead. */
    discountAmount: Maybe<Scalars['Float']>
    /** @deprecated Use the `custom_attributes` field instead. */
    discountPercentage: Maybe<Scalars['Float']>
    /** @deprecated Use the `custom_attributes` field instead. */
    featureIcons: Maybe<Scalars['String']>
    featureIconsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    features: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    fit: Maybe<Scalars['String']>
    fitValues: Maybe<Array<Maybe<AttributeOption>>>
    /** Indicates whether a gift message is available. */
    giftMessageAvailable: Maybe<Scalars['String']>
    hexColor: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    highlights: Maybe<Scalars['String']>
    highlightsValues: Maybe<Array<Maybe<AttributeOption>>>
    /**
     * The ID number assigned to the product.
     * @deprecated Use the `uid` field instead.
     */
    id: Scalars['Int']
    /** The relative path to the main image on the product page. */
    image: Maybe<ProductImage>
    /** URL for the Care Tab */
    infoTabCareUrl: Maybe<Scalars['String']>
    /** URL for the Features Tab */
    infoTabFeaturesUrl: Maybe<Scalars['String']>
    /** URL for the Fit Tab */
    infoTabFitUrl: Maybe<Scalars['String']>
    /** URL for the Highlights Tab */
    infoTabHighlightsUrl: Maybe<Scalars['String']>
    /** URL for the Materials Tab */
    infoTabMaterialsUrl: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    jeansSizeCombined: Maybe<Scalars['Int']>
    jeansSizeCombinedValues: Maybe<Array<Maybe<AttributeOption>>>
    /**
     * Proxy for ItemLabelTextB2C
     * @deprecated Use the `custom_attributes` field instead.
     */
    labeltekst: Maybe<Scalars['String']>
    /**
     * Proxy for ItemLabelTypeB2C
     * @deprecated Use the `custom_attributes` field instead.
     */
    labeltype: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    length: Maybe<Scalars['String']>
    lengthValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    lowestDate: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    lowestPrice: Maybe<Scalars['Float']>
    /** Main Category */
    mainCategory: Maybe<CategoryInterface>
    /**
     * A number representing the product's manufacturer.
     * @deprecated Use the `custom_attributes` field instead.
     */
    manufacturer: Maybe<Scalars['Int']>
    manufacturerValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    materialInside: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    materialOutside: Maybe<Scalars['String']>
    /** An array of media gallery objects. */
    mediaGallery: Maybe<Array<Maybe<MediaGalleryInterface>>>
    /**
     * An array of MediaGalleryEntry objects.
     * @deprecated Use `media_gallery` instead.
     */
    mediaGalleryEntries: Maybe<Array<Maybe<MediaGalleryEntry>>>
    /** A brief overview of the product for search results listings, maximum 255 characters. */
    metaDescription: Maybe<Scalars['String']>
    /** A comma-separated list of keywords that are visible only to search engines. */
    metaKeyword: Maybe<Scalars['String']>
    metaRobots: Maybe<Scalars['String']>
    /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
    metaTitle: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    modelImage: Maybe<Scalars['String']>
    /** Information related to the Model. */
    modelInfo: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    modelName: Maybe<Scalars['String']>
    /** The product name. Customers use this name to identify the product. */
    name: Scalars['String']
    /** The beginning date for new product listings, and determines if the product is featured as a new product. */
    newFromDate: Maybe<Scalars['String']>
    /** The end date for new product listings. */
    newToDate: Maybe<Scalars['String']>
    /** Product stock only x left count */
    onlyXLeftInStock: Maybe<Scalars['Float']>
    /** If the product has multiple options, determines where they appear on the product page. */
    optionsContainer: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    outletAdviesprijs: Maybe<Scalars['Float']>
    /** @deprecated Use the `custom_attributes` field instead. */
    parentSku: Maybe<Scalars['String']>
    /**
     * Indicates the price of an item.
     * @deprecated Use `price_range` for product price information.
     */
    price: Maybe<ProductPrices>
    /** The range of prices for the product */
    priceRange: PriceRange
    /** An array of `TierPrice` objects. */
    priceTiers: Maybe<Array<Maybe<TierPrice>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productArticleCategoryCode: Maybe<Scalars['Int']>
    productArticleCategoryCodeValues: Maybe<Array<Maybe<AttributeOption>>>
    /**
     * Product type like, jeans, jacket etc.
     * @deprecated Use the `custom_attributes` field instead.
     */
    productGroup: Maybe<Scalars['String']>
    /** An array of `ProductLinks` objects. */
    productLinks: Maybe<Array<Maybe<ProductLinksInterface>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    products: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productsustainable2: Maybe<Scalars['String']>
    productsustainable2Values: Maybe<Array<Maybe<AttributeOption>>>
    /** The average of all the ratings given to the product. */
    ratingSummary: Scalars['Float']
    /** @deprecated Use the `custom_attributes` field instead. */
    recommendations: Maybe<Scalars['String']>
    /** An array of products to be displayed in a Related Products block. */
    relatedProducts: Maybe<Array<Maybe<ProductInterface>>>
    /** The total count of all the reviews given to the product. */
    reviewCount: Scalars['Int']
    /** The list of products reviews. */
    reviews: ProductReviews
    /** @deprecated Use the `custom_attributes` field instead. */
    seasonCollection: Maybe<Scalars['String']>
    seasonCollectionValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    shopthelook: Maybe<Scalars['Int']>
    /** A short description of the product. Its use depends on the theme. */
    shortDescription: Maybe<ComplexTextValue>
    /** @deprecated Use the `custom_attributes` field instead. */
    shortDescription2: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    shortDescription3: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    shortDescription4: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    shortDescription5: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    shortDescription6: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    size: Maybe<Scalars['String']>
    sizeValues: Maybe<Array<Maybe<AttributeOption>>>
    /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
    sku: Scalars['String']
    /** The relative path to the small image, which is used on catalog pages. */
    smallImage: Maybe<ProductImage>
    /**
     * The beginning date that a product has a special price.
     * @deprecated The field should not be used on the storefront.
     */
    specialFromDate: Maybe<Scalars['String']>
    /** The discounted price of the product. */
    specialPrice: Maybe<Scalars['Float']>
    /** The end date for a product with a special price. */
    specialToDate: Maybe<Scalars['String']>
    /** Stock status of the product */
    stockStatus: Maybe<ProductStockStatus>
    /** The file name of a swatch image. */
    swatchImage: Maybe<Scalars['String']>
    /** The relative path to the product's thumbnail image. */
    thumbnail: Maybe<ProductImage>
    /**
     * The price when tier pricing is in effect and the items purchased threshold has been reached.
     * @deprecated Use `price_tiers` for product tier price information.
     */
    tierPrice: Maybe<Scalars['Float']>
    /**
     * An array of ProductTierPrices objects.
     * @deprecated Use `price_tiers` for product tier price information.
     */
    tierPrices: Maybe<Array<Maybe<ProductTierPrices>>>
    tweakwiseCrosssellGroupCode: Maybe<Scalars['String']>
    /** Value -1 means the group_code should be used */
    tweakwiseCrosssellTemplate: Maybe<Scalars['Int']>
    tweakwiseUpsellGroupCode: Maybe<Scalars['String']>
    /** Value -1 means the group_code should be used */
    tweakwiseUpsellTemplate: Maybe<Scalars['Int']>
    /**
     * One of simple, virtual, bundle, downloadable, grouped, or configurable.
     * @deprecated Use `__typename` instead.
     */
    typeId: Maybe<Scalars['String']>
    /** The unique ID for a `ProductInterface` object. */
    uid: Scalars['ID']
    /**
     * Timestamp indicating when the product was updated.
     * @deprecated The field should not be used on the storefront.
     */
    updatedAt: Maybe<Scalars['String']>
    /** Upsell Products */
    upsellProducts: Maybe<Array<Maybe<ProductInterface>>>
    /** The part of the URL that identifies the product */
    urlKey: Scalars['String']
    /** @deprecated Use product's `canonical_url` or url rewrites instead */
    urlPath: Maybe<Scalars['String']>
    /** URL rewrites list */
    urlRewrites: Maybe<Array<Maybe<UrlRewrite>>>
    /** The part of the product URL that is appended after the url key */
    urlSuffix: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    uspIcons: Maybe<Scalars['String']>
    uspIconsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** USPs for this specific Product. */
    usps: Array<Maybe<Usp>>
    warehouse: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    washingInstructions: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    washingSymbols: Maybe<Scalars['String']>
    washingSymbolsValues: Maybe<Array<Maybe<AttributeOption>>>
    /**
     * An array of websites in which the product is available.
     * @deprecated Use the `custom_attributes` field instead.
     */
    websites: Maybe<Array<Maybe<Website>>>
}

/** Contains fields that are common to all types of products. */
export interface ProductInterfaceCustomAttributesV2Args {
    filters: Maybe<AttributeFilterInput>
}

/** Contains fields that are common to all types of products. */
export interface ProductInterfaceReviewsArgs {
    pageSize?: Maybe<Scalars['Int']>
    currentPage?: Maybe<Scalars['Int']>
}

/** Contains fields that are common to all types of products. */
export interface ProductInterfaceUspsArgs {
    pageSize?: Maybe<Scalars['Int']>
    currentPage?: Maybe<Scalars['Int']>
}

/** An implementation of `ProductLinksInterface`. */
export interface ProductLinks extends ProductLinksInterface {
    __typename?: 'ProductLinks'
    /** One of related, associated, upsell, or crosssell. */
    linkType: Maybe<Scalars['String']>
    /** The SKU of the linked product. */
    linkedProductSku: Maybe<Scalars['String']>
    /** The type of linked product (simple, virtual, bundle, downloadable, grouped, configurable). */
    linkedProductType: Maybe<Scalars['String']>
    /** The position within the list of product links. */
    position: Maybe<Scalars['Int']>
    /** The identifier of the linked product. */
    sku: Maybe<Scalars['String']>
}

/** Contains information about linked products, including the link type and product type of each item. */
export interface ProductLinksInterface {
    /** One of related, associated, upsell, or crosssell. */
    linkType: Maybe<Scalars['String']>
    /** The SKU of the linked product. */
    linkedProductSku: Maybe<Scalars['String']>
    /** The type of linked product (simple, virtual, bundle, downloadable, grouped, configurable). */
    linkedProductType: Maybe<Scalars['String']>
    /** The position within the list of product links. */
    position: Maybe<Scalars['Int']>
    /** The identifier of the linked product. */
    sku: Maybe<Scalars['String']>
}

/** Contains an image in base64 format and basic information about the image. */
export interface ProductMediaGalleryEntriesContent {
    __typename?: 'ProductMediaGalleryEntriesContent'
    /** The image in base64 format. */
    base64EncodedData: Maybe<Scalars['String']>
    /** The file name of the image. */
    name: Maybe<Scalars['String']>
    /** The MIME type of the file, such as image/png. */
    type: Maybe<Scalars['String']>
}

/** Contains a link to a video file and basic information about the video. */
export interface ProductMediaGalleryEntriesVideoContent {
    __typename?: 'ProductMediaGalleryEntriesVideoContent'
    /** Must be external-video. */
    mediaType: Maybe<Scalars['String']>
    /** A description of the video. */
    videoDescription: Maybe<Scalars['String']>
    /** Optional data about the video. */
    videoMetadata: Maybe<Scalars['String']>
    /** Describes the video source. */
    videoProvider: Maybe<Scalars['String']>
    /** The title of the video. */
    videoTitle: Maybe<Scalars['String']>
    /** The URL to the video. */
    videoUrl: Maybe<Scalars['String']>
}

/** Represents a product price. */
export interface ProductPrice {
    __typename?: 'ProductPrice'
    /** The price discount. Represents the difference between the regular and final price. */
    discount: Maybe<ProductDiscount>
    /** The final price of the product after applying discounts. */
    finalPrice: Money
    /** An array of the multiple Fixed Product Taxes that can be applied to a product price. */
    fixedProductTaxes: Maybe<Array<Maybe<FixedProductTax>>>
    /** The regular price of the product. */
    regularPrice: Money
}

/**
 * Deprecated. Use `PriceRange` instead. Contains the regular price of an item, as
 * well as its minimum and maximum prices. Only composite products, which include
 * bundle, configurable, and grouped products, can contain a minimum and maximum price.
 */
export interface ProductPrices {
    __typename?: 'ProductPrices'
    /**
     * The highest possible final price for all the options defined within a
     * composite product. If you are specifying a price range, this would be the `to` value.
     * @deprecated Use `PriceRange.maximum_price` instead.
     */
    maximalPrice: Maybe<Price>
    /**
     * The lowest possible final price for all the options defined within a composite
     * product. If you are specifying a price range, this would be the `from` value.
     * @deprecated Use `PriceRange.minimum_price` instead.
     */
    minimalPrice: Maybe<Price>
    /**
     * The base price of a product.
     * @deprecated Use `regular_price` from `PriceRange.minimum_price` or `PriceRange.maximum_price` instead.
     */
    regularPrice: Maybe<Price>
}

/** Contains details of a product review. */
export interface ProductReview {
    __typename?: 'ProductReview'
    /** The average of all ratings for this product. */
    averageRating: Scalars['Float']
    /** The date the review was created. */
    createdAt: Scalars['String']
    /** The customer's nickname. Defaults to the customer name, if logged in. */
    nickname: Scalars['String']
    /** The reviewed product. */
    product: ProductInterface
    /** An array of ratings by rating category, such as quality, price, and value. */
    ratingsBreakdown: Array<Maybe<ProductReviewRating>>
    /** The summary (title) of the review. */
    summary: Scalars['String']
    /** The review text. */
    text: Scalars['String']
}

/** Contains data about a single aspect of a product review. */
export interface ProductReviewRating {
    __typename?: 'ProductReviewRating'
    /** The label assigned to an aspect of a product that is being rated, such as quality or price. */
    name: Scalars['String']
    /** The rating value given by customer. By default, possible values range from 1 to 5. */
    value: Scalars['String']
}

/** Contains the reviewer's rating for a single aspect of a review. */
export interface ProductReviewRatingInput {
    /** An encoded rating ID. */
    id: Scalars['String']
    /** An encoded rating value ID. */
    value_id: Scalars['String']
}

/** Contains details about a single aspect of a product review. */
export interface ProductReviewRatingMetadata {
    __typename?: 'ProductReviewRatingMetadata'
    /** An encoded rating ID. */
    id: Scalars['String']
    /** The label assigned to an aspect of a product that is being rated, such as quality or price. */
    name: Scalars['String']
    /** List of product review ratings sorted by position. */
    values: Array<Maybe<ProductReviewRatingValueMetadata>>
}

/** Contains an array of metadata about each aspect of a product review. */
export interface ProductReviewRatingsMetadata {
    __typename?: 'ProductReviewRatingsMetadata'
    /** An array of product reviews sorted by position. */
    items: Array<Maybe<ProductReviewRatingMetadata>>
}

/** Contains details about a single value in a product review. */
export interface ProductReviewRatingValueMetadata {
    __typename?: 'ProductReviewRatingValueMetadata'
    /** A ratings scale, such as the number of stars awarded. */
    value: Scalars['String']
    /** An encoded rating value ID. */
    valueId: Scalars['String']
}

/** Contains an array of product reviews. */
export interface ProductReviews {
    __typename?: 'ProductReviews'
    /** An array of product reviews. */
    items: Array<Maybe<ProductReview>>
    /** Metadata for pagination rendering. */
    pageInfo: SearchResultPageInfo
}

/** Contains the results of a `products` query. */
export interface Products {
    __typename?: 'Products'
    /** A bucket that contains the attribute code and label for each filterable option. */
    aggregations: Maybe<Array<Maybe<Aggregation>>>
    /**
     * Layered navigation filters array.
     * @deprecated Use `aggregations` instead.
     */
    filters: Maybe<Array<Maybe<LayerFilter>>>
    /** An array of products that match the specified search criteria. */
    items: Maybe<Array<Maybe<ProductInterface>>>
    /** An object that includes the page_info and currentPage values specified in the query. */
    pageInfo: Maybe<SearchResultPageInfo>
    /** An object that includes the default sort field and all available sort fields. */
    sortFields: Maybe<SortFields>
    /** An array of search suggestions for case when search query have no results. */
    suggestions: Maybe<Array<Maybe<SearchSuggestion>>>
    /**
     * The number of products that are marked as visible. By default, in complex
     * products, parent products are visible, but their child products are not.
     */
    totalCount: Maybe<Scalars['Int']>
}

/** Contains the results of a `products` query. */
export interface ProductsAggregationsArgs {
    filter: Maybe<AggregationsFilterInput>
}

/**
 * Deprecated. Use `ProductAttributeSortInput` instead. Specifies the attribute to
 * use for sorting search results and indicates whether the results are sorted in
 * ascending or descending order.
 */
export interface ProductSortInput {
    /** The product's country of origin. */
    country_of_manufacture?: Maybe<SortEnum>
    /** The timestamp indicating when the product was created. */
    created_at?: Maybe<SortEnum>
    /** The name of a custom layout. */
    custom_layout?: Maybe<SortEnum>
    /** XML code that is applied as a layout update to the product page. */
    custom_layout_update?: Maybe<SortEnum>
    /** Detailed information about the product. The value can include simple HTML tags. */
    description?: Maybe<SortEnum>
    /** Indicates whether a gift message is available. */
    gift_message_available?: Maybe<SortEnum>
    /** Indicates whether additional attributes have been created for the product. */
    has_options?: Maybe<SortEnum>
    /** The relative path to the main image on the product page. */
    image?: Maybe<SortEnum>
    /** The label assigned to a product image. */
    image_label?: Maybe<SortEnum>
    /** A number representing the product's manufacturer. */
    manufacturer?: Maybe<SortEnum>
    /** A brief overview of the product for search results listings, maximum 255 characters. */
    meta_description?: Maybe<SortEnum>
    /** A comma-separated list of keywords that are visible only to search engines. */
    meta_keyword?: Maybe<SortEnum>
    /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
    meta_title?: Maybe<SortEnum>
    /** The product name. Customers use this name to identify the product. */
    name?: Maybe<SortEnum>
    /** The beginning date for new product listings, and determines if the product is featured as a new product. */
    news_from_date?: Maybe<SortEnum>
    /** The end date for new product listings. */
    news_to_date?: Maybe<SortEnum>
    /** If the product has multiple options, determines where they appear on the product page. */
    options_container?: Maybe<SortEnum>
    /** The price of the item. */
    price?: Maybe<SortEnum>
    /** Indicates whether the product has required options. */
    required_options?: Maybe<SortEnum>
    /** A short description of the product. Its use depends on the theme. */
    short_description?: Maybe<SortEnum>
    /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
    sku?: Maybe<SortEnum>
    /** The relative path to the small image, which is used on catalog pages. */
    small_image?: Maybe<SortEnum>
    /** The label assigned to a product's small image. */
    small_image_label?: Maybe<SortEnum>
    /** The beginning date that a product has a special price. */
    special_from_date?: Maybe<SortEnum>
    /** The discounted price of the product. */
    special_price?: Maybe<SortEnum>
    /** The end date that a product has a special price. */
    special_to_date?: Maybe<SortEnum>
    /** Indicates the criteria to sort swatches. */
    swatch_image?: Maybe<SortEnum>
    /** The relative path to the product's thumbnail image. */
    thumbnail?: Maybe<SortEnum>
    /** The label assigned to a product's thumbnail image. */
    thumbnail_label?: Maybe<SortEnum>
    /** The price when tier pricing is in effect and the items purchased threshold has been reached. */
    tier_price?: Maybe<SortEnum>
    /** The timestamp indicating when the product was updated. */
    updated_at?: Maybe<SortEnum>
    /** The part of the URL that identifies the product */
    url_key?: Maybe<SortEnum>
    url_path?: Maybe<SortEnum>
    /** The weight of the item, in units defined by the store. */
    weight?: Maybe<SortEnum>
}

/** This enumeration states whether a product stock status is in stock or out of stock */
export enum ProductStockStatus {
    IN_STOCK = 'IN_STOCK',
    OUT_OF_STOCK = 'OUT_OF_STOCK',
}

/**
 * Deprecated. Use `TierPrice` instead. Defines a tier price, which is a quantity
 * discount offered to a specific customer group.
 */
export interface ProductTierPrices {
    __typename?: 'ProductTierPrices'
    /**
     * The ID of the customer group.
     * @deprecated Not relevant for the storefront.
     */
    customerGroupId: Maybe<Scalars['String']>
    /**
     * The percentage discount of the item.
     * @deprecated Use `TierPrice.discount` instead.
     */
    percentageValue: Maybe<Scalars['Float']>
    /**
     * The number of items that must be purchased to qualify for tier pricing.
     * @deprecated Use `TierPrice.quantity` instead.
     */
    qty: Maybe<Scalars['Float']>
    /**
     * The price of the fixed price item.
     * @deprecated Use `TierPrice.final_price` instead.
     */
    value: Maybe<Scalars['Float']>
    /**
     * The ID assigned to the website.
     * @deprecated Not relevant for the storefront.
     */
    websiteId: Maybe<Scalars['Float']>
}

/** Contains information about a product video. */
export interface ProductVideo extends MediaGalleryInterface {
    __typename?: 'ProductVideo'
    /** Indicates whether the image is hidden from view. */
    disabled: Maybe<Scalars['Boolean']>
    /** The label of the product image or video. */
    label: Maybe<Scalars['String']>
    /** The media item's position after it has been sorted. */
    position: Maybe<Scalars['Int']>
    /** The URL of the product image or video. */
    url: Maybe<Scalars['String']>
    /** Contains a `ProductMediaGalleryEntriesVideoContent` object. */
    videoContent: Maybe<ProductMediaGalleryEntriesVideoContent>
}

export interface Promoinfo extends Document, Linkable {
    __typename?: 'Promoinfo'
    storeview: Maybe<Scalars['String']>
    textMobile: Maybe<Scalars['String']>
    text: Maybe<Scalars['String']>
    modalTitle: Maybe<Scalars['String']>
    modalContent: Maybe<Scalars['Json']>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

/** A connection to a list of items. */
export interface PromoinfoConnectionConnection {
    __typename?: 'PromoinfoConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<PromoinfoConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface PromoinfoConnectionEdge {
    __typename?: 'PromoinfoConnectionEdge'
    /** The item at the end of the edge. */
    node: Promoinfo
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

export interface PsRegistrationBox extends Document, Linkable {
    __typename?: 'PsRegistrationBox'
    storeview: Maybe<Scalars['String']>
    accountHeading: Maybe<Scalars['String']>
    employeeName: Maybe<Scalars['String']>
    employeeImage: Maybe<PrismicImageType>
    employeeButtonText: Maybe<Scalars['String']>
    employeeButtonLink: Maybe<Scalars['String']>
    heroTitle: Maybe<Scalars['String']>
    heroImage: Maybe<PrismicImageType>
    mobileHeroImage: Maybe<PrismicImageType>
    orderTitle: Maybe<Scalars['String']>
    orderDescription: Maybe<Scalars['String']>
    orderButtonText: Maybe<Scalars['String']>
    orderButtonLink: Maybe<Scalars['String']>
    orderImage: Maybe<PrismicImageType>
    mobileOrderImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    description: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
    body: Maybe<Array<PsRegistrationBoxBody>>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

export type PsRegistrationBoxBody = PsRegistrationBoxBodyUsp

export interface PsRegistrationBoxBodyUsp {
    __typename?: 'PsRegistrationBoxBodyUsp'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<PsRegistrationBoxBodyUspPrimary>
}

export interface PsRegistrationBoxBodyUspPrimary {
    __typename?: 'PsRegistrationBoxBodyUspPrimary'
    uspTitle: Maybe<Scalars['String']>
    uspDescription: Maybe<Scalars['String']>
    uspIcon: Maybe<Scalars['String']>
}

/** A connection to a list of items. */
export interface PsRegistrationBoxConnectionConnection {
    __typename?: 'PsRegistrationBoxConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<PsRegistrationBoxConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface PsRegistrationBoxConnectionEdge {
    __typename?: 'PsRegistrationBoxConnectionEdge'
    /** The item at the end of the edge. */
    node: PsRegistrationBox
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

export interface Query {
    __typename?: 'Query'
    googleMapsGeocode: Maybe<Array<Maybe<GoogleMapsGeocodeResult>>>
    googleMapsReverseGeocode: Maybe<Array<Maybe<GoogleMapsGeocodeResult>>>
    googleTravelDistance: Maybe<Array<Maybe<GoogleMapsTravelDistance>>>
    googleTravelDistances: Maybe<Array<Maybe<GoogleMapsDistanceDestination>>>
    googlePlaceDetails: Maybe<GooglePlace>
    googlePlaceSearch: Maybe<Array<GooglePlaceSearchResults>>
    googlePlaceAutocomplete: Maybe<Array<Maybe<GooglePlaceAutocompleteResult>>>
    /** Fetch size guide based on product type */
    sizeGuide: Maybe<SizeGuideResult>
    /** Search for address */
    addressSearch: Maybe<AddressSearchResult>
    adyenPaymentMethods: Maybe<AdyenPaymentMethods>
    adyenPaymentMethodsBalance: Maybe<AdyenPaymentMethodsBalanceResponse>
    adyenPaymentStatus: Maybe<AdyenPaymentStatus>
    adyenRedeemedGiftcards: Maybe<AdyenRedeemedGiftcardsResponse>
    /** Return all the data of all the cms pages */
    allCmsPages: Array<Maybe<CmsPageInterface>>
    /**
     * Retrieve EAV attributes associated to a frontend form. Use countries query
     * provided by DirectoryGraphQl module to retrieve region_id and country_id
     * attribute options.
     */
    attributesForm: AttributesFormOutput
    /** Returns a list of attributes metadata for a given entity type. */
    attributesList: Maybe<AttributesMetadataOutput>
    /** Get a list of available store views and their config information. */
    availableStores: Maybe<Array<Maybe<StoreConfig>>>
    /** Return information about the specified shopping cart. */
    cart: Maybe<Cart>
    /** Return a list of categories that match the specified filter. */
    categories: Maybe<CategoryResult>
    /**
     * Search for categories that match the criteria specified in the `search` and `filter` attributes.
     * @deprecated Use `categories` instead.
     */
    category: Maybe<CategoryTree>
    /** Retrieve a category by UID */
    categoryByUid: Maybe<CategoryInterface>
    /**
     * Return an array of categories based on the specified filters.
     * @deprecated Use `categories` instead.
     */
    categoryList: Maybe<Array<Maybe<CategoryTree>>>
    /** Return Terms and Conditions configuration information. */
    checkoutAgreements: Maybe<Array<Maybe<CheckoutAgreement>>>
    /** Return information about CMS blocks. */
    cmsBlocks: Maybe<CmsBlocks>
    /** Return details about a CMS page. */
    cmsPage: Maybe<CmsPage>
    /** Return all the data of the cms page */
    cmsPageById: Maybe<CmsPageInterface>
    /** Return products that have been added to the specified compare list. */
    compareList: Maybe<CompareList>
    /** Returns all the config values that are whitelisted */
    coreConfig: Maybe<CoreConfigResult>
    /** The countries query provides information for all countries. */
    countries: Maybe<Array<Maybe<Country>>>
    /** The countries query provides information for a single country. */
    country: Maybe<Country>
    /** Return information about the store's currency. */
    currency: Maybe<Currency>
    /**
     * Return the attribute type, given an attribute code and entity type.
     * @deprecated Use `customAttributeMetadataV2` query instead.
     */
    customAttributeMetadata: Maybe<CustomAttributeMetadata>
    /** Retrieve EAV attributes metadata. */
    customAttributeMetadataV2: AttributesMetadataOutput
    /** Return detailed information about a customer account. */
    customer: Maybe<Customer>
    /** Return information about the customer's shopping cart. */
    customerCart: Cart
    /** Get list of applied coupons and coupons available when customer is logged in */
    customerCoupon: Maybe<CustomerCouponResult>
    /** @deprecated Use the `customer` query instead. */
    customerOrders: Maybe<CustomerOrders>
    /** Return a list of customer payment tokens stored in the vault. */
    customerPaymentTokens: Maybe<CustomerPaymentTokens>
    /** Get DhlParcel Shipping Service Points */
    dhlParcelShippingServicePoints: Maybe<DhlParcelShippingServicePointsResult>
    /** Retrieve the secure PayPal URL for a Payments Pro Hosted Solution transaction. */
    getHostedProUrl: Maybe<HostedProUrl>
    /** Retrieve payment credentials for a transaction. Use this query for Payflow Link and Payments Advanced payment methods. */
    getPayflowLinkToken: Maybe<PayflowLinkToken>
    /** Retrieve guest order details based on number, email and postcode. */
    guestOrder: CustomerOrder
    /** Retrieve guest order details based on token. */
    guestOrderByToken: CustomerOrder
    /** Check whether the specified email has already been used to create a customer account. */
    isEmailAvailable: Maybe<IsEmailAvailableOutput>
    /** Query Navigation object by ID or Code */
    navigation: Maybe<Navigation>
    order: Maybe<CustomerOrder>
    /** The pickup locations query searches for locations that match the search request requirements. */
    pickupLocations: Maybe<PickupLocations>
    /** Resolve Prismic Document. */
    prismicDocument: Maybe<PrismicDocument>
    /** Resolve Prismic Document by document ID. */
    prismicDocumentById: Maybe<PrismicDocument>
    /** Resolve Prismic documents. */
    prismicDocuments: Maybe<Array<Maybe<PrismicDocument>>>
    /** Resolve preview URL by document ID. */
    prismicResolvedPreviewUrl: Maybe<Scalars['String']>
    /** @deprecated id is deprecated, use productByUid */
    product: Maybe<ProductInterface>
    /** Retrieve a product by UID */
    productByUid: Maybe<ProductInterface>
    /** Return the active ratings attributes and the values each rating can have. */
    productReviewRatingsMetadata: ProductReviewRatingsMetadata
    /** Search for products that match the criteria specified in the `search` and `filter` attributes. */
    products: Maybe<Products>
    queueParameters: Maybe<QueueParameters>
    /** Returns details about Google reCAPTCHA V3-Invisible configuration. */
    recaptchaV3Config: Maybe<ReCaptchaConfigurationV3>
    /** Return the full details for a specified product, category, or CMS page. */
    route: Maybe<RoutableInterface>
    /** Fetch Size Table by its UID */
    sizeTable: Maybe<SizeTable>
    /** Fetch all Size Tables */
    sizeTables: Array<Maybe<SizeTable>>
    /** Get SRS Store by Store ID. */
    srsStore: Maybe<SrsStore>
    /** Get SRS Store by (SRS) Shop ID. */
    srsStoreByShopId: Maybe<SrsStore>
    /** The SRS Stores query searches for SRS stores that match the search request requirements. */
    srsStores: SrsStores
    /** Return details about the store's configuration. */
    storeConfig: Maybe<StoreConfig>
    /** Fetch tweakwise recommendations */
    tweakwiseRecommendation: Maybe<TweakwiseRecommendationResult>
    /**
     * Return the relative URL for a specified product, category or CMS page.
     * @deprecated Use the `route` query instead.
     */
    urlResolver: Maybe<EntityUrl>
    /** All USPs for this Store. */
    usps: Array<Maybe<Usp>>
    /** Validate Email Address */
    validateEmail: Maybe<EmailValidationResult>
    /**
     * Return the contents of a customer's wish list.
     * @deprecated Moved under `Customer.wishlist`.
     */
    wishlist: Maybe<WishlistOutput>
    /** Retrieve the specified wish list identified by the unique ID for a `Wishlist` object */
    wishlistV2: Maybe<Wishlist>
    allDocuments: DocumentConnection
    allHeroBanners: HeroBannerConnectionConnection
    allStorePages: StorePageConnectionConnection
    allSimpleForms: SimpleFormConnectionConnection
    allMonstersaleLoginPages: MonstersaleLoginPageConnectionConnection
    allLoyaltyCards: LoyaltyCardConnectionConnection
    allPmeOutletFaqPages: PmeOutletFaqPageConnectionConnection
    allVanguardTags: VanguardTagConnectionConnection
    allSearchPages: SearchPageConnectionConnection
    allFooterMonstersales: FooterMonstersaleConnectionConnection
    allMonstersalePages: MonstersalePageConnectionConnection
    allFooterJustbrandss: FooterJustbrandsConnectionConnection
    allStorelocatorPages: StorelocatorPageConnectionConnection
    allMemberUsps: MemberUspConnectionConnection
    allVanguardFaqPages: VanguardFaqPageConnectionConnection
    allJustbrandsTags: JustbrandsTagConnectionConnection
    allPlpMemberUspss: PlpMemberUspsConnectionConnection
    allFooterPmeoutlets: FooterPmeoutletConnectionConnection
    allFaqPages: FaqPageConnectionConnection
    allTags: TagConnectionConnection
    allJbfoFaqPages: JbfoFaqPageConnectionConnection
    allCastironFaqPages: CastironFaqPageConnectionConnection
    allPages: PageConnectionConnection
    allJustbrandsPages: JustbrandsPageConnectionConnection
    allVisualFilters: VisualFilterConnectionConnection
    allJustbrandsFaqPages: JustbrandsFaqPageConnectionConnection
    allPromoinfos: PromoinfoConnectionConnection
    allFooterJbfos: FooterJbfoConnectionConnection
    allPmeOutletPages: PmeOutletPageConnectionConnection
    allFooterVanguards: FooterVanguardConnectionConnection
    allCategoryBannerss: CategoryBannersConnectionConnection
    allPmeOutletLoginPages: PmeOutletLoginPageConnectionConnection
    allJbfoPages: JbfoPageConnectionConnection
    allVanguardPages: VanguardPageConnectionConnection
    allUspss: UspsConnectionConnection
    allJustBrandsLogins: JustBrandsLoginConnectionConnection
    allMemberUspss: MemberUspsConnectionConnection
    allCastironPages: CastironPageConnectionConnection
    allCategorys: CategoryConnectionConnection
    allGuidedSellingPages: GuidedSellingPageConnectionConnection
    allPrivilegesUspss: PrivilegesUspsConnectionConnection
    allPsRegistrationBoxs: PsRegistrationBoxConnectionConnection
    allPrivilegesPerkss: PrivilegesPerksConnectionConnection
    allCastironTags: CastironTagConnectionConnection
    allMonstersaleFaqPages: MonstersaleFaqPageConnectionConnection
    allFooterCastirons: FooterCastironConnectionConnection
    allQueuePages: QueuePageConnectionConnection
    allFooterPmes: FooterPmeConnectionConnection
    allJbfoLoginPages: JbfoLoginPageConnectionConnection
    allPrivilegesPages: PrivilegesPageConnectionConnection
    queuePosition: QueuePosition
    /**
     * Service that returns product results and search suggestions based on a search
     * term. This service can be called as soon as the user begins to type a search term.
     */
    tweakwiseAutocomplete: TweakwiseAutocompleteResult
    tweakwiseAdvisor: Maybe<TweakwiseAdvisor>
    tweakwiseFunnel: Maybe<TweakwiseFunnel>
    tweakwiseNavigation: TweakwiseNavigationResult
    tweakwiseSearchBanners: Array<TweakwiseSearchBanner>
    /**
     * Returns featured recommended products, to use for example on a homepage.
     *
     * Will return `null` when the template could not be found.
     */
    tweakwiseRecommendationsFeatured: Maybe<Array<TweakwiseNavigationItem>>
    /**
     * Service that returns all available templates for a product. Each template will contain all items matching the template.
     *
     * If a lot of rules have been set up, it is not efficient to request all rules
     * for all products. With these web services, all products of a product can be
     * retrieved in one go for all rules.
     *
     * Will return `null` when the product could not be found in the Tweakwise store.
     */
    tweakwiseRecommendationsProductTemplates: Maybe<
        Array<TweakwiseProductRecommendationsTemplate>
    >
    /**
     * Returns recommended products for a product, such as for cross sell or up sell.
     *
     * Will return `null` when the template could not be found.
     */
    tweakwiseRecommendationsProduct: Maybe<Array<TweakwiseNavigationItem>>
    /**
     * Returns recommended products for a product, such as for cross sell or up sell.
     *
     * Will use a group Key from tweakwise
     */
    tweakwiseRecommendationsProductGroup: Maybe<Array<TweakwiseRecommendation>>
}

export interface QueryGoogleMapsGeocodeArgs {
    search: Scalars['String']
}

export interface QueryGoogleMapsReverseGeocodeArgs {
    coordinates: GoogleMapsGeocodeLocationInput
}

export interface QueryGoogleTravelDistanceArgs {
    from: GoogleMapsGeocodeLocationInput
    to: GoogleMapsGeocodeLocationInput
}

export interface QueryGoogleTravelDistancesArgs {
    input: GoogleMapsDistanceDestinationsInput
}

export interface QueryGooglePlaceDetailsArgs {
    placeId: Scalars['ID']
    language: Scalars['String']
}

export interface QueryGooglePlaceSearchArgs {
    search: Scalars['String']
    language: Scalars['String']
}

export interface QueryGooglePlaceAutocompleteArgs {
    input: Scalars['String']
    language: Scalars['String']
    location: Maybe<Array<Scalars['Float']>>
    radius: Maybe<Scalars['Float']>
    strictBounds: Maybe<Scalars['Boolean']>
    components: Maybe<Scalars['String']>
}

export interface QuerySizeGuideArgs {
    product_type: Scalars['String']
    store: Maybe<Scalars['String']>
}

export interface QueryAddressSearchArgs {
    country: Scalars['String']
    street: Maybe<Scalars['String']>
    house_number: Maybe<Scalars['Int']>
    house_number_addition: Maybe<Scalars['String']>
    postcode: Maybe<Scalars['String']>
    city: Maybe<Scalars['String']>
    region: Maybe<Scalars['String']>
    store: Maybe<Scalars['String']>
}

export interface QueryAdyenPaymentMethodsArgs {
    cart_id: Scalars['String']
    shopper_locale: Maybe<Scalars['String']>
    country: Maybe<Scalars['String']>
    store: Maybe<Scalars['String']>
}

export interface QueryAdyenPaymentMethodsBalanceArgs {
    payload: Scalars['String']
    store: Maybe<Scalars['String']>
}

export interface QueryAdyenPaymentStatusArgs {
    orderNumber: Maybe<Scalars['String']>
    cartId: Maybe<Scalars['String']>
    store: Maybe<Scalars['String']>
}

export interface QueryAdyenRedeemedGiftcardsArgs {
    cartId: Scalars['String']
    store: Maybe<Scalars['String']>
}

export interface QueryAllCmsPagesArgs {
    storeId: Scalars['Int']
    store: Maybe<Scalars['String']>
}

export interface QueryAttributesFormArgs {
    formCode: Scalars['String']
    store: Maybe<Scalars['String']>
}

export interface QueryAttributesListArgs {
    entityType: AttributeEntityTypeEnum
    filters: Maybe<AttributeFilterInput>
    store: Maybe<Scalars['String']>
}

export interface QueryAvailableStoresArgs {
    useCurrentGroup: Maybe<Scalars['Boolean']>
    store: Maybe<Scalars['String']>
}

export interface QueryCartArgs {
    cart_id: Scalars['String']
    store: Maybe<Scalars['String']>
}

export interface QueryCategoriesArgs {
    filters: Maybe<CategoryFilterInput>
    pageSize?: Maybe<Scalars['Int']>
    currentPage?: Maybe<Scalars['Int']>
    store: Maybe<Scalars['String']>
}

export interface QueryCategoryArgs {
    id: Maybe<Scalars['Int']>
    store: Maybe<Scalars['String']>
}

export interface QueryCategoryByUidArgs {
    uid: Scalars['ID']
    store: Maybe<Scalars['String']>
}

export interface QueryCategoryListArgs {
    filters: Maybe<CategoryFilterInput>
    pageSize?: Maybe<Scalars['Int']>
    currentPage?: Maybe<Scalars['Int']>
    store: Maybe<Scalars['String']>
}

export interface QueryCheckoutAgreementsArgs {
    store: Maybe<Scalars['String']>
}

export interface QueryCmsBlocksArgs {
    identifiers: Maybe<Array<Maybe<Scalars['String']>>>
    store: Maybe<Scalars['String']>
}

export interface QueryCmsPageArgs {
    id: Maybe<Scalars['Int']>
    identifier: Maybe<Scalars['String']>
    store: Maybe<Scalars['String']>
}

export interface QueryCmsPageByIdArgs {
    id: Scalars['Int']
    store: Maybe<Scalars['String']>
}

export interface QueryCompareListArgs {
    uid: Scalars['ID']
    store: Maybe<Scalars['String']>
}

export interface QueryCoreConfigArgs {
    store: Maybe<Scalars['String']>
}

export interface QueryCountriesArgs {
    store: Maybe<Scalars['String']>
}

export interface QueryCountryArgs {
    id: Maybe<Scalars['String']>
    store: Maybe<Scalars['String']>
}

export interface QueryCurrencyArgs {
    store: Maybe<Scalars['String']>
}

export interface QueryCustomAttributeMetadataArgs {
    attributes: Array<AttributeInput>
    store: Maybe<Scalars['String']>
}

export interface QueryCustomAttributeMetadataV2Args {
    attributes: Maybe<Array<AttributeInput>>
    store: Maybe<Scalars['String']>
}

export interface QueryCustomerArgs {
    store: Maybe<Scalars['String']>
}

export interface QueryCustomerCartArgs {
    store: Maybe<Scalars['String']>
}

export interface QueryCustomerCouponArgs {
    cart_id: Maybe<Scalars['String']>
    include_used: Maybe<Scalars['Boolean']>
    store: Maybe<Scalars['String']>
}

export interface QueryCustomerOrdersArgs {
    store: Maybe<Scalars['String']>
}

export interface QueryCustomerPaymentTokensArgs {
    store: Maybe<Scalars['String']>
}

export interface QueryDhlParcelShippingServicePointsArgs {
    country: Scalars['String']
    postcode: Scalars['String']
    limit: Maybe<Scalars['Int']>
    store: Maybe<Scalars['String']>
}

export interface QueryGetHostedProUrlArgs {
    input: HostedProUrlInput
    store: Maybe<Scalars['String']>
}

export interface QueryGetPayflowLinkTokenArgs {
    input: PayflowLinkTokenInput
    store: Maybe<Scalars['String']>
}

export interface QueryGuestOrderArgs {
    input: OrderInformationInput
    store: Maybe<Scalars['String']>
}

export interface QueryGuestOrderByTokenArgs {
    input: OrderTokenInput
    store: Maybe<Scalars['String']>
}

export interface QueryIsEmailAvailableArgs {
    email: Scalars['String']
    store: Maybe<Scalars['String']>
}

export interface QueryNavigationArgs {
    id: Maybe<Scalars['ID']>
    code: Maybe<Scalars['String']>
    store: Maybe<Scalars['String']>
}

export interface QueryOrderArgs {
    orderMaskId: Scalars['String']
    store: Maybe<Scalars['String']>
}

export interface QueryPickupLocationsArgs {
    area: Maybe<AreaInput>
    filters: Maybe<PickupLocationFilterInput>
    sort: Maybe<PickupLocationSortInput>
    pageSize?: Maybe<Scalars['Int']>
    currentPage?: Maybe<Scalars['Int']>
    productsInfo: Maybe<Array<Maybe<ProductInfoInput>>>
    store: Maybe<Scalars['String']>
}

export interface QueryPrismicDocumentArgs {
    storeView: Maybe<Scalars['String']>
    predicate: PrismicPredicateOneValue
    lang: Maybe<Scalars['String']>
    ref: Maybe<Scalars['String']>
    store: Maybe<Scalars['String']>
}

export interface QueryPrismicDocumentByIdArgs {
    documentId: Scalars['String']
    store: Maybe<Scalars['String']>
}

export interface QueryPrismicDocumentsArgs {
    storeView: Maybe<Scalars['String']>
    predicate: PrismicPredicateManyValues
    lang: Maybe<Scalars['String']>
    ref: Maybe<Scalars['String']>
    store: Maybe<Scalars['String']>
}

export interface QueryPrismicResolvedPreviewUrlArgs {
    documentId: Scalars['String']
    store: Maybe<Scalars['String']>
}

export interface QueryProductArgs {
    id: Scalars['Int']
    store: Maybe<Scalars['String']>
}

export interface QueryProductByUidArgs {
    uid: Scalars['ID']
    store: Maybe<Scalars['String']>
}

export interface QueryProductReviewRatingsMetadataArgs {
    store: Maybe<Scalars['String']>
}

export interface QueryProductsArgs {
    search: Maybe<Scalars['String']>
    filter: Maybe<ProductAttributeFilterInput>
    pageSize?: Maybe<Scalars['Int']>
    currentPage?: Maybe<Scalars['Int']>
    sort: Maybe<ProductAttributeSortInput>
    store: Maybe<Scalars['String']>
}

export interface QueryQueueParametersArgs {
    store: Maybe<Scalars['String']>
}

export interface QueryRecaptchaV3ConfigArgs {
    store: Maybe<Scalars['String']>
}

export interface QueryRouteArgs {
    url: Scalars['String']
    store: Maybe<Scalars['String']>
}

export interface QuerySizeTableArgs {
    uid: Scalars['String']
    store: Maybe<Scalars['String']>
}

export interface QuerySizeTablesArgs {
    store: Maybe<Scalars['String']>
}

export interface QuerySrsStoreArgs {
    store_id: Scalars['Int']
    store: Maybe<Scalars['String']>
}

export interface QuerySrsStoreByShopIdArgs {
    shop_id: Scalars['Int']
    store: Maybe<Scalars['String']>
}

export interface QuerySrsStoresArgs {
    coordinates: Maybe<CoordinatesInput>
    filters: Maybe<SrsStoreFilterInput>
    pageSize?: Maybe<Scalars['Int']>
    currentPage?: Maybe<Scalars['Int']>
    store: Maybe<Scalars['String']>
}

export interface QueryStoreConfigArgs {
    store: Maybe<Scalars['String']>
}

export interface QueryTweakwiseRecommendationArgs {
    productId: Maybe<Scalars['Int']>
    templateId: Maybe<Scalars['Int']>
    templateGroupCode: Maybe<Scalars['String']>
    store: Maybe<Scalars['String']>
}

export interface QueryUrlResolverArgs {
    url: Scalars['String']
    store: Maybe<Scalars['String']>
}

export interface QueryUspsArgs {
    pageSize?: Maybe<Scalars['Int']>
    currentPage?: Maybe<Scalars['Int']>
    filter: Maybe<Scalars['Boolean']>
    store: Maybe<Scalars['String']>
}

export interface QueryValidateEmailArgs {
    email: Scalars['String']
    store: Maybe<Scalars['String']>
}

export interface QueryWishlistArgs {
    store: Maybe<Scalars['String']>
}

export interface QueryWishlistV2Args {
    id: Scalars['ID']
    store: Maybe<Scalars['String']>
}

export interface QueryAllDocumentsArgs {
    sortBy: Maybe<SortDocumentsBy>
    type: Maybe<Scalars['String']>
    type_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllHeroBannersArgs {
    sortBy: Maybe<SortHeroBannery>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WhereHeroBanner>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllStorePagesArgs {
    sortBy: Maybe<SortStorePagey>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WhereStorePage>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllSimpleFormsArgs {
    sortBy: Maybe<SortSimpleFormy>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WhereSimpleForm>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllMonstersaleLoginPagesArgs {
    sortBy: Maybe<SortMonstersaleLoginPagey>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WhereMonstersaleLoginPage>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllLoyaltyCardsArgs {
    sortBy: Maybe<SortLoyaltyCardy>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WhereLoyaltyCard>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllPmeOutletFaqPagesArgs {
    sortBy: Maybe<SortPmeOutletFaqPagey>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WherePmeOutletFaqPage>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllVanguardTagsArgs {
    sortBy: Maybe<SortVanguardTagy>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WhereVanguardTag>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllSearchPagesArgs {
    sortBy: Maybe<SortSearchPagey>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WhereSearchPage>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllFooterMonstersalesArgs {
    sortBy: Maybe<SortFooterMonstersaley>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WhereFooterMonstersale>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllMonstersalePagesArgs {
    sortBy: Maybe<SortMonstersalePagey>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WhereMonstersalePage>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllFooterJustbrandssArgs {
    sortBy: Maybe<SortFooterJustbrandsy>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WhereFooterJustbrands>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllStorelocatorPagesArgs {
    sortBy: Maybe<SortStorelocatorPagey>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WhereStorelocatorPage>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllMemberUspsArgs {
    sortBy: Maybe<SortMemberUspy>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WhereMemberUsp>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllVanguardFaqPagesArgs {
    sortBy: Maybe<SortVanguardFaqPagey>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WhereVanguardFaqPage>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllJustbrandsTagsArgs {
    sortBy: Maybe<SortJustbrandsTagy>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WhereJustbrandsTag>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllPlpMemberUspssArgs {
    sortBy: Maybe<SortPlpMemberUspsy>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WherePlpMemberUsps>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllFooterPmeoutletsArgs {
    sortBy: Maybe<SortFooterPmeoutlety>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WhereFooterPmeoutlet>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllFaqPagesArgs {
    sortBy: Maybe<SortFaqPagey>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WhereFaqPage>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllTagsArgs {
    sortBy: Maybe<SortTagy>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WhereTag>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllJbfoFaqPagesArgs {
    sortBy: Maybe<SortJbfoFaqPagey>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WhereJbfoFaqPage>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllCastironFaqPagesArgs {
    sortBy: Maybe<SortCastironFaqPagey>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WhereCastironFaqPage>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllPagesArgs {
    sortBy: Maybe<SortPagey>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WherePage>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllJustbrandsPagesArgs {
    sortBy: Maybe<SortJustbrandsPagey>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WhereJustbrandsPage>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllVisualFiltersArgs {
    sortBy: Maybe<SortVisualFiltery>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WhereVisualFilter>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllJustbrandsFaqPagesArgs {
    sortBy: Maybe<SortJustbrandsFaqPagey>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WhereJustbrandsFaqPage>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllPromoinfosArgs {
    sortBy: Maybe<SortPromoinfoy>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WherePromoinfo>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllFooterJbfosArgs {
    sortBy: Maybe<SortFooterJbfoy>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WhereFooterJbfo>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllPmeOutletPagesArgs {
    sortBy: Maybe<SortPmeOutletPagey>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WherePmeOutletPage>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllFooterVanguardsArgs {
    sortBy: Maybe<SortFooterVanguardy>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WhereFooterVanguard>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllCategoryBannerssArgs {
    sortBy: Maybe<SortCategoryBannersy>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WhereCategoryBanners>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllPmeOutletLoginPagesArgs {
    sortBy: Maybe<SortPmeOutletLoginPagey>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WherePmeOutletLoginPage>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllJbfoPagesArgs {
    sortBy: Maybe<SortJbfoPagey>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WhereJbfoPage>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllVanguardPagesArgs {
    sortBy: Maybe<SortVanguardPagey>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WhereVanguardPage>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllUspssArgs {
    sortBy: Maybe<SortUspsy>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WhereUsps>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllJustBrandsLoginsArgs {
    sortBy: Maybe<SortJustBrandsLoginy>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllMemberUspssArgs {
    sortBy: Maybe<SortMemberUspsy>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WhereMemberUsps>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllCastironPagesArgs {
    sortBy: Maybe<SortCastironPagey>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WhereCastironPage>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllCategorysArgs {
    sortBy: Maybe<SortCategoryy>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WhereCategory>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllGuidedSellingPagesArgs {
    sortBy: Maybe<SortGuidedSellingPagey>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WhereGuidedSellingPage>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllPrivilegesUspssArgs {
    sortBy: Maybe<SortPrivilegesUspsy>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WherePrivilegesUsps>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllPsRegistrationBoxsArgs {
    sortBy: Maybe<SortPsRegistrationBoxy>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WherePsRegistrationBox>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllPrivilegesPerkssArgs {
    sortBy: Maybe<SortPrivilegesPerksy>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WherePrivilegesPerks>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllCastironTagsArgs {
    sortBy: Maybe<SortCastironTagy>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WhereCastironTag>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllMonstersaleFaqPagesArgs {
    sortBy: Maybe<SortMonstersaleFaqPagey>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WhereMonstersaleFaqPage>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllFooterCastironsArgs {
    sortBy: Maybe<SortFooterCastirony>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WhereFooterCastiron>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllQueuePagesArgs {
    sortBy: Maybe<SortQueuePagey>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WhereQueuePage>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllFooterPmesArgs {
    sortBy: Maybe<SortFooterPmey>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WhereFooterPme>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllJbfoLoginPagesArgs {
    sortBy: Maybe<SortJbfoLoginPagey>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WhereJbfoLoginPage>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryAllPrivilegesPagesArgs {
    sortBy: Maybe<SortPrivilegesPagey>
    uid: Maybe<Scalars['String']>
    uid_in: Maybe<Array<Scalars['String']>>
    id: Maybe<Scalars['String']>
    id_in: Maybe<Array<Scalars['String']>>
    lang: Maybe<Scalars['String']>
    tags: Maybe<Array<Scalars['String']>>
    tags_in: Maybe<Array<Scalars['String']>>
    firstPublicationDate: Maybe<Scalars['DateTime']>
    firstPublicationDate_after: Maybe<Scalars['DateTime']>
    firstPublicationDate_before: Maybe<Scalars['DateTime']>
    lastPublicationDate: Maybe<Scalars['DateTime']>
    lastPublicationDate_after: Maybe<Scalars['DateTime']>
    lastPublicationDate_before: Maybe<Scalars['DateTime']>
    fulltext: Maybe<Scalars['String']>
    similar: Maybe<Similar>
    where: Maybe<WherePrivilegesPage>
    before: Maybe<Scalars['String']>
    after: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export interface QueryQueuePositionArgs {
    uid: Scalars['ID']
}

export interface QueryTweakwiseAutocompleteArgs {
    query: Scalars['String']
    params: Maybe<TweakwiseAutocompleteParams>
}

export interface QueryTweakwiseAdvisorArgs {
    params: TweakwiseAdvisorParams
}

export interface QueryTweakwiseFunnelArgs {
    params: Maybe<TweakwiseFunnelParams>
}

export interface QueryTweakwiseNavigationArgs {
    params: Maybe<TweakwiseNavigationParams>
}

export interface QueryTweakwiseSearchBannersArgs {
    params: Maybe<TweakwiseSearchBannerParams>
}

export interface QueryTweakwiseRecommendationsFeaturedArgs {
    templateId: Scalars['ID']
    params: Maybe<TweakwiseRecommendationsFeaturedParams>
}

export interface QueryTweakwiseRecommendationsProductTemplatesArgs {
    itemNo: Scalars['ID']
    params: Maybe<TweakwiseRecommendationsFeaturedParams>
}

export interface QueryTweakwiseRecommendationsProductArgs {
    itemNo: Scalars['ID']
    templateId: Scalars['ID']
    params: Maybe<TweakwiseRecommendationsFeaturedParams>
}

export interface QueryTweakwiseRecommendationsProductGroupArgs {
    itemNo: Scalars['ID']
    groupKey: Scalars['ID']
    params: Maybe<TweakwiseRecommendationsFeaturedParams>
}

export interface QueuePage extends Document, Linkable {
    __typename?: 'QueuePage'
    storeview: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    description: Maybe<Scalars['String']>
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

/** A connection to a list of items. */
export interface QueuePageConnectionConnection {
    __typename?: 'QueuePageConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<QueuePageConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface QueuePageConnectionEdge {
    __typename?: 'QueuePageConnectionEdge'
    /** The item at the end of the edge. */
    node: QueuePage
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

/** QueueParameters defines the queue configuration values */
export interface QueueParameters {
    __typename?: 'QueueParameters'
    expireTime: Maybe<Scalars['Int']>
    maxShoppers: Maybe<Scalars['Int']>
    queueCheckTime: Maybe<Scalars['Int']>
}

export interface QueuePosition {
    __typename?: 'QueuePosition'
    waitingAhead: Scalars['Int']
    ready: Scalars['Boolean']
    whitelisted: Scalars['Boolean']
}

/** Specifies the field to use for sorting quote items */
export interface QuoteItemsSortInput {
    /** Specifies the quote items field to sort by */
    field: SortQuoteItemsEnum
    /** Specifies the order of quote items' sorting */
    order: SortEnum
}

/** Contains reCAPTCHA V3-Invisible configuration details. */
export interface ReCaptchaConfigurationV3 {
    __typename?: 'ReCaptchaConfigurationV3'
    /** The position of the invisible reCAPTCHA badge on each page. */
    badgePosition: Scalars['String']
    /** The message that appears to the user if validation fails. */
    failureMessage: Scalars['String']
    /** A list of forms on the storefront that have been configured to use reCAPTCHA V3. */
    forms: Array<Maybe<ReCaptchaFormEnum>>
    /** Return whether recaptcha is enabled or not */
    isEnabled: Scalars['Boolean']
    /** A two-character code that specifies the language that is used for Google reCAPTCHA text and messaging. */
    languageCode: Maybe<Scalars['String']>
    /** The minimum score that identifies a user interaction as a potential risk. */
    minimumScore: Scalars['Float']
    /** The website key generated when the Google reCAPTCHA account was registered. */
    websiteKey: Scalars['String']
}

export enum ReCaptchaFormEnum {
    PLACE_ORDER = 'PLACE_ORDER',
    CONTACT = 'CONTACT',
    CUSTOMER_LOGIN = 'CUSTOMER_LOGIN',
    CUSTOMER_FORGOT_PASSWORD = 'CUSTOMER_FORGOT_PASSWORD',
    CUSTOMER_CREATE = 'CUSTOMER_CREATE',
    CUSTOMER_EDIT = 'CUSTOMER_EDIT',
    NEWSLETTER = 'NEWSLETTER',
    PRODUCT_REVIEW = 'PRODUCT_REVIEW',
    SENDFRIEND = 'SENDFRIEND',
    BRAINTREE = 'BRAINTREE',
}

export interface Region {
    __typename?: 'Region'
    /** The two-letter code for the region, such as TX for Texas. */
    code: Maybe<Scalars['String']>
    /** The unique ID for a `Region` object. */
    id: Maybe<Scalars['Int']>
    /** The name of the region, such as Texas. */
    name: Maybe<Scalars['String']>
}

export interface RelatedDocument {
    __typename?: 'RelatedDocument'
    /** The id of the document. */
    id: Scalars['String']
    /** The uid of the document. */
    uid: Maybe<Scalars['String']>
    /** The type of the document. */
    type: Scalars['String']
    /** The language of the document. */
    lang: Scalars['String']
}

/** Specifies the cart from which to remove a coupon. */
export interface RemoveCouponFromCartInput {
    /** The unique ID of a `Cart` object. */
    cart_id: Scalars['String']
    /** Optional voucher code */
    coupon_code?: Maybe<Scalars['String']>
}

/** Contains details about the cart after removing a coupon. */
export interface RemoveCouponFromCartOutput {
    __typename?: 'RemoveCouponFromCartOutput'
    /** The cart after removing a coupon. */
    cart: Maybe<Cart>
}

/** Specifies which items to remove from the cart. */
export interface RemoveItemFromCartInput {
    /** The unique ID of a `Cart` object. */
    cart_id: Scalars['String']
    /** Deprecated. Use `cart_item_uid` instead. */
    cart_item_id?: Maybe<Scalars['Int']>
    /** Required field. The unique ID for a `CartItemInterface` object. */
    cart_item_uid?: Maybe<Scalars['ID']>
}

/** Contains details about the cart after removing an item. */
export interface RemoveItemFromCartOutput {
    __typename?: 'RemoveItemFromCartOutput'
    /** The cart after removing an item. */
    cart: Cart
}

/** Defines which products to remove from a compare list. */
export interface RemoveProductsFromCompareListInput {
    /** An array of product IDs to remove from the compare list. */
    products: Array<Maybe<Scalars['ID']>>
    /** The unique identifier of the compare list to modify. */
    uid: Scalars['ID']
}

/** Contains the customer's wish list and any errors encountered. */
export interface RemoveProductsFromWishlistOutput {
    __typename?: 'RemoveProductsFromWishlistOutput'
    /** An array of errors encountered while deleting products from a wish list. */
    userErrors: Array<Maybe<WishListUserInputError>>
    /** Contains the wish list with after items were successfully deleted. */
    wishlist: Wishlist
}

/** Contains the cart and any errors after adding products. */
export interface ReorderItemsOutput {
    __typename?: 'ReorderItemsOutput'
    /** Detailed information about the customer's cart. */
    cart: Cart
    /** An array of reordering errors. */
    userInputErrors: Array<Maybe<CheckoutUserInputError>>
}

export interface Reservation {
    __typename?: 'Reservation'
    isSalable: Maybe<Scalars['Boolean']>
    reservationQty: Maybe<Scalars['Int']>
    salableQty: Maybe<Scalars['Int']>
}

/** Contains the result of a request to revoke a customer token. */
export interface RevokeCustomerTokenOutput {
    __typename?: 'RevokeCustomerTokenOutput'
    /** The result of a request to revoke a customer token. */
    result: Scalars['Boolean']
}

/** Routable entities serve as the model for a rendered page. */
export interface RoutableInterface {
    /**
     * Contains 0 when there is no redirect error. A value of 301 indicates the URL
     * of the requested resource has been changed permanently, while a value of 302
     * indicates a temporary redirect.
     */
    redirectCode: Scalars['Int']
    /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
    relativeUrl: Maybe<Scalars['String']>
    /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
    type: Maybe<UrlRewriteEntityTypeEnum>
}

/** Default implementation of RoutableInterface. This type is returned when the URL is not linked to an entity. */
export interface RoutableUrl extends RoutableInterface {
    __typename?: 'RoutableUrl'
    /**
     * Contains 0 when there is no redirect error. A value of 301 indicates the URL
     * of the requested resource has been changed permanently, while a value of 302
     * indicates a temporary redirect.
     */
    redirectCode: Scalars['Int']
    /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
    relativeUrl: Maybe<Scalars['String']>
    /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
    type: Maybe<UrlRewriteEntityTypeEnum>
}

/** Contains details about a comment. */
export interface SalesCommentItem {
    __typename?: 'SalesCommentItem'
    /** The text of the message. */
    message: Scalars['String']
    /** The timestamp of the comment. */
    timestamp: Scalars['String']
}

export interface SalesItemInterface {
    __typename?: 'SalesItemInterface'
    /** The entered gift message for the order item */
    giftMessage: Maybe<GiftMessage>
}

export interface SalesLimitsInfo {
    __typename?: 'SalesLimitsInfo'
    ordersRemaining: Maybe<Scalars['Int']>
    redirectUrl: Maybe<Scalars['String']>
    status: SalesLimitsStatus
}

export enum SalesLimitsStatus {
    UNLIMITED = 'UNLIMITED',
    LIMITED = 'LIMITED',
    LOCKED = 'LOCKED',
}

/** SalesRule */
export interface SalesRule {
    __typename?: 'SalesRule'
    /** Description */
    description: Maybe<Scalars['String']>
    /** ID */
    id: Scalars['Int']
    /** Image */
    image: Maybe<Scalars['String']>
    /** Is Active */
    isActive: Maybe<Scalars['Boolean']>
    /** Label */
    label: Maybe<Scalars['String']>
    /** Name */
    name: Maybe<Scalars['String']>
}

/** This enumeration defines the scope type for customer orders. */
export enum ScopeTypeEnum {
    GLOBAL = 'GLOBAL',
    WEBSITE = 'WEBSITE',
    STORE = 'STORE',
}

export interface SearchPage extends Document, Linkable {
    __typename?: 'SearchPage'
    storeview: Maybe<Scalars['String']>
    body: Maybe<Array<SearchPageBody>>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

export type SearchPageBody = SearchPageBodyCategoryList

export interface SearchPageBodyCategoryList {
    __typename?: 'SearchPageBodyCategoryList'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<SearchPageBodyCategoryListPrimary>
    fields: Maybe<Array<SearchPageBodyCategoryListFields>>
}

export interface SearchPageBodyCategoryListFields {
    __typename?: 'SearchPageBodyCategoryListFields'
    title: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
    list: Maybe<Scalars['String']>
}

export interface SearchPageBodyCategoryListPrimary {
    __typename?: 'SearchPageBodyCategoryListPrimary'
    heading: Maybe<Scalars['String']>
    title1: Maybe<Scalars['String']>
    title2: Maybe<Scalars['String']>
    title3: Maybe<Scalars['String']>
    title4: Maybe<Scalars['String']>
}

/** A connection to a list of items. */
export interface SearchPageConnectionConnection {
    __typename?: 'SearchPageConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<SearchPageConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface SearchPageConnectionEdge {
    __typename?: 'SearchPageConnectionEdge'
    /** The item at the end of the edge. */
    node: SearchPage
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

/** Provides navigation for the query response. */
export interface SearchResultPageInfo {
    __typename?: 'SearchResultPageInfo'
    /** The specific page to return. */
    currentPage: Maybe<Scalars['Int']>
    /** The maximum number of items to return per page of results. */
    pageSize: Maybe<Scalars['Int']>
    /** The total number of pages in the response. */
    totalPages: Maybe<Scalars['Int']>
}

/** A string that contains search suggestion */
export interface SearchSuggestion {
    __typename?: 'SearchSuggestion'
    /** The search suggestion of existing product. */
    search: Scalars['String']
}

/** Contains details about a selected bundle option. */
export interface SelectedBundleOption {
    __typename?: 'SelectedBundleOption'
    /** @deprecated Use `uid` instead */
    id: Scalars['Int']
    /** The display name of the selected bundle product option. */
    label: Scalars['String']
    /** The type of selected bundle product option. */
    type: Scalars['String']
    /** The unique ID for a `SelectedBundleOption` object */
    uid: Scalars['ID']
    /** An array of selected bundle option values. */
    values: Array<Maybe<SelectedBundleOptionValue>>
}

/** Contains details about a value for a selected bundle option. */
export interface SelectedBundleOptionValue {
    __typename?: 'SelectedBundleOptionValue'
    /** Use `uid` instead */
    id: Scalars['Int']
    /** The display name of the value for the selected bundle product option. */
    label: Scalars['String']
    /** The price of the value for the selected bundle product option. */
    price: Scalars['Float']
    /** The quantity of the value for the selected bundle product option. */
    quantity: Scalars['Float']
    /** The unique ID for a `SelectedBundleOptionValue` object */
    uid: Scalars['ID']
}

/** Contains details about a selected configurable option. */
export interface SelectedConfigurableOption {
    __typename?: 'SelectedConfigurableOption'
    /** The unique ID for a `ConfigurableProductOptions` object. */
    configurableProductOptionUid: Scalars['ID']
    /** The unique ID for a `ConfigurableProductOptionsValues` object. */
    configurableProductOptionValueUid: Scalars['ID']
    /** @deprecated Use `SelectedConfigurableOption.configurable_product_option_uid` instead. */
    id: Scalars['Int']
    /** The display text for the option. */
    optionLabel: Scalars['String']
    /** @deprecated Use `SelectedConfigurableOption.configurable_product_option_value_uid` instead. */
    valueId: Scalars['Int']
    /** The display name of the selected configurable option. */
    valueLabel: Scalars['String']
}

/** Identifies a customized product that has been placed in a cart. */
export interface SelectedCustomizableOption {
    __typename?: 'SelectedCustomizableOption'
    /**
     * The unique ID for a specific `CustomizableOptionInterface` object, such as a
     * `CustomizableFieldOption`, `CustomizableFileOption`, or
     * `CustomizableAreaOption` object.
     */
    customizableOptionUid: Scalars['ID']
    /** @deprecated Use `SelectedCustomizableOption.customizable_option_uid` instead. */
    id: Scalars['Int']
    /** Indicates whether the customizable option is required. */
    isRequired: Scalars['Boolean']
    /** The display name of the selected customizable option. */
    label: Scalars['String']
    /** A value indicating the order to display this option. */
    sortOrder: Scalars['Int']
    /** The type of `CustomizableOptionInterface` object. */
    type: Scalars['String']
    /** An array of selectable values. */
    values: Array<Maybe<SelectedCustomizableOptionValue>>
}

/** Identifies the value of the selected customized option. */
export interface SelectedCustomizableOptionValue {
    __typename?: 'SelectedCustomizableOptionValue'
    /** The unique ID for a value object that corresponds to the object represented by the `customizable_option_uid` attribute. */
    customizableOptionValueUid: Scalars['ID']
    /** @deprecated Use `SelectedCustomizableOptionValue.customizable_option_value_uid` instead. */
    id: Scalars['Int']
    /** The display name of the selected value. */
    label: Scalars['String']
    /** The price of the selected customizable value. */
    price: CartItemSelectedOptionValuePrice
    /** The text identifying the selected value. */
    value: Scalars['String']
}

/** Describes the payment method the shopper selected. */
export interface SelectedPaymentMethod {
    __typename?: 'SelectedPaymentMethod'
    /** The payment method code. */
    code: Scalars['String']
    /** The purchase order number. */
    purchaseOrderNumber: Maybe<Scalars['String']>
    /** The payment method title. */
    title: Scalars['String']
}

/** Contains details about the selected shipping method and carrier. */
export interface SelectedShippingMethod {
    __typename?: 'SelectedShippingMethod'
    /** The cost of shipping using this shipping method. */
    amount: Money
    /** @deprecated The field should not be used on the storefront. */
    baseAmount: Maybe<Money>
    /** A string that identifies a commercial carrier or an offline shipping method. */
    carrierCode: Scalars['String']
    /** The label for the carrier code. */
    carrierTitle: Scalars['String']
    /** A shipping method code associated with a carrier. */
    methodCode: Scalars['String']
    /** The label for the method code. */
    methodTitle: Scalars['String']
    optionCode: Maybe<Scalars['String']>
    /** The cost of shipping using this shipping method, excluding tax. */
    priceExclTax: Money
    /** The cost of shipping using this shipping method, including tax. */
    priceInclTax: Money
}

/** Defines the referenced product and the email sender and recipients. */
export interface SendEmailToFriendInput {
    /** The ID of the product that the sender is referencing. */
    product_id: Scalars['Int']
    /** An array containing information about each recipient. */
    recipients: Array<Maybe<SendEmailToFriendRecipientInput>>
    /** Information about the customer and the content of the message. */
    sender: SendEmailToFriendSenderInput
}

/** Contains information about the sender and recipients. */
export interface SendEmailToFriendOutput {
    __typename?: 'SendEmailToFriendOutput'
    /** An array containing information about each recipient. */
    recipients: Maybe<Array<Maybe<SendEmailToFriendRecipient>>>
    /** Information about the customer and the content of the message. */
    sender: Maybe<SendEmailToFriendSender>
}

/** An output object that contains information about the recipient. */
export interface SendEmailToFriendRecipient {
    __typename?: 'SendEmailToFriendRecipient'
    /** The email address of the recipient. */
    email: Scalars['String']
    /** The name of the recipient. */
    name: Scalars['String']
}

/** Contains details about a recipient. */
export interface SendEmailToFriendRecipientInput {
    /** The email address of the recipient. */
    email: Scalars['String']
    /** The name of the recipient. */
    name: Scalars['String']
}

/** An output object that contains information about the sender. */
export interface SendEmailToFriendSender {
    __typename?: 'SendEmailToFriendSender'
    /** The email address of the sender. */
    email: Scalars['String']
    /** The text of the message to be sent. */
    message: Scalars['String']
    /** The name of the sender. */
    name: Scalars['String']
}

/** Contains details about the sender. */
export interface SendEmailToFriendSenderInput {
    /** The email address of the sender. */
    email: Scalars['String']
    /** The text of the message to be sent. */
    message: Scalars['String']
    /** The name of the sender. */
    name: Scalars['String']
}

/** Contains details about the configuration of the Email to a Friend feature. */
export interface SendFriendConfiguration {
    __typename?: 'SendFriendConfiguration'
    /** Indicates whether the Email to a Friend feature is enabled. */
    enabledForCustomers: Scalars['Boolean']
    /** Indicates whether the Email to a Friend feature is enabled for guests. */
    enabledForGuests: Scalars['Boolean']
}

/** Sets the billing address. */
export interface SetBillingAddressOnCartInput {
    /** The billing address. */
    billing_address: BillingAddressInput
    /** The unique ID of a `Cart` object. */
    cart_id: Scalars['String']
}

/** Contains details about the cart after setting the billing address. */
export interface SetBillingAddressOnCartOutput {
    __typename?: 'SetBillingAddressOnCartOutput'
    /** The cart after setting the billing address. */
    cart: Cart
}

/** Defines the guest email and cart. */
export interface SetGuestEmailOnCartInput {
    /** The unique ID of a `Cart` object. */
    cart_id: Scalars['String']
    /** The email address of the guest. */
    email: Scalars['String']
}

/** Contains details about the cart after setting the email of a guest. */
export interface SetGuestEmailOnCartOutput {
    __typename?: 'SetGuestEmailOnCartOutput'
    /** The cart after setting the guest email. */
    cart: Cart
}

/** Applies a payment method to the quote. */
export interface SetPaymentMethodAndPlaceOrderInput {
    /** The unique ID of a `Cart` object. */
    cart_id: Scalars['String']
    /** The payment method data to apply to the cart. */
    payment_method: PaymentMethodInput
}

/** Applies a payment method to the cart. */
export interface SetPaymentMethodOnCartInput {
    /** The unique ID of a `Cart` object. */
    cart_id: Scalars['String']
    /** The payment method data to apply to the cart. */
    payment_method: PaymentMethodInput
}

/** Contains details about the cart after setting the payment method. */
export interface SetPaymentMethodOnCartOutput {
    __typename?: 'SetPaymentMethodOnCartOutput'
    /** The cart after setting the payment method. */
    cart: Cart
}

/** Specifies an array of addresses to use for shipping. */
export interface SetShippingAddressesOnCartInput {
    /** The unique ID of a `Cart` object. */
    cart_id: Scalars['String']
    /** An array of shipping addresses. */
    shipping_addresses: Array<Maybe<ShippingAddressInput>>
}

/** Contains details about the cart after setting the shipping addresses. */
export interface SetShippingAddressesOnCartOutput {
    __typename?: 'SetShippingAddressesOnCartOutput'
    /** The cart after setting the shipping addresses. */
    cart: Cart
}

export interface SetShippingMethodOptionInput {
    cart_id: Scalars['String']
    option_code: Scalars['String']
}

export interface SetShippingMethodOptionOutput {
    __typename?: 'SetShippingMethodOptionOutput'
    cart: Cart
}

/** Applies one or shipping methods to the cart. */
export interface SetShippingMethodsOnCartInput {
    /** The unique ID of a `Cart` object. */
    cart_id: Scalars['String']
    /** An array of shipping methods. */
    shipping_methods: Array<Maybe<ShippingMethodInput>>
}

/** Contains details about the cart after setting the shipping methods. */
export interface SetShippingMethodsOnCartOutput {
    __typename?: 'SetShippingMethodsOnCartOutput'
    /** The cart after setting the shipping methods. */
    cart: Cart
}

/** Defines whether bundle items must be shipped together. */
export enum ShipBundleItemsEnum {
    TOGETHER = 'TOGETHER',
    SEPARATELY = 'SEPARATELY',
}

export interface ShipmentItem extends ShipmentItemInterface {
    __typename?: 'ShipmentItem'
    /** The unique ID for a `ShipmentItemInterface` object. */
    id: Scalars['ID']
    /** The order item associated with the shipment item. */
    orderItem: Maybe<OrderItemInterface>
    /** The name of the base product. */
    productName: Maybe<Scalars['String']>
    /** The sale price for the base product. */
    productSalePrice: Money
    /** The SKU of the base product. */
    productSku: Scalars['String']
    /** The number of shipped items. */
    quantityShipped: Scalars['Float']
}

/** Order shipment item details. */
export interface ShipmentItemInterface {
    /** The unique ID for a `ShipmentItemInterface` object. */
    id: Scalars['ID']
    /** The order item associated with the shipment item. */
    orderItem: Maybe<OrderItemInterface>
    /** The name of the base product. */
    productName: Maybe<Scalars['String']>
    /** The sale price for the base product. */
    productSalePrice: Money
    /** The SKU of the base product. */
    productSku: Scalars['String']
    /** The number of shipped items. */
    quantityShipped: Scalars['Float']
}

/** Contains order shipment tracking details. */
export interface ShipmentTracking {
    __typename?: 'ShipmentTracking'
    /** The shipping carrier for the order delivery. */
    carrier: Scalars['String']
    /** The tracking number of the order shipment. */
    number: Maybe<Scalars['String']>
    /** The shipment tracking title. */
    title: Scalars['String']
    /** The tracking URL of the order shipment. */
    url: Maybe<Scalars['String']>
}

/** Defines a single shipping address. */
export interface ShippingAddressInput {
    /** Defines a shipping address. */
    address?: Maybe<CartAddressInput>
    /** An ID from the customer's address book that uniquely identifies the address to be used for shipping. */
    customer_address_id?: Maybe<Scalars['Int']>
    /** Text provided by the shopper. */
    customer_notes?: Maybe<Scalars['String']>
    /** The code of Pickup Location which will be used for In-Store Pickup. */
    pickup_location_code?: Maybe<Scalars['String']>
}

/** Contains shipping addresses and methods. */
export interface ShippingCartAddress extends CartAddressInterface {
    __typename?: 'ShippingCartAddress'
    /** An array that lists the shipping methods that can be applied to the cart. */
    availableShippingMethods: Maybe<Array<Maybe<AvailableShippingMethod>>>
    /** @deprecated Use `cart_items_v2` instead. */
    cartItems: Maybe<Array<Maybe<CartItemQuantity>>>
    /** An array that lists the items in the cart. */
    cartItemsV2: Maybe<Array<Maybe<CartItemInterface>>>
    /** The city specified for the billing or shipping address. */
    city: Scalars['String']
    /** The company specified for the billing or shipping address. */
    company: Maybe<Scalars['String']>
    /** An object containing the country label and code. */
    country: CartAddressCountry
    /** Text provided by the shopper. */
    customerNotes: Maybe<Scalars['String']>
    /** The customer's fax number. */
    fax: Maybe<Scalars['String']>
    /** The first name of the customer or guest. */
    firstname: Scalars['String']
    /** @deprecated This information should not be exposed on the frontend. */
    itemsWeight: Maybe<Scalars['Float']>
    /** The last name of the customer or guest. */
    lastname: Scalars['String']
    /** The middle name of the person associated with the billing/shipping address. */
    middlename: Maybe<Scalars['String']>
    pickupLocationCode: Maybe<Scalars['String']>
    /** The ZIP or postal code of the billing or shipping address. */
    postcode: Maybe<Scalars['String']>
    /** An honorific, such as Dr., Mr., or Mrs. */
    prefix: Maybe<Scalars['String']>
    /** An object containing the region label and code. */
    region: Maybe<CartAddressRegion>
    /** An object that describes the selected shipping method. */
    selectedShippingMethod: Maybe<SelectedShippingMethod>
    /** An array containing the street for the billing or shipping address. */
    street: Array<Maybe<Scalars['String']>>
    /** A value such as Sr., Jr., or III. */
    suffix: Maybe<Scalars['String']>
    /** The telephone number for the billing or shipping address. */
    telephone: Maybe<Scalars['String']>
    /** The unique id of the customer address. */
    uid: Scalars['String']
    /** The VAT company number for billing or shipping address. */
    vatId: Maybe<Scalars['String']>
}

/** Defines an individual shipping discount. This discount can be applied to shipping. */
export interface ShippingDiscount {
    __typename?: 'ShippingDiscount'
    /** The amount of the discount. */
    amount: Money
}

/** Contains details about shipping and handling costs. */
export interface ShippingHandling {
    __typename?: 'ShippingHandling'
    /** The shipping amount, excluding tax. */
    amountExcludingTax: Maybe<Money>
    /** The shipping amount, including tax. */
    amountIncludingTax: Maybe<Money>
    /** The applied discounts to the shipping. */
    discounts: Maybe<Array<Maybe<ShippingDiscount>>>
    /** Details about taxes applied for shipping. */
    taxes: Maybe<Array<Maybe<TaxItem>>>
    /** The total amount for shipping. */
    totalAmount: Money
}

/** Defines the shipping carrier and method. */
export interface ShippingMethodInput {
    /** A string that identifies a commercial carrier or an offline delivery method. */
    carrier_code: Scalars['String']
    /** Country ID of the DHL Parcel Service Point. */
    dhlparcel_shipping_servicepoint_country_id?: Maybe<Scalars['String']>
    /** Service Point ID of the DHL Parcel Service Point. */
    dhlparcel_shipping_servicepoint_id?: Maybe<Scalars['String']>
    /**
     * A string that indicates which service a commercial carrier will use to ship
     * items. For offline delivery methods, this value is similar to the label
     * displayed on the checkout page.
     */
    method_code: Scalars['String']
}

export interface Similar {
    documentId: Scalars['String']
    max: Scalars['Int']
}

/** An implementation for simple product cart items. */
export interface SimpleCartItem extends CartItemInterface {
    __typename?: 'SimpleCartItem'
    /** An array containing the customizable options the shopper selected. */
    customizableOptions: Array<Maybe<SelectedCustomizableOption>>
    /** An array of errors encountered while loading the cart item */
    errors: Maybe<Array<Maybe<CartItemError>>>
    extensionAttributes: Maybe<CartItemExtensionAttributes>
    /** The entered gift message for the cart item */
    giftMessage: Maybe<GiftMessage>
    /** @deprecated Use `uid` instead. */
    id: Scalars['String']
    /** True if requested quantity is less than available stock, false otherwise. */
    isAvailable: Scalars['Boolean']
    isFreeGift: Maybe<Scalars['Boolean']>
    /** Contains details about the price of the item, including taxes and discounts. */
    prices: Maybe<CartItemPrices>
    /** Details about an item in the cart. */
    product: ProductInterface
    promotional: Maybe<Scalars['Boolean']>
    /** The quantity of this item in the cart. */
    quantity: Scalars['Float']
    reservation: Maybe<Reservation>
    /** The unique ID for a `CartItemInterface` object. */
    uid: Scalars['ID']
}

export interface SimpleForm extends Document, Linkable {
    __typename?: 'SimpleForm'
    formTitle: Maybe<Scalars['Json']>
    formSubtitle: Maybe<Scalars['Json']>
    formFields: Maybe<Array<SimpleFormFormFields>>
    buttonLabel: Maybe<Scalars['String']>
    buttonSubmitUrl: Maybe<Scalars['String']>
    formFooter: Maybe<Scalars['Json']>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

/** A connection to a list of items. */
export interface SimpleFormConnectionConnection {
    __typename?: 'SimpleFormConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<SimpleFormConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface SimpleFormConnectionEdge {
    __typename?: 'SimpleFormConnectionEdge'
    /** The item at the end of the edge. */
    node: SimpleForm
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

export interface SimpleFormFormFields {
    __typename?: 'SimpleFormFormFields'
    inputLabel: Maybe<Scalars['String']>
    formField: Maybe<Scalars['String']>
    inputPlaceholder: Maybe<Scalars['String']>
    inputType: Maybe<Scalars['String']>
}

/** Defines a simple product, which is tangible and is usually sold in single units or in fixed quantities. */
export interface SimpleProduct
    extends ProductInterface,
        RoutableInterface,
        PhysicalProductInterface,
        CustomizableProductInterface {
    __typename?: 'SimpleProduct'
    /** @deprecated Use the `custom_attributes` field instead. */
    baseColorHexCode: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    brandAlias: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    brandMediaPdpBottom: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    brandMediaPdpTop: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    brandName: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    brandPdpDescription: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    brandPdpTitle: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    featureDescription: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    featureMedia: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    featureName: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    itemLabelTextB2C: Maybe<Scalars['Int']>
    itemLabelTextB2CValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    itemLabelTypeB2C: Maybe<Scalars['Int']>
    itemLabelTypeB2CValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    itemMaterialBody: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    itemMaterialBodyLining: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    itemMaterialLining: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    itemMaterialShell: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    itemSizeAdvice: Maybe<Scalars['Int']>
    itemSizeAdviceValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    modelAdvice: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productArticleNumber: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productBeltLoops: Maybe<Scalars['Int']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productBodyShell: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productChestPockets: Maybe<Scalars['Int']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productClosure: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productClosureDetails: Maybe<Scalars['String']>
    productClosureDetailsValues: Maybe<Array<Maybe<AttributeOption>>>
    productClosureValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productCollar: Maybe<Scalars['Int']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productCollarDetails: Maybe<Scalars['String']>
    productCollarDetailsValues: Maybe<Array<Maybe<AttributeOption>>>
    productCollarValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productContainsNonTextilePartsOfAnimalOrigin: Maybe<Scalars['Int']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productCuffs: Maybe<Scalars['String']>
    productCuffsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productDimensions: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productFabric: Maybe<Scalars['String']>
    productFabricValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productFashionColor: Maybe<Scalars['Int']>
    productFashionColorValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productFit: Maybe<Scalars['Int']>
    productFitValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productHeight: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productHood: Maybe<Scalars['Int']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productHoodDetails: Maybe<Scalars['Int']>
    productHoodDetailsValues: Maybe<Array<Maybe<AttributeOption>>>
    productHoodValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productInnerJacketDetails: Maybe<Scalars['String']>
    productInnerJacketDetailsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productInnerPockets: Maybe<Scalars['Int']>
    productInnerPocketsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productJacketDetails: Maybe<Scalars['String']>
    productJacketDetailsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productJacketType: Maybe<Scalars['Int']>
    productJacketTypeValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productJacketlayers: Maybe<Scalars['Int']>
    productJacketlayersValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productLength: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productLengthDescription: Maybe<Scalars['Int']>
    productLengthDescriptionValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productLogoLocation: Maybe<Scalars['String']>
    productLogoLocationValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMarketPlacesDescription: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMarketingStyle: Maybe<Scalars['Int']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMaterialBack: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMaterialFilling: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMaterialFront: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMaterialHood: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMaterialLining: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMaterialPadding: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMaterialShell: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productNumberOfPockets: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productOccassion: Maybe<Scalars['Int']>
    productOccassionValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productOuterPockets: Maybe<Scalars['String']>
    productOuterPocketsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productPaddingType: Maybe<Scalars['Int']>
    productPaddingTypeValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productPattern: Maybe<Scalars['String']>
    productPatternValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productPocketType: Maybe<Scalars['String']>
    productPocketTypeValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productRelevance: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productSeasonStatus: Maybe<Scalars['Int']>
    productSeasonStatusValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productShape: Maybe<Scalars['Int']>
    productShapeValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productSizeTable: Maybe<Scalars['Int']>
    productSizeTableValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productSleeveCuffDetails: Maybe<Scalars['Int']>
    productSleeveCuffDetailsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productSleeveDetails: Maybe<Scalars['Int']>
    productSleeveDetailsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productSleeveLength: Maybe<Scalars['Int']>
    productSleeveLengthValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productSustainable: Maybe<Scalars['String']>
    productSustainableValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productType: Maybe<Scalars['Int']>
    productTypeValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productVolume: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productWaistRise: Maybe<Scalars['Int']>
    productWaistRiseValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productWashingInstructionsClotex: Maybe<Scalars['String']>
    productWashingInstructionsClotexValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productWidth: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    sizeTableIdentifier: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    washingSymbolDescription: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    washingSymbolMedia: Maybe<Scalars['String']>
    /**
     * The attribute set assigned to the product.
     * @deprecated The field should not be used on the storefront.
     */
    attributeSetId: Maybe<Scalars['Int']>
    /** @deprecated Use the `custom_attributes` field instead. */
    baseColor: Maybe<Scalars['String']>
    baseColorValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    brand: Maybe<Scalars['String']>
    /**
     * The relative canonical URL. This value is returned only if the system setting
     * 'Use Canonical Link Meta Tag For Products' is enabled.
     */
    canonicalUrl: Maybe<Scalars['String']>
    /** The categories assigned to a product. */
    categories: Maybe<Array<Maybe<CategoryInterface>>>
    categoryIds: Maybe<Array<Maybe<Scalars['Int']>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    color: Maybe<Scalars['String']>
    colorValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    configAttributes: Maybe<Scalars['String']>
    /** Product bottom content */
    contentBottomContent: Maybe<Scalars['String']>
    /** Product top image one */
    contentBottomImageOne: Maybe<Scalars['String']>
    /** Product top image two */
    contentBottomImageTwo: Maybe<Scalars['String']>
    /** Product bottom title */
    contentBottomTitle: Maybe<Scalars['String']>
    contentBottomUsp: Maybe<Scalars['String']>
    /** Product middle content */
    contentMiddleContent: Maybe<Scalars['String']>
    /** Product top image one */
    contentMiddleImageOne: Maybe<Scalars['String']>
    /** Product top image two */
    contentMiddleImageTwo: Maybe<Scalars['String']>
    /** Product middle title */
    contentMiddleTitle: Maybe<Scalars['String']>
    /** Product size guide image above */
    contentSizeGuideImageAbove: Maybe<Scalars['String']>
    /** Product size guide image below */
    contentSizeGuideImageBelow: Maybe<Scalars['String']>
    /** Product top content */
    contentTopContent: Maybe<Scalars['String']>
    /** Product top image four */
    contentTopImageFour: Maybe<Scalars['String']>
    /** Product top image one */
    contentTopImageOne: Maybe<Scalars['String']>
    /** Product top image three */
    contentTopImageThree: Maybe<Scalars['String']>
    /** Product top image two */
    contentTopImageTwo: Maybe<Scalars['String']>
    /** Product top title */
    contentTopTitle: Maybe<Scalars['String']>
    /** The product's country of origin. */
    countryOfManufacture: Maybe<Scalars['String']>
    /**
     * Timestamp indicating when the product was created.
     * @deprecated The field should not be used on the storefront.
     */
    createdAt: Maybe<Scalars['String']>
    /** Crosssell Products */
    crosssellProducts: Maybe<Array<Maybe<ProductInterface>>>
    /** Product custom attributes. */
    customAttributesV2: Maybe<ProductCustomAttributes>
    /** Product Delivery Message */
    deliveryMessage: Maybe<Scalars['String']>
    /** Detailed information about the product. The value can include simple HTML tags. */
    description: Maybe<ComplexTextValue>
    /** @deprecated Use the `custom_attributes` field instead. */
    discountAmount: Maybe<Scalars['Float']>
    /** @deprecated Use the `custom_attributes` field instead. */
    discountPercentage: Maybe<Scalars['Float']>
    /** @deprecated Use the `custom_attributes` field instead. */
    featureIcons: Maybe<Scalars['String']>
    featureIconsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    features: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    fit: Maybe<Scalars['String']>
    fitValues: Maybe<Array<Maybe<AttributeOption>>>
    /** Indicates whether a gift message is available. */
    giftMessageAvailable: Maybe<Scalars['String']>
    hexColor: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    highlights: Maybe<Scalars['String']>
    highlightsValues: Maybe<Array<Maybe<AttributeOption>>>
    /**
     * The ID number assigned to the product.
     * @deprecated Use the `uid` field instead.
     */
    id: Scalars['Int']
    /** The relative path to the main image on the product page. */
    image: Maybe<ProductImage>
    /** URL for the Care Tab */
    infoTabCareUrl: Maybe<Scalars['String']>
    /** URL for the Features Tab */
    infoTabFeaturesUrl: Maybe<Scalars['String']>
    /** URL for the Fit Tab */
    infoTabFitUrl: Maybe<Scalars['String']>
    /** URL for the Highlights Tab */
    infoTabHighlightsUrl: Maybe<Scalars['String']>
    /** URL for the Materials Tab */
    infoTabMaterialsUrl: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    jeansSizeCombined: Maybe<Scalars['Int']>
    jeansSizeCombinedValues: Maybe<Array<Maybe<AttributeOption>>>
    /**
     * Proxy for ItemLabelTextB2C
     * @deprecated Use the `custom_attributes` field instead.
     */
    labeltekst: Maybe<Scalars['String']>
    /**
     * Proxy for ItemLabelTypeB2C
     * @deprecated Use the `custom_attributes` field instead.
     */
    labeltype: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    length: Maybe<Scalars['String']>
    lengthValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    lowestDate: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    lowestPrice: Maybe<Scalars['Float']>
    /** Main Category */
    mainCategory: Maybe<CategoryInterface>
    /**
     * A number representing the product's manufacturer.
     * @deprecated Use the `custom_attributes` field instead.
     */
    manufacturer: Maybe<Scalars['Int']>
    manufacturerValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    materialInside: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    materialOutside: Maybe<Scalars['String']>
    /** An array of media gallery objects. */
    mediaGallery: Maybe<Array<Maybe<MediaGalleryInterface>>>
    /**
     * An array of MediaGalleryEntry objects.
     * @deprecated Use `media_gallery` instead.
     */
    mediaGalleryEntries: Maybe<Array<Maybe<MediaGalleryEntry>>>
    /** A brief overview of the product for search results listings, maximum 255 characters. */
    metaDescription: Maybe<Scalars['String']>
    /** A comma-separated list of keywords that are visible only to search engines. */
    metaKeyword: Maybe<Scalars['String']>
    metaRobots: Maybe<Scalars['String']>
    /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
    metaTitle: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    modelImage: Maybe<Scalars['String']>
    /** Information related to the Model. */
    modelInfo: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    modelName: Maybe<Scalars['String']>
    /** The product name. Customers use this name to identify the product. */
    name: Scalars['String']
    /** The beginning date for new product listings, and determines if the product is featured as a new product. */
    newFromDate: Maybe<Scalars['String']>
    /** The end date for new product listings. */
    newToDate: Maybe<Scalars['String']>
    /** Product stock only x left count */
    onlyXLeftInStock: Maybe<Scalars['Float']>
    /** An array of options for a customizable product. */
    options: Maybe<Array<Maybe<CustomizableOptionInterface>>>
    /** If the product has multiple options, determines where they appear on the product page. */
    optionsContainer: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    outletAdviesprijs: Maybe<Scalars['Float']>
    /** @deprecated Use the `custom_attributes` field instead. */
    parentSku: Maybe<Scalars['String']>
    /**
     * Indicates the price of an item.
     * @deprecated Use `price_range` for product price information.
     */
    price: Maybe<ProductPrices>
    /** The range of prices for the product */
    priceRange: PriceRange
    /** An array of `TierPrice` objects. */
    priceTiers: Maybe<Array<Maybe<TierPrice>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productArticleCategoryCode: Maybe<Scalars['Int']>
    productArticleCategoryCodeValues: Maybe<Array<Maybe<AttributeOption>>>
    /**
     * Product type like, jeans, jacket etc.
     * @deprecated Use the `custom_attributes` field instead.
     */
    productGroup: Maybe<Scalars['String']>
    /** An array of `ProductLinks` objects. */
    productLinks: Maybe<Array<Maybe<ProductLinksInterface>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    products: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productsustainable2: Maybe<Scalars['String']>
    productsustainable2Values: Maybe<Array<Maybe<AttributeOption>>>
    /** The average of all the ratings given to the product. */
    ratingSummary: Scalars['Float']
    /** @deprecated Use the `custom_attributes` field instead. */
    recommendations: Maybe<Scalars['String']>
    /**
     * Contains 0 when there is no redirect error. A value of 301 indicates the URL
     * of the requested resource has been changed permanently, while a value of 302
     * indicates a temporary redirect.
     */
    redirectCode: Scalars['Int']
    /** An array of products to be displayed in a Related Products block. */
    relatedProducts: Maybe<Array<Maybe<ProductInterface>>>
    /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
    relativeUrl: Maybe<Scalars['String']>
    /** The total count of all the reviews given to the product. */
    reviewCount: Scalars['Int']
    /** The list of products reviews. */
    reviews: ProductReviews
    /** @deprecated Use the `custom_attributes` field instead. */
    seasonCollection: Maybe<Scalars['String']>
    seasonCollectionValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    shopthelook: Maybe<Scalars['Int']>
    /** A short description of the product. Its use depends on the theme. */
    shortDescription: Maybe<ComplexTextValue>
    /** @deprecated Use the `custom_attributes` field instead. */
    shortDescription2: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    shortDescription3: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    shortDescription4: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    shortDescription5: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    shortDescription6: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    size: Maybe<Scalars['String']>
    sizeValues: Maybe<Array<Maybe<AttributeOption>>>
    /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
    sku: Scalars['String']
    /** The relative path to the small image, which is used on catalog pages. */
    smallImage: Maybe<ProductImage>
    /**
     * The beginning date that a product has a special price.
     * @deprecated The field should not be used on the storefront.
     */
    specialFromDate: Maybe<Scalars['String']>
    /** The discounted price of the product. */
    specialPrice: Maybe<Scalars['Float']>
    /** The end date for a product with a special price. */
    specialToDate: Maybe<Scalars['String']>
    /** Stock status of the product */
    stockStatus: Maybe<ProductStockStatus>
    /** The file name of a swatch image. */
    swatchImage: Maybe<Scalars['String']>
    /** The relative path to the product's thumbnail image. */
    thumbnail: Maybe<ProductImage>
    /**
     * The price when tier pricing is in effect and the items purchased threshold has been reached.
     * @deprecated Use `price_tiers` for product tier price information.
     */
    tierPrice: Maybe<Scalars['Float']>
    /**
     * An array of ProductTierPrices objects.
     * @deprecated Use `price_tiers` for product tier price information.
     */
    tierPrices: Maybe<Array<Maybe<ProductTierPrices>>>
    tweakwiseCrosssellGroupCode: Maybe<Scalars['String']>
    /** Value -1 means the group_code should be used */
    tweakwiseCrosssellTemplate: Maybe<Scalars['Int']>
    tweakwiseUpsellGroupCode: Maybe<Scalars['String']>
    /** Value -1 means the group_code should be used */
    tweakwiseUpsellTemplate: Maybe<Scalars['Int']>
    /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
    type: Maybe<UrlRewriteEntityTypeEnum>
    /**
     * One of simple, virtual, bundle, downloadable, grouped, or configurable.
     * @deprecated Use `__typename` instead.
     */
    typeId: Maybe<Scalars['String']>
    /** The unique ID for a `ProductInterface` object. */
    uid: Scalars['ID']
    /**
     * Timestamp indicating when the product was updated.
     * @deprecated The field should not be used on the storefront.
     */
    updatedAt: Maybe<Scalars['String']>
    /** Upsell Products */
    upsellProducts: Maybe<Array<Maybe<ProductInterface>>>
    /** The part of the URL that identifies the product */
    urlKey: Scalars['String']
    /** @deprecated Use product's `canonical_url` or url rewrites instead */
    urlPath: Maybe<Scalars['String']>
    /** URL rewrites list */
    urlRewrites: Maybe<Array<Maybe<UrlRewrite>>>
    /** The part of the product URL that is appended after the url key */
    urlSuffix: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    uspIcons: Maybe<Scalars['String']>
    uspIconsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** USPs for this specific Product. */
    usps: Array<Maybe<Usp>>
    warehouse: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    washingInstructions: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    washingSymbols: Maybe<Scalars['String']>
    washingSymbolsValues: Maybe<Array<Maybe<AttributeOption>>>
    /**
     * An array of websites in which the product is available.
     * @deprecated Use the `custom_attributes` field instead.
     */
    websites: Maybe<Array<Maybe<Website>>>
    /** The weight of the item, in units defined by the store. */
    weight: Maybe<Scalars['Float']>
}

/** Defines a simple product, which is tangible and is usually sold in single units or in fixed quantities. */
export interface SimpleProductCustomAttributesV2Args {
    filters: Maybe<AttributeFilterInput>
}

/** Defines a simple product, which is tangible and is usually sold in single units or in fixed quantities. */
export interface SimpleProductReviewsArgs {
    pageSize?: Maybe<Scalars['Int']>
    currentPage?: Maybe<Scalars['Int']>
}

/** Defines a simple product, which is tangible and is usually sold in single units or in fixed quantities. */
export interface SimpleProductUspsArgs {
    pageSize?: Maybe<Scalars['Int']>
    currentPage?: Maybe<Scalars['Int']>
}

/** Defines a single product to add to the cart. */
export interface SimpleProductCartItemInput {
    /** An array that defines customizable options for the product. */
    customizable_options?: Maybe<Array<Maybe<CustomizableOptionInput>>>
    /** An object containing the `sku`, `quantity`, and other relevant information about the product. */
    data: CartItemInput
}

/** Contains a simple product wish list item. */
export interface SimpleWishlistItem extends WishlistItemInterface {
    __typename?: 'SimpleWishlistItem'
    /** The date and time the item was added to the wish list. */
    addedAt: Scalars['String']
    /** Custom options selected for the wish list item. */
    customizableOptions: Array<Maybe<SelectedCustomizableOption>>
    /** The description of the item. */
    description: Maybe<Scalars['String']>
    /** The unique ID for a `WishlistItemInterface` object. */
    id: Scalars['ID']
    /** Product details of the wish list item. */
    product: Maybe<ProductInterface>
    /** The quantity of this wish list item. */
    quantity: Scalars['Float']
}

export interface SizeGuide {
    __typename?: 'SizeGuide'
    description: Scalars['String']
    image: Maybe<Scalars['String']>
    note: Maybe<Scalars['String']>
    productType: Scalars['String']
    sizeTable: Maybe<Array<Maybe<SizeGuideRow>>>
    sizeTypeOne: SizeGuideSizeType
    sizeTypeThree: Maybe<SizeGuideSizeType>
    sizeTypeTwo: Maybe<SizeGuideSizeType>
}

export interface SizeGuideResult {
    __typename?: 'SizeGuideResult'
    guide: Maybe<SizeGuide>
}

export interface SizeGuideRow {
    __typename?: 'SizeGuideRow'
    header: Scalars['String']
    sizeOne: Scalars['String']
    sizeThree: Maybe<Scalars['String']>
    sizeTwo: Maybe<Scalars['String']>
}

export interface SizeGuideSizeType {
    __typename?: 'SizeGuideSizeType'
    description: Maybe<Scalars['String']>
    title: Scalars['String']
    titleShort: Maybe<Scalars['String']>
}

export interface SizeTable {
    __typename?: 'SizeTable'
    description: Maybe<Scalars['String']>
    name: Maybe<Scalars['String']>
    note: Maybe<Scalars['String']>
    rows: Array<Maybe<SizeTableRow>>
    uid: Scalars['String']
}

export interface SizeTableCell {
    __typename?: 'SizeTableCell'
    content: Maybe<Scalars['String']>
    isHead: Scalars['Boolean']
}

export interface SizeTableRow {
    __typename?: 'SizeTableRow'
    cells: Array<Maybe<SizeTableCell>>
}

export enum SortCastironFaqPagey {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
    URL_KEY_ASC = 'url_key_ASC',
    URL_KEY_DESC = 'url_key_DESC',
    SEO_TITLE_ASC = 'seo_title_ASC',
    SEO_TITLE_DESC = 'seo_title_DESC',
    SEO_DESCRIPTION_ASC = 'seo_description_ASC',
    SEO_DESCRIPTION_DESC = 'seo_description_DESC',
    SEO_KEYWORDS_ASC = 'seo_keywords_ASC',
    SEO_KEYWORDS_DESC = 'seo_keywords_DESC',
    STYLE_ASC = 'style_ASC',
    STYLE_DESC = 'style_DESC',
    HEAD_ASC = 'head_ASC',
    HEAD_DESC = 'head_DESC',
}

export enum SortCastironPagey {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
    URL_KEY_ASC = 'url_key_ASC',
    URL_KEY_DESC = 'url_key_DESC',
    SEO_TITLE_ASC = 'seo_title_ASC',
    SEO_TITLE_DESC = 'seo_title_DESC',
    SEO_DESCRIPTION_ASC = 'seo_description_ASC',
    SEO_DESCRIPTION_DESC = 'seo_description_DESC',
    SEO_KEYWORDS_ASC = 'seo_keywords_ASC',
    SEO_KEYWORDS_DESC = 'seo_keywords_DESC',
    STYLE_ASC = 'style_ASC',
    STYLE_DESC = 'style_DESC',
    HEAD_ASC = 'head_ASC',
    HEAD_DESC = 'head_DESC',
    PAGE_TYPE_ASC = 'page_type_ASC',
    PAGE_TYPE_DESC = 'page_type_DESC',
    STORY_DESCRIPTION_ASC = 'story_description_ASC',
    STORY_DESCRIPTION_DESC = 'story_description_DESC',
    STORY_TITLE_ASC = 'story_title_ASC',
    STORY_TITLE_DESC = 'story_title_DESC',
    PUBLICATION_FIELD_ASC = 'publication_field_ASC',
    PUBLICATION_FIELD_DESC = 'publication_field_DESC',
    PAGE_BACKGROUND_COLOR_ASC = 'page_background_color_ASC',
    PAGE_BACKGROUND_COLOR_DESC = 'page_background_color_DESC',
}

export enum SortCastironTagy {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
    TAG_ID_ASC = 'tag_id_ASC',
    TAG_ID_DESC = 'tag_id_DESC',
    TAG_NAME_ASC = 'tag_name_ASC',
    TAG_NAME_DESC = 'tag_name_DESC',
}

export enum SortCategoryBannersy {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
    STOREVIEW_ASC = 'storeview_ASC',
    STOREVIEW_DESC = 'storeview_DESC',
}

export enum SortCategoryy {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
    CATEGORY_ID_ASC = 'category_id_ASC',
    CATEGORY_ID_DESC = 'category_id_DESC',
    NAME_ASC = 'name_ASC',
    NAME_DESC = 'name_DESC',
}

export enum SortDocumentsBy {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
}

/** Indicates whether to return results in ascending or descending order. */
export enum SortEnum {
    ASC = 'ASC',
    DESC = 'DESC',
}

export enum SortFaqPagey {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
    URL_KEY_ASC = 'url_key_ASC',
    URL_KEY_DESC = 'url_key_DESC',
    SEO_TITLE_ASC = 'seo_title_ASC',
    SEO_TITLE_DESC = 'seo_title_DESC',
    SEO_DESCRIPTION_ASC = 'seo_description_ASC',
    SEO_DESCRIPTION_DESC = 'seo_description_DESC',
    SEO_KEYWORDS_ASC = 'seo_keywords_ASC',
    SEO_KEYWORDS_DESC = 'seo_keywords_DESC',
    STYLE_ASC = 'style_ASC',
    STYLE_DESC = 'style_DESC',
    HEAD_ASC = 'head_ASC',
    HEAD_DESC = 'head_DESC',
}

/** Defines a possible sort field. */
export interface SortField {
    __typename?: 'SortField'
    /** The label of the sort field. */
    label: Maybe<Scalars['String']>
    /** The attribute code of the sort field. */
    value: Maybe<Scalars['String']>
}

/** Contains a default value for sort fields and all available sort fields. */
export interface SortFields {
    __typename?: 'SortFields'
    /** The default sort field value. */
    default: Maybe<Scalars['String']>
    /** An array of possible sort fields. */
    options: Maybe<Array<Maybe<SortField>>>
}

export enum SortFooterCastirony {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
    URL_KEY_ASC = 'url_key_ASC',
    URL_KEY_DESC = 'url_key_DESC',
    URL_IOS_APP_ASC = 'url_ios_app_ASC',
    URL_IOS_APP_DESC = 'url_ios_app_DESC',
    URL_ANDROID_APP_ASC = 'url_android_app_ASC',
    URL_ANDROID_APP_DESC = 'url_android_app_DESC',
    NEWSLETTER_HEADING_ASC = 'newsletter_heading_ASC',
    NEWSLETTER_HEADING_DESC = 'newsletter_heading_DESC',
    NEWSLETTER_CONTENT_ASC = 'newsletter_content_ASC',
    NEWSLETTER_CONTENT_DESC = 'newsletter_content_DESC',
}

export enum SortFooterJbfoy {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
    URL_KEY_ASC = 'url_key_ASC',
    URL_KEY_DESC = 'url_key_DESC',
    URL_IOS_APP_ASC = 'url_ios_app_ASC',
    URL_IOS_APP_DESC = 'url_ios_app_DESC',
    URL_ANDROID_APP_ASC = 'url_android_app_ASC',
    URL_ANDROID_APP_DESC = 'url_android_app_DESC',
    NEWSLETTER_HEADING_ASC = 'newsletter_heading_ASC',
    NEWSLETTER_HEADING_DESC = 'newsletter_heading_DESC',
    NEWSLETTER_CONTENT_ASC = 'newsletter_content_ASC',
    NEWSLETTER_CONTENT_DESC = 'newsletter_content_DESC',
}

export enum SortFooterJustbrandsy {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
    URL_KEY_ASC = 'url_key_ASC',
    URL_KEY_DESC = 'url_key_DESC',
    URL_IOS_APP_ASC = 'url_ios_app_ASC',
    URL_IOS_APP_DESC = 'url_ios_app_DESC',
    URL_ANDROID_APP_ASC = 'url_android_app_ASC',
    URL_ANDROID_APP_DESC = 'url_android_app_DESC',
    NEWSLETTER_HEADING_ASC = 'newsletter_heading_ASC',
    NEWSLETTER_HEADING_DESC = 'newsletter_heading_DESC',
    NEWSLETTER_CONTENT_ASC = 'newsletter_content_ASC',
    NEWSLETTER_CONTENT_DESC = 'newsletter_content_DESC',
}

export enum SortFooterMonstersaley {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
    URL_KEY_ASC = 'url_key_ASC',
    URL_KEY_DESC = 'url_key_DESC',
    URL_IOS_APP_ASC = 'url_ios_app_ASC',
    URL_IOS_APP_DESC = 'url_ios_app_DESC',
    URL_ANDROID_APP_ASC = 'url_android_app_ASC',
    URL_ANDROID_APP_DESC = 'url_android_app_DESC',
    NEWSLETTER_HEADING_ASC = 'newsletter_heading_ASC',
    NEWSLETTER_HEADING_DESC = 'newsletter_heading_DESC',
    NEWSLETTER_CONTENT_ASC = 'newsletter_content_ASC',
    NEWSLETTER_CONTENT_DESC = 'newsletter_content_DESC',
}

export enum SortFooterPmeoutlety {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
    URL_KEY_ASC = 'url_key_ASC',
    URL_KEY_DESC = 'url_key_DESC',
    URL_IOS_APP_ASC = 'url_ios_app_ASC',
    URL_IOS_APP_DESC = 'url_ios_app_DESC',
    URL_ANDROID_APP_ASC = 'url_android_app_ASC',
    URL_ANDROID_APP_DESC = 'url_android_app_DESC',
    NEWSLETTER_HEADING_ASC = 'newsletter_heading_ASC',
    NEWSLETTER_HEADING_DESC = 'newsletter_heading_DESC',
    NEWSLETTER_CONTENT_ASC = 'newsletter_content_ASC',
    NEWSLETTER_CONTENT_DESC = 'newsletter_content_DESC',
}

export enum SortFooterPmey {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
    URL_KEY_ASC = 'url_key_ASC',
    URL_KEY_DESC = 'url_key_DESC',
    URL_IOS_APP_ASC = 'url_ios_app_ASC',
    URL_IOS_APP_DESC = 'url_ios_app_DESC',
    URL_ANDROID_APP_ASC = 'url_android_app_ASC',
    URL_ANDROID_APP_DESC = 'url_android_app_DESC',
    NEWSLETTER_HEADING_ASC = 'newsletter_heading_ASC',
    NEWSLETTER_HEADING_DESC = 'newsletter_heading_DESC',
    NEWSLETTER_CONTENT_ASC = 'newsletter_content_ASC',
    NEWSLETTER_CONTENT_DESC = 'newsletter_content_DESC',
}

export enum SortFooterVanguardy {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
    URL_KEY_ASC = 'url_key_ASC',
    URL_KEY_DESC = 'url_key_DESC',
    URL_IOS_APP_ASC = 'url_ios_app_ASC',
    URL_IOS_APP_DESC = 'url_ios_app_DESC',
    URL_ANDROID_APP_ASC = 'url_android_app_ASC',
    URL_ANDROID_APP_DESC = 'url_android_app_DESC',
    NEWSLETTER_HEADING_ASC = 'newsletter_heading_ASC',
    NEWSLETTER_HEADING_DESC = 'newsletter_heading_DESC',
    NEWSLETTER_CONTENT_ASC = 'newsletter_content_ASC',
    NEWSLETTER_CONTENT_DESC = 'newsletter_content_DESC',
}

export enum SortGuidedSellingPagey {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
    URL_KEY_ASC = 'url_key_ASC',
    URL_KEY_DESC = 'url_key_DESC',
    HEADING_ASC = 'heading_ASC',
    HEADING_DESC = 'heading_DESC',
    HEADING_STYLE_ASC = 'heading_style_ASC',
    HEADING_STYLE_DESC = 'heading_style_DESC',
    SUBHEADING_ASC = 'subheading_ASC',
    SUBHEADING_DESC = 'subheading_DESC',
    SUBHEADING_STYLE_ASC = 'subheading_style_ASC',
    SUBHEADING_STYLE_DESC = 'subheading_style_DESC',
    TWEAKWISE_FUNNEL_ID_ASC = 'tweakwise_funnel_id_ASC',
    TWEAKWISE_FUNNEL_ID_DESC = 'tweakwise_funnel_id_DESC',
    FOOTER_BUTTON_LABEL_ASC = 'footer_button_label_ASC',
    FOOTER_BUTTON_LABEL_DESC = 'footer_button_label_DESC',
    FOOTER_BUTTON_STYLE_ASC = 'footer_button_style_ASC',
    FOOTER_BUTTON_STYLE_DESC = 'footer_button_style_DESC',
    FOOTER_TEXT_ASC = 'footer_text_ASC',
    FOOTER_TEXT_DESC = 'footer_text_DESC',
}

export enum SortHeroBannery {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
    STOREVIEW_ASC = 'storeview_ASC',
    STOREVIEW_DESC = 'storeview_DESC',
    POSITION_ASC = 'position_ASC',
    POSITION_DESC = 'position_DESC',
    SIZE_ASC = 'size_ASC',
    SIZE_DESC = 'size_DESC',
    HEADER_ASC = 'header_ASC',
    HEADER_DESC = 'header_DESC',
    HEADING_VARIANT_ASC = 'heading_variant_ASC',
    HEADING_VARIANT_DESC = 'heading_variant_DESC',
    HEADING_ELEMENT_ASC = 'heading_element_ASC',
    HEADING_ELEMENT_DESC = 'heading_element_DESC',
    SUBHEADING_ASC = 'subheading_ASC',
    SUBHEADING_DESC = 'subheading_DESC',
    SUBHEADING_VARIANT_ASC = 'subheading_variant_ASC',
    SUBHEADING_VARIANT_DESC = 'subheading_variant_DESC',
    SUBHEADING_ELEMENT_ASC = 'subheading_element_ASC',
    SUBHEADING_ELEMENT_DESC = 'subheading_element_DESC',
    VIDEO_ID_ASC = 'video_id_ASC',
    VIDEO_ID_DESC = 'video_id_DESC',
    MOBILE_VIDEO_ID_ASC = 'mobile_video_id_ASC',
    MOBILE_VIDEO_ID_DESC = 'mobile_video_id_DESC',
}

export enum SortJbfoFaqPagey {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
    URL_KEY_ASC = 'url_key_ASC',
    URL_KEY_DESC = 'url_key_DESC',
    SEO_TITLE_ASC = 'seo_title_ASC',
    SEO_TITLE_DESC = 'seo_title_DESC',
    SEO_DESCRIPTION_ASC = 'seo_description_ASC',
    SEO_DESCRIPTION_DESC = 'seo_description_DESC',
    SEO_KEYWORDS_ASC = 'seo_keywords_ASC',
    SEO_KEYWORDS_DESC = 'seo_keywords_DESC',
    STYLE_ASC = 'style_ASC',
    STYLE_DESC = 'style_DESC',
    HEAD_ASC = 'head_ASC',
    HEAD_DESC = 'head_DESC',
}

export enum SortJbfoLoginPagey {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
    URL_KEY_ASC = 'url_key_ASC',
    URL_KEY_DESC = 'url_key_DESC',
    SEO_TITLE_ASC = 'seo_title_ASC',
    SEO_TITLE_DESC = 'seo_title_DESC',
    SEO_DESCRIPTION_ASC = 'seo_description_ASC',
    SEO_DESCRIPTION_DESC = 'seo_description_DESC',
    SEO_KEYWORDS_ASC = 'seo_keywords_ASC',
    SEO_KEYWORDS_DESC = 'seo_keywords_DESC',
    TITLE_ASC = 'title_ASC',
    TITLE_DESC = 'title_DESC',
    SUB_TITLE_ASC = 'sub_title_ASC',
    SUB_TITLE_DESC = 'sub_title_DESC',
    SUB_HEADING_ASC = 'sub_heading_ASC',
    SUB_HEADING_DESC = 'sub_heading_DESC',
    CONTENT_ASC = 'content_ASC',
    CONTENT_DESC = 'content_DESC',
}

export enum SortJbfoPagey {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
    URL_KEY_ASC = 'url_key_ASC',
    URL_KEY_DESC = 'url_key_DESC',
    SEO_TITLE_ASC = 'seo_title_ASC',
    SEO_TITLE_DESC = 'seo_title_DESC',
    SEO_DESCRIPTION_ASC = 'seo_description_ASC',
    SEO_DESCRIPTION_DESC = 'seo_description_DESC',
    SEO_KEYWORDS_ASC = 'seo_keywords_ASC',
    SEO_KEYWORDS_DESC = 'seo_keywords_DESC',
    STYLE_ASC = 'style_ASC',
    STYLE_DESC = 'style_DESC',
    HEAD_ASC = 'head_ASC',
    HEAD_DESC = 'head_DESC',
    PAGE_BACKGROUND_COLOR_ASC = 'page_background_color_ASC',
    PAGE_BACKGROUND_COLOR_DESC = 'page_background_color_DESC',
}

export enum SortJustbrandsFaqPagey {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
    URL_KEY_ASC = 'url_key_ASC',
    URL_KEY_DESC = 'url_key_DESC',
    SEO_TITLE_ASC = 'seo_title_ASC',
    SEO_TITLE_DESC = 'seo_title_DESC',
    SEO_DESCRIPTION_ASC = 'seo_description_ASC',
    SEO_DESCRIPTION_DESC = 'seo_description_DESC',
    SEO_KEYWORDS_ASC = 'seo_keywords_ASC',
    SEO_KEYWORDS_DESC = 'seo_keywords_DESC',
    STYLE_ASC = 'style_ASC',
    STYLE_DESC = 'style_DESC',
    HEAD_ASC = 'head_ASC',
    HEAD_DESC = 'head_DESC',
}

export enum SortJustBrandsLoginy {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
}

export enum SortJustbrandsPagey {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
    URL_KEY_ASC = 'url_key_ASC',
    URL_KEY_DESC = 'url_key_DESC',
    SEO_TITLE_ASC = 'seo_title_ASC',
    SEO_TITLE_DESC = 'seo_title_DESC',
    SEO_DESCRIPTION_ASC = 'seo_description_ASC',
    SEO_DESCRIPTION_DESC = 'seo_description_DESC',
    SEO_KEYWORDS_ASC = 'seo_keywords_ASC',
    SEO_KEYWORDS_DESC = 'seo_keywords_DESC',
    STYLE_ASC = 'style_ASC',
    STYLE_DESC = 'style_DESC',
    HEAD_ASC = 'head_ASC',
    HEAD_DESC = 'head_DESC',
    PAGE_TYPE_ASC = 'page_type_ASC',
    PAGE_TYPE_DESC = 'page_type_DESC',
    STORY_DESCRIPTION_ASC = 'story_description_ASC',
    STORY_DESCRIPTION_DESC = 'story_description_DESC',
    STORY_TITLE_ASC = 'story_title_ASC',
    STORY_TITLE_DESC = 'story_title_DESC',
    PUBLICATION_FIELD_ASC = 'publication_field_ASC',
    PUBLICATION_FIELD_DESC = 'publication_field_DESC',
    PAGE_BACKGROUND_COLOR_ASC = 'page_background_color_ASC',
    PAGE_BACKGROUND_COLOR_DESC = 'page_background_color_DESC',
}

export enum SortJustbrandsTagy {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
    TAG_ID_ASC = 'tag_id_ASC',
    TAG_ID_DESC = 'tag_id_DESC',
    TAG_NAME_ASC = 'tag_name_ASC',
    TAG_NAME_DESC = 'tag_name_DESC',
}

export enum SortLoyaltyCardy {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
    STYLE_ASC = 'style_ASC',
    STYLE_DESC = 'style_DESC',
    HEADER_ASC = 'header_ASC',
    HEADER_DESC = 'header_DESC',
    BUTTON_LABEL_ASC = 'button_label_ASC',
    BUTTON_LABEL_DESC = 'button_label_DESC',
    BUTTON_URL_ASC = 'button_url_ASC',
    BUTTON_URL_DESC = 'button_url_DESC',
    BUTTON_STYLE_ASC = 'button_style_ASC',
    BUTTON_STYLE_DESC = 'button_style_DESC',
}

export enum SortMemberUspsy {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
    STOREVIEW_ASC = 'storeview_ASC',
    STOREVIEW_DESC = 'storeview_DESC',
    TYPE_ASC = 'type_ASC',
    TYPE_DESC = 'type_DESC',
    HEADING_ASC = 'heading_ASC',
    HEADING_DESC = 'heading_DESC',
    HEADING_VARIANT_ASC = 'heading_variant_ASC',
    HEADING_VARIANT_DESC = 'heading_variant_DESC',
    CONTENT_ASC = 'content_ASC',
    CONTENT_DESC = 'content_DESC',
    BUTTON_TEXT_ASC = 'button_text_ASC',
    BUTTON_TEXT_DESC = 'button_text_DESC',
    BUTTON_URL_ASC = 'button_url_ASC',
    BUTTON_URL_DESC = 'button_url_DESC',
    BUTTON_STYLE_ASC = 'button_style_ASC',
    BUTTON_STYLE_DESC = 'button_style_DESC',
    BUTTON_2_TEXT_ASC = 'button_2_text_ASC',
    BUTTON_2_TEXT_DESC = 'button_2_text_DESC',
    BUTTON_2_URL_ASC = 'button_2_url_ASC',
    BUTTON_2_URL_DESC = 'button_2_url_DESC',
    BUTTON_2_STYLE_ASC = 'button_2_style_ASC',
    BUTTON_2_STYLE_DESC = 'button_2_style_DESC',
    MOBILE_ASC = 'mobile_ASC',
    MOBILE_DESC = 'mobile_DESC',
    TABLET_ASC = 'tablet_ASC',
    TABLET_DESC = 'tablet_DESC',
    DESKTOP_ASC = 'desktop_ASC',
    DESKTOP_DESC = 'desktop_DESC',
}

export enum SortMemberUspy {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
    STOREVIEW_ASC = 'storeview_ASC',
    STOREVIEW_DESC = 'storeview_DESC',
    HEADING_ASC = 'heading_ASC',
    HEADING_DESC = 'heading_DESC',
    HEADING_VARIANT_ASC = 'heading_variant_ASC',
    HEADING_VARIANT_DESC = 'heading_variant_DESC',
    BUTTON_TEXT_ASC = 'button_text_ASC',
    BUTTON_TEXT_DESC = 'button_text_DESC',
    BUTTON_URL_ASC = 'button_url_ASC',
    BUTTON_URL_DESC = 'button_url_DESC',
}

export enum SortMonstersaleFaqPagey {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
    URL_KEY_ASC = 'url_key_ASC',
    URL_KEY_DESC = 'url_key_DESC',
    SEO_TITLE_ASC = 'seo_title_ASC',
    SEO_TITLE_DESC = 'seo_title_DESC',
    SEO_DESCRIPTION_ASC = 'seo_description_ASC',
    SEO_DESCRIPTION_DESC = 'seo_description_DESC',
    SEO_KEYWORDS_ASC = 'seo_keywords_ASC',
    SEO_KEYWORDS_DESC = 'seo_keywords_DESC',
    STYLE_ASC = 'style_ASC',
    STYLE_DESC = 'style_DESC',
    HEAD_ASC = 'head_ASC',
    HEAD_DESC = 'head_DESC',
}

export enum SortMonstersaleLoginPagey {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
    URL_KEY_ASC = 'url_key_ASC',
    URL_KEY_DESC = 'url_key_DESC',
    SEO_TITLE_ASC = 'seo_title_ASC',
    SEO_TITLE_DESC = 'seo_title_DESC',
    SEO_DESCRIPTION_ASC = 'seo_description_ASC',
    SEO_DESCRIPTION_DESC = 'seo_description_DESC',
    SEO_KEYWORDS_ASC = 'seo_keywords_ASC',
    SEO_KEYWORDS_DESC = 'seo_keywords_DESC',
    TITLE_ASC = 'title_ASC',
    TITLE_DESC = 'title_DESC',
    SUB_TITLE_ASC = 'sub_title_ASC',
    SUB_TITLE_DESC = 'sub_title_DESC',
    SUB_HEADING_ASC = 'sub_heading_ASC',
    SUB_HEADING_DESC = 'sub_heading_DESC',
    CONTENT_ASC = 'content_ASC',
    CONTENT_DESC = 'content_DESC',
}

export enum SortMonstersalePagey {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
    URL_KEY_ASC = 'url_key_ASC',
    URL_KEY_DESC = 'url_key_DESC',
    SEO_TITLE_ASC = 'seo_title_ASC',
    SEO_TITLE_DESC = 'seo_title_DESC',
    SEO_DESCRIPTION_ASC = 'seo_description_ASC',
    SEO_DESCRIPTION_DESC = 'seo_description_DESC',
    SEO_KEYWORDS_ASC = 'seo_keywords_ASC',
    SEO_KEYWORDS_DESC = 'seo_keywords_DESC',
    STYLE_ASC = 'style_ASC',
    STYLE_DESC = 'style_DESC',
    HEAD_ASC = 'head_ASC',
    HEAD_DESC = 'head_DESC',
    PAGE_BACKGROUND_COLOR_ASC = 'page_background_color_ASC',
    PAGE_BACKGROUND_COLOR_DESC = 'page_background_color_DESC',
}

export enum SortPagey {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
    URL_KEY_ASC = 'url_key_ASC',
    URL_KEY_DESC = 'url_key_DESC',
    SEO_TITLE_ASC = 'seo_title_ASC',
    SEO_TITLE_DESC = 'seo_title_DESC',
    SEO_DESCRIPTION_ASC = 'seo_description_ASC',
    SEO_DESCRIPTION_DESC = 'seo_description_DESC',
    SEO_KEYWORDS_ASC = 'seo_keywords_ASC',
    SEO_KEYWORDS_DESC = 'seo_keywords_DESC',
    STYLE_ASC = 'style_ASC',
    STYLE_DESC = 'style_DESC',
    HEAD_ASC = 'head_ASC',
    HEAD_DESC = 'head_DESC',
    PAGE_TYPE_ASC = 'page_type_ASC',
    PAGE_TYPE_DESC = 'page_type_DESC',
    TWEAKWISE_FUNNEL_ID_ASC = 'tweakwise_funnel_id_ASC',
    TWEAKWISE_FUNNEL_ID_DESC = 'tweakwise_funnel_id_DESC',
    STORY_DESCRIPTION_ASC = 'story_description_ASC',
    STORY_DESCRIPTION_DESC = 'story_description_DESC',
    STORY_TITLE_ASC = 'story_title_ASC',
    STORY_TITLE_DESC = 'story_title_DESC',
    PUBLICATION_FIELD_ASC = 'publication_field_ASC',
    PUBLICATION_FIELD_DESC = 'publication_field_DESC',
    PAGE_BACKGROUND_COLOR_ASC = 'page_background_color_ASC',
    PAGE_BACKGROUND_COLOR_DESC = 'page_background_color_DESC',
}

export enum SortPlpMemberUspsy {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
    STOREVIEW_ASC = 'storeview_ASC',
    STOREVIEW_DESC = 'storeview_DESC',
    TYPE_ASC = 'type_ASC',
    TYPE_DESC = 'type_DESC',
    HEADING_ASC = 'heading_ASC',
    HEADING_DESC = 'heading_DESC',
    HEADING_VARIANT_ASC = 'heading_variant_ASC',
    HEADING_VARIANT_DESC = 'heading_variant_DESC',
    CONTENT_ASC = 'content_ASC',
    CONTENT_DESC = 'content_DESC',
    BUTTON_TEXT_ASC = 'button_text_ASC',
    BUTTON_TEXT_DESC = 'button_text_DESC',
    BUTTON_URL_ASC = 'button_url_ASC',
    BUTTON_URL_DESC = 'button_url_DESC',
    BUTTON_STYLE_ASC = 'button_style_ASC',
    BUTTON_STYLE_DESC = 'button_style_DESC',
}

export enum SortPmeOutletFaqPagey {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
    URL_KEY_ASC = 'url_key_ASC',
    URL_KEY_DESC = 'url_key_DESC',
    SEO_TITLE_ASC = 'seo_title_ASC',
    SEO_TITLE_DESC = 'seo_title_DESC',
    SEO_DESCRIPTION_ASC = 'seo_description_ASC',
    SEO_DESCRIPTION_DESC = 'seo_description_DESC',
    SEO_KEYWORDS_ASC = 'seo_keywords_ASC',
    SEO_KEYWORDS_DESC = 'seo_keywords_DESC',
    STYLE_ASC = 'style_ASC',
    STYLE_DESC = 'style_DESC',
    HEAD_ASC = 'head_ASC',
    HEAD_DESC = 'head_DESC',
}

export enum SortPmeOutletLoginPagey {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
    URL_KEY_ASC = 'url_key_ASC',
    URL_KEY_DESC = 'url_key_DESC',
    SEO_TITLE_ASC = 'seo_title_ASC',
    SEO_TITLE_DESC = 'seo_title_DESC',
    SEO_DESCRIPTION_ASC = 'seo_description_ASC',
    SEO_DESCRIPTION_DESC = 'seo_description_DESC',
    SEO_KEYWORDS_ASC = 'seo_keywords_ASC',
    SEO_KEYWORDS_DESC = 'seo_keywords_DESC',
    TITLE_ASC = 'title_ASC',
    TITLE_DESC = 'title_DESC',
    SUB_TITLE_ASC = 'sub_title_ASC',
    SUB_TITLE_DESC = 'sub_title_DESC',
    SUB_HEADING_ASC = 'sub_heading_ASC',
    SUB_HEADING_DESC = 'sub_heading_DESC',
    CONTENT_ASC = 'content_ASC',
    CONTENT_DESC = 'content_DESC',
}

export enum SortPmeOutletPagey {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
    URL_KEY_ASC = 'url_key_ASC',
    URL_KEY_DESC = 'url_key_DESC',
    SEO_TITLE_ASC = 'seo_title_ASC',
    SEO_TITLE_DESC = 'seo_title_DESC',
    SEO_DESCRIPTION_ASC = 'seo_description_ASC',
    SEO_DESCRIPTION_DESC = 'seo_description_DESC',
    SEO_KEYWORDS_ASC = 'seo_keywords_ASC',
    SEO_KEYWORDS_DESC = 'seo_keywords_DESC',
    STYLE_ASC = 'style_ASC',
    STYLE_DESC = 'style_DESC',
    HEAD_ASC = 'head_ASC',
    HEAD_DESC = 'head_DESC',
    PAGE_BACKGROUND_COLOR_ASC = 'page_background_color_ASC',
    PAGE_BACKGROUND_COLOR_DESC = 'page_background_color_DESC',
}

export enum SortPrivilegesPagey {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
    STOREVIEW_ASC = 'storeview_ASC',
    STOREVIEW_DESC = 'storeview_DESC',
}

export enum SortPrivilegesPerksy {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
    STOREVIEW_ASC = 'storeview_ASC',
    STOREVIEW_DESC = 'storeview_DESC',
    FOOTER_TEXT_ASC = 'footer_text_ASC',
    FOOTER_TEXT_DESC = 'footer_text_DESC',
    BUTTON_LABEL_ASC = 'button_label_ASC',
    BUTTON_LABEL_DESC = 'button_label_DESC',
    BUTTON_URL_ASC = 'button_url_ASC',
    BUTTON_URL_DESC = 'button_url_DESC',
    BUTTON_STYLE_ASC = 'button_style_ASC',
    BUTTON_STYLE_DESC = 'button_style_DESC',
}

export enum SortPrivilegesUspsy {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
    STOREVIEW_ASC = 'storeview_ASC',
    STOREVIEW_DESC = 'storeview_DESC',
    CONTENTS_ASC = 'contents_ASC',
    CONTENTS_DESC = 'contents_DESC',
    BUTTON_LABEL_ASC = 'button_label_ASC',
    BUTTON_LABEL_DESC = 'button_label_DESC',
    BUTTON_URL_ASC = 'button_url_ASC',
    BUTTON_URL_DESC = 'button_url_DESC',
    BUTTON_STYLE_ASC = 'button_style_ASC',
    BUTTON_STYLE_DESC = 'button_style_DESC',
}

export enum SortPromoinfoy {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
    STOREVIEW_ASC = 'storeview_ASC',
    STOREVIEW_DESC = 'storeview_DESC',
    TEXT_MOBILE_ASC = 'text_mobile_ASC',
    TEXT_MOBILE_DESC = 'text_mobile_DESC',
    TEXT_ASC = 'text_ASC',
    TEXT_DESC = 'text_DESC',
    MODAL_TITLE_ASC = 'modal_title_ASC',
    MODAL_TITLE_DESC = 'modal_title_DESC',
    MODAL_CONTENT_ASC = 'modal_content_ASC',
    MODAL_CONTENT_DESC = 'modal_content_DESC',
}

export enum SortPsRegistrationBoxy {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
    STOREVIEW_ASC = 'storeview_ASC',
    STOREVIEW_DESC = 'storeview_DESC',
    ACCOUNT_HEADING_ASC = 'account_heading_ASC',
    ACCOUNT_HEADING_DESC = 'account_heading_DESC',
    EMPLOYEE_NAME_ASC = 'employee_name_ASC',
    EMPLOYEE_NAME_DESC = 'employee_name_DESC',
    EMPLOYEE_BUTTON_TEXT_ASC = 'employee_button_text_ASC',
    EMPLOYEE_BUTTON_TEXT_DESC = 'employee_button_text_DESC',
    EMPLOYEE_BUTTON_LINK_ASC = 'employee_button_link_ASC',
    EMPLOYEE_BUTTON_LINK_DESC = 'employee_button_link_DESC',
    HERO_TITLE_ASC = 'hero_title_ASC',
    HERO_TITLE_DESC = 'hero_title_DESC',
    ORDER_TITLE_ASC = 'order_title_ASC',
    ORDER_TITLE_DESC = 'order_title_DESC',
    ORDER_DESCRIPTION_ASC = 'order_description_ASC',
    ORDER_DESCRIPTION_DESC = 'order_description_DESC',
    ORDER_BUTTON_TEXT_ASC = 'order_button_text_ASC',
    ORDER_BUTTON_TEXT_DESC = 'order_button_text_DESC',
    ORDER_BUTTON_LINK_ASC = 'order_button_link_ASC',
    ORDER_BUTTON_LINK_DESC = 'order_button_link_DESC',
    TITLE_ASC = 'title_ASC',
    TITLE_DESC = 'title_DESC',
    DESCRIPTION_ASC = 'description_ASC',
    DESCRIPTION_DESC = 'description_DESC',
    BUTTON_TEXT_ASC = 'button_text_ASC',
    BUTTON_TEXT_DESC = 'button_text_DESC',
}

export enum SortQueuePagey {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
    STOREVIEW_ASC = 'storeview_ASC',
    STOREVIEW_DESC = 'storeview_DESC',
    HEADING_ASC = 'heading_ASC',
    HEADING_DESC = 'heading_DESC',
    DESCRIPTION_ASC = 'description_ASC',
    DESCRIPTION_DESC = 'description_DESC',
}

/** Specifies the field to use for sorting quote items */
export enum SortQuoteItemsEnum {
    ITEM_ID = 'ITEM_ID',
    CREATED_AT = 'CREATED_AT',
    UPDATED_AT = 'UPDATED_AT',
    PRODUCT_ID = 'PRODUCT_ID',
    SKU = 'SKU',
    NAME = 'NAME',
    DESCRIPTION = 'DESCRIPTION',
    WEIGHT = 'WEIGHT',
    QTY = 'QTY',
    PRICE = 'PRICE',
    BASE_PRICE = 'BASE_PRICE',
    CUSTOM_PRICE = 'CUSTOM_PRICE',
    DISCOUNT_PERCENT = 'DISCOUNT_PERCENT',
    DISCOUNT_AMOUNT = 'DISCOUNT_AMOUNT',
    BASE_DISCOUNT_AMOUNT = 'BASE_DISCOUNT_AMOUNT',
    TAX_PERCENT = 'TAX_PERCENT',
    TAX_AMOUNT = 'TAX_AMOUNT',
    BASE_TAX_AMOUNT = 'BASE_TAX_AMOUNT',
    ROW_TOTAL = 'ROW_TOTAL',
    BASE_ROW_TOTAL = 'BASE_ROW_TOTAL',
    ROW_TOTAL_WITH_DISCOUNT = 'ROW_TOTAL_WITH_DISCOUNT',
    ROW_WEIGHT = 'ROW_WEIGHT',
    PRODUCT_TYPE = 'PRODUCT_TYPE',
    BASE_TAX_BEFORE_DISCOUNT = 'BASE_TAX_BEFORE_DISCOUNT',
    TAX_BEFORE_DISCOUNT = 'TAX_BEFORE_DISCOUNT',
    ORIGINAL_CUSTOM_PRICE = 'ORIGINAL_CUSTOM_PRICE',
    PRICE_INC_TAX = 'PRICE_INC_TAX',
    BASE_PRICE_INC_TAX = 'BASE_PRICE_INC_TAX',
    ROW_TOTAL_INC_TAX = 'ROW_TOTAL_INC_TAX',
    BASE_ROW_TOTAL_INC_TAX = 'BASE_ROW_TOTAL_INC_TAX',
    DISCOUNT_TAX_COMPENSATION_AMOUNT = 'DISCOUNT_TAX_COMPENSATION_AMOUNT',
    BASE_DISCOUNT_TAX_COMPENSATION_AMOUNT = 'BASE_DISCOUNT_TAX_COMPENSATION_AMOUNT',
    FREE_SHIPPING = 'FREE_SHIPPING',
}

export enum SortSearchPagey {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
    STOREVIEW_ASC = 'storeview_ASC',
    STOREVIEW_DESC = 'storeview_DESC',
}

export enum SortSimpleFormy {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
    FORM_TITLE_ASC = 'form_title_ASC',
    FORM_TITLE_DESC = 'form_title_DESC',
    FORM_SUBTITLE_ASC = 'form_subtitle_ASC',
    FORM_SUBTITLE_DESC = 'form_subtitle_DESC',
    BUTTON_LABEL_ASC = 'button_label_ASC',
    BUTTON_LABEL_DESC = 'button_label_DESC',
    BUTTON_SUBMIT_URL_ASC = 'button_submit_url_ASC',
    BUTTON_SUBMIT_URL_DESC = 'button_submit_url_DESC',
    FORM_FOOTER_ASC = 'form_footer_ASC',
    FORM_FOOTER_DESC = 'form_footer_DESC',
}

export enum SortStorelocatorPagey {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
    STOREVIEW_ASC = 'storeview_ASC',
    STOREVIEW_DESC = 'storeview_DESC',
    BRAND_LIST_HEADING_ASC = 'brand_list_heading_ASC',
    BRAND_LIST_HEADING_DESC = 'brand_list_heading_DESC',
    BRAND_LIST_SUBHEADING_ASC = 'brand_list_subheading_ASC',
    BRAND_LIST_SUBHEADING_DESC = 'brand_list_subheading_DESC',
    JB_LIST_HEADING_ASC = 'jb_list_heading_ASC',
    JB_LIST_HEADING_DESC = 'jb_list_heading_DESC',
    JB_LIST_SUBHEADING_ASC = 'jb_list_subheading_ASC',
    JB_LIST_SUBHEADING_DESC = 'jb_list_subheading_DESC',
}

export enum SortStorePagey {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
    STOREVIEW_ASC = 'storeview_ASC',
    STOREVIEW_DESC = 'storeview_DESC',
    URL_KEY_ASC = 'url_key_ASC',
    URL_KEY_DESC = 'url_key_DESC',
    SRS_ID_ASC = 'srs_id_ASC',
    SRS_ID_DESC = 'srs_id_DESC',
    SEO_TITLE_ASC = 'seo_title_ASC',
    SEO_TITLE_DESC = 'seo_title_DESC',
    SEO_DESCRIPTION_ASC = 'seo_description_ASC',
    SEO_DESCRIPTION_DESC = 'seo_description_DESC',
    SEO_KEYWORDS_ASC = 'seo_keywords_ASC',
    SEO_KEYWORDS_DESC = 'seo_keywords_DESC',
    HEAD_ASC = 'head_ASC',
    HEAD_DESC = 'head_DESC',
}

export enum SortTagy {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
    TAG_ID_ASC = 'tag_id_ASC',
    TAG_ID_DESC = 'tag_id_DESC',
    TAG_NAME_ASC = 'tag_name_ASC',
    TAG_NAME_DESC = 'tag_name_DESC',
}

export enum SortUspsy {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
}

export enum SortVanguardFaqPagey {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
    URL_KEY_ASC = 'url_key_ASC',
    URL_KEY_DESC = 'url_key_DESC',
    SEO_TITLE_ASC = 'seo_title_ASC',
    SEO_TITLE_DESC = 'seo_title_DESC',
    SEO_DESCRIPTION_ASC = 'seo_description_ASC',
    SEO_DESCRIPTION_DESC = 'seo_description_DESC',
    SEO_KEYWORDS_ASC = 'seo_keywords_ASC',
    SEO_KEYWORDS_DESC = 'seo_keywords_DESC',
    STYLE_ASC = 'style_ASC',
    STYLE_DESC = 'style_DESC',
    HEAD_ASC = 'head_ASC',
    HEAD_DESC = 'head_DESC',
}

export enum SortVanguardPagey {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
    URL_KEY_ASC = 'url_key_ASC',
    URL_KEY_DESC = 'url_key_DESC',
    SEO_TITLE_ASC = 'seo_title_ASC',
    SEO_TITLE_DESC = 'seo_title_DESC',
    SEO_DESCRIPTION_ASC = 'seo_description_ASC',
    SEO_DESCRIPTION_DESC = 'seo_description_DESC',
    SEO_KEYWORDS_ASC = 'seo_keywords_ASC',
    SEO_KEYWORDS_DESC = 'seo_keywords_DESC',
    STYLE_ASC = 'style_ASC',
    STYLE_DESC = 'style_DESC',
    HEAD_ASC = 'head_ASC',
    HEAD_DESC = 'head_DESC',
    PAGE_TYPE_ASC = 'page_type_ASC',
    PAGE_TYPE_DESC = 'page_type_DESC',
    STORY_DESCRIPTION_ASC = 'story_description_ASC',
    STORY_DESCRIPTION_DESC = 'story_description_DESC',
    STORY_TITLE_ASC = 'story_title_ASC',
    STORY_TITLE_DESC = 'story_title_DESC',
    PUBLICATION_FIELD_ASC = 'publication_field_ASC',
    PUBLICATION_FIELD_DESC = 'publication_field_DESC',
    PAGE_BACKGROUND_COLOR_ASC = 'page_background_color_ASC',
    PAGE_BACKGROUND_COLOR_DESC = 'page_background_color_DESC',
}

export enum SortVanguardTagy {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
    TAG_ID_ASC = 'tag_id_ASC',
    TAG_ID_DESC = 'tag_id_DESC',
    TAG_NAME_ASC = 'tag_name_ASC',
    TAG_NAME_DESC = 'tag_name_DESC',
}

export enum SortVisualFiltery {
    META_FIRST_PUBLICATION_DATE_ASC = 'meta_firstPublicationDate_ASC',
    META_FIRST_PUBLICATION_DATE_DESC = 'meta_firstPublicationDate_DESC',
    META_LAST_PUBLICATION_DATE_ASC = 'meta_lastPublicationDate_ASC',
    META_LAST_PUBLICATION_DATE_DESC = 'meta_lastPublicationDate_DESC',
}

/** Defines SRS Store information. */
export interface SrsStore {
    __typename?: 'SrsStore'
    ci: Scalars['Boolean']
    ciStore: Scalars['Boolean']
    city: Maybe<Scalars['String']>
    country: Maybe<Scalars['String']>
    countryCode: Maybe<Scalars['String']>
    customerName: Scalars['String']
    /** Distance in KM, only available if coordinates are supplied. */
    distance: Maybe<Scalars['Float']>
    gLat: Maybe<Scalars['Float']>
    gLong: Maybe<Scalars['Float']>
    gPhone: Maybe<Scalars['String']>
    /** An array of opening and closing times. */
    gTimes: Maybe<Array<Maybe<GTime>>>
    imageTitle: Maybe<Scalars['String']>
    imageUrl: Maybe<Scalars['String']>
    imageUrlMobile: Maybe<Scalars['String']>
    jbStore: Scalars['Boolean']
    name: Scalars['String']
    number: Maybe<Scalars['String']>
    onlineStore: Scalars['Boolean']
    pme: Scalars['Boolean']
    pmeStore: Scalars['Boolean']
    postal: Maybe<Scalars['String']>
    shopId: Maybe<Scalars['Int']>
    /** Stock, only available when filtered by Product in Stock. */
    skuStock: Maybe<Scalars['Float']>
    storeId: Scalars['Int']
    street: Maybe<Scalars['String']>
    vg: Scalars['Boolean']
}

/** SrsStoreFilterInput defines the list of attributes and filters for the search. */
export interface SrsStoreFilterInput {
    /** Filter by SRS Store Cast Iron flag. */
    ci?: Maybe<Scalars['Boolean']>
    /** Filter by SRS Store Cast Iron Store flag. */
    ci_store?: Maybe<Scalars['Boolean']>
    /** Filter by SRS Store Just Brands Store flag. */
    jb_store?: Maybe<Scalars['Boolean']>
    /** Filter by SRS Store PME Legend flag. */
    pme?: Maybe<Scalars['Boolean']>
    /** Filter by SRS Store PME Legend Store flag. */
    pme_store?: Maybe<Scalars['Boolean']>
    /** Filter by Product in Stock. */
    sku?: Maybe<Scalars['String']>
    /** Filter by SRS Store Van Guard flag. */
    vg?: Maybe<Scalars['Boolean']>
}

/** Contains details about SRS stores. */
export interface SrsStores {
    __typename?: 'SrsStores'
    /** An array of SRS stores. */
    items: Maybe<Array<Maybe<SrsStore>>>
    /** Pagination metadata. */
    pageInfo: Maybe<SearchResultPageInfo>
    /** The number of SRS stores returned. */
    totalCount: Maybe<Scalars['Int']>
}

/** SrsVoucher Data */
export interface SrsVoucher {
    __typename?: 'SrsVoucher'
    /** Barcode */
    barcode: Scalars['String']
    /** Description */
    description: Scalars['String']
    freeGiftRule: Maybe<FreeGiftRule>
    /** Group Name */
    groupName: Maybe<Scalars['String']>
    /** ID */
    id: Scalars['Int']
    /** Loyalty Card ID */
    loyaltyCardId: Maybe<Scalars['Int']>
    /** Max Product Quantity */
    maxProductQty: Maybe<Scalars['Int']>
    /** Minimal Product Amount */
    minPurchaseAmount: Maybe<Money>
    /** Receipt */
    receipt: Maybe<Scalars['Int']>
    /** Redeemed At */
    redeemedAt: Maybe<Scalars['String']>
    salesRule: Maybe<SalesRule>
    /** Status */
    status: Maybe<Scalars['Boolean']>
    /** Valid From */
    validFrom: Maybe<Scalars['String']>
    /** Valid Till */
    validTill: Maybe<Scalars['String']>
    /** Raw Value */
    value: Maybe<Scalars['String']>
    /** Value expressed as Amount */
    valueAmount: Maybe<Money>
    /** Value expressed as Percentage */
    valuePercentage: Maybe<Scalars['Float']>
}

/** The type contains information about a store config */
export interface StoreConfig {
    __typename?: 'StoreConfig'
    /** Contains scripts that must be included in the HTML before the closing `<body>` tag. */
    absoluteFooter: Maybe<Scalars['String']>
    /** Adyen front end resources region. */
    adyenCheckoutFrontendRegion: Maybe<Scalars['String']>
    /** Adyen client key for LIVE environment. */
    adyenClientKeyLive: Maybe<Scalars['String']>
    /** Adyen client key for TEST environment. */
    adyenClientKeyTest: Maybe<Scalars['String']>
    /** Adyen demo mode enabled (TEST). */
    adyenDemoMode: Maybe<Scalars['Boolean']>
    /** Adyen card holder's name is visible. */
    adyenHasHolderName: Maybe<Scalars['Boolean']>
    /** Adyen card holder's name is required. */
    adyenHolderNameRequired: Maybe<Scalars['Boolean']>
    /** Adyen Return path in case of error */
    adyenReturnPathError: Maybe<Scalars['String']>
    /** Adyen Render Mode for Payment Method. */
    adyenTitleRenderer: Maybe<Scalars['String']>
    /** Indicates whether guest users can write product reviews. Possible values: 1 (Yes) and 0 (No). */
    allowGuestsToWriteProductReviews: Maybe<Scalars['String']>
    /** The value of the Allow Gift Messages for Order Items option */
    allowItems: Maybe<Scalars['String']>
    /** The value of the Allow Gift Messages on Order Level option */
    allowOrder: Maybe<Scalars['String']>
    /** Indicates whether to enable autocomplete on login and forgot password forms. */
    autocompleteOnStorefront: Maybe<Scalars['String']>
    /** The base currency code. */
    baseCurrencyCode: Maybe<Scalars['String']>
    /** A fully-qualified URL that is used to create relative links to the `base_url`. */
    baseLinkUrl: Maybe<Scalars['String']>
    /** The fully-qualified URL that specifies the location of media files. */
    baseMediaUrl: Maybe<Scalars['String']>
    /** The fully-qualified URL that specifies the location of static view files. */
    baseStaticUrl: Maybe<Scalars['String']>
    /** The store’s fully-qualified base URL. */
    baseUrl: Maybe<Scalars['String']>
    /** Extended Config Data - checkout/cart/delete_quote_after */
    cartExpiresInDays: Maybe<Scalars['Int']>
    /** Extended Config Data - checkout/cart_link/use_qty */
    cartSummaryDisplayQuantity: Maybe<Scalars['Int']>
    /** The default sort order of the search results list. */
    catalogDefaultSortBy: Maybe<Scalars['String']>
    /**
     * Corresponds to the 'Display Prices In Product Lists' field in the Admin. It
     * indicates how FPT information is displayed on category pages.
     */
    categoryFixedProductTaxDisplaySetting: Maybe<FixedProductTaxDisplaySettings>
    /** The suffix applied to category pages, such as `.htm` or `.html`. */
    categoryUrlSuffix: Maybe<Scalars['String']>
    /** Indicates whether only specific countries can use this payment method. */
    checkMoneyOrderEnableForSpecificCountries: Maybe<Scalars['Boolean']>
    /** Indicates whether the Check/Money Order payment method is enabled. */
    checkMoneyOrderEnabled: Maybe<Scalars['Boolean']>
    /** The name of the party to whom the check must be payable. */
    checkMoneyOrderMakeCheckPayableTo: Maybe<Scalars['String']>
    /** The maximum order amount required to qualify for the Check/Money Order payment method. */
    checkMoneyOrderMaxOrderTotal: Maybe<Scalars['String']>
    /** The minimum order amount required to qualify for the Check/Money Order payment method. */
    checkMoneyOrderMinOrderTotal: Maybe<Scalars['String']>
    /** The status of new orders placed using the Check/Money Order payment method. */
    checkMoneyOrderNewOrderStatus: Maybe<Scalars['String']>
    /** A comma-separated list of specific countries allowed to use the Check/Money Order payment method. */
    checkMoneyOrderPaymentFromSpecificCountries: Maybe<Scalars['String']>
    /** The full street address or PO Box where the checks are mailed. */
    checkMoneyOrderSendCheckTo: Maybe<Scalars['String']>
    /**
     * A number indicating the position of the Check/Money Order payment method in
     * the list of available payment methods during checkout.
     */
    checkMoneyOrderSortOrder: Maybe<Scalars['Int']>
    /** The title of the Check/Money Order payment method displayed on the storefront. */
    checkMoneyOrderTitle: Maybe<Scalars['String']>
    /** The name of the CMS page that identifies the home page for the store. */
    cmsHomePage: Maybe<Scalars['String']>
    /** A specific CMS page that displays when cookies are not enabled for the browser. */
    cmsNoCookies: Maybe<Scalars['String']>
    /** A specific CMS page that displays when a 404 'Page Not Found' error occurs. */
    cmsNoRoute: Maybe<Scalars['String']>
    /**
     * A code assigned to the store to identify it.
     * @deprecated Use `store_code` instead.
     */
    code: Maybe<Scalars['String']>
    /** Indicates whether the `parent` or child (`itself`) thumbnail should be used in the cart for configurable products. */
    configurableThumbnailSource: Maybe<Scalars['String']>
    /** Indicates whether the Contact Us form in enabled. */
    contactEnabled: Scalars['Boolean']
    /** The copyright statement that appears at the bottom of each page. */
    copyright: Maybe<Scalars['String']>
    /** Extended Config Data - general/region/state_required */
    countriesWithRequiredRegion: Maybe<Scalars['String']>
    /** Indicates if the new accounts need confirmation. */
    createAccountConfirmation: Maybe<Scalars['Boolean']>
    /** Customer access token lifetime. */
    customerAccessTokenLifetime: Maybe<Scalars['Float']>
    /** Extended Config Data - general/country/default */
    defaultCountry: Maybe<Scalars['String']>
    /**
     * The description that provides a summary of your site for search engine
     * listings. It should not be more than 160 characters in length.
     */
    defaultDescription: Maybe<Scalars['String']>
    /** The default display currency code. */
    defaultDisplayCurrencyCode: Maybe<Scalars['String']>
    /** A series of keywords that describe your store, each separated by a comma. */
    defaultKeywords: Maybe<Scalars['String']>
    /** The title that appears at the title bar of each page when viewed in a browser. */
    defaultTitle: Maybe<Scalars['String']>
    /** Controls the display of the demo store notice at the top of the page. Options: 0 (No) or 1 (Yes). */
    demonotice: Maybe<Scalars['Int']>
    /** Extended Config Data - general/region/display_all */
    displayStateIfOptional: Maybe<Scalars['Boolean']>
    /** The landing page that is associated with the base URL. */
    front: Maybe<Scalars['String']>
    /** The default number of products per page in Grid View. */
    gridPerPage: Maybe<Scalars['Int']>
    /** A list of numbers that define how many products can be displayed in Grid View. */
    gridPerPageValues: Maybe<Scalars['String']>
    /** GTM Container IDs, optional a comma separated list of Container IDs */
    gtmContainerIds: Maybe<Scalars['String']>
    /** Scripts that must be included in the HTML before the closing `<head>` tag. */
    headIncludes: Maybe<Scalars['String']>
    /** The small graphic image (favicon) that appears in the address bar and tab of the browser. */
    headShortcutIcon: Maybe<Scalars['String']>
    /** The path to the logo that appears in the header. */
    headerLogoSrc: Maybe<Scalars['String']>
    /**
     * The ID number assigned to the store.
     * @deprecated Use `store_code` instead.
     */
    id: Maybe<Scalars['Int']>
    /** Indicates whether the store view has been designated as the default within the store group. */
    isDefaultStore: Maybe<Scalars['Boolean']>
    /** Indicates whether the store group has been designated as the default within the website. */
    isDefaultStoreGroup: Maybe<Scalars['Boolean']>
    /** Extended Config Data - checkout/options/guest_checkout */
    isGuestCheckoutEnabled: Maybe<Scalars['Boolean']>
    /** Extended Config Data - checkout/options/onepage_checkout_enabled */
    isOnePageCheckoutEnabled: Maybe<Scalars['Boolean']>
    /** The format of the search results list. */
    listMode: Maybe<Scalars['String']>
    /** The default number of products per page in List View. */
    listPerPage: Maybe<Scalars['Int']>
    /** A list of numbers that define how many products can be displayed in List View. */
    listPerPageValues: Maybe<Scalars['String']>
    /** The store locale. */
    locale: Maybe<Scalars['String']>
    /** The Alt text that is associated with the logo. */
    logoAlt: Maybe<Scalars['String']>
    /** The height of the logo image, in pixels. */
    logoHeight: Maybe<Scalars['Int']>
    /** The width of the logo image, in pixels. */
    logoWidth: Maybe<Scalars['Int']>
    /** Indicates whether wishlists are enabled (1) or disabled (0). */
    magentoWishlistGeneralIsEnabled: Maybe<Scalars['String']>
    /** Extended Config Data - checkout/options/max_items_display_count */
    maxItemsInOrderSummary: Maybe<Scalars['Int']>
    /** Extended Config Data - checkout/sidebar/display */
    minicartDisplay: Maybe<Scalars['Boolean']>
    /** Extended Config Data - checkout/sidebar/count */
    minicartMaxItems: Maybe<Scalars['Int']>
    /** The minimum number of characters required for a valid password. */
    minimumPasswordLength: Maybe<Scalars['String']>
    /** Indicates whether newsletters are enabled. */
    newsletterEnabled: Scalars['Boolean']
    /** The default page that displays when a 404 'Page not Found' error occurs. */
    noRoute: Maybe<Scalars['String']>
    /** Extended Config Data - general/country/optional_zip_countries */
    optionalZipCountries: Maybe<Scalars['String']>
    /** Indicates whether orders can be cancelled by customers or not. */
    orderCancellationEnabled: Scalars['Boolean']
    /** An array containing available cancellation reasons. */
    orderCancellationReasons: Array<Maybe<CancellationReason>>
    /** Payflow Pro vault status. */
    paymentPayflowproCcVaultActive: Maybe<Scalars['String']>
    /**
     * Corresponds to the 'Display Prices On Product View Page' field in the Admin.
     * It indicates how FPT information is displayed on product pages.
     */
    productFixedProductTaxDisplaySetting: Maybe<FixedProductTaxDisplaySettings>
    /** Indicates whether product reviews are enabled. Possible values: 1 (Yes) and 0 (No). */
    productReviewsEnabled: Maybe<Scalars['String']>
    /** The suffix applied to product pages, such as `.htm` or `.html`. */
    productUrlSuffix: Maybe<Scalars['String']>
    /** The number of different character classes (lowercase, uppercase, digits, special characters) required in a password. */
    requiredCharacterClassesNumber: Maybe<Scalars['String']>
    /**
     * The ID of the root category.
     * @deprecated Use `root_category_uid` instead.
     */
    rootCategoryId: Maybe<Scalars['Int']>
    /** The unique ID for a `CategoryInterface` object. */
    rootCategoryUid: Maybe<Scalars['ID']>
    /**
     * Corresponds to the 'Display Prices In Sales Modules' field in the Admin. It
     * indicates how FPT information is displayed on cart, checkout, and order pages.
     */
    salesFixedProductTaxDisplaySetting: Maybe<FixedProductTaxDisplaySettings>
    /** A secure fully-qualified URL that is used to create relative links to the `base_url`. */
    secureBaseLinkUrl: Maybe<Scalars['String']>
    /** The secure fully-qualified URL that specifies the location of media files. */
    secureBaseMediaUrl: Maybe<Scalars['String']>
    /** The secure fully-qualified URL that specifies the location of static view files. */
    secureBaseStaticUrl: Maybe<Scalars['String']>
    /** The store’s fully-qualified secure base URL. */
    secureBaseUrl: Maybe<Scalars['String']>
    /** Email to a Friend configuration. */
    sendFriend: Maybe<SendFriendConfiguration>
    /** Extended Config Data - tax/cart_display/full_summary */
    shoppingCartDisplayFullSummary: Maybe<Scalars['Boolean']>
    /** Extended Config Data - tax/cart_display/grandtotal */
    shoppingCartDisplayGrandTotal: Maybe<Scalars['Boolean']>
    /** Extended Config Data - tax/cart_display/price */
    shoppingCartDisplayPrice: Maybe<Scalars['Int']>
    /** Extended Config Data - tax/cart_display/shipping */
    shoppingCartDisplayShipping: Maybe<Scalars['Int']>
    /** Extended Config Data - tax/cart_display/subtotal */
    shoppingCartDisplaySubtotal: Maybe<Scalars['Int']>
    /** Extended Config Data - tax/cart_display/gift_wrapping */
    shoppingCartDisplayTaxGiftWrapping: Maybe<TaxWrappingEnum>
    /** Extended Config Data - tax/cart_display/zero_tax */
    shoppingCartDisplayZeroTax: Maybe<Scalars['Boolean']>
    /** Indicates whether a breadcrumb trail appears on all CMS pages in the catalog. 0 (No) or 1 (Yes). */
    showCmsBreadcrumbs: Maybe<Scalars['Int']>
    socialTrustpilotBusinessUnitId: Maybe<Scalars['String']>
    storeCity: Maybe<Scalars['String']>
    /**
     * The unique ID of the store view. In the Admin, this is called the Store View
     * Code. When making a GraphQL call, assign this value to the `Store` header to
     * provide the scope.
     */
    storeCode: Maybe<Scalars['ID']>
    storeCountryId: Maybe<Scalars['String']>
    /** The unique ID assigned to the store group. In the Admin, this is called the Store Name. */
    storeGroupCode: Maybe<Scalars['ID']>
    /** The label assigned to the store group. */
    storeGroupName: Maybe<Scalars['String']>
    storeHours: Maybe<Scalars['String']>
    storeMerchantVatNumber: Maybe<Scalars['String']>
    /** The label assigned to the store view. */
    storeName: Maybe<Scalars['String']>
    storePhone: Maybe<Scalars['String']>
    storePostcode: Maybe<Scalars['String']>
    storeRegionId: Maybe<Scalars['String']>
    /** The store view sort order. */
    storeSortOrder: Maybe<Scalars['Int']>
    storeStreetLine1: Maybe<Scalars['String']>
    storeStreetLine2: Maybe<Scalars['String']>
    /** The time zone of the store. */
    timezone: Maybe<Scalars['String']>
    /** A prefix that appears before the title to create a two- or three-part title. */
    titlePrefix: Maybe<Scalars['String']>
    /** The character that separates the category name and subcategory in the browser title bar. */
    titleSeparator: Maybe<Scalars['String']>
    /** A suffix that appears after the title to create a two- or three-part title. */
    titleSuffix: Maybe<Scalars['String']>
    /** Indicates whether the store code should be used in the URL. */
    useStoreInUrl: Maybe<Scalars['Boolean']>
    /** The unique ID for the website. */
    websiteCode: Maybe<Scalars['ID']>
    /**
     * The ID number assigned to the website store.
     * @deprecated The field should not be used on the storefront.
     */
    websiteId: Maybe<Scalars['Int']>
    /** The label assigned to the website. */
    websiteName: Maybe<Scalars['String']>
    /** The unit of weight. */
    weightUnit: Maybe<Scalars['String']>
    /** Text that appears in the header of the page and includes the name of the logged in customer. */
    welcome: Maybe<Scalars['String']>
    /** Indicates whether only specific countries can use this payment method. */
    zeroSubtotalEnableForSpecificCountries: Maybe<Scalars['Boolean']>
    /** Indicates whether the Zero Subtotal payment method is enabled. */
    zeroSubtotalEnabled: Maybe<Scalars['Boolean']>
    /** The status of new orders placed using the Zero Subtotal payment method. */
    zeroSubtotalNewOrderStatus: Maybe<Scalars['String']>
    /**
     * When the new order status is 'Processing', this can be set to
     * `authorize_capture` to automatically invoice all items that have a zero balance.
     */
    zeroSubtotalPaymentAction: Maybe<Scalars['String']>
    /** A comma-separated list of specific countries allowed to use the Zero Subtotal payment method. */
    zeroSubtotalPaymentFromSpecificCountries: Maybe<Scalars['String']>
    /**
     * A number indicating the position of the Zero Subtotal payment method in the
     * list of available payment methods during checkout.
     */
    zeroSubtotalSortOrder: Maybe<Scalars['Int']>
    /** The title of the Zero Subtotal payment method displayed on the storefront. */
    zeroSubtotalTitle: Maybe<Scalars['String']>
}

/** Indicates where an attribute can be displayed. */
export interface StorefrontProperties {
    __typename?: 'StorefrontProperties'
    /** The relative position of the attribute in the layered navigation block. */
    position: Maybe<Scalars['Int']>
    /** Indicates whether the attribute is filterable with results, without results, or not at all. */
    useInLayeredNavigation: Maybe<UseInLayeredNavigationOptions>
    /** Indicates whether the attribute is displayed in product listings. */
    useInProductListing: Maybe<Scalars['Boolean']>
    /** Indicates whether the attribute can be used in layered navigation on search results pages. */
    useInSearchResultsLayeredNavigation: Maybe<Scalars['Boolean']>
    /** Indicates whether the attribute is displayed on product pages. */
    visibleOnCatalogPages: Maybe<Scalars['Boolean']>
}

export interface StorelocatorPage extends Document, Linkable {
    __typename?: 'StorelocatorPage'
    storeview: Maybe<Scalars['String']>
    showStores: Maybe<Scalars['Boolean']>
    brandListHeading: Maybe<Scalars['String']>
    brandListSubheading: Maybe<Scalars['String']>
    showJbStores: Maybe<Scalars['Boolean']>
    jbListHeading: Maybe<Scalars['String']>
    jbListSubheading: Maybe<Scalars['String']>
    body: Maybe<Array<StorelocatorPageBody>>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

export type StorelocatorPageBody =
    | StorelocatorPageBodyProductHero
    | StorelocatorPageBodyTweakwiseSlider
    | StorelocatorPageBodyImageSlider
    | StorelocatorPageBodyHero
    | StorelocatorPageBodyTextOnly
    | StorelocatorPageBodyImageOnly
    | StorelocatorPageBodyTextWithMedia
    | StorelocatorPageBodyHtml
    | StorelocatorPageBodyCategoryElement
    | StorelocatorPageBodyFaq
    | StorelocatorPageBodyBannerBlocks
    | StorelocatorPageBodyBannerBlocks1
    | StorelocatorPageBodyBannerBlocks2
    | StorelocatorPageBodyCategoryList

export interface StorelocatorPageBodyBannerBlocks {
    __typename?: 'StorelocatorPageBodyBannerBlocks'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<StorelocatorPageBodyBannerBlocksPrimary>
    fields: Maybe<Array<StorelocatorPageBodyBannerBlocksFields>>
}

export interface StorelocatorPageBodyBannerBlocks1 {
    __typename?: 'StorelocatorPageBodyBannerBlocks1'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<StorelocatorPageBodyBannerBlocks1Primary>
    fields: Maybe<Array<StorelocatorPageBodyBannerBlocks1Fields>>
}

export interface StorelocatorPageBodyBannerBlocks1Fields {
    __typename?: 'StorelocatorPageBodyBannerBlocks1Fields'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    subtitle: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

export interface StorelocatorPageBodyBannerBlocks1Primary {
    __typename?: 'StorelocatorPageBodyBannerBlocks1Primary'
    style: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
}

export interface StorelocatorPageBodyBannerBlocks2 {
    __typename?: 'StorelocatorPageBodyBannerBlocks2'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<StorelocatorPageBodyBannerBlocks2Primary>
    fields: Maybe<Array<StorelocatorPageBodyBannerBlocks2Fields>>
}

export interface StorelocatorPageBodyBannerBlocks2Fields {
    __typename?: 'StorelocatorPageBodyBannerBlocks2Fields'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    subtitle: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

export interface StorelocatorPageBodyBannerBlocks2Primary {
    __typename?: 'StorelocatorPageBodyBannerBlocks2Primary'
    style: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
}

export interface StorelocatorPageBodyBannerBlocksFields {
    __typename?: 'StorelocatorPageBodyBannerBlocksFields'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
}

export interface StorelocatorPageBodyBannerBlocksPrimary {
    __typename?: 'StorelocatorPageBodyBannerBlocksPrimary'
    style: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
}

export interface StorelocatorPageBodyCategoryElement {
    __typename?: 'StorelocatorPageBodyCategoryElement'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<StorelocatorPageBodyCategoryElementPrimary>
    fields: Maybe<Array<StorelocatorPageBodyCategoryElementFields>>
}

export interface StorelocatorPageBodyCategoryElementFields {
    __typename?: 'StorelocatorPageBodyCategoryElementFields'
    title: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
}

export interface StorelocatorPageBodyCategoryElementPrimary {
    __typename?: 'StorelocatorPageBodyCategoryElementPrimary'
    style: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
}

export interface StorelocatorPageBodyCategoryList {
    __typename?: 'StorelocatorPageBodyCategoryList'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<StorelocatorPageBodyCategoryListPrimary>
    fields: Maybe<Array<StorelocatorPageBodyCategoryListFields>>
}

export interface StorelocatorPageBodyCategoryListFields {
    __typename?: 'StorelocatorPageBodyCategoryListFields'
    title: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
    list: Maybe<Scalars['String']>
}

export interface StorelocatorPageBodyCategoryListPrimary {
    __typename?: 'StorelocatorPageBodyCategoryListPrimary'
    style: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    title1: Maybe<Scalars['String']>
    title2: Maybe<Scalars['String']>
    title3: Maybe<Scalars['String']>
    title4: Maybe<Scalars['String']>
}

export interface StorelocatorPageBodyFaq {
    __typename?: 'StorelocatorPageBodyFaq'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<StorelocatorPageBodyFaqPrimary>
    fields: Maybe<Array<StorelocatorPageBodyFaqFields>>
}

export interface StorelocatorPageBodyFaqFields {
    __typename?: 'StorelocatorPageBodyFaqFields'
    iconimage: Maybe<PrismicImageType>
    category: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
}

export interface StorelocatorPageBodyFaqPrimary {
    __typename?: 'StorelocatorPageBodyFaqPrimary'
    style: Maybe<Scalars['String']>
    emptyCategoryText: Maybe<Scalars['String']>
}

export interface StorelocatorPageBodyHero {
    __typename?: 'StorelocatorPageBodyHero'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<StorelocatorPageBodyHeroPrimary>
}

export interface StorelocatorPageBodyHeroPrimary {
    __typename?: 'StorelocatorPageBodyHeroPrimary'
    fullWidth: Maybe<Scalars['Boolean']>
    size: Maybe<Scalars['String']>
    headingfuss: Maybe<Scalars['Boolean']>
    header: Maybe<Scalars['String']>
    headingVariant: Maybe<Scalars['String']>
    headingElement: Maybe<Scalars['String']>
    subheading: Maybe<Scalars['String']>
    subheadingVariant: Maybe<Scalars['String']>
    subheadingElement: Maybe<Scalars['String']>
    videoId: Maybe<Scalars['String']>
    mobileVideoId: Maybe<Scalars['String']>
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
}

export interface StorelocatorPageBodyHtml {
    __typename?: 'StorelocatorPageBodyHtml'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<StorelocatorPageBodyHtmlPrimary>
}

export interface StorelocatorPageBodyHtmlPrimary {
    __typename?: 'StorelocatorPageBodyHtmlPrimary'
    style: Maybe<Scalars['String']>
    html: Maybe<Scalars['Json']>
    tablet: Maybe<Scalars['String']>
    desktop: Maybe<Scalars['String']>
}

export interface StorelocatorPageBodyImageOnly {
    __typename?: 'StorelocatorPageBodyImageOnly'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<StorelocatorPageBodyImageOnlyPrimary>
}

export interface StorelocatorPageBodyImageOnlyPrimary {
    __typename?: 'StorelocatorPageBodyImageOnlyPrimary'
    style: Maybe<Scalars['String']>
    tablet: Maybe<Scalars['String']>
    desktop: Maybe<Scalars['String']>
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
}

export interface StorelocatorPageBodyImageSlider {
    __typename?: 'StorelocatorPageBodyImageSlider'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<StorelocatorPageBodyImageSliderPrimary>
    fields: Maybe<Array<StorelocatorPageBodyImageSliderFields>>
}

export interface StorelocatorPageBodyImageSliderFields {
    __typename?: 'StorelocatorPageBodyImageSliderFields'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    subtitle: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

export interface StorelocatorPageBodyImageSliderPrimary {
    __typename?: 'StorelocatorPageBodyImageSliderPrimary'
    style: Maybe<Scalars['String']>
    variant: Maybe<Scalars['String']>
    header: Maybe<Scalars['String']>
    content: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

export interface StorelocatorPageBodyProductHero {
    __typename?: 'StorelocatorPageBodyProductHero'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<StorelocatorPageBodyProductHeroPrimary>
}

export interface StorelocatorPageBodyProductHeroPrimary {
    __typename?: 'StorelocatorPageBodyProductHeroPrimary'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    content: Maybe<Scalars['String']>
    subheading: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
    button2Url: Maybe<Scalars['String']>
    button2Text: Maybe<Scalars['String']>
    buttonStyle: Maybe<Scalars['String']>
    textStyle: Maybe<Scalars['String']>
    subheadingStyle: Maybe<Scalars['String']>
    videoId: Maybe<Scalars['String']>
    mobileVideoId: Maybe<Scalars['String']>
    productSkus: Maybe<Scalars['String']>
    productCueTimes: Maybe<Scalars['String']>
}

export interface StorelocatorPageBodyTextOnly {
    __typename?: 'StorelocatorPageBodyTextOnly'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<StorelocatorPageBodyTextOnlyPrimary>
}

export interface StorelocatorPageBodyTextOnlyPrimary {
    __typename?: 'StorelocatorPageBodyTextOnlyPrimary'
    style: Maybe<Scalars['String']>
    visibility: Maybe<Scalars['String']>
    tablet: Maybe<Scalars['String']>
    desktop: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

export interface StorelocatorPageBodyTextWithMedia {
    __typename?: 'StorelocatorPageBodyTextWithMedia'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<StorelocatorPageBodyTextWithMediaPrimary>
    fields: Maybe<Array<StorelocatorPageBodyTextWithMediaFields>>
}

export interface StorelocatorPageBodyTextWithMediaFields {
    __typename?: 'StorelocatorPageBodyTextWithMediaFields'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
}

export interface StorelocatorPageBodyTextWithMediaPrimary {
    __typename?: 'StorelocatorPageBodyTextWithMediaPrimary'
    style: Maybe<Scalars['String']>
    desktopMediaPosition: Maybe<Scalars['String']>
    mobileMediaPosition: Maybe<Scalars['String']>
    alignment: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    headingfuss: Maybe<Scalars['Boolean']>
    headingVariant: Maybe<Scalars['String']>
    headingElement: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
    buttonStyle: Maybe<Scalars['String']>
    button2Url: Maybe<Scalars['String']>
    button2Text: Maybe<Scalars['String']>
    button2Style: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
    videoId: Maybe<Scalars['String']>
    mobileVideoId: Maybe<Scalars['String']>
}

export interface StorelocatorPageBodyTweakwiseSlider {
    __typename?: 'StorelocatorPageBodyTweakwiseSlider'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<StorelocatorPageBodyTweakwiseSliderPrimary>
}

export interface StorelocatorPageBodyTweakwiseSliderPrimary {
    __typename?: 'StorelocatorPageBodyTweakwiseSliderPrimary'
    style: Maybe<Scalars['String']>
    header: Maybe<Scalars['String']>
    content: Maybe<Scalars['String']>
    recommendationsIdentifier: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

/** A connection to a list of items. */
export interface StorelocatorPageConnectionConnection {
    __typename?: 'StorelocatorPageConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<StorelocatorPageConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface StorelocatorPageConnectionEdge {
    __typename?: 'StorelocatorPageConnectionEdge'
    /** The item at the end of the edge. */
    node: StorelocatorPage
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

export interface StorePage extends Document, Linkable {
    __typename?: 'StorePage'
    storeview: Maybe<Scalars['String']>
    urlKey: Maybe<Scalars['String']>
    search: Maybe<Scalars['Boolean']>
    srsId: Maybe<Scalars['String']>
    seoTitle: Maybe<Scalars['String']>
    seoDescription: Maybe<Scalars['String']>
    seoKeywords: Maybe<Scalars['String']>
    head: Maybe<Scalars['Json']>
    noindex: Maybe<Scalars['Boolean']>
    nofollow: Maybe<Scalars['Boolean']>
    body: Maybe<Array<StorePageBody>>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

export type StorePageBody =
    | StorePageBodyTextOnly
    | StorePageBodyImageOnly
    | StorePageBodyTextWithMedia
    | StorePageBodyHtml
    | StorePageBodyHero
    | StorePageBodyStoreinfo
    | StorePageBodyBannerBlocks2
    | StorePageBodyBannerBlocks
    | StorePageBodyBannerBlocks1

export interface StorePageBodyBannerBlocks {
    __typename?: 'StorePageBodyBannerBlocks'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<StorePageBodyBannerBlocksPrimary>
    fields: Maybe<Array<StorePageBodyBannerBlocksFields>>
}

export interface StorePageBodyBannerBlocks1 {
    __typename?: 'StorePageBodyBannerBlocks1'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<StorePageBodyBannerBlocks1Primary>
    fields: Maybe<Array<StorePageBodyBannerBlocks1Fields>>
}

export interface StorePageBodyBannerBlocks1Fields {
    __typename?: 'StorePageBodyBannerBlocks1Fields'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    subtitle: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

export interface StorePageBodyBannerBlocks1Primary {
    __typename?: 'StorePageBodyBannerBlocks1Primary'
    style: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
}

export interface StorePageBodyBannerBlocks2 {
    __typename?: 'StorePageBodyBannerBlocks2'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<StorePageBodyBannerBlocks2Primary>
    fields: Maybe<Array<StorePageBodyBannerBlocks2Fields>>
}

export interface StorePageBodyBannerBlocks2Fields {
    __typename?: 'StorePageBodyBannerBlocks2Fields'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    subtitle: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

export interface StorePageBodyBannerBlocks2Primary {
    __typename?: 'StorePageBodyBannerBlocks2Primary'
    style: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
}

export interface StorePageBodyBannerBlocksFields {
    __typename?: 'StorePageBodyBannerBlocksFields'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
}

export interface StorePageBodyBannerBlocksPrimary {
    __typename?: 'StorePageBodyBannerBlocksPrimary'
    style: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
}

export interface StorePageBodyHero {
    __typename?: 'StorePageBodyHero'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<StorePageBodyHeroPrimary>
}

export interface StorePageBodyHeroPrimary {
    __typename?: 'StorePageBodyHeroPrimary'
    fullWidth: Maybe<Scalars['Boolean']>
    size: Maybe<Scalars['String']>
    headingfuss: Maybe<Scalars['Boolean']>
    header: Maybe<Scalars['String']>
    headingVariant: Maybe<Scalars['String']>
    headingElement: Maybe<Scalars['String']>
    subheading: Maybe<Scalars['String']>
    subheadingVariant: Maybe<Scalars['String']>
    subheadingElement: Maybe<Scalars['String']>
    videoId: Maybe<Scalars['String']>
    mobileVideoId: Maybe<Scalars['String']>
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
}

export interface StorePageBodyHtml {
    __typename?: 'StorePageBodyHtml'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<StorePageBodyHtmlPrimary>
}

export interface StorePageBodyHtmlPrimary {
    __typename?: 'StorePageBodyHtmlPrimary'
    style: Maybe<Scalars['String']>
    html: Maybe<Scalars['Json']>
    tablet: Maybe<Scalars['String']>
    desktop: Maybe<Scalars['String']>
}

export interface StorePageBodyImageOnly {
    __typename?: 'StorePageBodyImageOnly'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<StorePageBodyImageOnlyPrimary>
}

export interface StorePageBodyImageOnlyPrimary {
    __typename?: 'StorePageBodyImageOnlyPrimary'
    style: Maybe<Scalars['String']>
    tablet: Maybe<Scalars['String']>
    desktop: Maybe<Scalars['String']>
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
}

export interface StorePageBodyStoreinfo {
    __typename?: 'StorePageBodyStoreinfo'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
}

export interface StorePageBodyTextOnly {
    __typename?: 'StorePageBodyTextOnly'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<StorePageBodyTextOnlyPrimary>
}

export interface StorePageBodyTextOnlyPrimary {
    __typename?: 'StorePageBodyTextOnlyPrimary'
    style: Maybe<Scalars['String']>
    visibility: Maybe<Scalars['String']>
    tablet: Maybe<Scalars['String']>
    desktop: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

export interface StorePageBodyTextWithMedia {
    __typename?: 'StorePageBodyTextWithMedia'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<StorePageBodyTextWithMediaPrimary>
    fields: Maybe<Array<StorePageBodyTextWithMediaFields>>
}

export interface StorePageBodyTextWithMediaFields {
    __typename?: 'StorePageBodyTextWithMediaFields'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
}

export interface StorePageBodyTextWithMediaPrimary {
    __typename?: 'StorePageBodyTextWithMediaPrimary'
    style: Maybe<Scalars['String']>
    desktopMediaPosition: Maybe<Scalars['String']>
    mobileMediaPosition: Maybe<Scalars['String']>
    alignment: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    headingfuss: Maybe<Scalars['Boolean']>
    headingVariant: Maybe<Scalars['String']>
    headingElement: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
    buttonStyle: Maybe<Scalars['String']>
    button2Url: Maybe<Scalars['String']>
    button2Text: Maybe<Scalars['String']>
    button2Style: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
    videoId: Maybe<Scalars['String']>
    mobileVideoId: Maybe<Scalars['String']>
}

/** A connection to a list of items. */
export interface StorePageConnectionConnection {
    __typename?: 'StorePageConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<StorePageConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface StorePageConnectionEdge {
    __typename?: 'StorePageConnectionEdge'
    /** The item at the end of the edge. */
    node: StorePage
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

/** Contains the result of the `subscribeEmailToNewsletter` operation. */
export interface SubscribeEmailToNewsletterOutput {
    __typename?: 'SubscribeEmailToNewsletterOutput'
    /** The status of the subscription request. */
    status: Maybe<SubscriptionStatusesEnum>
}

/** Indicates the status of the request. */
export enum SubscriptionStatusesEnum {
    NOT_ACTIVE = 'NOT_ACTIVE',
    SUBSCRIBED = 'SUBSCRIBED',
    UNSUBSCRIBED = 'UNSUBSCRIBED',
    UNCONFIRMED = 'UNCONFIRMED',
}

/** Describes the swatch type and a value. */
export interface SwatchData {
    __typename?: 'SwatchData'
    /** The type of swatch filter item: 1 - text; 2 - image. */
    type: Maybe<Scalars['String']>
    /** The value for the swatch item. It could be text or an image link. */
    value: Maybe<Scalars['String']>
}

export interface SwatchDataInterface {
    /** The value can be represented as color (HEX code), image link, or text. */
    value: Maybe<Scalars['String']>
}

/** Swatch attribute metadata input types. */
export enum SwatchInputTypeEnum {
    BOOLEAN = 'BOOLEAN',
    DATE = 'DATE',
    DATETIME = 'DATETIME',
    DROPDOWN = 'DROPDOWN',
    FILE = 'FILE',
    GALLERY = 'GALLERY',
    HIDDEN = 'HIDDEN',
    IMAGE = 'IMAGE',
    MEDIA_IMAGE = 'MEDIA_IMAGE',
    MULTILINE = 'MULTILINE',
    MULTISELECT = 'MULTISELECT',
    PRICE = 'PRICE',
    SELECT = 'SELECT',
    TEXT = 'TEXT',
    TEXTAREA = 'TEXTAREA',
    UNDEFINED = 'UNDEFINED',
    VISUAL = 'VISUAL',
    WEIGHT = 'WEIGHT',
}

export interface SwatchLayerFilterItem
    extends LayerFilterItemInterface,
        SwatchLayerFilterItemInterface {
    __typename?: 'SwatchLayerFilterItem'
    /**
     * The count of items per filter.
     * @deprecated Use `AggregationOption.count` instead.
     */
    itemsCount: Maybe<Scalars['Int']>
    /**
     * The label for a filter.
     * @deprecated Use `AggregationOption.label` instead.
     */
    label: Maybe<Scalars['String']>
    /** Data required to render a swatch filter item. */
    swatchData: Maybe<SwatchData>
    /**
     * The value of a filter request variable to be used in query.
     * @deprecated Use `AggregationOption.value` instead.
     */
    valueString: Maybe<Scalars['String']>
}

export interface SwatchLayerFilterItemInterface {
    /** Data required to render a swatch filter item. */
    swatchData: Maybe<SwatchData>
}

export interface Tag extends Document, Linkable {
    __typename?: 'Tag'
    tagId: Maybe<Scalars['String']>
    tagName: Maybe<Scalars['String']>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

/** A connection to a list of items. */
export interface TagConnectionConnection {
    __typename?: 'TagConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<TagConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface TagConnectionEdge {
    __typename?: 'TagConnectionEdge'
    /** The item at the end of the edge. */
    node: Tag
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

export interface TaggedTweakwiseItemData {
    __typename?: 'TaggedTweakwiseItemData'
    type: Scalars['String']
    store: Maybe<Scalars['Int']>
    id: Scalars['ID']
}

/** Contains tax item details. */
export interface TaxItem {
    __typename?: 'TaxItem'
    /** The amount of tax applied to the item. */
    amount: Money
    /** The rate used to calculate the tax. */
    rate: Scalars['Float']
    /** A title that describes the tax. */
    title: Scalars['String']
}

export enum TaxWrappingEnum {
    DISPLAY_EXCLUDING_TAX = 'DISPLAY_EXCLUDING_TAX',
    DISPLAY_INCLUDING_TAX = 'DISPLAY_INCLUDING_TAX',
    DISPLAY_TYPE_BOTH = 'DISPLAY_TYPE_BOTH',
}

export interface TextSwatchData extends SwatchDataInterface {
    __typename?: 'TextSwatchData'
    /** The value can be represented as color (HEX code), image link, or text. */
    value: Maybe<Scalars['String']>
}

/** Defines a price based on the quantity purchased. */
export interface TierPrice {
    __typename?: 'TierPrice'
    /** The price discount that this tier represents. */
    discount: Maybe<ProductDiscount>
    /** The price of the product at this tier. */
    finalPrice: Maybe<Money>
    /** The minimum number of items that must be purchased to qualify for this price tier. */
    quantity: Maybe<Scalars['Float']>
}

export interface TweakwiseAdvisor {
    __typename?: 'TweakwiseAdvisor'
    id: Scalars['ID']
    title: Maybe<Scalars['String']>
    code: Maybe<Scalars['String']>
    questions: Maybe<Array<Maybe<TweakwiseAdvisorQuestion>>>
    items: Maybe<Array<Maybe<TweakwiseAdvisorItem>>>
}

export interface TweakwiseAdvisorAnswer {
    __typename?: 'TweakwiseAdvisorAnswer'
    id: Scalars['ID']
    answer: Maybe<Scalars['String']>
    isSelected: Maybe<Scalars['Boolean']>
    nextQuestionId: Maybe<Scalars['ID']>
    subtext: Maybe<Scalars['String']>
    summary: Maybe<Scalars['String']>
    image: Maybe<Scalars['String']>
}

export interface TweakwiseAdvisorAnswerInput {
    questionId: Scalars['Int']
    answerIds: Array<Maybe<Scalars['Int']>>
}

export interface TweakwiseAdvisorItem {
    __typename?: 'TweakwiseAdvisorItem'
    itemNo: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
    price: Maybe<Scalars['Float']>
    brand: Maybe<Scalars['String']>
    properties: Maybe<Array<Maybe<TweakwiseAdvisorItemProperties>>>
}

export interface TweakwiseAdvisorItemProperties {
    __typename?: 'TweakwiseAdvisorItemProperties'
    propertyId: Maybe<Scalars['Int']>
    title: Maybe<Scalars['String']>
    attributes: Maybe<Array<Maybe<TweakwiseItemAttribute>>>
    isMatch: Maybe<Scalars['Boolean']>
    isFiltered: Maybe<Scalars['Boolean']>
}

export interface TweakwiseAdvisorParams {
    code: Scalars['String']
    answers?: Maybe<Array<Maybe<TweakwiseAdvisorAnswerInput>>>
    skippedQuestions?: Maybe<Array<Maybe<Scalars['Int']>>>
    pageSize?: Maybe<Scalars['Int']>
}

export interface TweakwiseAdvisorQuestion {
    __typename?: 'TweakwiseAdvisorQuestion'
    id: Scalars['ID']
    question: Maybe<Scalars['String']>
    isMultiSelect: Maybe<Scalars['Boolean']>
    attributeId: Maybe<Scalars['Int']>
    image: Maybe<Scalars['String']>
    explanation: Maybe<Scalars['String']>
    current: Maybe<Scalars['Boolean']>
    skippable: Maybe<Scalars['Boolean']>
    skiplabel: Maybe<Scalars['String']>
    answers: Maybe<Array<Maybe<TweakwiseAdvisorAnswer>>>
}

export interface TweakwiseAutocompleteParams {
    /**
     * The category that should contain the filtered results. If you want to provide
     * the entire path, you can provide multiple ids.
     */
    categoryId?: Maybe<Array<Scalars['ID']>>
    /** Use instant search. Defaults to false. */
    instant?: Maybe<Scalars['Boolean']>
    /** The number of results that will be returned per component. Defaults to 6. Max 20. */
    maxResults?: Maybe<Scalars['Int']>
    /** Specify the language to use for the search algorithms. */
    language?: Maybe<Scalars['String']>
    /** Specify the keyboard to use for the search algorithms. */
    keyboard?: Maybe<TweakwiseKeyboardLayout>
    /**
     * Hidden parameters can be added to the URL by using the parameter 'tn_parameters'.
     *
     * When filtering by property 'brand' with value 'Coca Cola' or with value
     * 'Pepsi' the value of tn_parameters should be: brand=Coca Cola&brand=Pepsi
     *
     * This value should then be URL encoded.
     */
    parameters?: Maybe<Array<TweakwiseNavigationParameter>>
}

export interface TweakwiseAutocompleteResult {
    __typename?: 'TweakwiseAutocompleteResult'
    items: Array<TweakwiseNavigationItem>
    keywords: Array<Scalars['String']>
    isInstantSearchActive: Maybe<Scalars['Boolean']>
    instantSearchTerm: Maybe<Scalars['String']>
}

export interface TweakwiseFunnel {
    __typename?: 'TweakwiseFunnel'
    code: Maybe<Scalars['String']>
    name: Maybe<Scalars['String']>
    count: Maybe<Scalars['Int']>
    navigationUrl: Maybe<Scalars['String']>
    filterTemplateId: Maybe<Scalars['Int']>
    questions: Maybe<Array<Maybe<TweakwiseFunnelQuestion>>>
    items: Maybe<Array<Maybe<TweakwiseFunnelItem>>>
}

export interface TweakwiseFunnelAnswer {
    __typename?: 'TweakwiseFunnelAnswer'
    answerId: Scalars['ID']
    answer: Maybe<Scalars['String']>
    caption: Maybe<Scalars['String']>
    summary: Maybe<Scalars['String']>
    imageUrl: Maybe<Scalars['String']>
    count: Maybe<Scalars['Int']>
    isSelected: Maybe<Scalars['Boolean']>
    nextQuestionId: Maybe<Scalars['ID']>
}

export interface TweakwiseFunnelAnswerInput {
    questionId: Scalars['ID']
    answerIds: Array<Maybe<Scalars['ID']>>
}

export interface TweakwiseFunnelItem {
    __typename?: 'TweakwiseFunnelItem'
    itemNo: Scalars['ID']
    title: Maybe<Scalars['String']>
    brand: Maybe<Scalars['String']>
    price: Maybe<Scalars['Float']>
    url: Maybe<Scalars['String']>
    imageUrl: Maybe<Scalars['String']>
    labels: Maybe<Scalars['String']>
    attributes: Maybe<Array<Maybe<TweakwiseItemAttribute>>>
    product: Maybe<ProductInterface>
    taggedData: Maybe<TaggedTweakwiseItemData>
}

export interface TweakwiseFunnelParams {
    code: Scalars['String']
    skippedQuestions?: Maybe<Array<Maybe<Scalars['Int']>>>
    pageSize?: Maybe<Scalars['Int']>
    skipProducts?: Maybe<Scalars['Int']>
    answers?: Maybe<Array<Maybe<TweakwiseFunnelAnswerInput>>>
}

export interface TweakwiseFunnelQuestion {
    __typename?: 'TweakwiseFunnelQuestion'
    id: Scalars['ID']
    question: Maybe<Scalars['String']>
    isMultiSelect: Maybe<Scalars['Boolean']>
    image: Maybe<Scalars['String']>
    explanation: Maybe<Scalars['String']>
    current: Maybe<Scalars['Boolean']>
    skippable: Maybe<Scalars['Boolean']>
    skipLabel: Maybe<Scalars['String']>
    answers: Maybe<Array<Maybe<TweakwiseFunnelAnswer>>>
}

export interface TweakwiseItemAttribute {
    __typename?: 'TweakwiseItemAttribute'
    name: Scalars['String']
    values: Array<Scalars['String']>
}

export enum TweakwiseKeyboardLayout {
    QWERTY = 'qwerty',
    AZERTY = 'azerty',
    QWERTZ = 'qwertz',
}

export interface TweakwiseNavigationAttribute {
    __typename?: 'TweakwiseNavigationAttribute'
    title: Scalars['String']
    facetAttributeName: Scalars['ID']
    isSelected: Scalars['Boolean']
    nrOfResults: Scalars['Int']
    attributeId: Maybe<Scalars['Int']>
    url: Maybe<Scalars['String']>
    imageTag: Maybe<Scalars['String']>
    children: Maybe<Scalars['TODO']>
    colorSwatch: Maybe<ColorSwatch>
}

export interface TweakwiseNavigationFacet {
    __typename?: 'TweakwiseNavigationFacet'
    facetSettings: FacetSettings
    attributes: Array<TweakwiseNavigationAttribute>
}

export interface TweakwiseNavigationFacetFilter {
    key: Scalars['String']
    values: Array<Scalars['String']>
}

export interface TweakwiseNavigationItem {
    __typename?: 'TweakwiseNavigationItem'
    itemNo: Scalars['ID']
    title: Scalars['String']
    price: Scalars['Float']
    brand: Maybe<Scalars['String']>
    image: Maybe<Scalars['String']>
    product: Maybe<ProductInterface>
    taggedData: Maybe<TaggedTweakwiseItemData>
    prismicDocument: Maybe<PrismicDocument>
}

export interface TweakwiseNavigationParameter {
    property: Scalars['String']
    value: Scalars['String']
}

export interface TweakwiseNavigationParams {
    /**
     * The category ID to query. When filtering by a subcategory, providing the
     * parent categoryIds will keep the category facet accurate.
     */
    categoryId?: Maybe<Array<Scalars['ID']>>
    /** The facets with which the results are filtered by. */
    facets?: Maybe<Array<TweakwiseNavigationFacetFilter>>
    /** Sorting the results. The XML returns the values which can be used for sorting. */
    sortKey?: Maybe<Scalars['String']>
    /** Sort direction when sortKey is set. */
    sortDirection?: Maybe<TweakwiseSortDirection>
    /** The page number of the page that must be displayed. */
    page?: Maybe<Scalars['Int']>
    /**
     * The number of products that must be displayed per page. The standard value is
     * configured at shop level. Only use this parameter to overrule this standard
     * configured quantity.
     */
    pageSize?: Maybe<Scalars['Int']>
    /** Overruling the linked filter template with the specified template. */
    filterTemplateId?: Maybe<Scalars['Int']>
    /** Overruling the linked sorting template with the specified template. */
    sortTemplateId?: Maybe<Scalars['Int']>
    /** Hidden parameters. */
    parameters?: Maybe<Array<TweakwiseNavigationParameter>>
    /**
     * Personal parameters.
     *
     * Personal parameters are similar to hidden parameters, except they only apply
     * to recommendations to which the filters are assigned. This is typically useful
     * when you are using personal merchandising and the filters should only be
     * applied to select components.
     */
    personalParameters?: Maybe<Array<TweakwiseNavigationParameter>>
    /** Specify the keyboard to use for the search algorithms. */
    keyboard?: Maybe<TweakwiseKeyboardLayout>
    /**
     * The visitor key is an identifier for the person browsing your website who is
     * initiating the navigation requests. This same identifier should also be used
     * to identify the visitor when sending events to our Analytics API. Based on the
     * sent events, the visitor can receive personalized results.
     *
     * The visitor key can be any string as long as it is unique to the visitor.
     * Generate for example a Universally Unique Identifier (UUID), a new id per
     * visitor or use an account id.
     */
    visitorKey?: Maybe<Scalars['String']>
    /**
     * Search term entered by the user.
     *
     * This executes a text based search on the Tweakwise rest api. Passing this
     * parameter also queries the `navigate-search` endpoint instead of the
     * `navigate` endpoint.
     */
    query?: Maybe<Scalars['String']>
}

export interface TweakwiseNavigationProperties {
    __typename?: 'TweakwiseNavigationProperties'
    nrOfItems: Scalars['Int']
    pageSize: Scalars['Int']
    nrOfPages: Scalars['Int']
    currentPage: Scalars['Int']
    selectedCategory: Scalars['Int']
    searchTerm: Maybe<Scalars['String']>
    suggestedSearchTerm: Maybe<Scalars['String']>
    isDirectSearch: Scalars['Boolean']
    isRootCategory: Scalars['Boolean']
    pageUrl: Scalars['String']
    resetUrl: Scalars['String']
    sortFields: Array<TweakwiseNavigationPropertiesSortField>
}

export interface TweakwiseNavigationPropertiesSortField {
    __typename?: 'TweakwiseNavigationPropertiesSortField'
    title: Scalars['String']
    displayTitle: Scalars['String']
    order: Scalars['String']
    isSelected: Scalars['Boolean']
    url: Scalars['String']
}

export interface TweakwiseNavigationRedirect {
    __typename?: 'TweakwiseNavigationRedirect'
    url: Scalars['String']
}

export interface TweakwiseNavigationResult {
    __typename?: 'TweakwiseNavigationResult'
    facets: Array<TweakwiseNavigationFacet>
    items: Array<TweakwiseNavigationItem>
    properties: TweakwiseNavigationProperties
    redirects: Array<TweakwiseNavigationRedirect>
}

export interface TweakwiseProductRecommendationsTemplate {
    __typename?: 'TweakwiseProductRecommendationsTemplate'
    id: Scalars['ID']
    name: Scalars['String']
    items: Array<TweakwiseNavigationItem>
}

export interface TweakwiseRecommendation {
    __typename?: 'TweakwiseRecommendation'
    id: Scalars['ID']
    name: Maybe<Scalars['String']>
    items: Array<TweakwiseNavigationItem>
}

export interface TweakwiseRecommendationResult {
    __typename?: 'TweakwiseRecommendationResult'
    /** An array of products */
    items: Maybe<Array<Maybe<ProductInterface>>>
}

export interface TweakwiseRecommendationsFeaturedParams {
    /**
     * The category that should contain the filtered results. If you want to provide
     * the entire path, you can provide multiple ids.
     */
    categoryId?: Maybe<Array<Scalars['ID']>>
    /**
     * Hidden parameters can be added to the URL by using the parameter 'tn_parameters'.
     *
     * When filtering by property 'brand' with value 'Coca Cola' or with value
     * 'Pepsi' the value of tn_parameters should be: brand=Coca Cola&brand=Pepsi
     *
     * This value should then be URL encoded.
     */
    parameters?: Maybe<Array<TweakwiseNavigationParameter>>
}

export interface TweakwiseSearchBanner {
    __typename?: 'TweakwiseSearchBanner'
    imageUrl: Maybe<Scalars['String']>
    clickUrl: Maybe<Scalars['String']>
    clickTarget: Maybe<Scalars['String']>
    type: Maybe<Scalars['String']>
    location: Maybe<Scalars['String']>
    categoryId: Scalars['Int']
}

export interface TweakwiseSearchBannerParams {
    /** The category ID to query. When filtering by a subcategory, providing the parent categoryIds will keep the search accurate. */
    categoryId?: Maybe<Array<Scalars['ID']>>
    /**
     * Search term entered by the user.
     * This executes a text based search on the Tweakwise rest api for search banners that have this keyword assigned.
     */
    query?: Maybe<Scalars['String']>
}

export enum TweakwiseSortDirection {
    ASCENDING = 'Ascending',
    DESCENDING = 'Descending',
}

/** Modifies the specified items in the cart. */
export interface UpdateCartItemsInput {
    /** The unique ID of a `Cart` object. */
    cart_id: Scalars['String']
    /** An array of items to be updated. */
    cart_items: Array<Maybe<CartItemUpdateInput>>
}

/** Contains details about the cart after updating items. */
export interface UpdateCartItemsOutput {
    __typename?: 'UpdateCartItemsOutput'
    /** The cart after updating products. */
    cart: Cart
}

/** Contains the customer's wish list and any errors encountered. */
export interface UpdateProductsInWishlistOutput {
    __typename?: 'UpdateProductsInWishlistOutput'
    /** An array of errors encountered while updating products in a wish list. */
    userErrors: Array<Maybe<WishListUserInputError>>
    /** Contains the wish list with all items that were successfully updated. */
    wishlist: Wishlist
}

/** Contains URL rewrite details. */
export interface UrlRewrite {
    __typename?: 'UrlRewrite'
    /** An array of request parameters. */
    parameters: Maybe<Array<Maybe<HttpQueryParameter>>>
    /** The request URL. */
    url: Maybe<Scalars['String']>
}

/** This enumeration defines the entity type. */
export enum UrlRewriteEntityTypeEnum {
    CMS_PAGE = 'CMS_PAGE',
    PRISMIC = 'PRISMIC',
    PRODUCT = 'PRODUCT',
    CATEGORY = 'CATEGORY',
    REDIRECT = 'REDIRECT',
}

/** Defines whether the attribute is filterable in layered navigation. */
export enum UseInLayeredNavigationOptions {
    NO = 'NO',
    FILTERABLE_WITH_RESULTS = 'FILTERABLE_WITH_RESULTS',
    FILTERABLE_NO_RESULT = 'FILTERABLE_NO_RESULT',
}

export interface Usp {
    __typename?: 'Usp'
    /** The first date on which this USP is active */
    activeFrom: Maybe<Scalars['String']>
    /** The last date on which this USP is active */
    activeTo: Maybe<Scalars['String']>
    /** The unique code for a `USP` object */
    code: Maybe<Scalars['ID']>
    /** The time on which the USP has been created */
    createdAt: Maybe<Scalars['String']>
    /** The customer group IDs for which this USP is active */
    customerGroupIds: Maybe<Array<Maybe<Scalars['Int']>>>
    /** The unique ID for a `USP` object */
    entityId: Maybe<Scalars['ID']>
    icon: Maybe<Scalars['String']>
    /** Flag indicating if this USP is assigned to the requested Category or one of the categories of the Product */
    isCategory: Maybe<Scalars['Boolean']>
    isEnabled: Maybe<Scalars['Int']>
    /** Flag indicating if this USP is for the entire Store instead of Product / Category specific */
    isGlobal: Maybe<Scalars['Boolean']>
    /** Flag indicating if this USP is assigned to the requested Product */
    isProduct: Maybe<Scalars['Boolean']>
    isPromotion: Maybe<Scalars['Int']>
    label: Maybe<Scalars['String']>
    position: Maybe<Scalars['String']>
    promotionImage: Maybe<Scalars['String']>
    promotionText: Maybe<Scalars['String']>
    /** The sort order */
    sortOrder: Maybe<Scalars['Int']>
    /** The time of the last modification to the USP */
    updatedAt: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
}

export interface Usps extends Document, Linkable {
    __typename?: 'Usps'
    usp: Maybe<Array<UspsUsp>>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

/** A connection to a list of items. */
export interface UspsConnectionConnection {
    __typename?: 'UspsConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<UspsConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface UspsConnectionEdge {
    __typename?: 'UspsConnectionEdge'
    /** The item at the end of the edge. */
    node: Usps
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

export interface UspsUsp {
    __typename?: 'UspsUsp'
    label: Maybe<Scalars['String']>
    icon: Maybe<Scalars['String']>
}

/** Defines a customer attribute validation rule. */
export interface ValidationRule {
    __typename?: 'ValidationRule'
    /** Validation rule name applied to a customer attribute. */
    name: Maybe<ValidationRuleEnum>
    /** Validation rule value. */
    value: Maybe<Scalars['String']>
}

/** List of validation rule names applied to a customer attribute. */
export enum ValidationRuleEnum {
    DATE_RANGE_MAX = 'DATE_RANGE_MAX',
    DATE_RANGE_MIN = 'DATE_RANGE_MIN',
    FILE_EXTENSIONS = 'FILE_EXTENSIONS',
    INPUT_VALIDATION = 'INPUT_VALIDATION',
    MAX_TEXT_LENGTH = 'MAX_TEXT_LENGTH',
    MIN_TEXT_LENGTH = 'MIN_TEXT_LENGTH',
    MAX_FILE_SIZE = 'MAX_FILE_SIZE',
    MAX_IMAGE_HEIGHT = 'MAX_IMAGE_HEIGHT',
    MAX_IMAGE_WIDTH = 'MAX_IMAGE_WIDTH',
}

export interface VanguardFaqPage extends Document, Linkable {
    __typename?: 'VanguardFaqPage'
    urlKey: Maybe<Scalars['String']>
    search: Maybe<Scalars['Boolean']>
    seoTitle: Maybe<Scalars['String']>
    seoDescription: Maybe<Scalars['String']>
    seoKeywords: Maybe<Scalars['String']>
    style: Maybe<Scalars['String']>
    head: Maybe<Scalars['Json']>
    noindex: Maybe<Scalars['Boolean']>
    nofollow: Maybe<Scalars['Boolean']>
    body: Maybe<Array<VanguardFaqPageBody>>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

export type VanguardFaqPageBody =
    | VanguardFaqPageBodyHero
    | VanguardFaqPageBodyFaqSubject

export interface VanguardFaqPageBodyFaqSubject {
    __typename?: 'VanguardFaqPageBodyFaqSubject'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<VanguardFaqPageBodyFaqSubjectPrimary>
    fields: Maybe<Array<VanguardFaqPageBodyFaqSubjectFields>>
}

export interface VanguardFaqPageBodyFaqSubjectFields {
    __typename?: 'VanguardFaqPageBodyFaqSubjectFields'
    heading: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
}

export interface VanguardFaqPageBodyFaqSubjectPrimary {
    __typename?: 'VanguardFaqPageBodyFaqSubjectPrimary'
    urlKey: Maybe<Scalars['String']>
    seoTitle: Maybe<Scalars['String']>
    seoKeywords: Maybe<Scalars['String']>
    seoDescription: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    icon: Maybe<PrismicImageType>
}

export interface VanguardFaqPageBodyHero {
    __typename?: 'VanguardFaqPageBodyHero'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<VanguardFaqPageBodyHeroPrimary>
}

export interface VanguardFaqPageBodyHeroPrimary {
    __typename?: 'VanguardFaqPageBodyHeroPrimary'
    fullWidth: Maybe<Scalars['Boolean']>
    size: Maybe<Scalars['String']>
    headingfuss: Maybe<Scalars['Boolean']>
    header: Maybe<Scalars['String']>
    headingVariant: Maybe<Scalars['String']>
    headingElement: Maybe<Scalars['String']>
    subheading: Maybe<Scalars['String']>
    subheadingVariant: Maybe<Scalars['String']>
    subheadingElement: Maybe<Scalars['String']>
    videoId: Maybe<Scalars['String']>
    mobileVideoId: Maybe<Scalars['String']>
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
}

/** A connection to a list of items. */
export interface VanguardFaqPageConnectionConnection {
    __typename?: 'VanguardFaqPageConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<VanguardFaqPageConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface VanguardFaqPageConnectionEdge {
    __typename?: 'VanguardFaqPageConnectionEdge'
    /** The item at the end of the edge. */
    node: VanguardFaqPage
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

export interface VanguardPage extends Document, Linkable {
    __typename?: 'VanguardPage'
    urlKey: Maybe<Scalars['String']>
    search: Maybe<Scalars['Boolean']>
    seoTitle: Maybe<Scalars['String']>
    seoDescription: Maybe<Scalars['String']>
    seoKeywords: Maybe<Scalars['String']>
    style: Maybe<Scalars['String']>
    head: Maybe<Scalars['Json']>
    noindex: Maybe<Scalars['Boolean']>
    nofollow: Maybe<Scalars['Boolean']>
    authRequired: Maybe<Scalars['Boolean']>
    pageType: Maybe<Scalars['String']>
    storyTag: Maybe<PrismicHyperlink>
    storyDescription: Maybe<Scalars['String']>
    storyTitle: Maybe<Scalars['String']>
    publicationField: Maybe<Scalars['Date']>
    thumbnailImage: Maybe<PrismicImageType>
    pageBackground: Maybe<Scalars['Json']>
    pageBackgroundColor: Maybe<Scalars['String']>
    body: Maybe<Array<VanguardPageBody>>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

export type VanguardPageBody =
    | VanguardPageBodyProductHero
    | VanguardPageBodyTweakwiseSlider
    | VanguardPageBodyImageSlider
    | VanguardPageBodyHero
    | VanguardPageBodyTextOnly
    | VanguardPageBodyImageOnly
    | VanguardPageBodyTextWithMedia
    | VanguardPageBodyTwoimageswithheading
    | VanguardPageBodyHtml
    | VanguardPageBodyCategoryElement
    | VanguardPageBodyFaq
    | VanguardPageBodyBannerBlocks
    | VanguardPageBodyBannerBlocks1
    | VanguardPageBodyBannerBlocks2
    | VanguardPageBodyCategoryList
    | VanguardPageBodyStoriesOverviewHeader
    | VanguardPageBodyStoryOverviewPage
    | VanguardPageBodyVisualFilter
    | VanguardPageBodyBlog

export interface VanguardPageBodyBannerBlocks {
    __typename?: 'VanguardPageBodyBannerBlocks'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<VanguardPageBodyBannerBlocksPrimary>
    fields: Maybe<Array<VanguardPageBodyBannerBlocksFields>>
}

export interface VanguardPageBodyBannerBlocks1 {
    __typename?: 'VanguardPageBodyBannerBlocks1'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<VanguardPageBodyBannerBlocks1Primary>
    fields: Maybe<Array<VanguardPageBodyBannerBlocks1Fields>>
}

export interface VanguardPageBodyBannerBlocks1Fields {
    __typename?: 'VanguardPageBodyBannerBlocks1Fields'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    subtitle: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

export interface VanguardPageBodyBannerBlocks1Primary {
    __typename?: 'VanguardPageBodyBannerBlocks1Primary'
    style: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
}

export interface VanguardPageBodyBannerBlocks2 {
    __typename?: 'VanguardPageBodyBannerBlocks2'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<VanguardPageBodyBannerBlocks2Primary>
    fields: Maybe<Array<VanguardPageBodyBannerBlocks2Fields>>
}

export interface VanguardPageBodyBannerBlocks2Fields {
    __typename?: 'VanguardPageBodyBannerBlocks2Fields'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    subtitle: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

export interface VanguardPageBodyBannerBlocks2Primary {
    __typename?: 'VanguardPageBodyBannerBlocks2Primary'
    style: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
}

export interface VanguardPageBodyBannerBlocksFields {
    __typename?: 'VanguardPageBodyBannerBlocksFields'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
}

export interface VanguardPageBodyBannerBlocksPrimary {
    __typename?: 'VanguardPageBodyBannerBlocksPrimary'
    style: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
}

export interface VanguardPageBodyBlog {
    __typename?: 'VanguardPageBodyBlog'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<VanguardPageBodyBlogPrimary>
    fields: Maybe<Array<VanguardPageBodyBlogFields>>
}

export interface VanguardPageBodyBlogFields {
    __typename?: 'VanguardPageBodyBlogFields'
    desktopImage: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    header: Maybe<Scalars['String']>
    subHeader: Maybe<Scalars['String']>
    blogText: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
}

export interface VanguardPageBodyBlogPrimary {
    __typename?: 'VanguardPageBodyBlogPrimary'
    title: Maybe<Scalars['String']>
    blogStyle: Maybe<Scalars['String']>
}

export interface VanguardPageBodyCategoryElement {
    __typename?: 'VanguardPageBodyCategoryElement'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<VanguardPageBodyCategoryElementPrimary>
    fields: Maybe<Array<VanguardPageBodyCategoryElementFields>>
}

export interface VanguardPageBodyCategoryElementFields {
    __typename?: 'VanguardPageBodyCategoryElementFields'
    title: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
}

export interface VanguardPageBodyCategoryElementPrimary {
    __typename?: 'VanguardPageBodyCategoryElementPrimary'
    style: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
}

export interface VanguardPageBodyCategoryList {
    __typename?: 'VanguardPageBodyCategoryList'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<VanguardPageBodyCategoryListPrimary>
    fields: Maybe<Array<VanguardPageBodyCategoryListFields>>
}

export interface VanguardPageBodyCategoryListFields {
    __typename?: 'VanguardPageBodyCategoryListFields'
    title: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
    list: Maybe<Scalars['String']>
}

export interface VanguardPageBodyCategoryListPrimary {
    __typename?: 'VanguardPageBodyCategoryListPrimary'
    style: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    title1: Maybe<Scalars['String']>
    title2: Maybe<Scalars['String']>
    title3: Maybe<Scalars['String']>
    title4: Maybe<Scalars['String']>
}

export interface VanguardPageBodyFaq {
    __typename?: 'VanguardPageBodyFaq'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<VanguardPageBodyFaqPrimary>
    fields: Maybe<Array<VanguardPageBodyFaqFields>>
}

export interface VanguardPageBodyFaqFields {
    __typename?: 'VanguardPageBodyFaqFields'
    iconimage: Maybe<PrismicImageType>
    category: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
}

export interface VanguardPageBodyFaqPrimary {
    __typename?: 'VanguardPageBodyFaqPrimary'
    style: Maybe<Scalars['String']>
    emptyCategoryText: Maybe<Scalars['String']>
}

export interface VanguardPageBodyHero {
    __typename?: 'VanguardPageBodyHero'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<VanguardPageBodyHeroPrimary>
}

export interface VanguardPageBodyHeroPrimary {
    __typename?: 'VanguardPageBodyHeroPrimary'
    fullWidth: Maybe<Scalars['Boolean']>
    size: Maybe<Scalars['String']>
    headingfuss: Maybe<Scalars['Boolean']>
    header: Maybe<Scalars['String']>
    headingVariant: Maybe<Scalars['String']>
    headingElement: Maybe<Scalars['String']>
    subheading: Maybe<Scalars['String']>
    subheadingVariant: Maybe<Scalars['String']>
    subheadingElement: Maybe<Scalars['String']>
    videoId: Maybe<Scalars['String']>
    mobileVideoId: Maybe<Scalars['String']>
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
}

export interface VanguardPageBodyHtml {
    __typename?: 'VanguardPageBodyHtml'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<VanguardPageBodyHtmlPrimary>
}

export interface VanguardPageBodyHtmlPrimary {
    __typename?: 'VanguardPageBodyHtmlPrimary'
    style: Maybe<Scalars['String']>
    html: Maybe<Scalars['Json']>
    tablet: Maybe<Scalars['String']>
    desktop: Maybe<Scalars['String']>
}

export interface VanguardPageBodyImageOnly {
    __typename?: 'VanguardPageBodyImageOnly'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<VanguardPageBodyImageOnlyPrimary>
}

export interface VanguardPageBodyImageOnlyPrimary {
    __typename?: 'VanguardPageBodyImageOnlyPrimary'
    style: Maybe<Scalars['String']>
    tablet: Maybe<Scalars['String']>
    desktop: Maybe<Scalars['String']>
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
}

export interface VanguardPageBodyImageSlider {
    __typename?: 'VanguardPageBodyImageSlider'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<VanguardPageBodyImageSliderPrimary>
    fields: Maybe<Array<VanguardPageBodyImageSliderFields>>
}

export interface VanguardPageBodyImageSliderFields {
    __typename?: 'VanguardPageBodyImageSliderFields'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    subtitle: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

export interface VanguardPageBodyImageSliderPrimary {
    __typename?: 'VanguardPageBodyImageSliderPrimary'
    style: Maybe<Scalars['String']>
    variant: Maybe<Scalars['String']>
    header: Maybe<Scalars['String']>
    content: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

export interface VanguardPageBodyProductHero {
    __typename?: 'VanguardPageBodyProductHero'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<VanguardPageBodyProductHeroPrimary>
}

export interface VanguardPageBodyProductHeroPrimary {
    __typename?: 'VanguardPageBodyProductHeroPrimary'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    content: Maybe<Scalars['String']>
    subheading: Maybe<Scalars['String']>
    buttonStyle: Maybe<Scalars['String']>
    textStyle: Maybe<Scalars['String']>
    subheadingStyle: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
    button2Url: Maybe<Scalars['String']>
    button2Text: Maybe<Scalars['String']>
    videoId: Maybe<Scalars['String']>
    mobileVideoId: Maybe<Scalars['String']>
    productSkus: Maybe<Scalars['String']>
    productCueTimes: Maybe<Scalars['String']>
}

export interface VanguardPageBodyStoriesOverviewHeader {
    __typename?: 'VanguardPageBodyStoriesOverviewHeader'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    fields: Maybe<Array<VanguardPageBodyStoriesOverviewHeaderFields>>
}

export interface VanguardPageBodyStoriesOverviewHeaderFields {
    __typename?: 'VanguardPageBodyStoriesOverviewHeaderFields'
    desktopImage: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
    title: Maybe<Scalars['String']>
    subtitle: Maybe<Scalars['String']>
    url: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

export interface VanguardPageBodyStoryOverviewPage {
    __typename?: 'VanguardPageBodyStoryOverviewPage'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<VanguardPageBodyStoryOverviewPagePrimary>
}

export interface VanguardPageBodyStoryOverviewPagePrimary {
    __typename?: 'VanguardPageBodyStoryOverviewPagePrimary'
    showStories: Maybe<Scalars['Boolean']>
}

export interface VanguardPageBodyTextOnly {
    __typename?: 'VanguardPageBodyTextOnly'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<VanguardPageBodyTextOnlyPrimary>
}

export interface VanguardPageBodyTextOnlyPrimary {
    __typename?: 'VanguardPageBodyTextOnlyPrimary'
    style: Maybe<Scalars['String']>
    visibility: Maybe<Scalars['String']>
    tablet: Maybe<Scalars['String']>
    desktop: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    headingVariant: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
    buttonText: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    blockBackground: Maybe<Scalars['Json']>
    blockBackgroundColor: Maybe<Scalars['String']>
}

export interface VanguardPageBodyTextWithMedia {
    __typename?: 'VanguardPageBodyTextWithMedia'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<VanguardPageBodyTextWithMediaPrimary>
    fields: Maybe<Array<VanguardPageBodyTextWithMediaFields>>
}

export interface VanguardPageBodyTextWithMediaFields {
    __typename?: 'VanguardPageBodyTextWithMediaFields'
    image: Maybe<PrismicImageType>
    mobileImage: Maybe<PrismicImageType>
}

export interface VanguardPageBodyTextWithMediaPrimary {
    __typename?: 'VanguardPageBodyTextWithMediaPrimary'
    style: Maybe<Scalars['String']>
    desktopMediaPosition: Maybe<Scalars['String']>
    mobileMediaPosition: Maybe<Scalars['String']>
    alignment: Maybe<Scalars['String']>
    heading: Maybe<Scalars['String']>
    headingfuss: Maybe<Scalars['Boolean']>
    headingVariant: Maybe<Scalars['String']>
    headingElement: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
    buttonStyle: Maybe<Scalars['String']>
    button2Url: Maybe<Scalars['String']>
    button2Text: Maybe<Scalars['String']>
    button2Style: Maybe<Scalars['String']>
    content: Maybe<RichTextBlock[]>
    videoId: Maybe<Scalars['String']>
    mobileVideoId: Maybe<Scalars['String']>
    blockBackground: Maybe<Scalars['Json']>
    blockBackgroundColor: Maybe<Scalars['String']>
}

export interface VanguardPageBodyTweakwiseSlider {
    __typename?: 'VanguardPageBodyTweakwiseSlider'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<VanguardPageBodyTweakwiseSliderPrimary>
}

export interface VanguardPageBodyTweakwiseSliderPrimary {
    __typename?: 'VanguardPageBodyTweakwiseSliderPrimary'
    blueconicPosition: Maybe<Scalars['String']>
    style: Maybe<Scalars['String']>
    header: Maybe<Scalars['String']>
    content: Maybe<Scalars['String']>
    recommendationsIdentifier: Maybe<Scalars['String']>
    buttonUrl: Maybe<Scalars['String']>
    buttonText: Maybe<Scalars['String']>
}

export interface VanguardPageBodyTwoimageswithheading {
    __typename?: 'VanguardPageBodyTwoimageswithheading'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<VanguardPageBodyTwoimageswithheadingPrimary>
}

export interface VanguardPageBodyTwoimageswithheadingPrimary {
    __typename?: 'VanguardPageBodyTwoimageswithheadingPrimary'
    style: Maybe<Scalars['String']>
    firstimage: Maybe<PrismicImageType>
    firstmobileimage: Maybe<PrismicImageType>
    secondimage: Maybe<PrismicImageType>
    secondmobileimage: Maybe<PrismicImageType>
    heading: Maybe<Scalars['String']>
}

export interface VanguardPageBodyVisualFilter {
    __typename?: 'VanguardPageBodyVisualFilter'
    type: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    primary: Maybe<VanguardPageBodyVisualFilterPrimary>
}

export interface VanguardPageBodyVisualFilterPrimary {
    __typename?: 'VanguardPageBodyVisualFilterPrimary'
    visualFilter: Maybe<PrismicHyperlink>
}

/** A connection to a list of items. */
export interface VanguardPageConnectionConnection {
    __typename?: 'VanguardPageConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<VanguardPageConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface VanguardPageConnectionEdge {
    __typename?: 'VanguardPageConnectionEdge'
    /** The item at the end of the edge. */
    node: VanguardPage
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

export interface VanguardTag extends Document, Linkable {
    __typename?: 'VanguardTag'
    tagId: Maybe<Scalars['String']>
    tagName: Maybe<Scalars['String']>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

/** A connection to a list of items. */
export interface VanguardTagConnectionConnection {
    __typename?: 'VanguardTagConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<VanguardTagConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface VanguardTagConnectionEdge {
    __typename?: 'VanguardTagConnectionEdge'
    /** The item at the end of the edge. */
    node: VanguardTag
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

/** Contains required input for payment methods with Vault support. */
export interface VaultTokenInput {
    /** The public hash of the payment token. */
    public_hash: Scalars['String']
}

/** An implementation for virtual product cart items. */
export interface VirtualCartItem extends CartItemInterface {
    __typename?: 'VirtualCartItem'
    /** An array containing customizable options the shopper selected. */
    customizableOptions: Array<Maybe<SelectedCustomizableOption>>
    /** An array of errors encountered while loading the cart item */
    errors: Maybe<Array<Maybe<CartItemError>>>
    extensionAttributes: Maybe<CartItemExtensionAttributes>
    /** @deprecated Use `uid` instead. */
    id: Scalars['String']
    /** True if requested quantity is less than available stock, false otherwise. */
    isAvailable: Scalars['Boolean']
    isFreeGift: Maybe<Scalars['Boolean']>
    /** Contains details about the price of the item, including taxes and discounts. */
    prices: Maybe<CartItemPrices>
    /** Details about an item in the cart. */
    product: ProductInterface
    promotional: Maybe<Scalars['Boolean']>
    /** The quantity of this item in the cart. */
    quantity: Scalars['Float']
    reservation: Maybe<Reservation>
    /** The unique ID for a `CartItemInterface` object. */
    uid: Scalars['ID']
}

/** Defines a virtual product, which is a non-tangible product that does not require shipping and is not kept in inventory. */
export interface VirtualProduct
    extends ProductInterface,
        RoutableInterface,
        CustomizableProductInterface {
    __typename?: 'VirtualProduct'
    /** @deprecated Use the `custom_attributes` field instead. */
    baseColorHexCode: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    brandAlias: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    brandMediaPdpBottom: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    brandMediaPdpTop: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    brandName: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    brandPdpDescription: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    brandPdpTitle: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    featureDescription: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    featureMedia: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    featureName: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    itemLabelTextB2C: Maybe<Scalars['Int']>
    itemLabelTextB2CValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    itemLabelTypeB2C: Maybe<Scalars['Int']>
    itemLabelTypeB2CValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    itemMaterialBody: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    itemMaterialBodyLining: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    itemMaterialLining: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    itemMaterialShell: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    itemSizeAdvice: Maybe<Scalars['Int']>
    itemSizeAdviceValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    modelAdvice: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productArticleNumber: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productBeltLoops: Maybe<Scalars['Int']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productBodyShell: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productChestPockets: Maybe<Scalars['Int']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productClosure: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productClosureDetails: Maybe<Scalars['String']>
    productClosureDetailsValues: Maybe<Array<Maybe<AttributeOption>>>
    productClosureValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productCollar: Maybe<Scalars['Int']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productCollarDetails: Maybe<Scalars['String']>
    productCollarDetailsValues: Maybe<Array<Maybe<AttributeOption>>>
    productCollarValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productContainsNonTextilePartsOfAnimalOrigin: Maybe<Scalars['Int']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productCuffs: Maybe<Scalars['String']>
    productCuffsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productDimensions: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productFabric: Maybe<Scalars['String']>
    productFabricValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productFashionColor: Maybe<Scalars['Int']>
    productFashionColorValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productFit: Maybe<Scalars['Int']>
    productFitValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productHeight: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productHood: Maybe<Scalars['Int']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productHoodDetails: Maybe<Scalars['Int']>
    productHoodDetailsValues: Maybe<Array<Maybe<AttributeOption>>>
    productHoodValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productInnerJacketDetails: Maybe<Scalars['String']>
    productInnerJacketDetailsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productInnerPockets: Maybe<Scalars['Int']>
    productInnerPocketsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productJacketDetails: Maybe<Scalars['String']>
    productJacketDetailsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productJacketType: Maybe<Scalars['Int']>
    productJacketTypeValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productJacketlayers: Maybe<Scalars['Int']>
    productJacketlayersValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productLength: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productLengthDescription: Maybe<Scalars['Int']>
    productLengthDescriptionValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productLogoLocation: Maybe<Scalars['String']>
    productLogoLocationValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMarketPlacesDescription: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMarketingStyle: Maybe<Scalars['Int']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMaterialBack: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMaterialFilling: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMaterialFront: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMaterialHood: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMaterialLining: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMaterialPadding: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productMaterialShell: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productNumberOfPockets: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productOccassion: Maybe<Scalars['Int']>
    productOccassionValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productOuterPockets: Maybe<Scalars['String']>
    productOuterPocketsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productPaddingType: Maybe<Scalars['Int']>
    productPaddingTypeValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productPattern: Maybe<Scalars['String']>
    productPatternValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productPocketType: Maybe<Scalars['String']>
    productPocketTypeValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productRelevance: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productSeasonStatus: Maybe<Scalars['Int']>
    productSeasonStatusValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productShape: Maybe<Scalars['Int']>
    productShapeValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productSizeTable: Maybe<Scalars['Int']>
    productSizeTableValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productSleeveCuffDetails: Maybe<Scalars['Int']>
    productSleeveCuffDetailsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productSleeveDetails: Maybe<Scalars['Int']>
    productSleeveDetailsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productSleeveLength: Maybe<Scalars['Int']>
    productSleeveLengthValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productSustainable: Maybe<Scalars['String']>
    productSustainableValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productType: Maybe<Scalars['Int']>
    productTypeValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productVolume: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productWaistRise: Maybe<Scalars['Int']>
    productWaistRiseValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productWashingInstructionsClotex: Maybe<Scalars['String']>
    productWashingInstructionsClotexValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productWidth: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    sizeTableIdentifier: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    washingSymbolDescription: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    washingSymbolMedia: Maybe<Scalars['String']>
    /**
     * The attribute set assigned to the product.
     * @deprecated The field should not be used on the storefront.
     */
    attributeSetId: Maybe<Scalars['Int']>
    /** @deprecated Use the `custom_attributes` field instead. */
    baseColor: Maybe<Scalars['String']>
    baseColorValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    brand: Maybe<Scalars['String']>
    /**
     * The relative canonical URL. This value is returned only if the system setting
     * 'Use Canonical Link Meta Tag For Products' is enabled.
     */
    canonicalUrl: Maybe<Scalars['String']>
    /** The categories assigned to a product. */
    categories: Maybe<Array<Maybe<CategoryInterface>>>
    categoryIds: Maybe<Array<Maybe<Scalars['Int']>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    color: Maybe<Scalars['String']>
    colorValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    configAttributes: Maybe<Scalars['String']>
    /** Product bottom content */
    contentBottomContent: Maybe<Scalars['String']>
    /** Product top image one */
    contentBottomImageOne: Maybe<Scalars['String']>
    /** Product top image two */
    contentBottomImageTwo: Maybe<Scalars['String']>
    /** Product bottom title */
    contentBottomTitle: Maybe<Scalars['String']>
    contentBottomUsp: Maybe<Scalars['String']>
    /** Product middle content */
    contentMiddleContent: Maybe<Scalars['String']>
    /** Product top image one */
    contentMiddleImageOne: Maybe<Scalars['String']>
    /** Product top image two */
    contentMiddleImageTwo: Maybe<Scalars['String']>
    /** Product middle title */
    contentMiddleTitle: Maybe<Scalars['String']>
    /** Product size guide image above */
    contentSizeGuideImageAbove: Maybe<Scalars['String']>
    /** Product size guide image below */
    contentSizeGuideImageBelow: Maybe<Scalars['String']>
    /** Product top content */
    contentTopContent: Maybe<Scalars['String']>
    /** Product top image four */
    contentTopImageFour: Maybe<Scalars['String']>
    /** Product top image one */
    contentTopImageOne: Maybe<Scalars['String']>
    /** Product top image three */
    contentTopImageThree: Maybe<Scalars['String']>
    /** Product top image two */
    contentTopImageTwo: Maybe<Scalars['String']>
    /** Product top title */
    contentTopTitle: Maybe<Scalars['String']>
    /** The product's country of origin. */
    countryOfManufacture: Maybe<Scalars['String']>
    /**
     * Timestamp indicating when the product was created.
     * @deprecated The field should not be used on the storefront.
     */
    createdAt: Maybe<Scalars['String']>
    /** Crosssell Products */
    crosssellProducts: Maybe<Array<Maybe<ProductInterface>>>
    /** Product custom attributes. */
    customAttributesV2: Maybe<ProductCustomAttributes>
    /** Product Delivery Message */
    deliveryMessage: Maybe<Scalars['String']>
    /** Detailed information about the product. The value can include simple HTML tags. */
    description: Maybe<ComplexTextValue>
    /** @deprecated Use the `custom_attributes` field instead. */
    discountAmount: Maybe<Scalars['Float']>
    /** @deprecated Use the `custom_attributes` field instead. */
    discountPercentage: Maybe<Scalars['Float']>
    /** @deprecated Use the `custom_attributes` field instead. */
    featureIcons: Maybe<Scalars['String']>
    featureIconsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    features: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    fit: Maybe<Scalars['String']>
    fitValues: Maybe<Array<Maybe<AttributeOption>>>
    /** Indicates whether a gift message is available. */
    giftMessageAvailable: Maybe<Scalars['String']>
    hexColor: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    highlights: Maybe<Scalars['String']>
    highlightsValues: Maybe<Array<Maybe<AttributeOption>>>
    /**
     * The ID number assigned to the product.
     * @deprecated Use the `uid` field instead.
     */
    id: Scalars['Int']
    /** The relative path to the main image on the product page. */
    image: Maybe<ProductImage>
    /** URL for the Care Tab */
    infoTabCareUrl: Maybe<Scalars['String']>
    /** URL for the Features Tab */
    infoTabFeaturesUrl: Maybe<Scalars['String']>
    /** URL for the Fit Tab */
    infoTabFitUrl: Maybe<Scalars['String']>
    /** URL for the Highlights Tab */
    infoTabHighlightsUrl: Maybe<Scalars['String']>
    /** URL for the Materials Tab */
    infoTabMaterialsUrl: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    jeansSizeCombined: Maybe<Scalars['Int']>
    jeansSizeCombinedValues: Maybe<Array<Maybe<AttributeOption>>>
    /**
     * Proxy for ItemLabelTextB2C
     * @deprecated Use the `custom_attributes` field instead.
     */
    labeltekst: Maybe<Scalars['String']>
    /**
     * Proxy for ItemLabelTypeB2C
     * @deprecated Use the `custom_attributes` field instead.
     */
    labeltype: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    length: Maybe<Scalars['String']>
    lengthValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    lowestDate: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    lowestPrice: Maybe<Scalars['Float']>
    /** Main Category */
    mainCategory: Maybe<CategoryInterface>
    /**
     * A number representing the product's manufacturer.
     * @deprecated Use the `custom_attributes` field instead.
     */
    manufacturer: Maybe<Scalars['Int']>
    manufacturerValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    materialInside: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    materialOutside: Maybe<Scalars['String']>
    /** An array of media gallery objects. */
    mediaGallery: Maybe<Array<Maybe<MediaGalleryInterface>>>
    /**
     * An array of MediaGalleryEntry objects.
     * @deprecated Use `media_gallery` instead.
     */
    mediaGalleryEntries: Maybe<Array<Maybe<MediaGalleryEntry>>>
    /** A brief overview of the product for search results listings, maximum 255 characters. */
    metaDescription: Maybe<Scalars['String']>
    /** A comma-separated list of keywords that are visible only to search engines. */
    metaKeyword: Maybe<Scalars['String']>
    metaRobots: Maybe<Scalars['String']>
    /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
    metaTitle: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    modelImage: Maybe<Scalars['String']>
    /** Information related to the Model. */
    modelInfo: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    modelName: Maybe<Scalars['String']>
    /** The product name. Customers use this name to identify the product. */
    name: Scalars['String']
    /** The beginning date for new product listings, and determines if the product is featured as a new product. */
    newFromDate: Maybe<Scalars['String']>
    /** The end date for new product listings. */
    newToDate: Maybe<Scalars['String']>
    /** Product stock only x left count */
    onlyXLeftInStock: Maybe<Scalars['Float']>
    /** An array of options for a customizable product. */
    options: Maybe<Array<Maybe<CustomizableOptionInterface>>>
    /** If the product has multiple options, determines where they appear on the product page. */
    optionsContainer: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    outletAdviesprijs: Maybe<Scalars['Float']>
    /** @deprecated Use the `custom_attributes` field instead. */
    parentSku: Maybe<Scalars['String']>
    /**
     * Indicates the price of an item.
     * @deprecated Use `price_range` for product price information.
     */
    price: Maybe<ProductPrices>
    /** The range of prices for the product */
    priceRange: PriceRange
    /** An array of `TierPrice` objects. */
    priceTiers: Maybe<Array<Maybe<TierPrice>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    productArticleCategoryCode: Maybe<Scalars['Int']>
    productArticleCategoryCodeValues: Maybe<Array<Maybe<AttributeOption>>>
    /**
     * Product type like, jeans, jacket etc.
     * @deprecated Use the `custom_attributes` field instead.
     */
    productGroup: Maybe<Scalars['String']>
    /** An array of `ProductLinks` objects. */
    productLinks: Maybe<Array<Maybe<ProductLinksInterface>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    products: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    productsustainable2: Maybe<Scalars['String']>
    productsustainable2Values: Maybe<Array<Maybe<AttributeOption>>>
    /** The average of all the ratings given to the product. */
    ratingSummary: Scalars['Float']
    /** @deprecated Use the `custom_attributes` field instead. */
    recommendations: Maybe<Scalars['String']>
    /**
     * Contains 0 when there is no redirect error. A value of 301 indicates the URL
     * of the requested resource has been changed permanently, while a value of 302
     * indicates a temporary redirect.
     */
    redirectCode: Scalars['Int']
    /** An array of products to be displayed in a Related Products block. */
    relatedProducts: Maybe<Array<Maybe<ProductInterface>>>
    /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
    relativeUrl: Maybe<Scalars['String']>
    /** The total count of all the reviews given to the product. */
    reviewCount: Scalars['Int']
    /** The list of products reviews. */
    reviews: ProductReviews
    /** @deprecated Use the `custom_attributes` field instead. */
    seasonCollection: Maybe<Scalars['String']>
    seasonCollectionValues: Maybe<Array<Maybe<AttributeOption>>>
    /** @deprecated Use the `custom_attributes` field instead. */
    shopthelook: Maybe<Scalars['Int']>
    /** A short description of the product. Its use depends on the theme. */
    shortDescription: Maybe<ComplexTextValue>
    /** @deprecated Use the `custom_attributes` field instead. */
    shortDescription2: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    shortDescription3: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    shortDescription4: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    shortDescription5: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    shortDescription6: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    size: Maybe<Scalars['String']>
    sizeValues: Maybe<Array<Maybe<AttributeOption>>>
    /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
    sku: Scalars['String']
    /** The relative path to the small image, which is used on catalog pages. */
    smallImage: Maybe<ProductImage>
    /**
     * The beginning date that a product has a special price.
     * @deprecated The field should not be used on the storefront.
     */
    specialFromDate: Maybe<Scalars['String']>
    /** The discounted price of the product. */
    specialPrice: Maybe<Scalars['Float']>
    /** The end date for a product with a special price. */
    specialToDate: Maybe<Scalars['String']>
    /** Stock status of the product */
    stockStatus: Maybe<ProductStockStatus>
    /** The file name of a swatch image. */
    swatchImage: Maybe<Scalars['String']>
    /** The relative path to the product's thumbnail image. */
    thumbnail: Maybe<ProductImage>
    /**
     * The price when tier pricing is in effect and the items purchased threshold has been reached.
     * @deprecated Use `price_tiers` for product tier price information.
     */
    tierPrice: Maybe<Scalars['Float']>
    /**
     * An array of ProductTierPrices objects.
     * @deprecated Use `price_tiers` for product tier price information.
     */
    tierPrices: Maybe<Array<Maybe<ProductTierPrices>>>
    tweakwiseCrosssellGroupCode: Maybe<Scalars['String']>
    /** Value -1 means the group_code should be used */
    tweakwiseCrosssellTemplate: Maybe<Scalars['Int']>
    tweakwiseUpsellGroupCode: Maybe<Scalars['String']>
    /** Value -1 means the group_code should be used */
    tweakwiseUpsellTemplate: Maybe<Scalars['Int']>
    /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
    type: Maybe<UrlRewriteEntityTypeEnum>
    /**
     * One of simple, virtual, bundle, downloadable, grouped, or configurable.
     * @deprecated Use `__typename` instead.
     */
    typeId: Maybe<Scalars['String']>
    /** The unique ID for a `ProductInterface` object. */
    uid: Scalars['ID']
    /**
     * Timestamp indicating when the product was updated.
     * @deprecated The field should not be used on the storefront.
     */
    updatedAt: Maybe<Scalars['String']>
    /** Upsell Products */
    upsellProducts: Maybe<Array<Maybe<ProductInterface>>>
    /** The part of the URL that identifies the product */
    urlKey: Scalars['String']
    /** @deprecated Use product's `canonical_url` or url rewrites instead */
    urlPath: Maybe<Scalars['String']>
    /** URL rewrites list */
    urlRewrites: Maybe<Array<Maybe<UrlRewrite>>>
    /** The part of the product URL that is appended after the url key */
    urlSuffix: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    uspIcons: Maybe<Scalars['String']>
    uspIconsValues: Maybe<Array<Maybe<AttributeOption>>>
    /** USPs for this specific Product. */
    usps: Array<Maybe<Usp>>
    warehouse: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    washingInstructions: Maybe<Scalars['String']>
    /** @deprecated Use the `custom_attributes` field instead. */
    washingSymbols: Maybe<Scalars['String']>
    washingSymbolsValues: Maybe<Array<Maybe<AttributeOption>>>
    /**
     * An array of websites in which the product is available.
     * @deprecated Use the `custom_attributes` field instead.
     */
    websites: Maybe<Array<Maybe<Website>>>
}

/** Defines a virtual product, which is a non-tangible product that does not require shipping and is not kept in inventory. */
export interface VirtualProductCustomAttributesV2Args {
    filters: Maybe<AttributeFilterInput>
}

/** Defines a virtual product, which is a non-tangible product that does not require shipping and is not kept in inventory. */
export interface VirtualProductReviewsArgs {
    pageSize?: Maybe<Scalars['Int']>
    currentPage?: Maybe<Scalars['Int']>
}

/** Defines a virtual product, which is a non-tangible product that does not require shipping and is not kept in inventory. */
export interface VirtualProductUspsArgs {
    pageSize?: Maybe<Scalars['Int']>
    currentPage?: Maybe<Scalars['Int']>
}

/** Defines a single product to add to the cart. */
export interface VirtualProductCartItemInput {
    /** An array that defines customizable options for the product. */
    customizable_options?: Maybe<Array<Maybe<CustomizableOptionInput>>>
    /** An object containing the `sku`, `quantity`, and other relevant information about the product. */
    data: CartItemInput
}

/** Contains a virtual product wish list item. */
export interface VirtualWishlistItem extends WishlistItemInterface {
    __typename?: 'VirtualWishlistItem'
    /** The date and time the item was added to the wish list. */
    addedAt: Scalars['String']
    /** Custom options selected for the wish list item. */
    customizableOptions: Array<Maybe<SelectedCustomizableOption>>
    /** The description of the item. */
    description: Maybe<Scalars['String']>
    /** The unique ID for a `WishlistItemInterface` object. */
    id: Scalars['ID']
    /** Product details of the wish list item. */
    product: Maybe<ProductInterface>
    /** The quantity of this wish list item. */
    quantity: Scalars['Float']
}

export interface VisualFilter extends Document, Linkable {
    __typename?: 'VisualFilter'
    cards: Maybe<Array<VisualFilterCards>>
    meta: Meta
    linkType: Maybe<Scalars['String']>
}

export interface VisualFilterCards {
    __typename?: 'VisualFilterCards'
    image: Maybe<PrismicImageType>
    relativeUrl: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
    label: Maybe<Scalars['String']>
    color: Maybe<Scalars['String']>
}

/** A connection to a list of items. */
export interface VisualFilterConnectionConnection {
    __typename?: 'VisualFilterConnectionConnection'
    /** Information to aid in pagination. */
    pageInfo: PageInfo
    /** A list of edges. */
    edges: Maybe<Array<Maybe<VisualFilterConnectionEdge>>>
    totalCount: Scalars['Long']
}

/** An edge in a connection. */
export interface VisualFilterConnectionEdge {
    __typename?: 'VisualFilterConnectionEdge'
    /** The item at the end of the edge. */
    node: VisualFilter
    /** A cursor for use in pagination. */
    cursor: Scalars['String']
}

/** Deprecated. It should not be used on the storefront. Contains information about a website. */
export interface Website {
    __typename?: 'Website'
    /**
     * A code assigned to the website to identify it.
     * @deprecated The field should not be used on the storefront.
     */
    code: Maybe<Scalars['String']>
    /**
     * The default group ID of the website.
     * @deprecated The field should not be used on the storefront.
     */
    defaultGroupId: Maybe<Scalars['String']>
    /**
     * The ID number assigned to the website.
     * @deprecated The field should not be used on the storefront.
     */
    id: Maybe<Scalars['Int']>
    /**
     * Indicates whether this is the default website.
     * @deprecated The field should not be used on the storefront.
     */
    isDefault: Maybe<Scalars['Boolean']>
    /**
     * The website name. Websites use this name to identify it easier.
     * @deprecated The field should not be used on the storefront.
     */
    name: Maybe<Scalars['String']>
    /**
     * The attribute to use for sorting websites.
     * @deprecated The field should not be used on the storefront.
     */
    sortOrder: Maybe<Scalars['Int']>
}

export interface WhereCastironFaqPage {
    urlKey?: Maybe<Scalars['String']>
    urlKeyFulltext?: Maybe<Scalars['String']>
    search?: Maybe<Scalars['Boolean']>
    seoTitle?: Maybe<Scalars['String']>
    seoTitleFulltext?: Maybe<Scalars['String']>
    seoDescription?: Maybe<Scalars['String']>
    seoDescriptionFulltext?: Maybe<Scalars['String']>
    seoKeywords?: Maybe<Scalars['String']>
    seoKeywordsFulltext?: Maybe<Scalars['String']>
    style?: Maybe<Scalars['String']>
    styleFulltext?: Maybe<Scalars['String']>
    /** head */
    headFulltext?: Maybe<Scalars['String']>
    noindex?: Maybe<Scalars['Boolean']>
    nofollow?: Maybe<Scalars['Boolean']>
}

export interface WhereCastironPage {
    urlKey?: Maybe<Scalars['String']>
    urlKeyFulltext?: Maybe<Scalars['String']>
    search?: Maybe<Scalars['Boolean']>
    seoTitle?: Maybe<Scalars['String']>
    seoTitleFulltext?: Maybe<Scalars['String']>
    seoDescription?: Maybe<Scalars['String']>
    seoDescriptionFulltext?: Maybe<Scalars['String']>
    seoKeywords?: Maybe<Scalars['String']>
    seoKeywordsFulltext?: Maybe<Scalars['String']>
    style?: Maybe<Scalars['String']>
    styleFulltext?: Maybe<Scalars['String']>
    /** head */
    headFulltext?: Maybe<Scalars['String']>
    noindex?: Maybe<Scalars['Boolean']>
    nofollow?: Maybe<Scalars['Boolean']>
    authRequired?: Maybe<Scalars['Boolean']>
    pageType?: Maybe<Scalars['String']>
    pageTypeFulltext?: Maybe<Scalars['String']>
    /** story_tag */
    storyTag?: Maybe<Scalars['String']>
    storyDescription?: Maybe<Scalars['String']>
    storyDescriptionFulltext?: Maybe<Scalars['String']>
    storyTitle?: Maybe<Scalars['String']>
    storyTitleFulltext?: Maybe<Scalars['String']>
    /** publication_field */
    publicationField?: Maybe<Scalars['Date']>
    /** publication_field */
    publicationFieldBefore?: Maybe<Scalars['Date']>
    /** publication_field */
    publicationFieldAfter?: Maybe<Scalars['Date']>
    pageBackgroundColor?: Maybe<Scalars['String']>
    pageBackgroundColorFulltext?: Maybe<Scalars['String']>
}

export interface WhereCastironTag {
    tagId?: Maybe<Scalars['String']>
    tagIdFulltext?: Maybe<Scalars['String']>
    tagName?: Maybe<Scalars['String']>
    tagNameFulltext?: Maybe<Scalars['String']>
}

export interface WhereCategory {
    categoryId?: Maybe<Scalars['String']>
    categoryIdFulltext?: Maybe<Scalars['String']>
    name?: Maybe<Scalars['String']>
    nameFulltext?: Maybe<Scalars['String']>
}

export interface WhereCategoryBanners {
    storeview?: Maybe<Scalars['String']>
    storeviewFulltext?: Maybe<Scalars['String']>
}

export interface WhereFaqPage {
    urlKey?: Maybe<Scalars['String']>
    urlKeyFulltext?: Maybe<Scalars['String']>
    search?: Maybe<Scalars['Boolean']>
    seoTitle?: Maybe<Scalars['String']>
    seoTitleFulltext?: Maybe<Scalars['String']>
    seoDescription?: Maybe<Scalars['String']>
    seoDescriptionFulltext?: Maybe<Scalars['String']>
    seoKeywords?: Maybe<Scalars['String']>
    seoKeywordsFulltext?: Maybe<Scalars['String']>
    style?: Maybe<Scalars['String']>
    styleFulltext?: Maybe<Scalars['String']>
    /** head */
    headFulltext?: Maybe<Scalars['String']>
    noindex?: Maybe<Scalars['Boolean']>
    nofollow?: Maybe<Scalars['Boolean']>
}

export interface WhereFooterCastiron {
    urlKey?: Maybe<Scalars['String']>
    urlKeyFulltext?: Maybe<Scalars['String']>
    showDownloadApp?: Maybe<Scalars['Boolean']>
    showPayment?: Maybe<Scalars['Boolean']>
    showSocials?: Maybe<Scalars['Boolean']>
    showLanguageSelector?: Maybe<Scalars['Boolean']>
    urlIosApp?: Maybe<Scalars['String']>
    urlIosAppFulltext?: Maybe<Scalars['String']>
    urlAndroidApp?: Maybe<Scalars['String']>
    urlAndroidAppFulltext?: Maybe<Scalars['String']>
    newsletterHeading?: Maybe<Scalars['String']>
    newsletterHeadingFulltext?: Maybe<Scalars['String']>
    newsletterContent?: Maybe<Scalars['String']>
    newsletterContentFulltext?: Maybe<Scalars['String']>
}

export interface WhereFooterJbfo {
    urlKey?: Maybe<Scalars['String']>
    urlKeyFulltext?: Maybe<Scalars['String']>
    showDownloadApp?: Maybe<Scalars['Boolean']>
    showPayment?: Maybe<Scalars['Boolean']>
    showSocials?: Maybe<Scalars['Boolean']>
    showLanguageSelector?: Maybe<Scalars['Boolean']>
    urlIosApp?: Maybe<Scalars['String']>
    urlIosAppFulltext?: Maybe<Scalars['String']>
    urlAndroidApp?: Maybe<Scalars['String']>
    urlAndroidAppFulltext?: Maybe<Scalars['String']>
    newsletterHeading?: Maybe<Scalars['String']>
    newsletterHeadingFulltext?: Maybe<Scalars['String']>
    newsletterContent?: Maybe<Scalars['String']>
    newsletterContentFulltext?: Maybe<Scalars['String']>
}

export interface WhereFooterJustbrands {
    urlKey?: Maybe<Scalars['String']>
    urlKeyFulltext?: Maybe<Scalars['String']>
    showDownloadApp?: Maybe<Scalars['Boolean']>
    showPayment?: Maybe<Scalars['Boolean']>
    showSocials?: Maybe<Scalars['Boolean']>
    showLanguageSelector?: Maybe<Scalars['Boolean']>
    urlIosApp?: Maybe<Scalars['String']>
    urlIosAppFulltext?: Maybe<Scalars['String']>
    urlAndroidApp?: Maybe<Scalars['String']>
    urlAndroidAppFulltext?: Maybe<Scalars['String']>
    newsletterHeading?: Maybe<Scalars['String']>
    newsletterHeadingFulltext?: Maybe<Scalars['String']>
    newsletterContent?: Maybe<Scalars['String']>
    newsletterContentFulltext?: Maybe<Scalars['String']>
}

export interface WhereFooterMonstersale {
    urlKey?: Maybe<Scalars['String']>
    urlKeyFulltext?: Maybe<Scalars['String']>
    showDownloadApp?: Maybe<Scalars['Boolean']>
    showPayment?: Maybe<Scalars['Boolean']>
    showSocials?: Maybe<Scalars['Boolean']>
    showLanguageSelector?: Maybe<Scalars['Boolean']>
    urlIosApp?: Maybe<Scalars['String']>
    urlIosAppFulltext?: Maybe<Scalars['String']>
    urlAndroidApp?: Maybe<Scalars['String']>
    urlAndroidAppFulltext?: Maybe<Scalars['String']>
    newsletterHeading?: Maybe<Scalars['String']>
    newsletterHeadingFulltext?: Maybe<Scalars['String']>
    newsletterContent?: Maybe<Scalars['String']>
    newsletterContentFulltext?: Maybe<Scalars['String']>
}

export interface WhereFooterPme {
    urlKey?: Maybe<Scalars['String']>
    urlKeyFulltext?: Maybe<Scalars['String']>
    showDownloadApp?: Maybe<Scalars['Boolean']>
    showPayment?: Maybe<Scalars['Boolean']>
    showSocials?: Maybe<Scalars['Boolean']>
    showLanguageSelector?: Maybe<Scalars['Boolean']>
    urlIosApp?: Maybe<Scalars['String']>
    urlIosAppFulltext?: Maybe<Scalars['String']>
    urlAndroidApp?: Maybe<Scalars['String']>
    urlAndroidAppFulltext?: Maybe<Scalars['String']>
    newsletterHeading?: Maybe<Scalars['String']>
    newsletterHeadingFulltext?: Maybe<Scalars['String']>
    newsletterContent?: Maybe<Scalars['String']>
    newsletterContentFulltext?: Maybe<Scalars['String']>
}

export interface WhereFooterPmeoutlet {
    urlKey?: Maybe<Scalars['String']>
    urlKeyFulltext?: Maybe<Scalars['String']>
    showDownloadApp?: Maybe<Scalars['Boolean']>
    showPayment?: Maybe<Scalars['Boolean']>
    showSocials?: Maybe<Scalars['Boolean']>
    showLanguageSelector?: Maybe<Scalars['Boolean']>
    urlIosApp?: Maybe<Scalars['String']>
    urlIosAppFulltext?: Maybe<Scalars['String']>
    urlAndroidApp?: Maybe<Scalars['String']>
    urlAndroidAppFulltext?: Maybe<Scalars['String']>
    newsletterHeading?: Maybe<Scalars['String']>
    newsletterHeadingFulltext?: Maybe<Scalars['String']>
    newsletterContent?: Maybe<Scalars['String']>
    newsletterContentFulltext?: Maybe<Scalars['String']>
}

export interface WhereFooterVanguard {
    urlKey?: Maybe<Scalars['String']>
    urlKeyFulltext?: Maybe<Scalars['String']>
    showDownloadApp?: Maybe<Scalars['Boolean']>
    showPayment?: Maybe<Scalars['Boolean']>
    showSocials?: Maybe<Scalars['Boolean']>
    showLanguageSelector?: Maybe<Scalars['Boolean']>
    urlIosApp?: Maybe<Scalars['String']>
    urlIosAppFulltext?: Maybe<Scalars['String']>
    urlAndroidApp?: Maybe<Scalars['String']>
    urlAndroidAppFulltext?: Maybe<Scalars['String']>
    newsletterHeading?: Maybe<Scalars['String']>
    newsletterHeadingFulltext?: Maybe<Scalars['String']>
    newsletterContent?: Maybe<Scalars['String']>
    newsletterContentFulltext?: Maybe<Scalars['String']>
}

export interface WhereGuidedSellingPage {
    urlKey?: Maybe<Scalars['String']>
    urlKeyFulltext?: Maybe<Scalars['String']>
    heading?: Maybe<Scalars['String']>
    headingFulltext?: Maybe<Scalars['String']>
    headingStyle?: Maybe<Scalars['String']>
    headingStyleFulltext?: Maybe<Scalars['String']>
    subheading?: Maybe<Scalars['String']>
    subheadingFulltext?: Maybe<Scalars['String']>
    subheadingStyle?: Maybe<Scalars['String']>
    subheadingStyleFulltext?: Maybe<Scalars['String']>
    tweakwiseFunnelId?: Maybe<Scalars['String']>
    tweakwiseFunnelIdFulltext?: Maybe<Scalars['String']>
    footerButtonLabel?: Maybe<Scalars['String']>
    footerButtonLabelFulltext?: Maybe<Scalars['String']>
    footerButtonStyle?: Maybe<Scalars['String']>
    footerButtonStyleFulltext?: Maybe<Scalars['String']>
    footerText?: Maybe<Scalars['String']>
    footerTextFulltext?: Maybe<Scalars['String']>
}

export interface WhereHeroBanner {
    storeview?: Maybe<Scalars['String']>
    storeviewFulltext?: Maybe<Scalars['String']>
    position?: Maybe<Scalars['String']>
    positionFulltext?: Maybe<Scalars['String']>
    size?: Maybe<Scalars['String']>
    sizeFulltext?: Maybe<Scalars['String']>
    headingfuss?: Maybe<Scalars['Boolean']>
    header?: Maybe<Scalars['String']>
    headerFulltext?: Maybe<Scalars['String']>
    headingVariant?: Maybe<Scalars['String']>
    headingVariantFulltext?: Maybe<Scalars['String']>
    headingElement?: Maybe<Scalars['String']>
    headingElementFulltext?: Maybe<Scalars['String']>
    subheading?: Maybe<Scalars['String']>
    subheadingFulltext?: Maybe<Scalars['String']>
    subheadingVariant?: Maybe<Scalars['String']>
    subheadingVariantFulltext?: Maybe<Scalars['String']>
    subheadingElement?: Maybe<Scalars['String']>
    subheadingElementFulltext?: Maybe<Scalars['String']>
    videoId?: Maybe<Scalars['String']>
    videoIdFulltext?: Maybe<Scalars['String']>
    mobileVideoId?: Maybe<Scalars['String']>
    mobileVideoIdFulltext?: Maybe<Scalars['String']>
}

export interface WhereJbfoFaqPage {
    urlKey?: Maybe<Scalars['String']>
    urlKeyFulltext?: Maybe<Scalars['String']>
    search?: Maybe<Scalars['Boolean']>
    seoTitle?: Maybe<Scalars['String']>
    seoTitleFulltext?: Maybe<Scalars['String']>
    seoDescription?: Maybe<Scalars['String']>
    seoDescriptionFulltext?: Maybe<Scalars['String']>
    seoKeywords?: Maybe<Scalars['String']>
    seoKeywordsFulltext?: Maybe<Scalars['String']>
    style?: Maybe<Scalars['String']>
    styleFulltext?: Maybe<Scalars['String']>
    /** head */
    headFulltext?: Maybe<Scalars['String']>
    noindex?: Maybe<Scalars['Boolean']>
    nofollow?: Maybe<Scalars['Boolean']>
}

export interface WhereJbfoLoginPage {
    urlKey?: Maybe<Scalars['String']>
    urlKeyFulltext?: Maybe<Scalars['String']>
    search?: Maybe<Scalars['Boolean']>
    seoTitle?: Maybe<Scalars['String']>
    seoTitleFulltext?: Maybe<Scalars['String']>
    seoDescription?: Maybe<Scalars['String']>
    seoDescriptionFulltext?: Maybe<Scalars['String']>
    seoKeywords?: Maybe<Scalars['String']>
    seoKeywordsFulltext?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
    titleFulltext?: Maybe<Scalars['String']>
    subTitle?: Maybe<Scalars['String']>
    subTitleFulltext?: Maybe<Scalars['String']>
    subHeading?: Maybe<Scalars['String']>
    subHeadingFulltext?: Maybe<Scalars['String']>
    /** content */
    contentFulltext?: Maybe<Scalars['String']>
}

export interface WhereJbfoPage {
    urlKey?: Maybe<Scalars['String']>
    urlKeyFulltext?: Maybe<Scalars['String']>
    search?: Maybe<Scalars['Boolean']>
    seoTitle?: Maybe<Scalars['String']>
    seoTitleFulltext?: Maybe<Scalars['String']>
    seoDescription?: Maybe<Scalars['String']>
    seoDescriptionFulltext?: Maybe<Scalars['String']>
    seoKeywords?: Maybe<Scalars['String']>
    seoKeywordsFulltext?: Maybe<Scalars['String']>
    style?: Maybe<Scalars['String']>
    styleFulltext?: Maybe<Scalars['String']>
    /** head */
    headFulltext?: Maybe<Scalars['String']>
    noindex?: Maybe<Scalars['Boolean']>
    nofollow?: Maybe<Scalars['Boolean']>
    authRequired?: Maybe<Scalars['Boolean']>
    pageBackgroundColor?: Maybe<Scalars['String']>
    pageBackgroundColorFulltext?: Maybe<Scalars['String']>
}

export interface WhereJustbrandsFaqPage {
    urlKey?: Maybe<Scalars['String']>
    urlKeyFulltext?: Maybe<Scalars['String']>
    search?: Maybe<Scalars['Boolean']>
    seoTitle?: Maybe<Scalars['String']>
    seoTitleFulltext?: Maybe<Scalars['String']>
    seoDescription?: Maybe<Scalars['String']>
    seoDescriptionFulltext?: Maybe<Scalars['String']>
    seoKeywords?: Maybe<Scalars['String']>
    seoKeywordsFulltext?: Maybe<Scalars['String']>
    style?: Maybe<Scalars['String']>
    styleFulltext?: Maybe<Scalars['String']>
    /** head */
    headFulltext?: Maybe<Scalars['String']>
    noindex?: Maybe<Scalars['Boolean']>
    nofollow?: Maybe<Scalars['Boolean']>
}

export interface WhereJustbrandsPage {
    urlKey?: Maybe<Scalars['String']>
    urlKeyFulltext?: Maybe<Scalars['String']>
    search?: Maybe<Scalars['Boolean']>
    seoTitle?: Maybe<Scalars['String']>
    seoTitleFulltext?: Maybe<Scalars['String']>
    seoDescription?: Maybe<Scalars['String']>
    seoDescriptionFulltext?: Maybe<Scalars['String']>
    seoKeywords?: Maybe<Scalars['String']>
    seoKeywordsFulltext?: Maybe<Scalars['String']>
    style?: Maybe<Scalars['String']>
    styleFulltext?: Maybe<Scalars['String']>
    /** head */
    headFulltext?: Maybe<Scalars['String']>
    noindex?: Maybe<Scalars['Boolean']>
    nofollow?: Maybe<Scalars['Boolean']>
    authRequired?: Maybe<Scalars['Boolean']>
    pageType?: Maybe<Scalars['String']>
    pageTypeFulltext?: Maybe<Scalars['String']>
    /** story_tag */
    storyTag?: Maybe<Scalars['String']>
    storyDescription?: Maybe<Scalars['String']>
    storyDescriptionFulltext?: Maybe<Scalars['String']>
    storyTitle?: Maybe<Scalars['String']>
    storyTitleFulltext?: Maybe<Scalars['String']>
    /** publication_field */
    publicationField?: Maybe<Scalars['Date']>
    /** publication_field */
    publicationFieldBefore?: Maybe<Scalars['Date']>
    /** publication_field */
    publicationFieldAfter?: Maybe<Scalars['Date']>
    pageBackgroundColor?: Maybe<Scalars['String']>
    pageBackgroundColorFulltext?: Maybe<Scalars['String']>
}

export interface WhereJustbrandsTag {
    tagId?: Maybe<Scalars['String']>
    tagIdFulltext?: Maybe<Scalars['String']>
    tagName?: Maybe<Scalars['String']>
    tagNameFulltext?: Maybe<Scalars['String']>
}

export interface WhereLoyaltyCard {
    style?: Maybe<Scalars['String']>
    styleFulltext?: Maybe<Scalars['String']>
    header?: Maybe<Scalars['String']>
    headerFulltext?: Maybe<Scalars['String']>
    buttonLabel?: Maybe<Scalars['String']>
    buttonLabelFulltext?: Maybe<Scalars['String']>
    buttonUrl?: Maybe<Scalars['String']>
    buttonUrlFulltext?: Maybe<Scalars['String']>
    buttonStyle?: Maybe<Scalars['String']>
    buttonStyleFulltext?: Maybe<Scalars['String']>
    /** usp */
    usp?: Maybe<Scalars['String']>
}

export interface WhereMemberUsp {
    storeview?: Maybe<Scalars['String']>
    storeviewFulltext?: Maybe<Scalars['String']>
    heading?: Maybe<Scalars['String']>
    headingFulltext?: Maybe<Scalars['String']>
    headingVariant?: Maybe<Scalars['String']>
    headingVariantFulltext?: Maybe<Scalars['String']>
    buttonText?: Maybe<Scalars['String']>
    buttonTextFulltext?: Maybe<Scalars['String']>
    buttonUrl?: Maybe<Scalars['String']>
    buttonUrlFulltext?: Maybe<Scalars['String']>
    usps?: Maybe<WhereMemberUspUsps>
}

export interface WhereMemberUsps {
    storeview?: Maybe<Scalars['String']>
    storeviewFulltext?: Maybe<Scalars['String']>
    type?: Maybe<Scalars['String']>
    typeFulltext?: Maybe<Scalars['String']>
    showPriviligesLogo?: Maybe<Scalars['Boolean']>
    heading?: Maybe<Scalars['String']>
    headingFulltext?: Maybe<Scalars['String']>
    headingVariant?: Maybe<Scalars['String']>
    headingVariantFulltext?: Maybe<Scalars['String']>
    content?: Maybe<Scalars['String']>
    contentFulltext?: Maybe<Scalars['String']>
    buttonText?: Maybe<Scalars['String']>
    buttonTextFulltext?: Maybe<Scalars['String']>
    buttonUrl?: Maybe<Scalars['String']>
    buttonUrlFulltext?: Maybe<Scalars['String']>
    buttonStyle?: Maybe<Scalars['String']>
    buttonStyleFulltext?: Maybe<Scalars['String']>
    button2Text?: Maybe<Scalars['String']>
    button2TextFulltext?: Maybe<Scalars['String']>
    button2Url?: Maybe<Scalars['String']>
    button2UrlFulltext?: Maybe<Scalars['String']>
    button2Style?: Maybe<Scalars['String']>
    button2StyleFulltext?: Maybe<Scalars['String']>
    mobile?: Maybe<Scalars['String']>
    mobileFulltext?: Maybe<Scalars['String']>
    tablet?: Maybe<Scalars['String']>
    tabletFulltext?: Maybe<Scalars['String']>
    desktop?: Maybe<Scalars['String']>
    desktopFulltext?: Maybe<Scalars['String']>
    usps?: Maybe<WhereMemberUspsUsps>
}

export interface WhereMemberUspsUsps {
    label?: Maybe<Scalars['String']>
    labelFulltext?: Maybe<Scalars['String']>
    icon?: Maybe<Scalars['String']>
    iconFulltext?: Maybe<Scalars['String']>
}

export interface WhereMemberUspUsps {
    label?: Maybe<Scalars['String']>
    labelFulltext?: Maybe<Scalars['String']>
    icon?: Maybe<Scalars['String']>
    iconFulltext?: Maybe<Scalars['String']>
}

export interface WhereMonstersaleFaqPage {
    urlKey?: Maybe<Scalars['String']>
    urlKeyFulltext?: Maybe<Scalars['String']>
    search?: Maybe<Scalars['Boolean']>
    seoTitle?: Maybe<Scalars['String']>
    seoTitleFulltext?: Maybe<Scalars['String']>
    seoDescription?: Maybe<Scalars['String']>
    seoDescriptionFulltext?: Maybe<Scalars['String']>
    seoKeywords?: Maybe<Scalars['String']>
    seoKeywordsFulltext?: Maybe<Scalars['String']>
    style?: Maybe<Scalars['String']>
    styleFulltext?: Maybe<Scalars['String']>
    /** head */
    headFulltext?: Maybe<Scalars['String']>
    noindex?: Maybe<Scalars['Boolean']>
    nofollow?: Maybe<Scalars['Boolean']>
}

export interface WhereMonstersaleLoginPage {
    urlKey?: Maybe<Scalars['String']>
    urlKeyFulltext?: Maybe<Scalars['String']>
    search?: Maybe<Scalars['Boolean']>
    seoTitle?: Maybe<Scalars['String']>
    seoTitleFulltext?: Maybe<Scalars['String']>
    seoDescription?: Maybe<Scalars['String']>
    seoDescriptionFulltext?: Maybe<Scalars['String']>
    seoKeywords?: Maybe<Scalars['String']>
    seoKeywordsFulltext?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
    titleFulltext?: Maybe<Scalars['String']>
    subTitle?: Maybe<Scalars['String']>
    subTitleFulltext?: Maybe<Scalars['String']>
    subHeading?: Maybe<Scalars['String']>
    subHeadingFulltext?: Maybe<Scalars['String']>
    /** content */
    contentFulltext?: Maybe<Scalars['String']>
}

export interface WhereMonstersalePage {
    urlKey?: Maybe<Scalars['String']>
    urlKeyFulltext?: Maybe<Scalars['String']>
    search?: Maybe<Scalars['Boolean']>
    seoTitle?: Maybe<Scalars['String']>
    seoTitleFulltext?: Maybe<Scalars['String']>
    seoDescription?: Maybe<Scalars['String']>
    seoDescriptionFulltext?: Maybe<Scalars['String']>
    seoKeywords?: Maybe<Scalars['String']>
    seoKeywordsFulltext?: Maybe<Scalars['String']>
    style?: Maybe<Scalars['String']>
    styleFulltext?: Maybe<Scalars['String']>
    /** head */
    headFulltext?: Maybe<Scalars['String']>
    noindex?: Maybe<Scalars['Boolean']>
    nofollow?: Maybe<Scalars['Boolean']>
    authRequired?: Maybe<Scalars['Boolean']>
    pageBackgroundColor?: Maybe<Scalars['String']>
    pageBackgroundColorFulltext?: Maybe<Scalars['String']>
}

export interface WherePage {
    urlKey?: Maybe<Scalars['String']>
    urlKeyFulltext?: Maybe<Scalars['String']>
    search?: Maybe<Scalars['Boolean']>
    seoTitle?: Maybe<Scalars['String']>
    seoTitleFulltext?: Maybe<Scalars['String']>
    seoDescription?: Maybe<Scalars['String']>
    seoDescriptionFulltext?: Maybe<Scalars['String']>
    seoKeywords?: Maybe<Scalars['String']>
    seoKeywordsFulltext?: Maybe<Scalars['String']>
    style?: Maybe<Scalars['String']>
    styleFulltext?: Maybe<Scalars['String']>
    /** head */
    headFulltext?: Maybe<Scalars['String']>
    noindex?: Maybe<Scalars['Boolean']>
    nofollow?: Maybe<Scalars['Boolean']>
    authRequired?: Maybe<Scalars['Boolean']>
    hideLayout?: Maybe<Scalars['Boolean']>
    pageType?: Maybe<Scalars['String']>
    pageTypeFulltext?: Maybe<Scalars['String']>
    tweakwiseFunnelId?: Maybe<Scalars['String']>
    tweakwiseFunnelIdFulltext?: Maybe<Scalars['String']>
    /** story_tag */
    storyTag?: Maybe<Scalars['String']>
    storyDescription?: Maybe<Scalars['String']>
    storyDescriptionFulltext?: Maybe<Scalars['String']>
    storyTitle?: Maybe<Scalars['String']>
    storyTitleFulltext?: Maybe<Scalars['String']>
    /** publication_field */
    publicationField?: Maybe<Scalars['Date']>
    /** publication_field */
    publicationFieldBefore?: Maybe<Scalars['Date']>
    /** publication_field */
    publicationFieldAfter?: Maybe<Scalars['Date']>
    pageBackgroundColor?: Maybe<Scalars['String']>
    pageBackgroundColorFulltext?: Maybe<Scalars['String']>
}

export interface WherePlpMemberUsps {
    storeview?: Maybe<Scalars['String']>
    storeviewFulltext?: Maybe<Scalars['String']>
    type?: Maybe<Scalars['String']>
    typeFulltext?: Maybe<Scalars['String']>
    heading?: Maybe<Scalars['String']>
    headingFulltext?: Maybe<Scalars['String']>
    headingVariant?: Maybe<Scalars['String']>
    headingVariantFulltext?: Maybe<Scalars['String']>
    content?: Maybe<Scalars['String']>
    contentFulltext?: Maybe<Scalars['String']>
    buttonText?: Maybe<Scalars['String']>
    buttonTextFulltext?: Maybe<Scalars['String']>
    buttonUrl?: Maybe<Scalars['String']>
    buttonUrlFulltext?: Maybe<Scalars['String']>
    buttonStyle?: Maybe<Scalars['String']>
    buttonStyleFulltext?: Maybe<Scalars['String']>
    usps?: Maybe<WherePlpMemberUspsUsps>
}

export interface WherePlpMemberUspsUsps {
    label?: Maybe<Scalars['String']>
    labelFulltext?: Maybe<Scalars['String']>
    icon?: Maybe<Scalars['String']>
    iconFulltext?: Maybe<Scalars['String']>
}

export interface WherePmeOutletFaqPage {
    urlKey?: Maybe<Scalars['String']>
    urlKeyFulltext?: Maybe<Scalars['String']>
    search?: Maybe<Scalars['Boolean']>
    seoTitle?: Maybe<Scalars['String']>
    seoTitleFulltext?: Maybe<Scalars['String']>
    seoDescription?: Maybe<Scalars['String']>
    seoDescriptionFulltext?: Maybe<Scalars['String']>
    seoKeywords?: Maybe<Scalars['String']>
    seoKeywordsFulltext?: Maybe<Scalars['String']>
    style?: Maybe<Scalars['String']>
    styleFulltext?: Maybe<Scalars['String']>
    /** head */
    headFulltext?: Maybe<Scalars['String']>
    noindex?: Maybe<Scalars['Boolean']>
    nofollow?: Maybe<Scalars['Boolean']>
}

export interface WherePmeOutletLoginPage {
    urlKey?: Maybe<Scalars['String']>
    urlKeyFulltext?: Maybe<Scalars['String']>
    search?: Maybe<Scalars['Boolean']>
    seoTitle?: Maybe<Scalars['String']>
    seoTitleFulltext?: Maybe<Scalars['String']>
    seoDescription?: Maybe<Scalars['String']>
    seoDescriptionFulltext?: Maybe<Scalars['String']>
    seoKeywords?: Maybe<Scalars['String']>
    seoKeywordsFulltext?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
    titleFulltext?: Maybe<Scalars['String']>
    subTitle?: Maybe<Scalars['String']>
    subTitleFulltext?: Maybe<Scalars['String']>
    subHeading?: Maybe<Scalars['String']>
    subHeadingFulltext?: Maybe<Scalars['String']>
    /** content */
    contentFulltext?: Maybe<Scalars['String']>
}

export interface WherePmeOutletPage {
    urlKey?: Maybe<Scalars['String']>
    urlKeyFulltext?: Maybe<Scalars['String']>
    search?: Maybe<Scalars['Boolean']>
    seoTitle?: Maybe<Scalars['String']>
    seoTitleFulltext?: Maybe<Scalars['String']>
    seoDescription?: Maybe<Scalars['String']>
    seoDescriptionFulltext?: Maybe<Scalars['String']>
    seoKeywords?: Maybe<Scalars['String']>
    seoKeywordsFulltext?: Maybe<Scalars['String']>
    style?: Maybe<Scalars['String']>
    styleFulltext?: Maybe<Scalars['String']>
    /** head */
    headFulltext?: Maybe<Scalars['String']>
    noindex?: Maybe<Scalars['Boolean']>
    nofollow?: Maybe<Scalars['Boolean']>
    authRequired?: Maybe<Scalars['Boolean']>
    pageBackgroundColor?: Maybe<Scalars['String']>
    pageBackgroundColorFulltext?: Maybe<Scalars['String']>
}

export interface WherePrivilegesPage {
    storeview?: Maybe<Scalars['String']>
    storeviewFulltext?: Maybe<Scalars['String']>
}

export interface WherePrivilegesPerks {
    storeview?: Maybe<Scalars['String']>
    storeviewFulltext?: Maybe<Scalars['String']>
    footerText?: Maybe<Scalars['String']>
    footerTextFulltext?: Maybe<Scalars['String']>
    buttonLabel?: Maybe<Scalars['String']>
    buttonLabelFulltext?: Maybe<Scalars['String']>
    buttonUrl?: Maybe<Scalars['String']>
    buttonUrlFulltext?: Maybe<Scalars['String']>
    buttonStyle?: Maybe<Scalars['String']>
    buttonStyleFulltext?: Maybe<Scalars['String']>
    perks?: Maybe<WherePrivilegesPerksPerks>
}

export interface WherePrivilegesPerksPerks {
    header?: Maybe<Scalars['String']>
    headerFulltext?: Maybe<Scalars['String']>
    subheader?: Maybe<Scalars['String']>
    subheaderFulltext?: Maybe<Scalars['String']>
    iconInactive?: Maybe<Scalars['String']>
    iconInactiveFulltext?: Maybe<Scalars['String']>
    iconActive?: Maybe<Scalars['String']>
    iconActiveFulltext?: Maybe<Scalars['String']>
}

export interface WherePrivilegesUsps {
    storeview?: Maybe<Scalars['String']>
    storeviewFulltext?: Maybe<Scalars['String']>
    contents?: Maybe<Scalars['String']>
    contentsFulltext?: Maybe<Scalars['String']>
    buttonLabel?: Maybe<Scalars['String']>
    buttonLabelFulltext?: Maybe<Scalars['String']>
    buttonUrl?: Maybe<Scalars['String']>
    buttonUrlFulltext?: Maybe<Scalars['String']>
    buttonStyle?: Maybe<Scalars['String']>
    buttonStyleFulltext?: Maybe<Scalars['String']>
    usps?: Maybe<WherePrivilegesUspsUsps>
}

export interface WherePrivilegesUspsUsps {
    label?: Maybe<Scalars['String']>
    labelFulltext?: Maybe<Scalars['String']>
    icon?: Maybe<Scalars['String']>
    iconFulltext?: Maybe<Scalars['String']>
}

export interface WherePromoinfo {
    storeview?: Maybe<Scalars['String']>
    storeviewFulltext?: Maybe<Scalars['String']>
    textMobile?: Maybe<Scalars['String']>
    textMobileFulltext?: Maybe<Scalars['String']>
    text?: Maybe<Scalars['String']>
    textFulltext?: Maybe<Scalars['String']>
    modalTitle?: Maybe<Scalars['String']>
    modalTitleFulltext?: Maybe<Scalars['String']>
    /** modal_content */
    modalContentFulltext?: Maybe<Scalars['String']>
}

export interface WherePsRegistrationBox {
    storeview?: Maybe<Scalars['String']>
    storeviewFulltext?: Maybe<Scalars['String']>
    accountHeading?: Maybe<Scalars['String']>
    accountHeadingFulltext?: Maybe<Scalars['String']>
    employeeName?: Maybe<Scalars['String']>
    employeeNameFulltext?: Maybe<Scalars['String']>
    employeeButtonText?: Maybe<Scalars['String']>
    employeeButtonTextFulltext?: Maybe<Scalars['String']>
    employeeButtonLink?: Maybe<Scalars['String']>
    employeeButtonLinkFulltext?: Maybe<Scalars['String']>
    heroTitle?: Maybe<Scalars['String']>
    heroTitleFulltext?: Maybe<Scalars['String']>
    orderTitle?: Maybe<Scalars['String']>
    orderTitleFulltext?: Maybe<Scalars['String']>
    orderDescription?: Maybe<Scalars['String']>
    orderDescriptionFulltext?: Maybe<Scalars['String']>
    orderButtonText?: Maybe<Scalars['String']>
    orderButtonTextFulltext?: Maybe<Scalars['String']>
    orderButtonLink?: Maybe<Scalars['String']>
    orderButtonLinkFulltext?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
    titleFulltext?: Maybe<Scalars['String']>
    description?: Maybe<Scalars['String']>
    descriptionFulltext?: Maybe<Scalars['String']>
    buttonText?: Maybe<Scalars['String']>
    buttonTextFulltext?: Maybe<Scalars['String']>
}

export interface WhereQueuePage {
    storeview?: Maybe<Scalars['String']>
    storeviewFulltext?: Maybe<Scalars['String']>
    heading?: Maybe<Scalars['String']>
    headingFulltext?: Maybe<Scalars['String']>
    description?: Maybe<Scalars['String']>
    descriptionFulltext?: Maybe<Scalars['String']>
}

export interface WhereSearchPage {
    storeview?: Maybe<Scalars['String']>
    storeviewFulltext?: Maybe<Scalars['String']>
}

export interface WhereSimpleForm {
    /** form_title */
    formTitleFulltext?: Maybe<Scalars['String']>
    /** form_subtitle */
    formSubtitleFulltext?: Maybe<Scalars['String']>
    formFields?: Maybe<WhereSimpleFormFormFields>
    buttonLabel?: Maybe<Scalars['String']>
    buttonLabelFulltext?: Maybe<Scalars['String']>
    buttonSubmitUrl?: Maybe<Scalars['String']>
    buttonSubmitUrlFulltext?: Maybe<Scalars['String']>
    /** form_footer */
    formFooterFulltext?: Maybe<Scalars['String']>
}

export interface WhereSimpleFormFormFields {
    inputLabel?: Maybe<Scalars['String']>
    inputLabelFulltext?: Maybe<Scalars['String']>
    formField?: Maybe<Scalars['String']>
    formFieldFulltext?: Maybe<Scalars['String']>
    inputPlaceholder?: Maybe<Scalars['String']>
    inputPlaceholderFulltext?: Maybe<Scalars['String']>
    inputType?: Maybe<Scalars['String']>
    inputTypeFulltext?: Maybe<Scalars['String']>
}

export interface WhereStorelocatorPage {
    storeview?: Maybe<Scalars['String']>
    storeviewFulltext?: Maybe<Scalars['String']>
    showStores?: Maybe<Scalars['Boolean']>
    brandListHeading?: Maybe<Scalars['String']>
    brandListHeadingFulltext?: Maybe<Scalars['String']>
    brandListSubheading?: Maybe<Scalars['String']>
    brandListSubheadingFulltext?: Maybe<Scalars['String']>
    showJbStores?: Maybe<Scalars['Boolean']>
    jbListHeading?: Maybe<Scalars['String']>
    jbListHeadingFulltext?: Maybe<Scalars['String']>
    jbListSubheading?: Maybe<Scalars['String']>
    jbListSubheadingFulltext?: Maybe<Scalars['String']>
}

export interface WhereStorePage {
    storeview?: Maybe<Scalars['String']>
    storeviewFulltext?: Maybe<Scalars['String']>
    urlKey?: Maybe<Scalars['String']>
    urlKeyFulltext?: Maybe<Scalars['String']>
    search?: Maybe<Scalars['Boolean']>
    srsId?: Maybe<Scalars['String']>
    srsIdFulltext?: Maybe<Scalars['String']>
    seoTitle?: Maybe<Scalars['String']>
    seoTitleFulltext?: Maybe<Scalars['String']>
    seoDescription?: Maybe<Scalars['String']>
    seoDescriptionFulltext?: Maybe<Scalars['String']>
    seoKeywords?: Maybe<Scalars['String']>
    seoKeywordsFulltext?: Maybe<Scalars['String']>
    /** head */
    headFulltext?: Maybe<Scalars['String']>
    noindex?: Maybe<Scalars['Boolean']>
    nofollow?: Maybe<Scalars['Boolean']>
}

export interface WhereTag {
    tagId?: Maybe<Scalars['String']>
    tagIdFulltext?: Maybe<Scalars['String']>
    tagName?: Maybe<Scalars['String']>
    tagNameFulltext?: Maybe<Scalars['String']>
}

export interface WhereUsps {
    usp?: Maybe<WhereUspsUsp>
}

export interface WhereUspsUsp {
    label?: Maybe<Scalars['String']>
    labelFulltext?: Maybe<Scalars['String']>
    icon?: Maybe<Scalars['String']>
    iconFulltext?: Maybe<Scalars['String']>
}

export interface WhereVanguardFaqPage {
    urlKey?: Maybe<Scalars['String']>
    urlKeyFulltext?: Maybe<Scalars['String']>
    search?: Maybe<Scalars['Boolean']>
    seoTitle?: Maybe<Scalars['String']>
    seoTitleFulltext?: Maybe<Scalars['String']>
    seoDescription?: Maybe<Scalars['String']>
    seoDescriptionFulltext?: Maybe<Scalars['String']>
    seoKeywords?: Maybe<Scalars['String']>
    seoKeywordsFulltext?: Maybe<Scalars['String']>
    style?: Maybe<Scalars['String']>
    styleFulltext?: Maybe<Scalars['String']>
    /** head */
    headFulltext?: Maybe<Scalars['String']>
    noindex?: Maybe<Scalars['Boolean']>
    nofollow?: Maybe<Scalars['Boolean']>
}

export interface WhereVanguardPage {
    urlKey?: Maybe<Scalars['String']>
    urlKeyFulltext?: Maybe<Scalars['String']>
    search?: Maybe<Scalars['Boolean']>
    seoTitle?: Maybe<Scalars['String']>
    seoTitleFulltext?: Maybe<Scalars['String']>
    seoDescription?: Maybe<Scalars['String']>
    seoDescriptionFulltext?: Maybe<Scalars['String']>
    seoKeywords?: Maybe<Scalars['String']>
    seoKeywordsFulltext?: Maybe<Scalars['String']>
    style?: Maybe<Scalars['String']>
    styleFulltext?: Maybe<Scalars['String']>
    /** head */
    headFulltext?: Maybe<Scalars['String']>
    noindex?: Maybe<Scalars['Boolean']>
    nofollow?: Maybe<Scalars['Boolean']>
    authRequired?: Maybe<Scalars['Boolean']>
    pageType?: Maybe<Scalars['String']>
    pageTypeFulltext?: Maybe<Scalars['String']>
    /** story_tag */
    storyTag?: Maybe<Scalars['String']>
    storyDescription?: Maybe<Scalars['String']>
    storyDescriptionFulltext?: Maybe<Scalars['String']>
    storyTitle?: Maybe<Scalars['String']>
    storyTitleFulltext?: Maybe<Scalars['String']>
    /** publication_field */
    publicationField?: Maybe<Scalars['Date']>
    /** publication_field */
    publicationFieldBefore?: Maybe<Scalars['Date']>
    /** publication_field */
    publicationFieldAfter?: Maybe<Scalars['Date']>
    pageBackgroundColor?: Maybe<Scalars['String']>
    pageBackgroundColorFulltext?: Maybe<Scalars['String']>
}

export interface WhereVanguardTag {
    tagId?: Maybe<Scalars['String']>
    tagIdFulltext?: Maybe<Scalars['String']>
    tagName?: Maybe<Scalars['String']>
    tagNameFulltext?: Maybe<Scalars['String']>
}

export interface WhereVisualFilter {
    cards?: Maybe<WhereVisualFilterCards>
}

export interface WhereVisualFilterCards {
    relativeUrl?: Maybe<Scalars['String']>
    relativeUrlFulltext?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
    titleFulltext?: Maybe<Scalars['String']>
    label?: Maybe<Scalars['String']>
    labelFulltext?: Maybe<Scalars['String']>
}

/** Contains a customer wish list. */
export interface Wishlist {
    __typename?: 'Wishlist'
    /** The unique ID for a `Wishlist` object. */
    id: Maybe<Scalars['ID']>
    /** @deprecated Use the `items_v2` field instead. */
    items: Maybe<Array<Maybe<WishlistItem>>>
    /** The number of items in the wish list. */
    itemsCount: Maybe<Scalars['Int']>
    /** An array of items in the customer's wish list. */
    itemsV2: Maybe<WishlistItems>
    /** An encrypted code that Magento uses to link to the wish list. */
    sharingCode: Maybe<Scalars['String']>
    /** The time of the last modification to the wish list. */
    updatedAt: Maybe<Scalars['String']>
}

/** Contains a customer wish list. */
export interface WishlistItemsV2Args {
    currentPage?: Maybe<Scalars['Int']>
    pageSize?: Maybe<Scalars['Int']>
}

/** Contains details about errors encountered when a customer added wish list items to the cart. */
export interface WishlistCartUserInputError {
    __typename?: 'WishlistCartUserInputError'
    /** An error code that describes the error encountered. */
    code: WishlistCartUserInputErrorType
    /** A localized error message. */
    message: Scalars['String']
    /** The unique ID of the `Wishlist` object containing an error. */
    wishlistId: Scalars['ID']
    /** The unique ID of the wish list item containing an error. */
    wishlistItemId: Scalars['ID']
}

/** A list of possible error types. */
export enum WishlistCartUserInputErrorType {
    PRODUCT_NOT_FOUND = 'PRODUCT_NOT_FOUND',
    NOT_SALABLE = 'NOT_SALABLE',
    INSUFFICIENT_STOCK = 'INSUFFICIENT_STOCK',
    UNDEFINED = 'UNDEFINED',
}

/** Contains details about a wish list item. */
export interface WishlistItem {
    __typename?: 'WishlistItem'
    /** The time when the customer added the item to the wish list. */
    addedAt: Maybe<Scalars['String']>
    /** The customer's comment about this item. */
    description: Maybe<Scalars['String']>
    /** The unique ID for a `WishlistItem` object. */
    id: Maybe<Scalars['Int']>
    /** Details about the wish list item. */
    product: Maybe<ProductInterface>
    /** The quantity of this wish list item */
    qty: Maybe<Scalars['Float']>
}

/** Defines the items to add to a wish list. */
export interface WishlistItemInput {
    /** An array of options that the customer entered. */
    entered_options?: Maybe<Array<Maybe<EnteredOptionInput>>>
    /** For complex product types, the SKU of the parent product. */
    parent_sku?: Maybe<Scalars['String']>
    /** The amount or number of items to add. */
    quantity: Scalars['Float']
    /** An array of strings corresponding to options the customer selected. */
    selected_options?: Maybe<Array<Maybe<Scalars['ID']>>>
    /** The SKU of the product to add. For complex product types, specify the child product SKU. */
    sku: Scalars['String']
}

/** The interface for wish list items. */
export interface WishlistItemInterface {
    /** The date and time the item was added to the wish list. */
    addedAt: Scalars['String']
    /** Custom options selected for the wish list item. */
    customizableOptions: Array<Maybe<SelectedCustomizableOption>>
    /** The description of the item. */
    description: Maybe<Scalars['String']>
    /** The unique ID for a `WishlistItemInterface` object. */
    id: Scalars['ID']
    /** Product details of the wish list item. */
    product: Maybe<ProductInterface>
    /** The quantity of this wish list item. */
    quantity: Scalars['Float']
}

/** Contains an array of items in a wish list. */
export interface WishlistItems {
    __typename?: 'WishlistItems'
    /** A list of items in the wish list. */
    items: Array<Maybe<WishlistItemInterface>>
    /** Contains pagination metadata. */
    pageInfo: Maybe<SearchResultPageInfo>
}

/** Defines updates to items in a wish list. */
export interface WishlistItemUpdateInput {
    /** Customer-entered comments about the item. */
    description?: Maybe<Scalars['String']>
    /** An array of options that the customer entered. */
    entered_options?: Maybe<Array<Maybe<EnteredOptionInput>>>
    /** The new amount or number of this item. */
    quantity?: Maybe<Scalars['Float']>
    /** An array of strings corresponding to options the customer selected. */
    selected_options?: Maybe<Array<Maybe<Scalars['ID']>>>
    /** The unique ID for a `WishlistItemInterface` object. */
    wishlist_item_id: Scalars['ID']
}

/** Deprecated: Use the `Wishlist` type instead. */
export interface WishlistOutput {
    __typename?: 'WishlistOutput'
    /**
     * An array of items in the customer's wish list
     * @deprecated Use the `Wishlist.items` field instead.
     */
    items: Maybe<Array<Maybe<WishlistItem>>>
    /**
     * The number of items in the wish list.
     * @deprecated Use the `Wishlist.items_count` field instead.
     */
    itemsCount: Maybe<Scalars['Int']>
    /**
     * When multiple wish lists are enabled, the name the customer assigns to the wishlist.
     * @deprecated This field is related to Commerce functionality and is always `null` in Open Source.
     */
    name: Maybe<Scalars['String']>
    /**
     * An encrypted code that links to the wish list.
     * @deprecated Use the `Wishlist.sharing_code` field instead.
     */
    sharingCode: Maybe<Scalars['String']>
    /**
     * The time of the last modification to the wish list.
     * @deprecated Use the `Wishlist.updated_at` field instead.
     */
    updatedAt: Maybe<Scalars['String']>
}

/** An error encountered while performing operations with WishList. */
export interface WishListUserInputError {
    __typename?: 'WishListUserInputError'
    /** A wish list-specific error code. */
    code: WishListUserInputErrorType
    /** A localized error message. */
    message: Scalars['String']
}

/** A list of possible error types. */
export enum WishListUserInputErrorType {
    PRODUCT_NOT_FOUND = 'PRODUCT_NOT_FOUND',
    UNDEFINED = 'UNDEFINED',
}
