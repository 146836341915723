import { useApolloClient } from '@apollo/client'
import { useSetCartId } from '@emico-hooks/cart-id'
import { useLoginTokenContext } from '@emico-hooks/login-token'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { wishlistIdVar } from '../../../wishlist/useWishlistId'

export const useLogOut = (redirectToHome: boolean = false) => {
    const loginTokenContext = useLoginTokenContext()
    const setCartId = useSetCartId()
    const client = useApolloClient()
    const { replace } = useHistory()

    return useCallback(async () => {
        loginTokenContext(undefined)
        wishlistIdVar(undefined)
        setCartId(undefined)
        client.stop()
        await client.clearStore()
        if (redirectToHome) {
            replace('/')
        }
    }, [client, replace, loginTokenContext, redirectToHome, setCartId])
}
